import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';

import './services/i18n';
import './services/yup';
import './style/sass/index.scss';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    // Sentry adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
});

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
