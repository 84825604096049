import { useEffect, useState } from 'react';
import { Box } from "@material-ui/core";
import { useHistory, useLocation } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useRouteMatch } from "react-router-dom";
import { connect, mapDispatchToProps, mapStateToProps } from '../../store/dispatchers';
import { useTheme } from '@material-ui/core/styles';

const Path = ({folders,companies,users}) => {
    const location = useLocation();
    const [locationList,setLocationList] = useState([]);
    const palette = useTheme().palette;
    let history = useHistory();

    let matchFolder = useRouteMatch('/folders/:id')
    let matchFolderCreate = useRouteMatch('/folders/create')
    let matchFolderEdit = useRouteMatch('/folders/:id/edit')
    let matchFolderSubfolder = useRouteMatch('/folders/:id/subfolders/:idSub')
    let matchFolderSubfolderCreate = useRouteMatch('/folders/:id/subfolders/create')
    let matchFolderSubfolderEdit = useRouteMatch('/folders/:id/subfolders/:idSub/edit')
    let matchFolderSubfolderValidate = useRouteMatch('/folders/:id/subfolders/:idSub/validateInvoice')

    let matchCompany = useRouteMatch('/companies/:id')
    let matchCompanyCreate = useRouteMatch('/companies/create')
    let matchCompanyedit = useRouteMatch('/companies/:id/edit')

    let matchUsers = useRouteMatch('/users/:id')
    let matchUsersCreate = useRouteMatch('/users/create')
    let matchUsersedit = useRouteMatch('/users/:id/edit')

    useEffect(()=>{
        let arr = location.pathname.split('/')
        arr.shift()
        setLocationList(locItemToObjectNavbar(arr));
        // eslint-disable-next-line
    },[location,folders,companies,users])

    const locItemToObjectNavbar = (arr) => {
        if(arr.length === 0)return [];
        if(arr[0] === "third-party-services"){
            return [
                {text:"Third-Party Services",link:"/third-party-services"}
            ]
        }

        if(arr[0] === "viewReports"){
            return [
                {text:"View Reports",link:"/viewReports"}
            ]
        }
        if(arr.length === 1)return arr.map(e=>{return {text:e,link:'/'+e}});
        

        if(arr[0] === "users"){
            if(matchUsersCreate && matchUsersCreate.isExact){
                return [
                    {text:"users",link:"/users"},
                    {text:"create",link:`/users/create`},
                ]
            }

            if(matchUsers && matchUsers.isExact){
                let foundUser = users.find(e=>e.id === +matchUsers.params.id)
                return [
                    {text:"users",link:"/users"},
                    {text:foundUser ? foundUser.first_name +" "+ foundUser.last_name : "?",link:`/users/${matchUsers.params.id}`},
                ]
            }

            if(matchUsersedit && matchUsersedit.isExact){
                let foundUser = users.find(e=>e.id === +matchUsers.params.id)
                return [
                    {text:"users",link:"/users"},
                    {text:foundUser ? foundUser.first_name +" "+ foundUser.last_name : "?",link:`/users/${matchUsers.params.id}`},
                    {text:"edit",link:`/users/${matchUsers.params.id}/edit`},
                ]
            }
        }

        if(arr[0] === "companies"){
            if(matchCompanyCreate && matchCompanyCreate.isExact){
                return [
                    {text:"companies",link:"/companies"},
                    {text:"create",link:`/companies/create`},
                ]
            }

            if(matchCompany && matchCompany.isExact){
                let foundCompany = companies.find(e=>e.id === +matchCompany.params.id)
                return [
                    {text:"companies",link:"/companies"},
                    {text:foundCompany ? (foundCompany.reference || foundCompany.name) : "?",link:`/companies/${matchCompany.params.id}`},
                ]
            }

            if(matchCompanyedit && matchCompanyedit.isExact){
                let foundCompany = companies.find(e=>e.id === +matchCompanyedit.params.id)
                return [
                    {text:"companies",link:"/companies"},
                    {text:foundCompany ? foundCompany.reference : "?",link:`/companies/${matchCompany.params.id}`},
                    {text:"edit",link:`/companies/${matchCompany.params.id}/edit`},
                ]
            }
        }

        if(arr[0] === "folders"){
            if(matchFolderCreate && matchFolderCreate.isExact){
                return [
                    {text:"folders",link:"/folders"},
                    {text:"create",link:`/folders/create`},
                ]
            }
            if(matchFolder){
                let fold = folders?.find(e=>e.id === +matchFolder.params.id)
                let firstLinks = [
                    {text:"folders",link:"/folders"},
                    {text:fold ? fold.code: "?",link:`/folders/${matchFolder.params.id}`},
                ]

                if(matchFolder && matchFolder.isExact){
                    return firstLinks;
                }

                if(matchFolderEdit && matchFolderEdit.isExact){
                    firstLinks.push({text:"edit",link:`/folders/${matchFolder.params.id}/edit`});
                    return firstLinks;
                }

                if(matchFolderSubfolderCreate && matchFolderSubfolderCreate.isExact){
                    firstLinks.push({text:"subfolders",link:`/folders/${matchFolder.params.id}`})
                    firstLinks.push({text:"create",link:`/folders/${matchFolder.params.id}/subfolders/create`})
                    return firstLinks;
                }

                if(matchFolderSubfolder && matchFolderSubfolder.isExact){
                    let subfold = fold ? fold?.subfolders?.find(e=>e.id === +matchFolderSubfolder.params.idSub) : null
                    firstLinks.push({text:"subfolders",link:`/folders/${matchFolderSubfolder.params.id}`})
                    firstLinks.push({text:subfold ? subfold.code : "?",link:`/folders/${matchFolderSubfolder.params.id}/subfolders/${+matchFolderSubfolder.params.idSub}`})
                    return firstLinks;
                }

                if(matchFolderSubfolderEdit && matchFolderSubfolderEdit.isExact){
                    let subfold = fold ? fold?.subfolders?.find(e=>e.id === +matchFolderSubfolder.params.idSub) : null
                    firstLinks.push({text:"subfolders",link:`/folders/${matchFolderSubfolder.params.id}`})
                    firstLinks.push({text:subfold ? subfold.code : "?",link:`/folders/${matchFolderSubfolder.params.id}/subfolders/${+matchFolderSubfolder.params.idSub}`})
                    firstLinks.push({text:"edit",link:`/folders/${matchFolderSubfolder.params.id}/subfolders/${+matchFolderSubfolder.params.idSub}/edit`})
                    return firstLinks;
                }

                if(matchFolderSubfolderValidate && matchFolderSubfolderValidate.isExact){
                    let subfold = fold ? fold?.subfolders?.find(e=>e.id === +matchFolderSubfolder.params.idSub) : null
                    firstLinks.push({text:"subfolders",link:`/folders/${matchFolderSubfolder.params.id}`})
                    firstLinks.push({text:subfold ? subfold.code : "?",link:`/folders/${matchFolderSubfolder.params.id}/subfolders/${+matchFolderSubfolder.params.idSub}`})
                    firstLinks.push({text:"validate",link:`/folders/${matchFolderSubfolder.params.id}/subfolders/${+matchFolderSubfolder.params.idSub}/validate`})
                    return firstLinks;
                }
            }
        }
        
        
        
        return arr.map(e=>{return {text:e,link:'/'}})
    }

    const renderLocation = (loc,index) => {
        return (
            <Box display="flex" flexDirection="row" key={index}>
                {index !== 0 && <MdKeyboardArrowRight style={{marginTop:"auto",marginLeft:"2px",marginRight:"2px",fontSize:"20px",color: palette.secondary.main}} />}
                <span className="link-path" style={{color: palette.secondary.main}} onClick={()=>history.push(loc.link)}>
                    {
                        (loc?.text || " ").charAt(0).toUpperCase() + (loc?.text || " ").slice(1)
                    }
                </span>
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="row">
            {locationList.map((loc,index)=>renderLocation(loc,index))}
        </Box>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Path);;