import { useTranslation } from "react-i18next";
import { MdCheck } from 'react-icons/md';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { defaultLanguage } from '../../services/i18n';

const useStyles = makeStyles(theme => ({
    root: focused => ({
        backgroundColor: focused ? theme.palette.admin.light : theme.palette.admin.main,
        '&:hover': {
            backgroundColor: focused ? theme.palette.admin.light : theme.palette.admin.dark,
        },
    }),
}));

const TranslationKeyButton = ({ focused, language, translationKey, onClick, style }) => {
    const { i18n } = useTranslation();
    const classes = useStyles(focused);

    return (
        <Box
            onClick={() => onClick(translationKey)}
            data-testid="translation-key-button"
            className={`translation-explorer-button ${classes.root}`}
            style={style}
        >
            <Box>
                { i18n.getResource(language, 'translation', translationKey) &&
                    <MdCheck color="green"/>
                }
            </Box>
            <Box>
                { i18n.getResource(defaultLanguage, 'translation', translationKey) }
            </Box>
        </Box>
    );
};

export default TranslationKeyButton;