import { useTheme } from "@material-ui/core";
import { MdAddCircle } from "react-icons/md";

const ButtonAddTask = ({OnClickFunction}) => {

    const palette = useTheme().palette;

    return (
        <>
            <MdAddCircle onClick={()=>OnClickFunction()} className="wbs-add-task-button" style={{fontSize:"25px",color:palette.primary.main, marginRight:"10px"}}/>
        </>
    )
}

export default ButtonAddTask;