import { useEffect, useState, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { getAllUsers } from '../../services/users';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
});

const IncarnationDialog = ({ open, onClose, classes }) => {
    const [ users, setUsers ] = useState([]);
    const { t } = useTranslation();
    const fetchUsers = useCallback(async () => {
        if(users.length === 0) {
            const allUsers = await getAllUsers();
            setUsers(allUsers.value);
        }
    }, [ users ]);
    useEffect(() => {
        if(open) {
            fetchUsers();
        }
    }, [ open, fetchUsers ]);

    let currentUsers = users.sort(function (a, b) {
        let la = a.last_name.toUpperCase(),
            lb = b.last_name.toUpperCase();
        let fa = a.first_name.toUpperCase(),
            fb = b.first_name.toUpperCase();
        /* console.log(fa,la,"||",fb,lb); */
        if (fa > fb ) {
            return 1;
        } else if (fa < fb) {
            return -1;
        }
    
        if (la > lb) {
            return 1;
        } else if (la < lb) {
            return -1;
        } else {
            return 0;
        }
    });
    
    return (
        <Dialog
            data-testid={`incarnation-dialog-` + open}
            open={open}
            onClose={() => onClose()}
            scroll="paper"
            className="incarnation-dialog no-select"
            classes={{
                paper: classes.root,
            }}
        >
            <DialogTitle>{ t('navbar.dropdown.options.incarnate-user', 'Incarnate a user') }</DialogTitle>      
            <DialogContent dividers>
                <List>
                    {
                        currentUsers.map(user => (
                            <ListItem button key={user.id} onClick={() => onClose(user)} className={`incarnation-dialog-user-button ${classes.userButton}`}>
                                <ListItemText>
                                     {user.first_name} {user.last_name}
                                </ListItemText>
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => onClose()}>{ t('button.cancel', 'Cancel') }</Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(IncarnationDialog);