import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: ({ noPadding, useMediumFontWeight }) => ({
        backgroundColor: theme.palette.white.dark,
        color: theme.palette.common.white,
        paddingTop: noPadding ? 5 : 20,
        paddingBottom: noPadding ? 5 : 20,
        fontWeight: useMediumFontWeight ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
    }),
}));

const TableHeadCell = ({ noPadding, useMediumFontWeight, ...props }) => {
    const classes = useStyles({ noPadding, useMediumFontWeight });
    return (
        <TableCell className={classes.root} {...props}/>
    );
}

export default TableHeadCell;