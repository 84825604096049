import { TableCell,TableRow,Table, Box, TableBody, Button, TableHead } from "@material-ui/core"
/* import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'; */
import IconButton from '@material-ui/core/IconButton';
import TableHeadCell from "../table/TableHeadCell";
import { MdDelete, MdModeEdit, MdCloudUpload } from 'react-icons/md';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { useTheme } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from "react";
import FormAutocomplete from "../form/FormAutocomplete";
//import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import moment from "moment";
import templateMethods from '../../store/modules/Template';
import DialogEditInvoice from './DialogEditInvoice';
import DialogUploadInvoice from './DialogUploadInvoice';
import SnackbarContext from "../../context/snackbar";
import DialogUploadFileInvoice from "./DialogUploadFileInvoice";
import DialogEditForecast from "./DialogEditForecast";
import DialogEditInvoiceIssued from './DialogEditInvoiceIssued';
import DialogEmitInvoice from './DialogEmitInvoice';
import DialogThirdParties from './DialogThirdParties';
import { Alert } from "@material-ui/lab";
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import { uppercaseFirst } from '../../utils/converters';
import {  MdExpandLess, MdExpandMore } from 'react-icons/md';
import Collapse from '@material-ui/core/Collapse';
import { set } from "date-fns";
import { useHistory, useParams } from "react-router";
import UserContext from "../../context/user";
const tableHeader = [
    {
        label:"Date d'émission"
    },
    {
        label:"Numéro de facture"
    },
    {
        label:'Type'
    },
    {
        label:'Montant HTVA'
    },{
        label:'TVA rate'
    },{
        label:'Montant TVAC'
    },{
        label:'WBS'
    },{
        label:'Description'
    },{
        label:'Commentaire interne'
    },{
        label:'Term_date'
    },{
        label:'Payment_date'
    },{
        label:'Actions'
    }
]
const InvoiceList = ({
    template,
    setTab, 
    companies, 
    subfolder,
    folder, 
    wbsPlannings, 
    removeInvoice,
    removeInvoiceDetail, 
    editInvoiceDetail,
    isIssued = false,
    emitInvoices,
    setInvoicingEncodingType,
    setCurrentInvoice,
    editInvoice,
    dowloadInvoiceFile,
    fetchSubfoldersInvoices,
    setCurrentInvoiceState,
    uploadInvoicesFile,
    sendToClearFacts,
    thirdParties,
    sendToBillit
}) => {
    const palette = useTheme().palette; 
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);
    const [commentsValues,setCommentsValues] = useState([]);
    // eslint-disable-next-line
    const [invoices,setInvoices] = useState([]);
    const [dialogOpen,setOpen] = useState(false);
    const [currentInvoiceNotIssued,setCurrentInvoiceNotIssued] = useState(null);
    const [editInvoiceOpen,setEditInvoiceOpen] = useState(false);
    const [openUploadInvoice,setOpenUploadInvoice] = useState(false);
    const [openUploadInvoiceFile,setOpenUploadInvoiceFile] = useState(false);
    const [editObject,setEditObject] = useState(null);
    const [openArrow,setOpenArrow] = useState(false);
    const [emitInvoicesOpen,setEmitInvoicesOpen] = useState(false);
    const history = useHistory();
    const { folderId,id } = useParams();
    const [invoiceEmittedId,setInvoiceEmittedId] = useState(null);
    const [openUploadThirdPartiesDialog,setOpenUploadThirdPartiesDialog] = useState(false);
    const [selectedInviceTirdPaties,setSelectedInviceTirdPaties] = useState(null);
    const { hasPermission } = useContext(UserContext);
    //const { t } = useTranslation();

    const form = useForm({
        defaultValues:{
            companyId:folder ? folder.end_user_company_id : null,
            invoice_type:'invoice_fr'
        }
    })

    useEffect(()=>{
        let invoicesListCopy = [];
        let type = null;
        let currentInvoiceNotIssuedInit = null;
        if(subfolder && subfolder.invoices &&  subfolder.invoices.length !== 0){
            subfolder.invoices.forEach(item=>{
                
                if(isIssued && item.status_type !== "paid" && item.status_type !== "emitted")return;
                if(!isIssued && (item.status_type === "paid" || item.status_type === "emitted"))return;
                type = item.invoice_type;
                currentInvoiceNotIssuedInit = item;
                invoicesListCopy.push(item);
                
            })
        }
        if(!isIssued){
            setInvoicingEncodingType(type)
            setCurrentInvoiceNotIssued(currentInvoiceNotIssuedInit);
            setCurrentInvoice(currentInvoiceNotIssuedInit);
        };
        
        setInvoices(invoicesListCopy.sort((a,b)=>new Date(b.issue_date).getTime() - new Date(a.issue_date).getTime()));
        // eslint-disable-next-line
    },[subfolder,isIssued])
    useEffect(()=>{
        if(currentInvoiceNotIssued !== null && !isIssued)setInvoices([currentInvoiceNotIssued])
        // eslint-disable-next-line
    },[currentInvoiceNotIssued])

    useEffect(()=>{
        form.reset({companyId:folder ? folder.end_user_company_id : null})
        // eslint-disable-next-line
    },[folder])

    const removeInvoiceHandler = (invoice) => {
        removeInvoice(invoice.id).then(()=>{
            fetchSubfoldersInvoices({subfolder_id:subfolder.id,folder_id:folder.id})
        })
    }

    const removeInvoiceDetailsHandler = (invoiceDetail) => {
        removeInvoiceDetail(invoiceDetail.id).then(()=>{
            fetchSubfoldersInvoices({subfolder_id:subfolder.id,folder_id:folder.id})
        })
    }
    const editCommentDetail = (event,invoiceId) => {
        editInvoice({
            id:invoiceId,
            invoice:{
                comment:event.target.value
            }
        }).then(()=>{
            fetchSubfoldersInvoices({subfolder_id:subfolder.id,folder_id:folder.id})
        })
    }
    
    const onInputChange = (event,id) => {
        let valuesCommentCopy = [...commentsValues];
        let indexOfValue = valuesCommentCopy.map(e=>e.id).indexOf(id);
        valuesCommentCopy[indexOfValue].val = event.target.value;
        setCommentsValues(valuesCommentCopy)
    }
    
    const getInput = (invoice) => {
        let valuesCommentCopy = [...commentsValues];
        if(invoice.invoice_details.length === 0){
            return null
        }
        let value = valuesCommentCopy.find(e=>e.id === invoice.id);
        if(!value){
            value = {
                id:invoice.id,
                val:invoice.comment ? invoice.comment : ''
            }
            valuesCommentCopy.push(value);
            setCommentsValues(valuesCommentCopy)
        }
        return <input onChange={(event)=>onInputChange(event,invoice.id)} value={value.val} onBlur={(event)=>editCommentDetail(event,invoice.id)} className="invoices-list-input-comment" placeholder="Type here ..."></input>
    }
    
    const generatePdf = (formD,invoices) => {
        //update invoice.status_type => emitted for all invoices
        let formData = formD.control.getValues();
        let compId = formData.companyId;
        let type = formData.invoice_type;

        editInvoice({id:invoices[0].id,invoice:{recipient_company_id:compId}}).then(()=>{
            templateMethods.getInvoiceTemplate({id:invoices[0].id,template_type:type}).then((response)=>{
                setCurrentInvoiceState({...response,id:invoices[0].id,recipient_company_id:compId});
                history.push('/folders/'+folderId+'/subfolders/'+id+"/validateInvoice")
            })
        })

    }
    
    const emitInvoice = (form,invoices) => {
        let formData = form.control.getValues();
        let compId = formData.companyId;
        //let type = formData.invoice_type;
        emitInvoices({id:invoices[0].id,recipient_company_id:compId}).then(()=>{
            //get genereted pdf with template invoice
            fetchSubfoldersInvoices({subfolder_id:subfolder.id,folder_id:folder.id})
            openSuccessSnackbar('Report emitted !')
        })
    }
    
    const editInvoiceFromDialog = (val) => {
        editInvoice({
            id:val.id,
            invoice:val.data
        }).then((res)=>{
            fetchSubfoldersInvoices({subfolder_id:subfolder.id,folder_id:folder.id})
            setEditInvoiceOpen(false)
        })
    }

    const dowloadReport = (pdf_link) => {
        dowloadInvoiceFile(pdf_link).then(res=>{
            res.payload.text().then(resp=>{
                if(resp === "error"){
                    openErrorSnackbar('File not found on the server under /reports folder')
                }
                else{
                    const files = new File([res.payload],"invoice.pdf", { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(files);
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.download = pdf_link;
                    fileLink.click();
                }
            })
        })    
    }
    const format = (n) => {
        // 
          n = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(n);
          return n;/* .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
      }
    const renderHVATPriceInvoicing = (invoicing) => {
        
        let invoicingsCopy = [...invoicing.invoice_details];
        
        let tot = 0;
        invoicing.invoice_details?.forEach(invDet=>{
            tot += invDet.amount;
        })
        //tot = tot + vat;
        return format(tot)
        
    }
    const renderVATPrice = (invoicing) => {
        let invoicingsCopy = [...invoicing.invoice_details];
        let tot = 0;
        invoicing.invoice_details?.forEach(invDet=>{
            tot += Math.round(((invDet.amount*(validateVatRate(invDet.vat_rate/100)))+invDet.amount)*100)/100;
        })
        // {Math.round(((invForDetail.amount*0.21)+invForDetail.amount)*100)/100} €
        
        return format(tot) 
    }
    

    const onEditClick = (invDet,invoice,companies) => {
        setEditObject({
            id: invDet.id,
            invId: invoice.id,
            companyId: companies.find(e=>e.id === invoice.recipient_company_id)?.id,
            amount:invDet.amount,
            comment: invoice.comment,
            payment_date: invoice.payment_date,
            vat_rate:invDet.vat_rate,
        });
        setOpen(true);
    }
    const editAction = (val) => {
        editInvoiceDetail({
            id:editObject.id,
            invoiceDetail:{
                amount:val.data.amount,
                comment:val.data.comment,
                vat_rate:val.data.vat_rate,
            }
        }).then((res)=>{
            editInvoice({
                id: val.data.invId,
                invoice:{
                    comment: val.data.comment,
                    recipient_company_id: val.data.companyId
                }
            }).then((res) => { 
                fetchSubfoldersInvoices({subfolder_id:subfolder.id,folder_id:folder.id}).then((res)=>{
                    setOpen(false);
                })
            })

        })
    }

    const handleEditDoc = () => {
        setEditInvoiceOpen(false);
        setEmitInvoicesOpen(true);
    }

    const handleUploadThirdParties = (invoice) => {
        setOpenUploadThirdPartiesDialog(true);
        setSelectedInviceTirdPaties(invoice)
    }

    const handleClearfacts = (invoiceId) => {
        if(thirdParties.clearfacts){
            sendToClearFacts({id:invoiceId}).then(res=>{
                if(res.payload && res.payload.uuid){
                    openSuccessSnackbar('Invoice sent to ClearFacts !')
                }else{
                    openErrorSnackbar('Error sending to ClearFacts')
                }
            });
        }else{
            openErrorSnackbar('You need to enable ClearFacts to send invoices to ClearFacts (see thirdparties menu)')
        }
    }

    const handleBillit = (invoiceId) => {
        sendToBillit({id:invoiceId}).then(res=>{
            if(res.payload && res.payload.uuid && !res.payload.uuid.error){
                openSuccessSnackbar('Invoice sent to Billit !')
            }else{
                let message = 'Error sending to Billit';
                if(res.payload && res.payload.uuid && res.payload.uuid.error){
                    if(res.payload.uuid.message && res.payload.uuid.message.errors && res.payload.uuid.message.errors.length > 0)message = res.payload.uuid.message.errors[0].Description
                    else if(res.payload.uuid.message && typeof res.payload.uuid.message === 'string')message = res.payload.uuid.message
                }
                openErrorSnackbar(message)
            }
            fetchSubfoldersInvoices({subfolder_id:subfolder.id,folder_id:folder.id})
            setOpenUploadThirdPartiesDialog(false);
        });
    }

    const validateVatRate = (vatRate) => {
        return  vatRate === null || vatRate === undefined || vatRate === false ? 21 : (vatRate < 0 ? 0 : vatRate)
    }
   
    
    const RenderRow = (props) =>{
        const {invoice} = props
        const [open, setOpen] = useState(false);
        return (
            <>
            <TableRow >
                <TableCell >
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open  ? <MdExpandLess/> : <MdExpandMore/>}
                    </IconButton>
                </TableCell>
                <TableCell style={{width:"10%",fontWeight:'bolder'}}>
                    { invoice.issue_date ? invoice.issue_date && moment(invoice.issue_date).format('DD/MM/YYYY') : invoice.forecast_date}
                </TableCell>
                <TableCell style={{fontWeight:'bolder'}} >{invoice.number}</TableCell>
                <TableCell style={{fontWeight:'bolder'}}>{invoice.invoice_type ? uppercaseFirst(invoice.invoice_type) : ''}</TableCell>
                <TableCell style={{fontWeight:'bolder'}}>{renderHVATPriceInvoicing(invoice)}</TableCell>
                <TableCell/>
                <TableCell style={{fontWeight:'bolder'}}>{renderVATPrice(invoice)}</TableCell>
                <TableCell></TableCell>
                <TableCell style={{fontWeight:'bolder'}}>{invoice.memo ? invoice.memo : ''}</TableCell>
                <TableCell style={{fontWeight:'bolder'}}>{invoice.comment ? invoice.comment : ''}</TableCell>
                <TableCell style={{fontWeight:'bolder'}}>{invoice.term_date ? invoice.term_date && moment(invoice.term_date).format('DD/MM/YYYY') : ' '}</TableCell>
                <TableCell style={{textAlign:"center",fontWeight:'bolder'}}>
                    {invoice.payment_date && moment(invoice.payment_date).format('DD/MM/YYYY')}
                </TableCell> 
                <TableCell style={{width:"100px"}}>
                    <Box display="flex" flexDirection="row">
                    {   !isIssued && <Box style={{display:'flex'}}>
                        <MdModeEdit
                            style={{color:palette.primary.main}}
                            className="invoices-list-remove-button"
                            onClick={()=>{
                                setEditObject(invoice);
                                setEditInvoiceOpen(true);
                                setInvoiceEmittedId(null);
                            }}/>
                        {
                            hasPermission("invoice.delete") &&
                            <MdDelete style={{color:palette.primary.main}} className="invoices-list-remove-button" onClick={()=>{removeInvoiceHandler(invoice)}}/>
                        }
                    </Box>}
                    {
                        isIssued ? <MdModeEdit style={{color:palette.primary.main, width:'33%'}} className="invoices-list-remove-button" onClick={()=>{
                            setEditObject(invoice);
                            setEditInvoiceOpen(true);
                            setInvoiceEmittedId(invoice.id);
                        }}/> 
                        : " " 
                    }
                    {
                        invoice.pdf_link && <FaFileInvoiceDollar onClick={()=>dowloadReport(invoice.pdf_link)} style={{color:palette.primary.main, width:'33%'}} className="invoices-list-remove-button"/>
                    }
                    {
                        invoice.pdf_link && <Box width="33%" >
                            <MdCloudUpload onClick={()=>handleUploadThirdParties(invoice)} style={{color:palette.primary.main, width:'100%'}} className="invoices-list-remove-button"/>
                            <Box class="tooltiptext" display="flex" style={{position:"absolute",visibility:"hidden"}}>Send to ClearFact</Box>
                        </Box>
                    }
                    </Box>
                </TableCell>
            </TableRow>
            {
                !isIssued ?
                !open && invoice.invoice_details.map(invDet => {
                    return (
                        <TableRow key={invDet.id}>
                        <TableCell style={{textAlign:"center"}}/>
                                    <TableCell style={{textAlign:"center"}}/>
                                    <TableCell style={{textAlign:"center"}}/>
                                    <TableCell style={{textAlign:"center"}}/>
                                    <TableCell style={{fontWeight:'bolder'}}>{format(invDet.amount)} </TableCell>
                                    {/*<TableCell style={{fontWeight:'bolder'}}>{invDet.vat_rate ?  invDet.vat_rate + "%" : '21%'}</TableCell>*/}
                                    <TableCell style={{fontWeight:'bolder'}}>{validateVatRate(invDet.vat_rate)} %</TableCell>
                                    {/*<TableCell style={{fontWeight:'bolder'}}>{format(Math.round(((invDet.amount*(invDet.vat_rate ? (invDet.vat_rate/100) :0.21))+invDet.amount)*100)/100)}</TableCell>*/}
                            <TableCell style={{fontWeight:'bolder'}}>{format(Math.round(((invDet.amount*(validateVatRate(invDet.vat_rate)/100))+invDet.amount)*100)/100)}</TableCell>
                                        {invDet.wbs_task_id ? <TableCell style={{textAlign:"center",fontWeight:"bolder"}}>
                                      {wbsPlannings.find(e=>e.id === invDet.wbs_task_id)?.name}
                                    </TableCell> : <TableCell style={{textAlign:"center",fontWeight:'bolder'}}></TableCell>}
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell/> 
                                    <TableCell/>
                                    {/* <TableCell/> */}
                                    {!isIssued ? 
                                    <TableCell style={{textAlign:"center"}}>
                                        {
                                            hasPermission("invoice.update") &&
                                            <MdDelete style={{color:palette.primary.main}} className="invoices-list-remove-button" onClick={()=>{removeInvoiceDetailsHandler(invDet)}}/>
                                        }
                                    </TableCell> :
                                    <>
                                      {/*   <TableCell style={{textAlign:"center"}}>
                                        Company : {companies.find(e=>e.id === invoice.recipient_company_id)?.name}
                                    </TableCell> */}
                                    
                                    { isIssued && <TableCell style={{textAlign:"center"}}>
                                        <MdModeEdit onClick={()=>onEditClick(invDet,invoice,companies,false)} style={{color:palette.primary.main}} className="invoice-forecast-list-edit-icon"/>
                                    </TableCell>}
                                    </>
                                    }
                        </TableRow>
                    )
                }) : ''
            }
                {isIssued ?
                   open && invoice.invoice_details.map(invDet => {
                    return (
                        <TableRow key={invDet.id}>
                        <TableCell style={{textAlign:"center"}}/>
                                    <TableCell style={{textAlign:"center"}}/>
                                    <TableCell style={{textAlign:"center"}}/>
                                    <TableCell style={{textAlign:"center"}}/>
                                    <TableCell style={{fontWeight:'bolder'}}>{format(invDet.amount)} </TableCell>
                                    <TableCell style={{fontWeight:'bolder'}}>{validateVatRate(invDet.vat_rate)}</TableCell>
                                    <TableCell style={{fontWeight:'bolder'}}>{format(Math.round(((invDet.amount*(validateVatRate(invDet.vat_rate/100)))+invDet.amount)*100)/100)}</TableCell>
                                    {invDet.wbs_task_id ? <TableCell style={{textAlign:"center",fontWeight:"bolder"}}>
                                      {wbsPlannings.find(e=>e.id === invDet.wbs_task_id)?.name}
                                    </TableCell> : <TableCell style={{textAlign:"center",fontWeight:'bolder'}}></TableCell>}
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell/> 
                                    <TableCell/>
                                    {/* <TableCell/> */}
                                    {!isIssued ? 
                                    <TableCell style={{textAlign:"center"}}>
                                        <MdDelete style={{color:palette.primary.main}} className="invoices-list-remove-button" onClick={()=>{removeInvoiceDetailsHandler(invDet)}}/>
                                    </TableCell> :
                                    <>
                                        { isIssued && <TableCell style={{textAlign:"center"}}>
                                            <MdModeEdit onClick={()=>onEditClick(invDet,invoice,companies,true)} style={{color:palette.primary.main}} className="invoice-forecast-list-edit-icon"/>
                                        </TableCell>}
                                    </>
                                    }
                        </TableRow>
                    )
                }) : ''
                }

            </>
        )
    }
    return (
        <>
        <Box style={{paddingBottom:'1rem'}}>  
            <Table size="small" style={{width:"100%"}}>
                <TableHead >
                    <TableRow >
                        <TableHeadCell ></TableHeadCell>
                        {   
                            tableHeader.map((header,index)=>{ 
                                return <TableHeadCell key={index} >{header.label}</TableHeadCell>
                            })
                        }
                    </TableRow>
                </TableHead>
            
                <TableBody style={{width:"100%"}} className="invoice-forecast-list-table">
                  
                 {
                        invoices.map((invoice)=>{
                           return(<>
                           <RenderRow key={invoice.id} invoice={invoice}/></>)
                        })
                 }
                </TableBody>
            </Table>
        </Box>
            {!isIssued ? <Box display="flex" flexDirection="row" width="100%">
                {invoices.length !== 0 ? <FormProvider {...form}>
                    <Box display="flex" flexDirection="row" width="100%" padding="0px 30px" justifyContent="space-between">
                        <Box display="flex" flex={0.2}>
                           <Button onClick={()=>{setEmitInvoicesOpen(true)}} color="primary" style={{display:"flex",whiteSpace:"nowrap",width:"100%",height:"50%",minHeight:"40px",marginTop:"auto",marginRight:"20px"}} variant="outlined">Emit Invoice</Button>
                        </Box>
                    </Box>
                </FormProvider> : 
                <Box display="flex" flex={0.2}>
                    { hasPermission("admin.read") && <Button onClick={()=>{setOpenUploadInvoice(true)}} color="primary" style={{display:"flex",whiteSpace:"nowrap",width:"100%",marginTop:"auto"}} variant="outlined">Upload invoice</Button>}
                </Box>
                }
            </Box> : null}
            { dialogOpen && <DialogEditInvoiceIssued companies={companies} editAction={(val)=>editAction(val)} editObject={editObject} open={dialogOpen} setOpen={(val)=>setOpen(val)}/> }
            <DialogEditInvoice open={editInvoiceOpen} setOpen={(val)=>setEditInvoiceOpen(val)} editAction={(val) => editInvoiceFromDialog(val)} editObject={editObject} invoiceEmittedId={invoiceEmittedId} handleEditDoc={handleEditDoc}/>
            <DialogUploadInvoice goInvoiceList={()=>setTab(1)} subfolderId={subfolder.id} open={openUploadInvoice} setOpen={(val)=>setOpenUploadInvoice(val)}/>
            <DialogUploadFileInvoice goInvoiceList={()=>setTab(0)} open={openUploadInvoiceFile} setOpen={(val)=>setOpenUploadInvoiceFile(val)} folderId={folder.id} subfolderId={subfolder.id} subfolder={subfolder} invoiceId={invoices.length > 0 ? invoices[0].id : null}/>
            {emitInvoicesOpen && <DialogEmitInvoice 
                open={emitInvoicesOpen} setOpen={(val)=>setEmitInvoicesOpen(val)}
                template={template} invoices={invoiceEmittedId ? [editObject] :invoices} folder={folder} subfolder={subfolder} companies={companies}
                generatePdf={generatePdf} emitInvoice={emitInvoice} fetchSubfoldersInvoices={fetchSubfoldersInvoices} uploadInvoicesFile={uploadInvoicesFile}
                invoiceEmittedId={invoiceEmittedId}
            />}
            {openUploadThirdPartiesDialog&&<DialogThirdParties open={openUploadThirdPartiesDialog} setOpen={(val)=>setOpenUploadThirdPartiesDialog(val)} invoice={selectedInviceTirdPaties} handleClearFact={handleClearfacts} handleBillit={handleBillit}/>}
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)