import { TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white,
        },
        '&$active': {
            color: theme.palette.common.white,
        },
    },
    active: {},
    icon: {
        color: 'inherit !important',
    },
}));

const WhiteTableSortLabel = (props) => {
    const classes = useStyles();
    return (
        <TableSortLabel {...props} classes={classes}/>
    );
};

export default WhiteTableSortLabel;