import { Box } from '@material-ui/core';
import MONTH from '../../../enums/month';
import TableHeadCell from '../../table/TableHeadCell';
import moment from 'moment';


const renderMonth = (numberOfMonth,startDate,t) => {
    const monthArray = Array(numberOfMonth).fill(0).map((x,i)=>(i+startDate.getMonth())%12)
    return monthArray.map((month,i)=>{
        let year = moment(startDate).add(i,"month").year()
        return (
            <TableHeadCell key={month}>
                <Box display="flex" justifyContent="center">
                    {t('list.month.'+MONTH[month],MONTH[month])}<br/>{year}
                </Box>
            </TableHeadCell>
        )
    })
}

export {
    renderMonth
}