import { useCallback, useState, useMemo, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Card from '../../card/Card';
import ConsultTextField from '../../form/ConsultTextField';
import FormCategoryHeader from '../../form/FormCategoryHeader';
import FormRow from '../../form/FormRow';
import FormAutocomplete from '../../form/FormAutocomplete';
import FormTable from '../../form/FormTable';
import FormDatePicker from '../../form/FormDatePicker';
import FormNumberField from '../../form/FormNumberField';
import FormSelect from '../../form/FormSelect';
import FormTextField from '../../form/FormTextField';
import { mapFieldKeys } from '../../../utils/converters';
import { connect, mapStateToProps, mapDispatchToProps } from '../../../store/dispatchers';
import useYupResolver from '../../../hooks/useYupResolver';
import { handleResponse } from '../../../utils/http';
import SnackbarContext from '../../../context/snackbar';
import FormattedNumber from '../../FormattedNumber';

import {
    subfolderSchema as schema,
    subfolderAssociateSchema as associateSchema,
    subfolderBudgetSchema as budgetSchema,
    subfolderBankGuaranteeSchema as bankGuaranteeSchema,
    subfolderCompetitorSchema as competitorSchema,
    subfolderSubcontractorSchema as subcontractorSchema,
} from '../../../services/yup';
import moment from 'moment';
import CompetitorDialog from './CompetitorDialog';
import { Box } from '@material-ui/core';

const SubfoldersCreate = ({
    folders,
    companies,
    parameters,
    addSubfolder,
    addSubfolderAssociate,
    addSubfolderBankGuarantee,
    addSubfolderBudget,
    addSubfolderCompetitor,
    addSubfolderExtraInfoOffer,
    addSubfolderExtraInfoProspect,
    addSubfolderSubcontractor,
    users,
    wbsAll,
    fetchWbsPlanning,
    parametersRelations,
    fetchCompanies
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [ folder ] = useState(folders.find(f => f.id.toString() === id));
    const code = useMemo(() => (folder.code ?? ''), [ folder ]);
    const [ associates, setAssociates ] = useState([]);
    const [ bankGuarantees, setBankGuarantees ] = useState([])
    const [ budgets, setBudgets ] = useState([]);
    const [ subcontractors, setSubcontractors ] = useState([]);
    const [ competitors, setCompetitors ] = useState([]);
    const { openSuccessSnackbar, openErrorSnackbar, openWarningSnackbar } = useContext(SnackbarContext);
    const [ loading, setLoading ] = useState(false);
    const [selectedRelations, setSelectedRelations] = useState([]);
    const [paramsList, setParamsList] = useState(parametersRelations?.service_workpackage?.default?.service_subworkpackage?.map(p => p.type) ||[]);
    const [competitorDialogOpen, setCompetitorDialogOpen] = useState(false);
    const [fetchingCompanies, setFetchingCompanies] = useState(false);

    useEffect(()=>{
        setSelectedRelations(parametersRelations.service_workpackage ? parametersRelations.service_workpackage : {})
    },[parametersRelations])

    const onChangeTypeTarget = (targetValue) => {
        let toReturn = [];
        if(selectedRelations[targetValue] && selectedRelations[targetValue].service_subworkpackage && selectedRelations[targetValue].service_subworkpackage.length){
            toReturn = selectedRelations[targetValue].service_subworkpackage.map(item=>item.type);
        }
        setParamsList(toReturn);
    }

    const getSubfolderRank = () => {
        let rank = 0;
        if(folder && folder.subfolders){
            for(const subfold of folder.subfolders){
                if(subfold.rank >= rank){
                    rank = (subfold.rank +1)
                }
            }
        }
        return rank
    }

    // SUBFOLDER
    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            // Subfolder
            rank:getSubfolderRank(),
            status_type: '',
            name: '',
            project_leader:'',
            code: code,
            // Subfolder extra info
            mission_start: null,
            temporary_reception_date: null,
            final_reception_date: null,
            good_execution_attest_date: null,
            bank_guarantee: false,
            associative_mission: false,
            subcontracting: false,
            total_work_amount: '',
            service_workpackage_type: 'default',
            service_subworkpackage_type: 'default',

            // Subfolder extra info prospect
            object: '',
            opportunity_amount_low: '',
            opportunity_amount_high: '',
            source_type: '',
            comment: '',
            prospection_date: null,
            // Subfolder extra info offer
            submission_done: false,
            submission_date: null,
            submission_hour: null,
            go_to_tender_date: null,
            submission_result_date: null,
            tendering_mode_type: 'private',
            offer_amount: '',
            submission_result_type: '',
            order_intake: null,
            submission_comment: '',
            refusal_type: '',
            refusal_comment: '',
        },
    });

    const subfolderType = form.watch('subfolder_type');
    const showOffer = subfolderType === 'O2 Tendering';
    const showProspect = subfolderType === 'O1 Prospection';
    const showBankGuarantees = form.watch('bank_guarantee');
    const showAssociates = form.watch('associative_mission');
    const showSubcontractors = form.watch('subcontracting');

    const watchMissionStartDate = form.watch('mission_start');
    const watchTemporaryReceptionDate = form.watch('temporary_reception_date');
    const watchFinalReceptionDate = form.watch('final_reception_date');
    const watchGoodExecutionAttestDate = form.watch('good_execution_attest_date');

    // BUDGET
    const budgetResolver = useYupResolver(budgetSchema);
    const budgetForm = useForm({
        resolver: budgetResolver,
        defaultValues: {
            budget_type: '',
            amount: '',
            number_blocks:'',
            year:'',
            comment:''
        },
    });

    const handleBudgetSubmit = useCallback(budget => {
        setBudgets([ ...budgets, budget ]);
        budgetForm.reset();
        budgetForm.clearErrors();
    }, [ budgets, budgetForm ]);

    const handleRemoveBudget = useCallback(toRemove => {
        setBudgets(budgets.filter(budget => budget !== toRemove));
    }, [ budgets ]);

    // BANK GUARANTEE
    const bankGuaranteeResolver = useYupResolver(bankGuaranteeSchema);
    const bankGuaranteeForm = useForm({
        resolver: bankGuaranteeResolver,
        defaultValues: {
            amount: null,
            constitution_date: null,
            organism_reference: '',
            release_date: null,
        },
    });

    const handleBankGuaranteeSubmit = useCallback(bankGuarantee => {
        setBankGuarantees([ ...bankGuarantees, bankGuarantee ]);
        bankGuaranteeForm.reset();
        bankGuaranteeForm.clearErrors();
    }, [ bankGuarantees, bankGuaranteeForm ]);

    const handleRemoveBankGuarantee = useCallback(toRemove => {
        setBankGuarantees(bankGuarantees.filter(bankGuarantee => bankGuarantee !== toRemove))
    }, [ bankGuarantees ]);

    // ASSOCIATE
    const associateResolver = useYupResolver(associateSchema);
    const associateForm = useForm({
        resolver: associateResolver,
        defaultValues: {
            associate_role_type: '',
            shares_percentage: null,
        },
    });

    const handleAssociateSubmit = useCallback(associate => {
        setAssociates([ ...associates, associate ]);
        associateForm.reset();
        associateForm.clearErrors();
    }, [ associates, associateForm ]);

    const handleRemoveAssociate = useCallback(toRemove => {
        setAssociates(associates.filter(associate => associate !== toRemove));
    }, [ associates ]);

    // SUBCONTRACTOR
    const subcontractorResolver = useYupResolver(subcontractorSchema);
    const subcontractorForm = useForm({
        resolver: subcontractorResolver,
        defaultValues: {
            role: '',
            contract_type: '', // Régie ou Forfaitaire
            contract_amount: '',
        },
    });

    const handleSubcontractorSubmit = useCallback(subcontractor => {

        setSubcontractors([ ...subcontractors, subcontractor ]);
        subcontractorForm.reset();
        subcontractorForm.clearErrors();
    }, [ subcontractors, subcontractorForm ]);

    const handleRemoveSubcontractor = useCallback(toRemove => {
        setSubcontractors(subcontractors.filter(subcontractor => subcontractor !== toRemove));
    }, [ subcontractors ]);

    // COMPETITORS
    const competitorResolver = useYupResolver(competitorSchema);
    const competitorForm = useForm({
        resolver: competitorResolver,
        defaultValues: {
            company_id: undefined,
            name: '',
            price: '',
            final_rating: '',
            comment: '',
        },
    });

    const handleCompetitorSubmit = useCallback(competitor => {
        setCompetitors([ ...competitors, competitor ]);
        competitorForm.reset();
        competitorForm.clearErrors();
        setCompetitorDialogOpen(false);
    }, [ competitors, competitorForm ]);

    const handleRemoveCompetitor = useCallback(toRemove => {
        setCompetitors(competitors.filter(competitor => competitor !== toRemove));
    }, [ competitors ]);

    const handleUnhandledError = useCallback((message, value) => {
        setLoading(false);
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
    }, [ t, openErrorSnackbar ]);

    const handleProspectSuccess = useCallback(async (subfolderId, competitors, budgets, bankGuarantees, associates, subcontractors) => {
        if(showOffer && competitors.length > 0) {
            const result = await addSubfolderCompetitor({
                ...competitors[0],
                subfolder_id: subfolderId,
            });
            handleResponse(result.payload, null, () => handleProspectSuccess(subfolderId, competitors.slice(1), budgets, bankGuarantees, associates, subcontractors), handleUnhandledError);
        } else if (budgets.length > 0) {
            const result = await addSubfolderBudget({
                ...budgets[0],
                subfolder_id: subfolderId,
            });
            handleResponse(result.payload, null, () => handleProspectSuccess(subfolderId, competitors, budgets.slice(1), bankGuarantees, associates, subcontractors), handleUnhandledError);
        } else if (bankGuarantees.length > 0) {
            const result = await addSubfolderBankGuarantee({
                ...bankGuarantees[0],
                subfolder_id: subfolderId,
            });
            handleResponse(result.payload, null, () => handleProspectSuccess(subfolderId, competitors, budgets, bankGuarantees.slice(1), associates, subcontractors), handleUnhandledError);
        } else if (associates.length > 0) {
            const result = await addSubfolderAssociate({
                ...associates[0],
                subfolder_id: subfolderId,
            });
            handleResponse(result.payload, null, () => handleProspectSuccess(subfolderId, competitors, budgets, bankGuarantees, associates.slice(1), subcontractors), handleUnhandledError);
        } else if (subcontractors.length > 0) {
            const result = await addSubfolderSubcontractor({
                ...subcontractors[0],
                subfolder_id: subfolderId,
            });
            handleResponse(result.payload, null, () => handleProspectSuccess(subfolderId, competitors, budgets, bankGuarantees, associates, subcontractors.slice(1)), handleUnhandledError);
        } else {
            setLoading(false);
            openSuccessSnackbar(t('success.subfolder_created', 'Subfolder successfully created'));
            history.push(`/folders/${id}/subfolders/${subfolderId}`)
        }
    }, [ t, id, history, showOffer, openSuccessSnackbar, addSubfolderCompetitor, addSubfolderBudget, addSubfolderBankGuarantee, addSubfolderAssociate, addSubfolderSubcontractor, handleUnhandledError ]);

    const handleOfferSuccess = useCallback(async (subfolderId, mappedValues) => {
        if(showProspect) {
            const result = await addSubfolderExtraInfoProspect({
                ...mappedValues,
                subfolder_id: subfolderId,
            });
            return handleResponse(result.payload, form, () => handleProspectSuccess(subfolderId, competitors, budgets, bankGuarantees, associates, subcontractors), handleUnhandledError);
        }
        handleProspectSuccess(subfolderId, competitors, budgets, bankGuarantees, associates, subcontractors);
    }, [ form, competitors, budgets, bankGuarantees, associates, subcontractors, showProspect, addSubfolderExtraInfoProspect, handleProspectSuccess, handleUnhandledError ]);

    const handleSuccess = useCallback(async (subfolderId, mappedValues) => {
        if(showOffer) {
            const result = await addSubfolderExtraInfoOffer({
                ...mappedValues,
                subfolder_id: subfolderId,
            });
            return handleResponse(result.payload, form, () => handleOfferSuccess(subfolderId, mappedValues), handleUnhandledError,(message,value)=>handleWarningOffer(message,value.id,mappedValues));
        }
        await handleOfferSuccess(subfolderId, mappedValues);
    }, [ form, showOffer, addSubfolderExtraInfoOffer, handleOfferSuccess, handleUnhandledError ]);

    const handleWarningOffer = useCallback(async (message, subfolderId,mappedValues) => {
        if(showProspect) {
            const result = await addSubfolderExtraInfoProspect({
                ...mappedValues,
                subfolder_id: subfolderId,
            });
            return handleResponse(result.payload, form, () => handleProspectSuccess(subfolderId, competitors, budgets, bankGuarantees, associates, subcontractors), handleUnhandledError);
        }
        openWarningSnackbar(t('warning.' + message));
        await handleProspectSuccess(subfolderId, competitors, budgets, bankGuarantees, associates, subcontractors);
    })


    const handleWarning = useCallback(async (message, subfolderId,mappedValues) => {
        if(showOffer) {
            const result = await addSubfolderExtraInfoOffer({
                ...mappedValues,
                subfolder_id: subfolderId,
            });
            return handleResponse(result.payload, form, () => handleOfferSuccess(subfolderId, mappedValues), handleUnhandledError);
        }
        handleOfferSuccess(subfolderId, mappedValues);
        openWarningSnackbar(t('warning.' + message));
        console.warn(message, subfolderId);
        // eslint-disable-next-line
    }, [ t, history, openWarningSnackbar,showProspect,showOffer ]);

    const handleSubmit = useCallback(async (values) => {
        setLoading(true);
        const mappedValues = mapFieldKeys(values);
        const result = await addSubfolder({
            subFolder: {
                ...mappedValues,
                folder_id: parseInt(id),
                rank:getSubfolderRank()
            },
            subFolderExtraInfo: {
                ...mappedValues,
            },
            wbsId:wbsAll.length > 0 ? wbsAll[0].id : null,
        })
        if(wbsAll?.length > 0){
            fetchWbsPlanning(wbsAll[0].id)
        }
        handleResponse(result.payload, form, (value) => handleSuccess(value.id, mappedValues), handleUnhandledError,(message,value)=>handleWarning(message,value.id,mappedValues));
        // eslint-disable-next-line
    }, [ id, code, form, addSubfolder, handleSuccess, handleUnhandledError,handleWarning,showProspect,wbsAll,fetchWbsPlanning ]);

    return (
        <Card
            title={ t('subfolders.title.create-a-subfolder', 'Create a subfolder') }
            doneLabel={ t('button.submit', 'Submit') }
            onDoneClick={form.handleSubmit(handleSubmit)}
            loading={loading}
        >
            <FormProvider {...form}>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormCategoryHeader smallMarginTop>
                        { t('subfolders.subfolder-information', 'Subfolder information') }
                    </FormCategoryHeader>
                    <FormRow>
                        <ConsultTextField
                            name="folder"
                            label={t('forms.fields.subfolder.folder.label', 'Folder')}
                            value={folder.name}
                        />
                        <FormNumberField
                            name="total_work_amount"
                            label={t('forms.fields.subfolder.total-work-amount.label', 'Total Work Amount')}
                            min={0}
                            max={9999999999.99}
                            endAdornment="€"
                        />
                    </FormRow>
                    <ConsultTextField
                        name="rank"
                        label={t('forms.fields.subfolder.rank.label', 'Code rank')}
                        value={folder.lead_code+'/'+getSubfolderRank()}
                    />
                    <FormTextField
                        name="code"
                        label={t('forms.fields.subfolder.code.label', 'Code')}
                    />
                    <FormSelect
                        name="status_type"
                        label={t('forms.fields.subfolder.status.label', 'Status')}
                        options={parameters.subfolder_status}
                        mapOptionToText={val=>t('parameters.subfolder_status.'+val,val)}
                    />
                    <FormAutocomplete
                        name="customer_company_id"
                        label={t('forms.fields.subfolder.customer.label', 'Customer')}
                        options={companies}
                        mapOptionToValue={company => company.id}
                        mapOptionToText={company => company.name}
                        allowNull
                        refreshClick={()=>{
                            setFetchingCompanies(true);
                            fetchCompanies().then(()=>{
                                setFetchingCompanies(false);
                            })
                        }}
                        refreshLoading={fetchingCompanies}
                    />
                    <FormTextField
                        name="name"
                        label={t('forms.fields.subfolder.name.label', 'Name')}
                    />
                    <FormSelect
                        name="subfolder_type"
                        label={t('forms.fields.subfolder.type.label', 'Subfolder type')}
                        options={parameters.subfolder_type}
                        mapOptionToText={val=>t('parameters.subfolder_status.'+val,val)}
                    />
                    <FormAutocomplete
                        name="project_leader"
                        label={t('forms.fields.subfolder.project_leader.label', 'Project leader')}
                        options={users}
                        mapOptionToValue={user => user.id}
                        mapOptionToText={user => user.user_user_extra_info?.code
                            ? `${user.user_user_extra_info.code} - ${user.first_name} ${user.last_name}`
                            : `${user.first_name} ${user.last_name}`
                        }
                        allowNull
                    />
                    <FormRow>
                        <FormSelect
                            name="service_workpackage_type"
                            label={t('forms.fields.subfolder.service_workpackage_type.label', 'Service Workpackage')}
                            options={parameters.service_workpackage}
                            mapOptionToText={val=>t('parameters.service_workpackage_type.'+val,val)}
                            changeEvent={(event)=>onChangeTypeTarget(event.target.value)}
                        />
                        <FormSelect
                            name="service_subworkpackage_type"
                            label={t('forms.fields.subfolder.service_subworkpackage_type.label', 'Service subworkpackage')}
                            options={paramsList}
                            mapOptionToText={val=>t('parameters.service_workpackage_type.'+val,val)}
                        />
                    </FormRow>

                    <FormRow>
                        <FormDatePicker
                            name="mission_start"
                            label={t('forms.fields.subfolder.mission-start.label', 'Mission start date')}
                            maxDate={watchTemporaryReceptionDate || watchFinalReceptionDate || watchGoodExecutionAttestDate}
                        />
                        <FormDatePicker
                            name="temporary_reception_date"
                            label={t('forms.fields.subfolder.temporary-reception-date.label', 'Temporary reception date')}
                            minDate={watchMissionStartDate}
                            maxDate={watchFinalReceptionDate || watchGoodExecutionAttestDate}
                        />
                    </FormRow>
                    <FormRow>
                        <FormDatePicker
                            name="final_reception_date"
                            label={t('forms.fields.subfolder.final-reception-date.label', 'Final reception date')}
                            minDate={watchTemporaryReceptionDate || watchMissionStartDate}
                            maxDate={watchGoodExecutionAttestDate}
                        />
                        <FormDatePicker
                            name="good_execution_attest_date"
                            label={t('forms.fields.subfolder.good-execution-attest-date.label', 'Good execution attest date')}
                            minDate={watchFinalReceptionDate || watchFinalReceptionDate || watchMissionStartDate}
                        />
                    </FormRow>
                    <FormRow>
                        <FormSelect
                            name="bank_guarantee"
                            label={t('forms.fields.subfolder.bank-guarantee.label', 'Bank guarantee?')}
                            options={[ true, false ]}
                            mapOptionToText={option => option ? 'Yes' : 'No'}
                        />
                        <FormSelect
                            name="subcontracting"
                            label={t('forms.fields.subfolder.subcontracting.label', 'Subcontracting?')}
                            options={[ true, false ]}
                            mapOptionToText={option => option ? 'Yes' : 'No'}
                        />
                    </FormRow>
                    <FormRow>
                        <FormSelect
                            name="associative_mission"
                            label={t('forms.fields.subfolder.associative-mission.label', 'Associative mission?')}
                            options={[ true, false ]}
                            mapOptionToText={option => option ? 'Yes' : 'No'}
                        />
                        { showAssociates ? (
                            <FormAutocomplete
                                name="association_company_id"
                                label={t('forms.fields.subfolder.association-name.label', 'Association name')}
                                options={companies}
                                mapOptionToValue={company => company.id}
                                mapOptionToText={company => company.name}
                                refreshClick={()=>{
                                    setFetchingCompanies(true);
                                    fetchCompanies().then(()=>{
                                        setFetchingCompanies(false);
                                    })
                                }}
                                refreshLoading={fetchingCompanies}
                            />
                        ) : (
                            <ConsultTextField
                                label={t('forms.fields.subfolder.association-name.label', 'Association name')}
                            />
                        ) }
                    </FormRow>
                    { showProspect && (
                        <>
                            <FormCategoryHeader>
                                { t('subfolders.prospect', 'Prospect') }
                            </FormCategoryHeader>
                            <FormTextField
                                name="object"
                                label={t('forms.fields.prospect.object.label', 'Object')}
                            />
                            <FormRow>
                                <FormNumberField
                                    name="opportunity_amount_low"
                                    label={t('forms.fields.prospect.opportunity-amount-low.label', 'Opportunity amount low')}
                                    min={0}
                                    max={9999999999.99}
                                    endAdornment="€"
                                />
                                <FormNumberField
                                    name="opportunity_amount_high"
                                    label={t('forms.fields.prospect.opportunity-amount-high.label', 'Opportunity amount high')}
                                    min={0}
                                    max={9999999999.99}
                                    endAdornment="€"
                                />
                            </FormRow>
                            <FormSelect
                                name="source_type"
                                label={t('forms.fields.prospect.source-type.label', 'Source')}
                                options={parameters.publication_source}
                                allowNull
                                mapOptionToText={val=>t('parameters.publication_source.'+val)}
                            />
                            <FormTextField
                                name="comment"
                                label={t('forms.fields.prospect.comment.label', 'Comment')}
                            />
                            <FormDatePicker
                                name="prospection_date"
                                label={t('forms.fields.prospect.prospection-date.label', 'Date')}
                            />
                        </>
                    ) }
                    { showOffer && (
                        <>
                            <FormCategoryHeader>
                                { t('subfolders.offer', 'Offer') }
                            </FormCategoryHeader>
                            <FormSelect
                                name="submission_done"
                                label={t('forms.fields.offer.submission-done.label', 'Submission done?')}
                                options={[ true, false ]}
                                mapOptionToText={option => option ? 'Yes' : 'No'}
                            />
                            <FormRow>
                                <FormDatePicker
                                    name="submission_date"
                                    label={t('forms.fields.offer.submission-date.label', 'Submission date')}
                                />
                                <FormNumberField
                                    name="submission_hour"
                                    label={t('forms.fields.offer.submission-hour.label', 'Submission hour')}
                                    min={0}
                                    max={24}
                                    endAdornment="H"
                                    notNullable
                                    decimalPlaces={0}
                                    noSeparator
                                />
                                <FormNumberField
                                    name="offer_amount"
                                    label={t('forms.fields.offer.offer-amount.label', 'Offer amount')}
                                    min={0}
                                    max={9999999999.99}
                                    endAdornment="€ HTVA"
                                    notNullable
                                />
                            </FormRow>
                            <FormDatePicker
                                name="go_to_tender_date"
                                label={t('forms.fields.offer.go-to-tender-date.label', 'Go to tender date')}
                            />
                            <FormSelect
                                    name="tendering_mode_type"
                                    label={t('forms.fields.offer.tendering_mode_type-done.label', 'Tendering mode')}
                                    options={parameters.tendering_mode}
                                    mapOptionToText={val=>t('parameters.tendering_mode_type.'+val,val)}
                            />
                            <FormDatePicker
                                name="submission_result_date"
                                label={t('forms.fields.offer.submission_result_date.label', 'Submission result date')}
                            />
                            <FormSelect
                                name="submission_result_type"
                                label={t('forms.fields.offer.submission-result-type.label', 'Result')}
                                options={parameters.submission_result_type}
                                allowNull
                                mapOptionToText={val=>t('parameters.submission_result_type.'+val)}
                            />
                            <FormNumberField
                                name="order_intake"
                                label={t('forms.fields.offer.order-intake.label', 'Order intake')}
                                min={0}
                                max={9999999999.99}
                                endAdornment="€ HTVA"
                            />
                            <FormTextField
                                name="submission_comment"
                                label={t('forms.fields.offer.submission-comment.label', 'Submission comment')}
                            />
                            <FormSelect
                                name="refusal_type"
                                label={t('forms.fields.offer.refusal-type.label', 'Refusal reason')}
                                options={parameters.refusal_type}
                                allowNull
                                mapOptionToText={val=>t('parameters.refusal_type.'+val)}
                            />
                            <FormTextField
                                name="refusal_comment"
                                label={t('forms.fields.offer.refusal-comment.label', 'Refusal comment')}
                            />
                        </>
                    ) }
                </form>
            </FormProvider>
            { showOffer && (
                <FormProvider {...competitorForm}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader>
                            { t('subfolders.competitors', 'Competitors') }
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.competitor.mandatary.label', 'Mandatary'),
                                t('forms.fields.competitor.price.label', 'Price'),
                                t('forms.fields.competitor.final-rating.label', 'Final rating'),
                                t('forms.fields.competitor.comment.label', 'Comment')
                            ]}
                            items={competitors}
                            mapToCellContents={item => [
                                companies.find(company => company.id === item.partner1_company_id).name,
                                item.price && <FormattedNumber value={item.price} suffix=" €"/>,
                                item.final_rating && <FormattedNumber value={item.final_rating} suffix="/100"/>,
                                item.comment,
                            ]}
                            formFields={[<Box></Box>, <Box></Box>, <Box></Box>, <Box></Box>]}
                            onAddClick={()=>setCompetitorDialogOpen(true)}
                            onRemoveClick={handleRemoveCompetitor}
                        />
                    </form>
                </FormProvider>
            ) }
            <FormProvider {...budgetForm}>
                <form style={{ display: 'flex', flexDirection: 'column' }}>

                    <FormCategoryHeader>
                        { t('subfolders.budget', 'Budget') }
                    </FormCategoryHeader>
                    <FormTable
                        columns={folder.project_type !== "internal_project" ?[
                            t('forms.fields.budget.budget-type.label', 'Budget group'),
                            t('forms.fields.budget.number-blocks.label', 'Amount'),
                            t('forms.fields.budget.comment.label', 'Comment'),
                        ]:[
                            t('forms.fields.budget.budget-type.label', 'Budget group'),
                            t('forms.fields.budget.number-blocks.label', 'Amount'),
                            t('forms.fields.budget.number_block.label', 'Heures'),
                            t('forms.fields.budget.year.label', 'Year'),
                            t('forms.fields.budget.comment.label', 'Comment'),
                        ]}
                        items={budgets}
                        mapToCellContents={item => folder.project_type !== "internal_project" ?[
                            item.budget_type,
                            <FormattedNumber value={item.amount} suffix=" €"/>,
                            item.comment
                        ]:[
                            item.budget_type,
                            <FormattedNumber value={item.amount} suffix=" €"/>,
                            <FormattedNumber value={item.number_blocks} suffix=""/>,
                            item.year,
                            item.comment
                        ]}
                        formFields={folder.project_type !== "internal_project" ?[
                            <FormSelect
                                name="budget_type"
                                options={parameters.employee_cost_category}
                                tableField
                                mapOptionToText={val=>t('parameters.employee_cost_category.'+val)}
                            />,
                            <FormNumberField
                                name="amount"
                                min={0}
                                max={99999.99}
                                endAdornment="€"
                                notNullable
                                tableField
                            />,
                            <FormTextField
                                name="comment"
                                tableField
                            />,
                        ] : [
                            <FormSelect
                                name="budget_type"
                                options={parameters.employee_cost_category}
                                tableField
                                mapOptionToText={val=>t('parameters.employee_cost_category.'+val)}
                            />,
                            <FormNumberField
                                name="amount"
                                min={0}
                                max={99999.99}
                                endAdornment="€"
                                notNullable
                                tableField
                            />,
                            <FormNumberField
                                name="number_blocks"
                                min={0}
                                max={99999.99}
                                endAdornment=""
                                notNullable
                                tableField
                            />,
                            <FormTextField
                                name="year"
                                tableField
                            />,
                            <FormTextField
                                name="comment"
                                tableField
                            />,
                        ]}
                        onAddClick={budgetForm.handleSubmit(handleBudgetSubmit)}
                        onRemoveClick={handleRemoveBudget}
                    />
                </form>
            </FormProvider>
            { showBankGuarantees && (
                <FormProvider {...bankGuaranteeForm}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader>
                            { t('subfolders.bank-guarantees', 'Bank guarantees') }
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.bank-guarantee.amount.label', 'Amount'),
                                t('forms.fields.bank-guarantee.constitution-date.label', 'Constitution date'),
                                t('forms.fields.bank-guarantee.organism-reference.label', 'Organism reference'),
                                t('forms.fields.bank-guarantee.release-date.label', 'Release date'),
                            ]}
                            items={bankGuarantees}
                            mapToCellContents={item => [
                                <FormattedNumber value={item.amount} suffix=" €"/>,
                                item.constitution_date && moment(item.constitution_date).format("DD/MM/YYYY"),
                                item.organism_reference,
                                item.release_date && moment(item.release_date).format("DD/MM/YYYY"),
                            ]}
                            formFields={[
                                <FormNumberField
                                    name="amount"
                                    min={0}
                                    max={9999999999.99}
                                    endAdornment="€"
                                    notNullable
                                    tableField
                                />,
                                <FormDatePicker
                                    name="constitution_date"
                                    disableFuture
                                    tableField
                                />,
                                <FormTextField
                                    name="organism_reference"
                                    tableField
                                />,
                                <FormDatePicker
                                    name="release_date"
                                    tableField
                                />,
                            ]}
                            onAddClick={bankGuaranteeForm.handleSubmit(handleBankGuaranteeSubmit)}
                            onRemoveClick={handleRemoveBankGuarantee}
                        />
                    </form>
                </FormProvider>
            ) }
            { showAssociates && (
                <FormProvider {...associateForm}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader>
                            { t('subfolders.associates', 'Associates') }
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.associate.name.label', 'Name'),
                                t('forms.fields.associate.role-in-association.label', 'Role in the association'),
                                t('forms.fields.associate.shares.label', 'Shares'),
                            ]}
                            items={associates}
                            mapToCellContents={item => [
                                companies.find(company => company.id === item.associate_company_id).name,
                                item.associate_role_type,
                                <FormattedNumber value={item.shares_percentage} suffix="%"/>,
                            ]}
                            formFields={[
                                <FormAutocomplete
                                    name="associate_company_id"
                                    options={companies}
                                    mapOptionToValue={company => company.id}
                                    mapOptionToText={company => company.name}
                                    tableField
                                    refreshClick={()=>{
                                        setFetchingCompanies(true);
                                        fetchCompanies().then(()=>{
                                            setFetchingCompanies(false);
                                        })
                                    }}
                                    refreshLoading={fetchingCompanies}
                                />,
                                <FormSelect
                                    name="associate_role_type"
                                    options={parameters.association_role}
                                    tableField
                                    mapOptionToText={val=>t('parameters.association_role.'+val)}
                                />,
                                <FormNumberField
                                    name="shares_percentage"
                                    min={0}
                                    max={100}
                                    endAdornment="%"
                                    tableField
                                />,
                            ]}
                            onAddClick={associateForm.handleSubmit(handleAssociateSubmit)}
                            onRemoveClick={handleRemoveAssociate}
                        />
                    </form>
                </FormProvider>
            ) }
            { showSubcontractors && (
                <FormProvider {...subcontractorForm}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader>
                            { t('subfolders.subcontractors', 'Subcontractors') }
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.subcontractor.company.label', 'Subcontractor'),
                                t('forms.fields.subcontractor.role.label', 'Role'),
                                t('forms.fields.subcontractor.contract-type.label', 'Contract type'),
                                t('forms.fields.subcontractor.contractual-amount', 'Contractual amount'),
                            ]}
                            items={subcontractors}
                            mapToCellContents={item => [
                                companies.find(company => company.id === item.company_id).name,
                                item.role,
                                item.contract_type,
                                <FormattedNumber value={item.contract_amount} suffix=" €"/>,
                            ]}
                            formFields={[
                                <FormAutocomplete
                                    name="company_id"
                                    options={companies}
                                    mapOptionToValue={company => company.id}
                                    mapOptionToText={company => company.name}
                                    tableField
                                    refreshClick={()=>{
                                        setFetchingCompanies(true);
                                        fetchCompanies().then(()=>{
                                            setFetchingCompanies(false);
                                        })
                                    }}
                                    refreshLoading={fetchingCompanies}
                                />,
                                <FormTextField
                                    name="role"
                                    tableField
                                />,
                                <FormSelect
                                    name="contract_type"
                                    options={parameters.contract_type}
                                    tableField
                                    mapOptionToText={val=>t('parameters.contrat_type.'+val)}

                                />,
                                <FormNumberField
                                    name="contract_amount"
                                    min={0}
                                    max={9999999999.99}
                                    endAdornment="€"
                                    notNullable
                                    tableField
                                />
                            ]}
                            onAddClick={/* () => handleSubcontractorSubmit()  */ subcontractorForm.handleSubmit(handleSubcontractorSubmit)}
                            onRemoveClick={handleRemoveSubcontractor}
                        />
                    </form>
                </FormProvider>
             )}
             {competitorDialogOpen && <CompetitorDialog
                open={competitorDialogOpen}
                setOpen={setCompetitorDialogOpen}
                handleAdd={(v)=>handleCompetitorSubmit(v)}
                companies={companies || []}
             />}
        </Card>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubfoldersCreate);
