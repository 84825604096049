import { Box, Typography, withStyles } from '@material-ui/core';

import LoadingButton from '../form/LoadingButton';

import CardTitle from './CardTitle';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.admin.light,
    },
    grey: {
        backgroundColor: theme.palette.admin.main,
    },
});

const Card = ({
    children,
    className = '',
    header,
    title,
    explanation,
    doneLabel,
    doneDisabled,
    onDoneClick,
    hideButtons,
    leftAlignedButtons,
    rightAlignedButtons,
    isGrey = false,
    loading = false,
    classes,
    showAfterButton,
    noMarginTop = false,
    ...props
}) => {
    return (
        <Box className={`card ${className} ${isGrey ? classes.grey : classes.root}`} {...props}>
            <Box>
                <Box className="card-header">
                    { title &&
                        <CardTitle>{ title }</CardTitle>
                    }
                    { explanation &&
                        <Typography component="p" variant="body1" color="textPrimary">
                            { explanation }
                        </Typography>
                    }
                    { header }
                </Box>
                {noMarginTop ?
                <Box>
                    { children }
                 </Box>:
                <Box  className="card-body">
                    { children }
                </Box>}
            </Box>
            { !hideButtons &&
                <Box className="card-buttons">
                    <Box>
                        { leftAlignedButtons }
                    </Box>
                    <Box>
                        { rightAlignedButtons }
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={doneDisabled}
                            onClick={event => !doneDisabled && onDoneClick(event)}
                            loading={loading}
                        >
                            { doneLabel }
                        </LoadingButton>
                    </Box>
                </Box>
            }
            { showAfterButton && (showAfterButton)}
        </Box>
    );
};

export default withStyles(styles)(Card);