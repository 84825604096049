import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchTemplatePdf = createAsyncThunk(
    'template/fetchTemplatePdf',
    async () => {
        const response = await axios.get('/template/all');
        return response.data;
    },
);

const createTemplatePdf = createAsyncThunk(
    'template/createTemplatePdf',
    async (template) => {
        const response = await axios.post('/template/create',template);
        if(response.data.status === 'error') throw response.data.value;
        const res = await axios.get('/template/all');
        return res.data;
    },
);

const updateTemplatePdf = createAsyncThunk(
    'template/updateTemplatePdf',
    async ({id,template}) => {
        const response = await axios.put(`/template/update/${id}`,template);
        if(response.data.status === 'error') throw response.data.value;
        const res = await axios.get('/template/all');
        return res.data;
    },
);

export const templateMethods = {
    fetchTemplatePdf,
    createTemplatePdf,
    updateTemplatePdf
}

const pdfTemplateSlice = createSlice({
    name: 'template',
    initialState: [],
    extraReducers: {
        [fetchTemplatePdf.fulfilled]: (_state, action) => action.payload,
        [createTemplatePdf.fulfilled]: (_state, action) => action.payload,
        [updateTemplatePdf.fulfilled]: (_state, action) => action.payload,
    },
});

export default pdfTemplateSlice;