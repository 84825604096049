import { useCallback, useContext } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography, Button } from '@material-ui/core';

import axios from '../services/axios';
import yup from '../services/yup';
import SnackbarContext from '../context/snackbar';
import useYupResolver from '../hooks/useYupResolver';
import { handleResponse } from '../utils/http';
import ErrorMessage from '../enums/ErrorMessage';
import FormTextField from '../components/form/FormTextField';

const schema = yup.object().shape({
    password: yup.string().required(),
    password_confirm: yup.string().equalTo(yup.ref('password')).required(),
});

const ResetPassword = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [ { token } ] = useQueryParams({
        token: StringParam,
    });
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            password: '',
            password_confirm: '',
        }
    });

    const handleUnhandledError = useCallback((message, value) => {
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
        if(message === ErrorMessage.INVALID_TOKEN) {
            history.replace('/login');
        }
    }, [ t, history, openErrorSnackbar ]);

    const handleSuccess = useCallback(() => {
        openSuccessSnackbar(t('success.password_changed', 'Password successfully changed'));
        history.replace('/login');
    }, [ t, history, openSuccessSnackbar ]);

    const handleSubmit = useCallback(async (values) => {
        const response = await axios.post('/reset-password', {
            ...values,
            token,
        });
        handleResponse(response.data, form, handleSuccess, handleUnhandledError);
    }, [ form, token, handleSuccess, handleUnhandledError ]);

    return (
        <Box style={{ display: 'flex', height: '100vh', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography component="h1" variant="h4" style={{ marginBottom: 20 }}>
                Change Password
            </Typography>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)} style={{ display: 'flex', flexDirection: 'column', width: 600 }}>
                    <FormTextField
                        name="password"
                        label={t('forms.fields.user.password.label', 'Password')}
                        hideText
                    />
                    <FormTextField
                        name="password_confirm"
                        label={t('forms.fields.user.password-confirm.label', 'Confirm Password')}
                        hideText
                    />
                    <Button variant="contained" color="primary" type="submit" className="form-input">
                        { t('button.change-password', 'Change password') }
                    </Button>
                </form>
            </FormProvider>
        </Box>
    );
}

export default ResetPassword;