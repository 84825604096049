import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { Box, Typography } from "@material-ui/core"
import CONFIG_TOOLBAR from '../../utils/ckeditorConfig';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const TemplateCKEditor = (props) => {

    const [tempalteData, setTemplateData] = useState('');
    const [ready, setReady] = useState(false);

    useEffect(()=>{
        setTemplateData(props.template);
    },[props.template])

    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column">
                        <Typography variant="h5" component="h2">{t('template.body','Template Body')}</Typography>
                        <div id="toolbar-container" style={{maxWidth:"99%",border:"1px solid lightgrey",borderTop:"none",backgroundColor:"white",borderRadius:"3px"}}>
                            <CKEditor style={{width:"300px"}}
                                editor={ DecoupledEditor }
                                config={{
                                    toolbar:CONFIG_TOOLBAR
                                }}
                                data={ready ? tempalteData : 'loading'}
                                onReady={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        const toolbarContainer = document.querySelector( '#toolbar-container' );
                                        toolbarContainer.prepend( editor.ui.view.toolbar.element );
                                        setReady(true);
                                        window.editor = editor
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    props.setTemplate(data);
                                } }
                            />
                        </div>
                    </Box>
    )
}

export default TemplateCKEditor;