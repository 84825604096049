import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchKeywords = createAsyncThunk(
    'keywords/fetchKeywords',
    async (_, { rejectWithValue }) => {
        const response = await axios.get('/keywords/all');
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

export const keywordMethods = {
    fetchKeywords,
}

const keywordsSlice = createSlice({
    name: 'keywords',
    initialState: [],
    extraReducers: {
        [fetchKeywords.fulfilled]: (_state, action) => action.payload.value,
    },
});

export default keywordsSlice;