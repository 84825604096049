import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
});

const NotificationDialog = ({ open, notification, onClose, classes, users }) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [user,setUser] = useState({});

    useEffect(()=>{
        if(notification) {
            const userFound = users.find(u=>u.id === notification.created_by)
            if(userFound)setUser(userFound);
        }
    },[ users, notification ]);
   
    return (
        <Dialog
            open={open}
            onClose={onClose}
            onClick={event => event.stopPropagation()}
            classes={{
                root: 'notification-dialog',
                paper: classes.root,
            }}
        >
            <DialogTitle>
                { notification?.title ?? t('navbar.notifications.title', 'Notification Detail') }
            </DialogTitle>
            <DialogContent dividers>
                <Typography component="p" variant="body2" color="primary">
                    { notification && new Date(notification.created_at).toLocaleString(i18n.language) } - {user && user.first_name} {user && user.last_name}
                </Typography>
                <Typography component="p" variant="body1" className="notification-dialog-message">
                    { notification?.message }
                </Typography>
                {notification && notification.url && <Link onClick={()=>{
                    history.push(notification.url)
                    onClose()
                    }} to={notification.url}>Link</Link>}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    { t('button.close', 'Close') }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(NotificationDialog);