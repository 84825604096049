import { useTheme } from "@material-ui/core";
import { MdModeEdit } from "react-icons/md";

const ButtonEditTask = ({OnClickFunction}) => {

    const palette = useTheme().palette;

    return (
        <>
            <MdModeEdit onClick={()=>OnClickFunction()} className="wbs-add-task-button" style={{fontSize:"25px",color:palette.primary.main, marginRight:"10px"}}/>
        </>
    )
}

export default ButtonEditTask;