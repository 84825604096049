/**
 * Sets a cookie.
 * 
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} expiresInDays - The number of days before the cookie expires.
 */
export const setCookie = (name, value, expiresInDays) => {
    const date = new Date();
    date.setTime(date.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
};

/**
 * Returns a cookie.
 * 
 * @param {string} name - The name of the cookie.
 * @returns {string|null} The value of the cookie.
 */
export const getCookie = name => {
    const rawCookies = decodeURIComponent(document.cookie).split(';');
    for(const cookie of rawCookies) {
        const c = cookie.trimStart();
        if(c.indexOf(name + '=') === 0) {
            return c.substring(name.length + 1);
        }
    }
    return null;
};

/**
 * Returns all cookies.
 * 
 * @returns An object mapping each cookie's name to its value.
 */
export const getCookies = () => {
    const cookies = {};
    const rawCookies = decodeURIComponent(document.cookie).split(';');
    for(const cookie of rawCookies) {
        const c = cookie.trimStart();
        const i = c.indexOf('=');
        const name = c.slice(0, i);
        cookies[name] = c.slice(i + 1);
    }
    return cookies;
};