import { useCallback, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FaUserPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import Card from '../card/Card';
import ExplorerToolbar from '../explorer/ExplorerToolbar';
import ExplorerTable from '../explorer/ExplorerTable';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import UserContext from '../../context/user';

const UsersExplorer = ({ users, deleteUser }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [ filteredUsers, setFilteredUsers ] = useState(users);
    const [ activeUsersFilter, setActiveUsersFilter] = useState(true);
    const { hasPermission } = useContext(UserContext);

    const mapToRow = useCallback(user => [
        user.id,
        user.user_user_extra_info?.code ?? '',
        user.first_name,
        user.last_name,
    ], []);
    
    const getFilteredUsers = () => {

        const activeFiltered = filteredUsers.filter(user=>{
            if(user.is_super_admin)return false
            if(activeUsersFilter){
                if(user && user.user_user_extra_info &&  user.user_user_extra_info.exit_Date){
                    let now = new Date().getTime()
                    let date = new Date(user.user_user_extra_info.exit_Date).getTime();
                    if(date >= now)return true
                    return false
                }
            }
            return true;
        })
        return activeFiltered;
    }

    const usersFields = useMemo(() => users.map(mapToRow), [ users, mapToRow ]);
    
    const handleConsultClick = useCallback(user => {
        history.push('/users/' + user.id);
    }, [ history ]);

    const handleCreateClick = useCallback(() => {
        history.push('/users/create');
    }, [ history ]);

    const handleEditClick = useCallback(user => {
        history.push(`/users/${user.id}/edit`);
    }, [ history ]);

    const handleDeleteClick = useCallback(user => {
        deleteUser(user.id);
    }, [ deleteUser ]);

    const columns = [
        { name: t('table.users.columns.id', 'Number'), width: 25 },
        { name: t('table.users.columns.code', 'Trigramme'), width: 25 },
        { name: t('table.users.columns.first-name', 'First Name'), width: 25 },
        { name: t('table.users.columns.last-name', 'Last Name'), width: 25 },
    ];

    const columnsExcel = [
        { name: t('table.users.columns.idd', 'id')},//
        { name: t('table.users.columns.coded', 'code')},//
        { name: t('table.users.columns.first_name', 'first_name')},//
        { name: t('table.users.columns.last_name', 'last_name')},//
        { name: t('table.users.columns.birthdate', 'birthdate')},//
        { name: t('table.users.columns.blocks_availibility_per_week', 'blocks_availibility_per_week')},//
        { name: t('table.users.columns.absence_rate', 'absence_rate')},//
        { name: t('table.users.columns.cost_type', 'cost_type')},//
        { name: t('table.users.columns.degree', 'degree')},//
        { name: t('table.users.columns.direct_cost', 'direct_cost')},//
        { name: t('table.users.columns.entry_date', 'entry_date')},//
        { name: t('table.users.columns.exit_Date', 'exit_Date')},//
        { name: t('table.users.columns.external_folder_link', 'external_folder_link')},//
        { name: t('table.users.columns.function_type', 'function_type')},//
        { name: t('table.users.columns.graduation_date', 'graduation_date')},//
        { name: t('table.users.columns.last_evaluation_date', 'last_evaluation_date')},//
        { name: t('table.users.columns.next_evaluation_date', 'next_evaluation_date')},//
        { name: t('table.users.columns.number_holidays', 'number_holidays')},//
        { name: t('table.users.columns.payroll_type', 'payroll_type')},
        { name: t('table.users.columns.remarks', 'remarks')},
        { name: t('table.users.columns.seniority', 'seniority')},
        { name: t('table.users.columns.status_type', 'status_type')},
        { name: t('table.users.columns.email', 'email')},
    ];

    const mapToRowExcel = useCallback(user => [
        user.id,
        user.user_user_extra_info?.code ?? '',
        user.first_name,
        user.last_name,
        user.birthdate,
        user.blocks_availibility_per_week,
        user.user_user_extra_info?.absence_rate || '',
        user.user_user_extra_info?.cost_type || '',
        user.user_user_extra_info?.degree || '',
        user.user_user_extra_info?.direct_cost || '',
        user.user_user_extra_info?.entry_date || '',
        user.user_user_extra_info?.exit_Date || '',
        user.user_user_extra_info?.external_folder_link || '',
        user.user_user_extra_info?.function_type || '',
        user.user_user_extra_info?.graduation_date || '',
        user.user_user_extra_info?.last_evaluation_date || '',
        user.user_user_extra_info?.next_evaluation_date || '',
        user.user_user_extra_info?.number_holidays || '',
        user.user_user_extra_info?.payroll_type || '',
        user.user_user_extra_info?.remarks || '',
        user.user_user_extra_info?.seniority || '',
        user.user_user_extra_info?.status_type || '',
        user.user_logins?.find(login => login.login_type === "email")?.email || '',
    ], []);

    return (
        <Card
            hideButtons
            style={{ minHeight: 'calc(100vh - 114px)' }}
            header={
                <ExplorerToolbar
                    data={users}
                    dataFields={usersFields}
                    createLabel={t('button.create-a-user', 'Create a user')}
                    createIcon={<FaUserPlus/>}
                    onCreateClick={hasPermission('users.write') && hasPermission('user_extended.read') && handleCreateClick}
                    onFilter={setFilteredUsers}
                    mapToExcelRow={mapToRowExcel}
                    excelColumnNames={columnsExcel.map(column => column.name)}
                    excelSheetName={t('table.users.title', 'Users')}>
                    <FormControlLabel
                    style={{ whiteSpace: 'nowrap' }}
                    control={
                        <Checkbox
                            name="show_only_closed"
                            color="primary"
                            checked={activeUsersFilter}
                            onChange={() => setActiveUsersFilter(!activeUsersFilter)}
                        />
                    }
                    label={t('table.users.active_users', 'Active users')}
                />
            </ExplorerToolbar>
            }
        >
            <ExplorerTable
                columns={columns}
                items={getFilteredUsers()}
                mapToCellContents={mapToRow}
                onConsultClick={hasPermission('users.read') && handleConsultClick}
                onEditClick={hasPermission('users.update') && hasPermission('user_extended.read') && handleEditClick}
                onDeleteClick={hasPermission('users.delete') && hasPermission('user_extended.read') && handleDeleteClick}
            />
        </Card>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersExplorer);