import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilledInput, Box } from '@material-ui/core';

import { deaccentString } from '../../utils/converters';

import TemplateButtonKey from './TemplateButtonKey';

const TemplateExplorer = ({ keys, focusedKey, onKeyClick }) => {
    const { t } = useTranslation();
    const [ filter, setFilter ] = useState('');

    const filteredKeys = keys.filter(key => {
        const treatedFilter = deaccentString(filter.toLowerCase());
        if(!treatedFilter)return true;
        if(key.includes(treatedFilter)) return true;
        return false;
    });

    return (
        <>
            <FilledInput
                id="template-searchbar"
                inputProps={{ 
                    role: 'searchbox'
                }}
                name="template-searchbar"
                placeholder={t('translate.search', 'Search')}
                value={filter}
                onChange={event => setFilter(event.target.value)}
                fullWidth
                disableUnderline
                className="template-explorer-input"
            />
            <Box className="template-explorer-list">
                {
                    filteredKeys.map((key,index) => (
                        <TemplateButtonKey
                        key={key}
                        keyName={key}
                        focused={focusedKey === index ? true : false}
                        onClick={()=>onKeyClick(index)}
                        />
                    ))
                }
            </Box>
        </>
    );
};

export default TemplateExplorer;