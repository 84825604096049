import { useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    content: {
        color: theme.palette.text.secondary,
    },
}));

const AppDialog = ({ children, open, setOpen, title, cancelLabel, okLabel, onOk, onCancel, keepOpenAfterOk,fullWidth = false,maxWidth = false }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleCancel = useCallback(() => {
        setOpen(false);
        onCancel && onCancel();
    }, [ setOpen, onCancel ]);

    const handleOk = useCallback(() => {
        if(!keepOpenAfterOk)setOpen(false);
        onOk && onOk();
    }, [ setOpen, onOk, keepOpenAfterOk  ]);

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            fullWidth={fullWidth ? true : false}
            maxWidth={maxWidth}
        >
            <DialogTitle>
                { title }
            </DialogTitle>
            <DialogContent className={classes.content} className="app-kit-overflow-y">
                { children }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    { cancelLabel ?? t('button.cancel', 'Cancel') }
                </Button>
                {onOk && <Button onClick={handleOk} color="primary">
                    { okLabel ?? t('button.ok', 'Ok') }
                </Button>}
            </DialogActions>
        </Dialog>
    )
}

export default AppDialog;