import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Tab, Tabs } from '@material-ui/core';
import { MdModeEdit } from 'react-icons/md';

import SubfoldersExplorer from './subfolders/SubfoldersExplorer';
import Card from '../card/Card';
import FormCategoryHeader from '../form/FormCategoryHeader';
import FormRow from '../form/FormRow';
import ConsultDatePicker from '../form/ConsultDatePicker';
import ConsultTextField from '../form/ConsultTextField';
import ConsultNumberField from '../form/ConsultNumberField';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import Map from '../Map';
import MapZoom from '../../enums/MapZoom';
import { a11yProps, TabPanel } from '../navbar/TabsNavigation';
import UserContext from '../../context/user';
import Planning from '../wbs/Planning';
import Structure from '../wbs/Structure';

const FoldersConsult = ({ companies, folders, users, fetchWbsAllForFolderId }) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const { hasPermission } = useContext(UserContext);
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const folder = useMemo(() => folders.find(f => f.id.toString() === id), [ id, folders ]);
    const [tab, setTab] = useState(+query.get('tab') || 0);
    const [wbsId,setWbsId] = useState(null);
    const [fold, setFold] = useState({});
    

    useEffect(()=>{
       
        let folderFound = folders.find(fold=>fold.id === +id);
       
        setFold(folderFound); 
    },[folders, id])

    useEffect(()=>{
        if(hasPermission("wbs.read"))fetchWbsAllForFolderId(id).then(wbsData=>{
            if(wbsData.payload && wbsData.payload.length !== 0){
                setWbsId(wbsData.payload[0].id)
            }
        })
        // eslint-disable-next-line
    },[])

    const handleEditClick = useCallback(() => {
        history.push(`/folders/${id}/edit`);
    }, [ id, history ])

    const projectManager = useMemo(() => {
        const pm = users.find(u => u.id === folder?.project_manager_id);
        if(pm) {
            return pm.user_user_extra_info?.code
                ? `${pm.user_user_extra_info.code} - ${pm.first_name} ${pm.last_name}`
                : `${pm.first_name} ${pm.last_name}`;
        }
        return pm;
    }, [ folder.project_manager_id, users ]);

    const handleChangeTab = (_event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                className="tab-item"
            >
                <Tab label="Informations générales" {...a11yProps(0)}/>
                {hasPermission("subfolder.read") && <Tab label="Sous-dossiers" {...a11yProps(1)} />}
                {hasPermission("wbs.read") && <Tab label="WBS" {...a11yProps(2)} />}
                {hasPermission("wbs.read") && <Tab label="Planning" {...a11yProps(3)} />}
            </Tabs>
            <TabPanel value={tab} index={1}>
                <SubfoldersExplorer folder={folder}/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <Structure wbsId={wbsId} folder={folder}/>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <Planning wbsId={wbsId} folder={fold}/>
            </TabPanel>
            <TabPanel value={tab} index={0}>    
                <Card
                    title={ t('folders.title.consult-folder', 'Folder information') }
                    hideButtons
                >
                    {hasPermission('folders.update') && <Button
                        color="primary"
                        style={{ position: 'absolute', top: 25, right: 25 }}
                        variant="contained"
                        startIcon={<MdModeEdit/>}
                        onClick={handleEditClick}
                    >
                        { t('button.edit', 'Edit') }
                    </Button>}
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader smallMarginTop>
                            { t('folders.folder-information', 'Folder information') }
                        </FormCategoryHeader>
                        <FormRow>
                            <Box display="flex" flexDirection="column">
                                <ConsultTextField
                                    name="lead_code"
                                    label={t('forms.fields.folder.lead_code.label', 'Code prospect')}
                                    value={folder.lead_code}
                                />
                                <ConsultTextField
                                    name="code"
                                    label={t('forms.fields.folder.code.label', 'Folder code')}
                                    value={folder.code}
                                />
                                <ConsultTextField
                                    name="project_type"
                                    label={t('forms.fields.folder.project_type.label', 'Project type')}
                                    value={folder.project_type}
                                />
                                <ConsultTextField
                                    name="language_type"
                                    label={t('forms.fields.folder.project_language.label', 'Project language')}
                                    value={folder.language_type}
                                />
                                <ConsultTextField
                                    name="project_owner_company_id"
                                    label={t('forms.fields.folder.project-owner.label', 'Project Owner')}
                                    value={companies.find(c => c.id === folder.project_owner_company_id)?.name}
                                />
                                <ConsultTextField
                                    name="end_user_company_id"
                                    label={t('forms.fields.folder.project-end-user.label', 'Project End User')}
                                    value={companies.find(c => c.id === folder.end_user_company_id)?.name}
                                />
                                <ConsultTextField
                                    name="name"
                                    label={t('forms.fields.folder.name.label', 'Name')}
                                    value={folder.name}
                                />
                                <ConsultTextField
                                    name="location"
                                    label={t('forms.fields.folder.location.label', 'Location')}
                                    value={folder.location}
                                />
                                <ConsultTextField
                                    name="project_manager_id"
                                    label={t('forms.fields.folder.project-manager', 'Account Manager')}
                                    value={projectManager}
                                />
                                <ConsultTextField
                                    name="keywords"
                                    label={t('forms.fields.folder.keywords.label', 'Keywords')}
                                    value={folder.folder_keywords.map(keyword => keyword.label).join(', ')}
                                />
                                <ConsultTextField
                                    name="description"
                                    label={t('forms.fields.folder.description.label', 'Description')}
                                    value={folder.description}
                                />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                                { folder.location && (
                                    <Map
                                        containerStyle={{ height: 640, width: 640 }}
                                        center={folder.location}
                                        zoom={MapZoom.STREETS}
                                    />
                                ) }
                            </Box>
                        </FormRow>
                        <FormRow>
                            <ConsultNumberField
                                name="work_budget"
                                label={t('forms.fields.folder.work-budget.label', 'Work budget')}
                                value={folder.folder_extra_info.work_budget}
                                endAdornment="€ HTVA"
                            />
                            <ConsultNumberField
                                name="investment_budget"
                                label={t('forms.fields.folder.investment-budget.label', 'Investment budget')}
                                value={folder.folder_extra_info.investment_budget}
                                endAdornment="€ TTC"
                            />
                        </FormRow>
                        <FormRow>
                            <ConsultDatePicker
                                name="work_start_date"
                                label={t('forms.fields.folder.work-start-date.label', 'Work start date')}
                                value={folder.folder_extra_info.work_start_date ? new Date(folder.folder_extra_info.work_start_date) : null}
                                format="dd/MM/yyyy"
                            />
                            <ConsultDatePicker
                                name="work_end_date"
                                label={t('forms.fields.folder.work-end-date.label', 'Work end date')}
                                value={folder.folder_extra_info.work_end_date ? new Date(folder.folder_extra_info.work_end_date) : null}
                                format="dd/MM/yyyy"
                            />
                        </FormRow>
                        <FormRow>
                            <ConsultTextField
                                name="project_gfa"
                                label={t('forms.fields.folder.project-gfa.label', 'Project GFA')}
                                endAdornment="m²"
                                value={folder.folder_extra_info.project_gfa}
                            />
                            <ConsultTextField
                                name="procurement_type"
                                label={t('forms.fields.folder.procurement-type.label', 'Procurement type')}
                                value={folder.folder_extra_info.procurement_type}
                            />
                        </FormRow>
                        <FormRow>
                            <ConsultTextField
                                name="building_sector_type"
                                label={t('forms.fields.folder.building-sector-type.label', 'Building Sector')}
                                value={folder.folder_extra_info.building_sector_type}
                            />
                            <ConsultTextField
                                name="building_subsector_type"
                                label={t('forms.fields.folder.building-subsector.label', 'Building Subsector')}
                                value={folder.folder_extra_info.building_subsector_type}
                            />
                        </FormRow>
                    </Box>
                </Card>
            </TabPanel>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FoldersConsult);