import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchUserBookingsForSubfolder = createAsyncThunk(
    'userBookings/fetchUserBookingsForSubfolder',
    async (id , { rejectWithValue }) => {
        const response = await axios.get(`/user_booking/by_subfolder_id/${id}`);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editUserBooking = createAsyncThunk(
    'userBookings/editUserBooking',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/wbs/user_booking/edit/${data.id}`,data.userBooking);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const fetchUserBookingsForUser = createAsyncThunk(
    'userBookings/fetchUserBookingsForUser',
    async (_ , { rejectWithValue }) => {
        const response = await axios.get(`/wbs/user_booking/all`);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const fetchUserBookingsTaskForUser = createAsyncThunk(
    'userBookings/fetchUserBookingsTaskForUser',
    async (_ , { rejectWithValue }) => {
        const response = await axios.get(`/wbs/user_booking_tasks/all`);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const fetchUserBookingsTaskForUserId = createAsyncThunk(
    'userBookings/fetchUserBookingsTaskForUserId',
    async (id , { rejectWithValue }) => {
        const response = await axios.get(`/wbs/task_planning/by_user/${id}`);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const createUserBooking = createAsyncThunk(
    'userBookings/createUserBooking',
    async (userBooking , { rejectWithValue }) => {
        const response = await axios.post(`/wbs/user_booking/create`,userBooking);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        const res = await axios.get(`/wbs/user_booking_tasks/all`);
        if(res.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return res.data;
    },
);

const deleteUserBooking = createAsyncThunk(
    'userBookings/deleteUserBooking',
    async (userBookingId , { rejectWithValue }) => {
        const response = await axios.delete(`/wbs/user_booking/delete/${userBookingId}`);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        const res = await axios.get(`/wbs/user_booking_tasks/all`);
        if(res.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return res.data;
    },
);

const updateUserBooking = createAsyncThunk(
    'userBookings/updateUserBooking',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/wbs/user_booking/edit/${data.id}`,data.data);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        const res = await axios.get(`/wbs/user_booking_tasks/all`);
        if(res.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return res.data;
    },
);


export const userBookingMethods = {
    fetchUserBookingsForSubfolder,
    editUserBooking,
    fetchUserBookingsForUser,
    fetchUserBookingsTaskForUser,
    createUserBooking,
    deleteUserBooking,
    updateUserBooking,
    fetchUserBookingsTaskForUserId
}

const userbookingSlice = createSlice({
    name: 'userBookings',
    initialState: [],
    extraReducers: {
        [fetchUserBookingsForSubfolder.fulfilled]: (_state, action) => action.payload,
        [fetchUserBookingsForUser.fulfilled]: (_state, action) => action.payload,
        [fetchUserBookingsTaskForUser.fulfilled]: (_state, action) => action.payload,
        [fetchUserBookingsTaskForUserId.fulfilled]: (_state, action) => action.payload,
        [createUserBooking.fulfilled]: (_state, action) => action.payload,
        [deleteUserBooking.fulfilled]: (_state, action) => action.payload,
        [updateUserBooking.fulfilled]: (_state, action) => action.payload,
        [editUserBooking.fulfilled]:(state, action) => {
            return state.map(userBook => {
                if(userBook.id === action.meta.arg.taskId)return {...userBook,user_bookings:userBook.user_bookings.map(userB=>{
                    if(userB.id === action.meta.arg.id)return action.payload.value
                    return userB
                })}
                return userBook;
            })
        }
    },
});

export default userbookingSlice;