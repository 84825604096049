import { useEffect, useState } from 'react';
import { Box, Switch, Typography } from '@material-ui/core';
// import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../store/dispatchers';
import Card from './Card';

const OutlookCard = ({ thirdParties, updateThirdPartiesOutlook }) => {
    // const { t } = useTranslation();
    const [activate, setActivate] = useState(false);

    useEffect(() => {
        setActivate(thirdParties.outlook?.activate || false);
    }, [thirdParties]);

    const updateOutlookActivate = () => {
        let newState = { ...thirdParties.outlook };
        newState.activate = !activate;
        updateThirdPartiesOutlook(newState);
    };

    return (
        <Card
            title="Outlook"
            explanations="Enable/Disable Outlook service"
            hideButtons
        >
            <Box
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
            >
                <Typography
                    component="p"
                    variant="body1"
                    color="textPrimary"
                    style={{ marginTop: 'auto', marginBottom: 'auto' }}
                >
                    Activate
                </Typography>
                <Box display="flex">
                    <Switch
                        value={activate}
                        checked={activate}
                        onChange={updateOutlookActivate}
                        color="primary"
                    />
                </Box>
            </Box>
        </Card>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlookCard);
