import axios from 'axios';
import axiosServ from '../../services/axios';
//import axios from '../../services/axios'
let axiosInstance = null;
if(process.env.IS_SERVER){
    axiosInstance = axiosServ;
}else{
    const instance = axios.create({
        // baseURL: "http://localhost:3000",
        baseURL: "https://newvp.atosborne.be/api",
        headers: {
            'Content-Type': 'application/json',
            'authorization':"Bearer eyJhbGciOiJIUzI1NiJ9.MA.yw8ACcIIXLxtyF6A8adkRxn-E8qQ6oFDopqnE-6sUmY"
        },
        withCredentials: true,
    });
    
    instance.interceptors.request.use(config => {
        const incarnatedUserId = sessionStorage.getItem('incarnatedUser');
        
        if(incarnatedUserId) {
            config.headers = {
                ...config.headers,
                userId: incarnatedUserId,
            };
        }
        return config;
    });
    axiosInstance = instance;
}

const actions = {
    getSSRSReport(payload){
        return new Promise((resolve,reject)=>{
            axiosInstance.post('/generateReport',payload).then(res=>resolve(res.data));
        })
    },
    getSSRSReportsList(payload){
        return new Promise((resolve,reject)=>{
            axiosInstance.post('/reportsList',payload).then(res=>resolve(res.data));
        })
    },
    getSSRSReportParams(payload){
        return new Promise((resolve,reject)=>{
            axiosInstance.post('/reportsParams',payload).then(res=>resolve(res.data));
        })
    }
}

export default actions