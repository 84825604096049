import { useCallback, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaFolderPlus } from 'react-icons/fa';
import { FormControlLabel, Button, Switch, Box } from '@material-ui/core';

import Card from '../card/Card';
import ExplorerToolbar from '../explorer/ExplorerToolbar';
import ExplorerTable from '../explorer/ExplorerTable';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import UserContext from '../../context/user';
import AppDialog from '../dialogs/AppDialog';

const FoldersExplorer = ({ folders, companies, users, deleteFolder, parameters }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { hasPermission, user } = useContext(UserContext);
    const [ filteredFolders, setFilteredFolders ] = useState(folders);
    const [filters,setFilters] = useState({
        closed:false,
        abandoned:false,
        active:true,
        intermediate:true,
        internal_project:true,
        litigation:true,
        tendering:true,
        transfer:true,
        prospect:true,
        no_subfolder:true,
    });
    const [open,setOpen] = useState(false);

    const mapToRow = useCallback(folder => [
        folder.lead_code,
        folder.code,
        companies.find(c => c.id === folder.project_owner_company_id)?.name ?? '',
        folder.name,
        folder.location,
        users.find(u => u.id === folder.project_manager_id)?.user_user_extra_info?.code ?? '',
        folder.folder_keywords.map(kw => kw.label).join(', '),
    ], [ users, companies ]);

    const foldersFields = useMemo(() => folders.map(folder => [
        folder.lead_code,
        folder.code,
        companies.find(c => c.id === folder.project_owner_company_id)?.name ?? '',
        folder.name,
        folder.location,
        users.find(u => u.id === folder.project_manager_id)?.user_user_extra_info?.code ?? '',
        ...folder.folder_keywords.map(kw => kw.label),
    ]), [ folders, companies, users ]);

    const filter = useCallback(folder => {
        if(!user.is_super_admin && !hasPermission("folders_all.read")){
            if(folder.project_type !== "operations")return false;
        }
        let isToReturn = false;
        if(filters.closed) if(folder.closed_subfolders_count && folder.closed_subfolders_count !== 0)isToReturn = true;
        if(filters.abandoned) if(folder.abandoned_subfolders_count && folder.abandoned_subfolders_count !== 0) isToReturn = true;
        if(filters.active) if(folder.active_subfolders_count && folder.active_subfolders_count !== 0) isToReturn = true;
        if(filters.intermediate) if(folder.intermediate_subfolders_count && folder.intermediate_subfolders_count !== 0) isToReturn = true;
        if(filters.internal_project) if(folder.internal_project_subfolders_count && folder.internal_project_subfolders_count !== 0) isToReturn = true;
        if(filters.litigation) if(folder.litigation_subfolders_count && folder.litigation_subfolders_count !== 0) isToReturn = true;
        if(filters.tendering) if(folder.tendering_subfolders_count && folder.tendering_subfolders_count !== 0) isToReturn = true;
        if(filters.transfer) if(folder.transfer_subfolders_count && folder.transfer_subfolders_count !== 0) isToReturn = true;
        if(filters.prospect) if(folder.prospect_subfolders_count && folder.prospect_subfolders_count !== 0) isToReturn = true;
        if(filters.no_subfolder) if(folder.subfolders_count === 0) isToReturn = true;
        return isToReturn;
    }, [filters,user,hasPermission]);

    const handleConsultClick = useCallback(folder => {
        history.push('/folders/' + folder.id);
    }, [ history ]);

    const handleCreateClick = useCallback(() => {
        history.push('/folders/create');
    }, [ history ]);

    const handleEditClick = useCallback(folder => {
        history.push(`/folders/${folder.id}/edit`);
    }, [ history ]);

    const handleDeleteClick = useCallback(folder => {
        deleteFolder(folder.id);
    }, [ deleteFolder ]);
    
    const columns = [
        { name: t('table.folders.columns.lead_code', 'Code prospect'), width: 10 },
        { name: t('table.folders.columns.code', 'Folder code'), width: 10 },
        { name: t('table.folders.columns.client', 'Client'), width: 10 },
        { name: t('table.folders.columns.name', 'Name'), width: 20 },
        { name: t('table.folders.columns.location', 'Location'), width: 10 },
        { name: t('table.folders.columns.operation-manager', 'Operation Manager'), width: 10 },
        { name: t('table.folders.columns.keywords', 'Keywords'), width: 20 },
    ];

    const filtersRender = () =>{
        let items = []
        let filtersCopy = {...filters};

        for(const [key] of Object.entries(filters)){
            if (parameters.subfolder_status.includes(key)) {
                items.push(<>
                    <FormControlLabel key={key}
                          control={<Switch checked={filters[key]}
                                           onChange={(event)=>{filtersCopy[key] = !filtersCopy[key]; setFilters(filtersCopy)}}
                          />}
                          label={key.replace(/[_]/g,' ')}
                          style={{color:"black"}}
                    />
                </>)
            }

        }
        return items;
    }

    return (
        <>
        <Card
            hideButtons
            style={{ minHeight: 'calc(100vh - 114px)' }}
            header={
                <ExplorerToolbar
                    filter={filter}
                    data={folders}
                    dataFields={foldersFields}
                    createLabel={t('button.create-a-folder', 'Create a folder')}
                    createIcon={<FaFolderPlus/>}
                    onCreateClick={hasPermission('folders.write') ? handleCreateClick : null}
                    onFilter={setFilteredFolders}
                    mapToExcelRow={mapToRow}
                    excelColumnNames={columns.map(column => column.name)}
                    excelSheetName={t('table.folders.title', 'Folders')}
                >
                    <Button style={{width:"250px"}} onClick={()=>setOpen(true)}>
                        Set Filters
                    </Button>

                </ExplorerToolbar>
            }
        >
            <ExplorerTable
                columns={columns}
                items={filteredFolders}
                mapToCellContents={mapToRow}
                onConsultClick={handleConsultClick}
                onEditClick={hasPermission('folders.update') ? handleEditClick : null}
                onDeleteClick={hasPermission('folders.delete') ? handleDeleteClick : null}
            />
        </Card>
        <AppDialog
            open={open}
            setOpen={setOpen}
            title={t('dialog.filters.title', 'Filters settings')}
            onCancel={()=>setOpen(false)}
        >
            <Box display="flex" flexDirection="column">
                {filtersRender()}
            </Box>
        </AppDialog>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FoldersExplorer);