import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
    root: focused => ({
        backgroundColor: focused ? theme.palette.admin.light : theme.palette.admin.main,
        '&:hover': {
            backgroundColor: focused ? theme.palette.admin.light : theme.palette.admin.dark,
        },
    }),
}));

const TemplateButtonKey = ({ focused, keyName, onClick }) => {
    const classes = useStyles(focused);

    return (
        <Box
            className={`template-explorer-button ${classes.root}`}
            onClick={()=>onClick()}
        >
            <Box>
                { keyName }
            </Box>
        </Box>
    );
};

export default TemplateButtonKey;