import { Redirect } from "react-router-dom";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LanguagePicker from "../components/LanguagePicker";
import TranslationKeyTranslator from '../components/translation/TranslationKeyTranslator';
import TranslationKeysExplorer from '../components/translation/TranslationKeysExplorer';
import UserContext from '../context/user';
import { defaultLanguage } from '../services/i18n';
import { objectToKeyList } from "../utils/converters";

const styles = theme => ({
    explorer: {
        backgroundColor: theme.palette.admin.main,
    },
    translator: {
        backgroundColor: theme.palette.admin.light,
    },
});

// TODO: use AdminCard in AdminTranslation
const Translation = ({ classes }) => {
    const { i18n } = useTranslation();
    const [ language, setLanguage ] = useState(i18n.language);
    const { hasPermission } = useContext(UserContext);

    const translationData = i18n.getDataByLanguage(defaultLanguage).translation;
    const getSortedTranslationKeys = lang => objectToKeyList(translationData).sort((a, b) => {
        // Sorting the keys so that untranslated keys appear first
        if(!i18n.getResource(lang, 'translation', b)) return 1;
        if(!i18n.getResource(lang, 'translation', a)) return -1;
        return 0;
    })

    const [ translationKeys, setTranslationKeys ] = useState(getSortedTranslationKeys(language));
    const [ focusedKeyIndex, setFocusedKeyIndex ] = useState(0);

    if(!hasPermission('translate.write')) {
        return <Redirect to="/"/>
    }

    const updateLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        setTranslationKeys(getSortedTranslationKeys(newLanguage));
        setFocusedKeyIndex(0);
    };

    return (
        <Box className="page">
            <Box className="cards-row" height="calc(100vh - 114px)">
                <Box className={`card translation-explorer ${classes.explorer}`}>
                    <TranslationKeysExplorer
                        keys={translationKeys}
                        language={language}
                        focusedKey={translationKeys[focusedKeyIndex]}
                        onKeyClick={key => setFocusedKeyIndex(translationKeys.indexOf(key))}
                    />
                </Box>
                <Box className={`card translation-translator ${classes.translator}`}>
                    <TranslationKeyTranslator
                        translationKey={translationKeys[focusedKeyIndex]}
                        language={language}
                        onPreviousClick={() => setFocusedKeyIndex(focusedKeyIndex === 0 ? translationKeys.length - 1 : focusedKeyIndex - 1)}
                        onNextClick={() => setFocusedKeyIndex((focusedKeyIndex + 1) % translationKeys.length)}
                    />
                    <Box className="translation-language-picker">
                        <LanguagePicker language={language} onLanguagePick={updateLanguage}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default withStyles(styles)(Translation);