import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchInvoices = createAsyncThunk(
    'invoice/fetchInvoices',
    async (_ , { rejectWithValue }) => {
        const response = await axios.get('/keywords/all');
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const createInvoice = createAsyncThunk(
    'invoice/createInvoice',
    async (invoice , { rejectWithValue }) => {
        const response = await axios.post('/invoice/create',invoice);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const createInvoiceDetail = createAsyncThunk(
    'invoice/createInvoiceDetail',
    async (invoiceDetail , { rejectWithValue }) => {
        const response = await axios.post('/invoice/detail/create',invoiceDetail);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editInvoiceDetail = createAsyncThunk(
    'invoice/editInvoiceDetail',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/invoice/detail/edit/${data.id}`,data.invoiceDetail);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editInvoice = createAsyncThunk(
    'invoice/editInvoice',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/invoice/edit/${data.id}`,data.invoice);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const emitInvoices = createAsyncThunk(
    'invoice/emitInvoices',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/invoice/emit`,data);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const removeInvoice = createAsyncThunk(
    'invoice/removeInvoice',
    async (id , { rejectWithValue }) => {
        const response = await axios.delete(`/invoice/delete/${id}`);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const removeInvoiceDetail = createAsyncThunk(
    'invoice/removeInvoiceDetail',
    async (id , { rejectWithValue }) => {
        const response = await axios.put(`/invoice/detail/delete/${id}`);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const removeInvoiceForecast = createAsyncThunk(
    'invoice/removeInvoiceForecast',
    async (id , { rejectWithValue }) => {
        const response = await axios.delete(`/invoice/forecast/delete/${id}`);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const uploadInvoices = createAsyncThunk(
    'invoice/uploadInvoices',
    async (data , { rejectWithValue }) => {
        const formData = new FormData();
        formData.append('file',data.file);
        formData.append('memo',data.form.memo);
        formData.append('invoice_type',data.form.invoice_type);
        formData.append('issue_date',data.form.issue_date);
        formData.append('number',data.form.number);
        formData.append('subfolder_id',data.form.subfolder_id);
        formData.append('amount',data.form.amount);
        formData.append('vat_rate',data.form.vat_rate);
        formData.append('amount_vat_included',data.form.amount_vat_included);
        formData.append('term_date',data.form.term_date);

        const response = await axios.post('/invoice/upload', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
        })
        if (response.data.status === 'error') throw response.data.value;

        return response.data;
    },
);

const uploadInvoicesFile = createAsyncThunk(
    'invoice/uploadInvoicesFile',
    async (data , { rejectWithValue }) => {
        const formData = new FormData();
        formData.append('file',data.file);
        formData.append('invoice_id',data.invoice_id);

        const response = await axios.post('/invoice/uploadfile', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
        })
        if (response.data.status === 'error') throw response.data.value;

        return response.data;
    },
);

const dowloadInvoiceFile = createAsyncThunk(
    'invoice/dowloadInvoiceFile',
    async (link , { rejectWithValue }) => {
        const response = await axios.get(`/invoice/reports/${link}`, { responseType: 'blob' })
        if (response.data.status === 'error') throw response.data.value;
        return response.data;
    },
);

const sendToClearFacts = createAsyncThunk(
    'invoice/sendToClearFacts',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/invoice/sendToClearFacts/${data.id}`,data);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const sendToBillit = createAsyncThunk(
    'invoice/sendToBillit',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/invoice/sendBillit/${data.id}`,data);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

export const invoiceMethods = {
    fetchInvoices,
    createInvoice,
    createInvoiceDetail,
    editInvoiceDetail,
    removeInvoice,
    editInvoice,
    removeInvoiceForecast,
    emitInvoices,
    removeInvoiceDetail,
    uploadInvoices,
    dowloadInvoiceFile,
    uploadInvoicesFile,
    sendToClearFacts,
    sendToBillit
}

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: [],
    extraReducers: {
        [fetchInvoices.fulfilled]: (_state, action) => action.payload.value,
    },
});

export default invoiceSlice;