import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IoClose } from 'react-icons/io5';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
});

const Banner = ({ classes, children, onClose }) => {
    return (
        <Box className={`banner no-select ${classes.root}`}>
            { children }
            <IoClose size={24} className="banner-close" onClick={onClose}/>
        </Box>
    );
};

export default withStyles(styles)(Banner);