import { useCallback, useState, useContext, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Card from '../../card/Card';
import ConsultTextField from '../../form/ConsultTextField';
import FormCategoryHeader from '../../form/FormCategoryHeader';
import FormRow from '../../form/FormRow';
import FormAutocomplete from '../../form/FormAutocomplete';
import FormTable from '../../form/FormTable';
import FormDatePicker from '../../form/FormDatePicker';
import FormNumberField from '../../form/FormNumberField';
import FormSelect from '../../form/FormSelect';
import FormTextField from '../../form/FormTextField';
import { mapFieldKeys } from '../../../utils/converters';
import { connect, mapStateToProps, mapDispatchToProps } from '../../../store/dispatchers';
import useYupResolver from '../../../hooks/useYupResolver';
import { handleResponse } from '../../../utils/http';
import SnackbarContext from '../../../context/snackbar';
import FormattedNumber from '../../FormattedNumber';
import ContractEditDialog from './ContractEditDialog';

import {
    subfolderSchema as schema,
    subfolderAssociateSchema as associateSchema,
    subfolderStockSchema as stockSchema,
    subfolderBudgetSchema as budgetSchema,
    subfolderBankGuaranteeSchema as bankGuaranteeSchema,
    subfolderCompetitorSchema as competitorSchema,
    subfolderSubcontractorSchema as subcontractorSchema,
} from '../../../services/yup';
import { Tab, Tabs, TextField, Box } from '@material-ui/core';
import { a11yProps, TabPanel } from '../../navbar/TabsNavigation';
import moment from 'moment';
import UserContext from '../../../context/user';
import InvoiceExplorer from '../../invoice/InvoiceExplorer';
import CompetitorDialog from './CompetitorDialog';

const SubfoldersEdit = ({
    folders,
    companies,
    parameters,
    editSubfolder,
    addSubfolderStock,
    deleteSubfolderStock,
    addSubfolderBudget,
    deleteSubfolderBudget,
    addSubfolderBankGuarantee,
    deleteSubfolderBankGuarantee,
    addSubfolderAssociate,
    deleteSubfolderAssociate,
    addSubfolderCompetitor,
    deleteSubfolderCompetitor,
    addSubfolderExtraInfoOffer,
    editSubfolderExtraInfoOffer,
    addSubfolderExtraInfoProspect,
    editSubfolderExtraInfoProspect,
    addSubfolderSubcontractor,
    deleteSubfolderSubcontractor,
    users,
    editSubfolderStock,
    parametersRelations,
    editSubfolderCompetitor,
    deleteSubfolderContract,
    addSubfolderContract,
    wbsPlanning,
    fetchCompanies
}) => {
    const { hasPermission } = useContext(UserContext);
    const { t } = useTranslation();
    const history = useHistory();
    const { folderId, id } = useParams();
    const [folder] = useState(folders.find(f => f.id.toString() === folderId));
    const [subfolder] = useState(folder.subfolders.find(sf => sf.id.toString() === id));
    const [associates, setAssociates] = useState(subfolder.subfolder_associates);
    const [bankGuarantees, setBankGuarantees] = useState(subfolder.subfolder_bank_guarantees);
    const [stocks, setStocks] = useState(subfolder.subfolder_stocks);
    const [budgets, setBudgets] = useState(subfolder.subfolder_budgets);
    const [subcontractors, setSubcontractors] = useState(subfolder.subfolder_subcontractors);
    const [competitors, setCompetitors] = useState(subfolder.subfolder_competitors);
    const [contracts, setContracts] = useState(subfolder.subfolder_contracts);
    const { openSuccessSnackbar, openErrorSnackbar, openWarningSnackbar } = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [selectedRelations, setSelectedRelations] = useState([]);
    const [paramsList, setParamsList] = useState(parametersRelations?.service_workpackage?.default?.service_subworkpackage?.map(p => p.type) ||[]);
    const [competitorDialogOpen, setCompetitorDialogOpen] = useState(false);
    const [competitorDialogEditOpen, setCompetitorDialogEditOpen] = useState(false);
    const [competitorToEdit, setCompetitorToEdit] = useState(null);
    const [fetchingCompanies, setFetchingCompanies] = useState(false);
    const [contractEditOpen,setContractEditOpen] = useState(false);
    const [contractToEdit,setContractToEdit] = useState(null);

    useEffect(()=>{
        setSelectedRelations(parametersRelations.service_workpackage ? parametersRelations.service_workpackage : {})
    },[parametersRelations])

    const onChangeTypeTarget = (targetValue) => {
        let toReturn = [];
        if(selectedRelations[targetValue] && selectedRelations[targetValue].service_subworkpackage && selectedRelations[targetValue].service_subworkpackage.length){
            toReturn = selectedRelations[targetValue].service_subworkpackage.map(item=>item.type);
        }
        setParamsList(toReturn);
    }

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [tab, setTab] = useState(+query.get('tab'));

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(()=>{
        let foldFound = folders.find(f => f.id.toString() === folderId);
        let subFoldFound = foldFound.subfolders.find(sf => sf.id.toString() === id);
        setStocks(subFoldFound.subfolder_stocks);
        // eslint-disable-next-line
    },[folders])

    // SUBFOLDER
    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            // Subfolder
            rank: subfolder.rank,
            status_type: subfolder.status_type,
            customer_company_id: subfolder.customer_company_id,
            name: subfolder.name,
            subfolder_type: subfolder.subfolder_type,
            project_leader: subfolder.project_leader,
            code:subfolder.code,
            service_subworkpackage_type: subfolder.subfolder_extra_info.service_subworkpackage_type,
            service_workpackage_type: subfolder.subfolder_extra_info.service_workpackage_type,
            // Subfolder extra info
            mission_start: subfolder.subfolder_extra_info.mission_start,
            temporary_reception_date: subfolder.subfolder_extra_info.temporary_reception_date,
            final_reception_date: subfolder.subfolder_extra_info.final_reception_date,
            good_execution_attest_date: subfolder.subfolder_extra_info.good_execution_attest_date,
            association_company_id: subfolder.subfolder_extra_info.association_company_id,
            bank_guarantee: subfolder.subfolder_extra_info.bank_guarantee,
            associative_mission: subfolder.subfolder_extra_info.associative_mission,
            subcontracting: subfolder.subfolder_extra_info.subcontracting,
            total_work_amount: subfolder.subfolder_extra_info.total_work_amount,
            // Subfolder extra info prospect
            object: subfolder.subfolder_extra_info_prospect?.object,
            opportunity_amount_low: subfolder.subfolder_extra_info_prospect?.opportunity_amount_low,
            opportunity_amount_high: subfolder.subfolder_extra_info_prospect?.opportunity_amount_high,
            source_type: subfolder.subfolder_extra_info_prospect?.source_type,
            comment: subfolder.subfolder_extra_info_prospect?.comment,
            prospection_date: subfolder.subfolder_extra_info_prospect?.prospection_date,
            // Subfolder extra info offer
            submission_done: subfolder.subfolder_extra_info_offer?.submission_done ?? false,
            submission_date: subfolder.subfolder_extra_info_offer?.submission_date ?? null,
            submission_hour: subfolder.subfolder_extra_info_offer?.submission_hour ?? null,
            go_to_tender_date: subfolder.subfolder_extra_info_offer?.go_to_tender_date ?? null,
            tendering_mode_type: subfolder.subfolder_extra_info_offer?.tendering_mode_type ?? 'private',
            submission_result_date: subfolder.subfolder_extra_info_offer?.submission_result_date ?? null,
            offer_amount: subfolder.subfolder_extra_info_offer?.offer_amount ?? '',
            submission_result_type: subfolder.subfolder_extra_info_offer?.submission_result_type ?? '',
            order_intake: subfolder.subfolder_extra_info_offer?.order_intake ?? null,
            submission_comment: subfolder.subfolder_extra_info_offer?.submission_comment ?? '',
            refusal_type: subfolder.subfolder_extra_info_offer?.refusal_type ?? '',
            refusal_comment: subfolder.subfolder_extra_info_offer?.refusal_comment ?? '',
        },
    });

    const statusType = form.watch('status_type');
    const subfolderType = form.watch('subfolder_type');
    const showOffer = subfolderType === 'O2 Tendering' || subfolder.subfolder_extra_info_offer;
    const showProspect = subfolderType === 'O1 Prospection' || subfolder.subfolder_extra_info_prospect;
    const showAssociates = form.watch('associative_mission');
    const showBankGuarantees = form.watch('bank_guarantee');
    const showSubcontractors = form.watch('subcontracting');
    const watchMissionStartDate = form.watch('mission_start');
    const watchTemporaryReceptionDate = form.watch('temporary_reception_date');
    const watchFinalReceptionDate = form.watch('final_reception_date');
    const watchGoodExecutionAttestDate = form.watch('good_execution_attest_date');

    //STOCK
    const stockResolver = useYupResolver(stockSchema);
    const stockForm = useForm({
        resolver: stockResolver,
        defaultValues: {
            year: '',
            trimester: '',
            amount: '',
            comment: '',
        },
    })

    const contractForm = useForm({
        defaultValues: {
            date: new Date(),
        },
    })

    const handleContractSubmit = (contract) => {
        if (!contract.wbs_task_id) {
            openErrorSnackbar(t('error.subfolder_incomplete', 'WBS field is mandatory'))
        } else {
            addSubfolderContract({...contract,subfolder_id: subfolder.id}).then((val)=>{
                setContracts([...contracts, val.payload.value]);
                contractForm.reset();
                contractForm.clearErrors();
                openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated'))
            })
        }

    }

    const handleRemoveContract = (contract) => {
        deleteSubfolderContract(contract.id).then(()=>{
            setContracts(contracts.filter(c => c.id !== contract.id));
            openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated'))
        })
    }


    const handleStockSubmit = useCallback(stock => {
        addSubfolderStock({ ...stock, subfolder_id: subfolder.id }).then((val) => {
            setStocks([...stocks, val.payload.value]);
            stockForm.reset();
            stockForm.clearErrors();
            openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated'))
        });
    }, [stocks, stockForm, addSubfolderStock, subfolder.id,t,openSuccessSnackbar]);

    const handleRemoveStock = useCallback(stock => {
        setStocks(stocks.filter(b => b !== stock));
        deleteSubfolderStock(stock.id).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')))
    }, [stocks, deleteSubfolderStock,openSuccessSnackbar,t]);

    // BUDGET
    const budgetResolver = useYupResolver(budgetSchema);
    const budgetForm = useForm({
        resolver: budgetResolver,
        defaultValues: {
            budget_type: '',
            number_blocks: null,

        },
    });
    // validation for input budget

    const handleBudgetSubmit = useCallback(budget => {

        addSubfolderBudget({ ...budget, subfolder_id: subfolder.id}).then((val) => {
            setBudgets([...budgets, val.payload.value]);
            budgetForm.reset();
            budgetForm.clearErrors();
            openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated'))
        });

    }, [budgets, budgetForm, addSubfolderBudget,openSuccessSnackbar,t,subfolder.id]);

    const handleRemoveBudget = useCallback(budget => {
        // If it contains an id, it is already in the database
        setBudgets(budgets.filter(b => b !== budget));
        deleteSubfolderBudget(budget.id).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')))
        /* if(toRemove.id >= 0) {
            setBudgets(budgets.map(b => b === toRemove ? ({ ...toRemove, deleteOnSubmit: !toRemove.deleteOnSubmit }) : b));
        } else {

        } */
    }, [budgets, deleteSubfolderBudget,openSuccessSnackbar,t]);

    // BANK GUARANTEE
    const bankGuaranteeResolver = useYupResolver(bankGuaranteeSchema);
    const bankGuaranteeForm = useForm({
        resolver: bankGuaranteeResolver,
        defaultValues: {
            amount: null,
            constitution_date: null,
            organism_reference: '',
            release_date: null,
        },
    });

    const handleBankGuaranteeSubmit = useCallback(bankGuarantee => {
        setBankGuarantees([...bankGuarantees, bankGuarantee]);
        bankGuaranteeForm.reset();
        bankGuaranteeForm.clearErrors();
        addSubfolderBankGuarantee({ ...bankGuarantee, subfolder_id: subfolder.id }).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')));
    }, [bankGuarantees, bankGuaranteeForm,t,openSuccessSnackbar,subfolder.id,addSubfolderBankGuarantee]);


    const handleRemoveBankGuarantee = useCallback(bankGuarantee => {
        // If it contains an id, it is already in the database
        setBankGuarantees(bankGuarantees.filter(bg => bg !== bankGuarantee));
        deleteSubfolderBankGuarantee(bankGuarantee.id).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')))
        /* if(toRemove.id >= 0) {
            setBankGuarantees(bankGuarantees.map(bg => bg === toRemove ? ({ ...toRemove, deleteOnSubmit: !toRemove.deleteOnSubmit }) : bg));
        } else {
            setBankGuarantees(bankGuarantees.filter(bg => bg !== toRemove));
        } */
    }, [bankGuarantees,deleteSubfolderBankGuarantee,openSuccessSnackbar,t]);

    // ASSOCIATE
    const associateResolver = useYupResolver(associateSchema);
    const associateForm = useForm({
        resolver: associateResolver,
        defaultValues: {
            associate_role_type: '',
            shares_percentage: null,
        },
    });

    const handleAssociateSubmit = useCallback(associate => {
        setAssociates([...associates, associate]);
        associateForm.reset();
        associateForm.clearErrors();
        addSubfolderAssociate({ ...associate, subfolder_id: subfolder.id }).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')));
    }, [associates, associateForm,openSuccessSnackbar,t,subfolder.id,addSubfolderAssociate]);

    const handleRemoveAssociate = useCallback(associate => {
        // If it contains an id, it is already in the database
        setAssociates(associates.filter(a => a !== associate));
        deleteSubfolderAssociate(associate.id).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')))
        /* if(toRemove.id >= 0) {
            setAssociates(associates.map(a => a === toRemove ? ({ ...toRemove, deleteOnSubmit: !toRemove.deleteOnSubmit }) : a));
        } else {
            setAssociates(associates.filter(a => a !== toRemove));
        } */
    }, [associates,openSuccessSnackbar,t,deleteSubfolderAssociate]);

    // SUBCONTRACTOR
    const subcontractorResolver = useYupResolver(subcontractorSchema);
    const subcontractorForm = useForm({
        resolver: subcontractorResolver,
        defaultValues: {
            role: '',
            contract_type: '', // Régie ou Forfaitaire
            contract_amount: '',
        },
    });

    const handleSubcontractorSubmit = useCallback(subcontractor => {
        setSubcontractors([...subcontractors, subcontractor]);
        subcontractorForm.reset();
        subcontractorForm.clearErrors();
        addSubfolderSubcontractor({ ...subcontractor, subfolder_id: subfolder.id }).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')));
    }, [subcontractors, subcontractorForm,t,openSuccessSnackbar,subfolder.id,addSubfolderSubcontractor]);

    const handleRemoveSubcontractor = useCallback(subcontractor => {
        // If it contains an id, it is already in the database
        setSubcontractors(subcontractors.filter(s => s !== subcontractor));
        deleteSubfolderSubcontractor(subcontractor.id).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')))
        /* if(toRemove.id >= 0) {
            setSubcontractors(subcontractors.map(s => s === toRemove ? ({ ...toRemove, deleteOnSubmit: !toRemove.deleteOnSubmit }) : s));
        } else {
            setSubcontractors(subcontractors.filter(s => s !== toRemove));
        } */
    }, [subcontractors,openSuccessSnackbar,t,deleteSubfolderSubcontractor]);

    // COMPETITORS
    const competitorResolver = useYupResolver(competitorSchema);
    const competitorForm = useForm({
        resolver: competitorResolver,
        defaultValues: {
            company_id: undefined,
            name: '',
            price: '',
            final_rating: '',
            comment: '',
        },
    });

    const handleCompetitorSubmit = useCallback(competitor => {
        setCompetitors([...competitors, competitor]);
        competitorForm.reset();
        competitorForm.clearErrors();
        setCompetitorDialogOpen(false);
        addSubfolderCompetitor({ ...competitor, subfolder_id: subfolder.id }).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')));
    }, [competitors, competitorForm,openSuccessSnackbar,t,subfolder.id,addSubfolderCompetitor]);

    const handleEditCompetitor = useCallback(competitor => {
        setCompetitorDialogEditOpen(false);
        setCompetitors([...competitors.map(c => c.id === competitor.id ? competitor : c)]);
        editSubfolderCompetitor({ data:competitor,id:competitor.id, subfolder_id:subfolder.id }).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')));
    }, [competitors, competitorForm,openSuccessSnackbar,t,subfolder.id,editSubfolderCompetitor]);

    const handleRemoveCompetitor = useCallback(competitor => {
        // If it contains an id, it is already in the database
        deleteSubfolderCompetitor(competitor.id).then(() => openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated')));
        setCompetitors(competitors.filter(c => c !== competitor));
        /* if(toRemove.id >= 0) {
            setCompetitors(competitors.map(c => c === toRemove ? ({ ...toRemove, deleteOnSubmit: !toRemove.deleteOnSubmit }) : c));
        } else {

        } */
    }, [competitors,openSuccessSnackbar,t,deleteSubfolderCompetitor]);

    const handleUnhandledError = useCallback((message, value) => {
        setLoading(false);
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
    }, [t, openErrorSnackbar]);

    const handleOfferSuccess = useCallback(async (mappedValues, competitors, budgets, bankGuarantees, associates, subcontractors, stocks) => {
        /*if(subfolder.subfolder_extra_info_offer && competitors.length > 0) {
            const competitor = competitors[0];
            const result = competitor.deleteOnSubmit
                ? await deleteSubfolderCompetitor(competitor.id)
                : await addSubfolderCompetitor({ ...competitor, subfolder_id: subfolder.id });
            handleResponse(result.payload, null, () => handleOfferSuccess(mappedValues, competitors.slice(1), budgets, bankGuarantees, associates, subcontractors), handleUnhandledError);
        } else if(budgets.length > 0) {
            const budget = budgets[0];
            const result = budget.deleteOnSubmit
                ? await deleteSubfolderBudget(budget.id)
                : await addSubfolderBudget({ ...budget, subfolder_id: subfolder.id });
            handleResponse(result.payload, null, () => handleOfferSuccess(mappedValues, competitors, budgets.slice(1), bankGuarantees, associates, subcontractors), handleUnhandledError);
        } else if(bankGuarantees.length > 0) {
            const bankGuarantee = bankGuarantees[0];
            const result = bankGuarantee.deleteOnSubmit
                ? await deleteSubfolderBankGuarantee(bankGuarantee.id)
                : await addSubfolderBankGuarantee({ ...bankGuarantee, subfolder_id: subfolder.id });
            handleResponse(result.payload, null, () => handleOfferSuccess(mappedValues, competitors, budgets, bankGuarantees.slice(1), associates, subcontractors), handleUnhandledError);
        } else if(associates.length > 0) {
            const associate = associates[0];
            const result = associate.deleteOnSubmit
                ? await deleteSubfolderAssociate(associate.id)
                : await addSubfolderAssociate({ ...associate, subfolder_id: subfolder.id });
            handleResponse(result.payload, null, () => handleOfferSuccess(mappedValues, competitors, budgets, bankGuarantees, associates.slice(1), subcontractors), handleUnhandledError);
        } else if(subcontractors.length > 0) {
            const subcontractor = subcontractors[0];
            const result = subcontractor.deleteOnSubmit
                ? await deleteSubfolderSubcontractor(subcontractor.id)
                : await addSubfolderSubcontractor({ ...subcontractor, subfolder_id: subfolder.id });
            handleResponse(result.payload, null, () => handleOfferSuccess(mappedValues, competitors, budgets, bankGuarantees, associates, subcontractors.slice(1)), handleUnhandledError);
        } else {*/
        setLoading(false);
        openSuccessSnackbar(t('success.subfolder_updated', 'Subfolder successfully updated'));
        history.push(`/folders/${folderId}/subfolders/${subfolder.id}`);
        /*}*/
    }, [
        t, history, subfolder, folderId,
        openSuccessSnackbar,
    ]);

    const handleProspectSuccess = useCallback(async (mappedValues) => {
        const filteredCompetitors = competitors.filter(competitor => competitor.deleteOnSubmit || !competitor.id);
        const filteredBudgets = budgets.filter(budget => budget.deleteOnSubmit || !budget.id);
        const filteredBankGuarantees = bankGuarantees.filter(bankGuarantee => bankGuarantee.deleteOnSubmit || !bankGuarantee.id);
        const filteredAssociates = associates.filter(associate => associate.deleteOnSubmit || !associate.id);
        const filteredSubcontractors = subcontractors.filter(subcontractor => subcontractor.deleteOnSubmit || !subcontractor.id);
        if (subfolder.subfolder_extra_info_offer) {
            const result = await editSubfolderExtraInfoOffer({
                id: subfolder.id,
                offer: {
                    ...mappedValues,
                },
            });
            return handleResponse(
                result.payload,
                form,
                () => handleOfferSuccess(
                    mappedValues,
                    filteredCompetitors,
                    filteredBudgets,
                    filteredBankGuarantees,
                    filteredAssociates,
                    filteredSubcontractors
                ),
                handleUnhandledError
            );
        }

        // if no offer existed before edit.
        if (showOffer) {
            const result = await addSubfolderExtraInfoOffer({
                ...mappedValues,
                subfolder_id: subfolder.id,
            });
            return handleResponse(result.payload, form, () => handleOfferSuccess(mappedValues, filteredCompetitors, filteredBudgets, filteredBankGuarantees, filteredAssociates, filteredSubcontractors), handleUnhandledError);
        }

        if (mappedValues.status_type === 'Recherche Affaire') {
            const result = await addSubfolderExtraInfoOffer({
                ...mappedValues,
                subfolder_id: subfolder.id,
            });
            return handleResponse(result.payload, form, () => handleOfferSuccess(mappedValues, filteredCompetitors, filteredBudgets, filteredBankGuarantees, filteredAssociates, filteredSubcontractors), handleUnhandledError);
        }
        await handleOfferSuccess(mappedValues, filteredCompetitors, filteredBudgets, filteredBankGuarantees, filteredAssociates, filteredSubcontractors);
    }, [form, subfolder, competitors, budgets, bankGuarantees, associates, subcontractors, addSubfolderExtraInfoOffer, editSubfolderExtraInfoOffer, handleOfferSuccess, handleUnhandledError]);

    const handleSuccess = useCallback(async (mappedValues) => {

        if (subfolder.subfolder_extra_info_prospect) {
            const result = await editSubfolderExtraInfoProspect({
                id: subfolder.id,
                prospect: {
                    ...mappedValues,
                },
            });
            return handleResponse(result.payload, form, () => handleProspectSuccess(mappedValues), handleUnhandledError);
        }

        if (mappedValues.subfolder_type === 'O1 Prospection') {
            const result = await addSubfolderExtraInfoProspect({
                ...mappedValues,
                subfolder_id: subfolder.id,
            });
            return handleResponse(result.payload, form, () => handleProspectSuccess(mappedValues), handleUnhandledError);
        }

        await handleProspectSuccess(mappedValues);

    }, [form, subfolder, addSubfolderExtraInfoProspect, editSubfolderExtraInfoProspect, handleProspectSuccess, handleUnhandledError]);

    const handleSubmit = useCallback(async (values) => {
        // console.log('values : ', values);
        setLoading(true);
        const mappedValues = mapFieldKeys(values);
        const result = await editSubfolder({
            id: parseInt(id),
            subfolder: {
                subFolder: {
                    ...mappedValues,
                },
                subFolderExtraInfo: {
                    ...mappedValues,
                },
            },
        });
        handleResponse(result.payload, form, () => handleSuccess(mappedValues), handleUnhandledError);
    }, [id, form, editSubfolder, handleSuccess, handleUnhandledError]);

    return (
        <>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                className="tab-item"
            >
                <Tab label="Informations générales" {...a11yProps(0)} />
                {/* <Tab label="Budget" {...a11yProps(3)} /> */}
                <Tab label="Contract" {...a11yProps(10)} />
                <Tab label="Stock" {...a11yProps(8)} />
                { hasPermission("invoice.read") &&
                hasPermission("invoice.write") &&
                hasPermission("invoice.update") &&
                hasPermission("invoice.delete") && <Tab label="Prévisions de facturation" {...a11yProps(9)} />}
                {subfolder.subfolder_extra_info.bank_guarantee && <Tab label="Garanties bancaires" {...a11yProps(4)} />}
                {subfolder.subfolder_extra_info.associative_mission && <Tab label="Associés" {...a11yProps(5)} />}
                {subfolder.subfolder_extra_info.subcontracting && <Tab label="Sous-contractants" {...a11yProps(6)} />}
            </Tabs>
            <TabPanel value={tab} index={0}>
                <Card
                    title={t('subfolders.title.edit-subfolder-informations', 'Edit general informations')}
                    doneLabel={t('button.submit', 'Submit')}
                    onDoneClick={form.handleSubmit(handleSubmit)}
                    loading={loading}
                    showAfterButton={showOffer && (
                        <FormProvider {...competitorForm}>
                            <form style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormCategoryHeader>
                                    {t('subfolders.competitors', 'Competitors')}
                                </FormCategoryHeader>
                                <FormTable
                                    columns={[
                                        t('forms.fields.competitor.mandatary.label', 'Mandatary'),
                                        t('forms.fields.competitor.price.label', 'Price'),
                                        t('forms.fields.competitor.final-rating.label', 'Final rating'),
                                        t('forms.fields.competitor.comment.label', 'Comment')
                                    ]}
                                    items={competitors}
                                    mapToCellContents={item => [
                                        companies.find(company => company.id === item.partner1_company_id).name,
                                        item.price && <FormattedNumber value={item.price} suffix=" €"/>,
                                        item.final_rating && <FormattedNumber value={item.final_rating} suffix="/100"/>,
                                        item.comment,
                                    ]}
                                    formFields={[<Box></Box>, <Box></Box>, <Box></Box>, <Box></Box>]}
                                    onAddClick={()=>setCompetitorDialogOpen(true)}
                                    onRemoveClick={handleRemoveCompetitor}
                                    onEditClick={(item)=>{
                                        setCompetitorToEdit(item);
                                        setCompetitorDialogEditOpen(true);
                                    }}
                                />
                            </form>
                        </FormProvider>
                    )}
                >
                    <FormProvider {...form}>
                        <form style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormCategoryHeader smallMarginTop>
                                {t('subfolders.subfolder-information', 'Subfolder information')}
                            </FormCategoryHeader>
                            <FormRow>
                                <ConsultTextField
                                    name="folder"
                                    label={t('forms.fields.subfolder.folder.label', 'Folder')}
                                    value={folder.name}
                                />
                                <FormNumberField
                                    name="total_work_amount"
                                    label={t('forms.fields.subfolder.total-work-amount.label', 'Total Work Amount')}
                                    min={0}
                                    max={9999999999.99}
                                    endAdornment="€"
                                />
                            </FormRow>
                            <ConsultTextField
                                name="rank"
                                label={t('forms.fields.subfolder.rank.label', 'Code rank')}
                                value={folder.lead_code+'/'+subfolder.rank}
                            />
                            <FormTextField
                                name="code"
                                label={t('forms.fields.subfolder.code.label', 'Code')}
                            />
                            {/*{ hasPermission('subfolder_admin') && <FormSelect*/}
                            {/*    name="status_type"*/}
                            {/*    label={t('forms.fields.subfolder.status.label', 'Status')}*/}
                            {/*    options={parameters.subfolder_status}*/}
                            {/*    mapOptionToText={val=>t('parameters.subfolder_status.'+val)}*/}
                            {/*/>}*/}
                            <FormSelect
                                disabled={!hasPermission('subfolder_admin')}
                                name="status_type"
                                label={t('forms.fields.subfolder.status.label', 'Status')}
                                options={parameters.subfolder_status}
                                mapOptionToText={val=>t('parameters.subfolder_status.'+val)}
                            />
                            <FormAutocomplete
                                name="customer_company_id"
                                label={t('forms.fields.subfolder.customer.label', 'Customer')}
                                options={companies}
                                mapOptionToValue={company => company.id}
                                mapOptionToText={company => company.name}
                                allowNull
                                refreshClick={()=>{
                                    setFetchingCompanies(true);
                                    fetchCompanies().then(()=>{
                                        setFetchingCompanies(false);
                                    })
                                }}
                                refreshLoading={fetchingCompanies}
                            />
                            <FormTextField
                                name="name"
                                label={t('forms.fields.subfolder.name.label', 'Name')}
                            />
                            <FormSelect
                                name="subfolder_type"
                                label={t('forms.fields.subfolder.type.label', 'Subfolder type')}
                                options={parameters.subfolder_type}
                                mapOptionToText={val=>t('parameters.subfolder_status.'+val,val)}
                            />
                            {/*{ hasPermission('subfolder_admin') && <FormAutocomplete*/}
                            {/*    name="project_leader"*/}
                            {/*    label={t('forms.fields.subfolder.project_leader.label', 'Project leader')}*/}
                            {/*    options={users}*/}
                            {/*    mapOptionToValue={user => user.id}*/}
                            {/*    mapOptionToText={user => user.user_user_extra_info?.code*/}
                            {/*        ? `${user.user_user_extra_info.code} - ${user.first_name} ${user.last_name}`*/}
                            {/*        : `${user.first_name} ${user.last_name}`*/}
                            {/*    }*/}
                            {/*    allowNull*/}
                            {/*/>}*/}
                            <FormAutocomplete
                                disabled={!hasPermission('subfolder_admin')}
                                name="project_leader"
                                label={t('forms.fields.subfolder.project_leader.label', 'Project leader')}
                                options={users}
                                mapOptionToValue={user => user.id}
                                mapOptionToText={user => user.user_user_extra_info?.code
                                    ? `${user.user_user_extra_info.code} - ${user.first_name} ${user.last_name}`
                                    : `${user.first_name} ${user.last_name}`
                                }
                                allowNull
                            />
                            <FormRow>
                                <FormSelect
                                    name="service_workpackage_type"
                                    label={t('forms.fields.subfolder.service_workpackage_type.label', 'Service Workpackage')}
                                    options={parameters.service_workpackage}
                                    mapOptionToText={val=>t('parameters.service_workpackage_type.'+val,val)}
                                    changeEvent={(event)=>onChangeTypeTarget(event.target.value)}
                                />
                                <FormSelect
                                    name="service_subworkpackage_type"
                                    label={t('forms.fields.subfolder.service_subworkpackage_type.label', 'Service subworkpackage')}
                                    options={paramsList}
                                    mapOptionToText={val=>t('parameters.service_workpackage_type.'+val,val)}
                                />
                            </FormRow>
                            <FormRow>
                                <FormDatePicker
                                    name="mission_start"
                                    label={t('forms.fields.subfolder.mission-start.label', 'Mission start date')}
                                    maxDate={watchTemporaryReceptionDate || watchFinalReceptionDate || watchGoodExecutionAttestDate}
                                />
                                <FormDatePicker
                                    name="temporary_reception_date"
                                    label={t('forms.fields.subfolder.temporary-reception-date.label', 'Temporary reception date')}
                                    minDate={watchMissionStartDate}
                                    maxDate={watchFinalReceptionDate || watchGoodExecutionAttestDate}
                                />
                            </FormRow>
                            <FormRow>
                                <FormDatePicker
                                    name="final_reception_date"
                                    label={t('forms.fields.subfolder.final-reception-date.label', 'Final reception date')}
                                    minDate={watchTemporaryReceptionDate || watchMissionStartDate}
                                    maxDate={watchGoodExecutionAttestDate}
                                />
                                <FormDatePicker
                                    name="good_execution_attest_date"
                                    label={t('forms.fields.subfolder.good-execution-attest-date.label', 'Good execution attest date')}
                                    minDate={watchFinalReceptionDate || watchFinalReceptionDate || watchMissionStartDate}
                                />
                            </FormRow>
                            <FormRow>
                                <FormSelect
                                    name="bank_guarantee"
                                    label={t('forms.fields.subfolder.bank-guarantee.label', 'Bank guarantee?')}
                                    options={[true, false]}
                                    mapOptionToText={option => option ? 'Yes' : 'No'}
                                />
                                <FormSelect
                                    name="subcontracting"
                                    label={t('forms.fields.subfolder.subcontracting.label', 'Subcontracting?')}
                                    options={[true, false]}
                                    mapOptionToText={option => option ? 'Yes' : 'No'}
                                />
                            </FormRow>
                            <FormRow>
                                <FormSelect
                                    name="associative_mission"
                                    label={t('forms.fields.subfolder.associative-mission.label', 'Associative mission?')}
                                    options={[true, false]}
                                    mapOptionToText={option => option ? 'Yes' : 'No'}
                                />
                                {showAssociates ? (
                                    <FormAutocomplete
                                        name="association_company_id"
                                        label={t('forms.fields.subfolder.association-name.label', 'Association name')}
                                        options={companies}
                                        mapOptionToValue={company => company.id}
                                        mapOptionToText={company => company.name}
                                        refreshClick={()=>{
                                            setFetchingCompanies(true);
                                            fetchCompanies().then(()=>{
                                                setFetchingCompanies(false);
                                            })
                                        }}
                                        refreshLoading={fetchingCompanies}
                                    />
                                ) : (
                                    <ConsultTextField
                                        label={t('forms.fields.subfolder.association-name.label', 'Association name')}
                                    />
                                )}
                            </FormRow>
                            {showProspect && (
                                <>
                                    <FormCategoryHeader>
                                        {t('subfolders.prospect', 'Prospect')}
                                    </FormCategoryHeader>
                                    <FormTextField
                                        name="object"
                                        label={t('forms.fields.prospect.object.label', 'Object')}
                                    />
                                    <FormRow>
                                        <FormNumberField
                                            name="opportunity_amount_low"
                                            label={t('forms.fields.prospect.opportunity-amount-low.label', 'Opportunity amount low')}
                                            min={0}
                                            max={9999999999.99}
                                            endAdornment="€"
                                        />
                                        <FormNumberField
                                            name="opportunity_amount_high"
                                            label={t('forms.fields.prospect.opportunity-amount-high.label', 'Opportunity amount high')}
                                            min={0}
                                            max={9999999999.99}
                                            endAdornment="€"
                                        />
                                    </FormRow>
                                    <FormSelect
                                        name="source_type"
                                        label={t('forms.fields.prospect.source-type.label', 'Source')}
                                        options={parameters.publication_source}
                                        allowNull
                                        mapOptionToText={val=>t('parameters.publication_source.'+val)}
                                    />
                                    <FormTextField
                                        name="comment"
                                        label={t('forms.fields.prospect.comment.label', 'Comment')}
                                    />
                                    <FormDatePicker
                                        name="prospection_date"
                                        label={t('forms.fields.prospect.prospection-date.label', 'Date')}
                                    />
                                </>
                            )}
                            {showOffer && (
                                <>
                                    <FormCategoryHeader>
                                        {t('subfolders.offer', 'Offer')}
                                    </FormCategoryHeader>
                                    <FormSelect
                                        name="submission_done"
                                        label={t('forms.fields.offer.submission-done.label', 'Submission done?')}
                                        options={[true, false]}
                                        mapOptionToText={option => option ? 'Yes' : 'No'}
                                    />
                                    <FormRow>
                                        <FormDatePicker
                                            name="submission_date"
                                            label={t('forms.fields.offer.submission-date.label', 'Submission date')}
                                        />
                                        <FormNumberField
                                            name="submission_hour"
                                            label={t('forms.fields.offer.submission-hour.label', 'Submission hour')}
                                            min={0}
                                            max={24}
                                            endAdornment="H"
                                            notNullable
                                            decimalPlaces={0}
                                            noSeparator
                                        />
                                        <FormNumberField
                                            name="offer_amount"
                                            label={t('forms.fields.offer.offer-amount.label', 'Offer amount')}
                                            min={0}
                                            max={9999999999.99}
                                            endAdornment="€ HTVA"
                                            notNullable
                                        />
                                    </FormRow>
                                    <FormDatePicker
                                        name="go_to_tender_date"
                                        label={t('forms.fields.offer.go-to-tender-date.label', 'Go to tender date')}
                                    />
                                    <FormSelect
                                        name="tendering_mode_type"
                                        label={t('forms.fields.offer.tendering_mode_type.label', 'Tendering mode')}
                                        options={parameters.tendering_mode}
                                        allowNull
                                        mapOptionToText={val=>t('parameters.tendering_mode_type.'+val,val)}
                                    />
                                    <FormDatePicker
                                        name="submission_result_date"
                                        label={t('forms.fields.offer.submission_result_date.label', 'Submission result date')}
                                    />
                                    <FormSelect
                                        name="submission_result_type"
                                        label={t('forms.fields.offer.submission-result-type.label', 'Result')}
                                        options={parameters.result_type}
                                        allowNull
                                        mapOptionToText={val=>t('parameters.submission_result_type.'+val,val)}
                                    />
                                    <FormNumberField
                                        name="order_intake"
                                        label={t('forms.fields.offer.order-intake.label', 'Order intake')}
                                        min={0}
                                        max={9999999999.99}
                                        endAdornment="€ HTVA"
                                    />
                                    <FormTextField
                                        name="submission_comment"
                                        label={t('forms.fields.offer.submission-comment.label', 'Submission comment')}
                                    />
                                    <FormSelect
                                        name="refusal_type"
                                        label={t('forms.fields.offer.refusal-type.label', 'Refusal reason')}
                                        options={parameters.refusal_type}
                                        allowNull
                                        mapOptionToText={val=>t('parameters.refusal_type.'+val,val)}
                                    />
                                    <FormTextField
                                        name="refusal_comment"
                                        label={t('forms.fields.offer.refusal-comment.label', 'Refusal comment')}
                                    />
                                </>
                            )}
                        </form>
                    </FormProvider>
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <Card
                    title={t('subfolders.title.edit-subfolder-budget', 'Edit subfolder budget')}
                    hideButtons
                >
                    <FormProvider {...budgetForm}>
                        <form style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormCategoryHeader>
                                {t('subfolders.budget', 'Budget')}
                            </FormCategoryHeader>
                            <FormTable
                                columns={folder.project_type !== "internal_project" ? [
                                    t('forms.fields.budget.budget-type.label', 'Budget group'),
                                    t('forms.fields.budget.amount.label', 'Amount'),
                                    t('forms.fields.budget.comment.label', 'Comment'),
                                   /*  t('forms.fields.budget.number_block.label', 'Heures'), */
                                ] : [
                                    t('forms.fields.budget.budget-type.label', 'Budget group'),
                                    t('forms.fields.budget.amount.label', 'Amount'),
                                    t('forms.fields.budget.number_block.label', 'Heures'),
                                    t('forms.fields.budget.year.label', 'Year'),
                                    t('forms.fields.budget.comment.label', 'Comment'),
                                ]}
                                items={budgets}
                                mapToCellContents={item => folder.project_type !== "internal_project" ? [
                                    item.budget_type,
                                    <FormattedNumber value={item.amount} suffix=" €" />,
                                    item.comment
                                    /* <FormattedNumber value={item.number_blocks} suffix="" /> */
                                ] : [
                                    item.budget_type,
                                    <FormattedNumber value={item.amount} suffix=" €" />,
                                    <FormattedNumber value={item.number_blocks} suffix="" />,
                                    item.year,
                                    item.comment
                                ]}
                                formFields={folder.project_type !== "internal_project" ? [
                                    <FormSelect
                                        name="budget_type"
                                        options={parameters.employee_cost_category}
                                        tableField
                                        mapOptionToText={val=>t('parameters.employee_cost_category.'+val)}
                                    />,
                                    <FormNumberField
                                        name="amount"
                                        min={0}
                                        max={99999.99}
                                        endAdornment="€"
                                        notNullable
                                        tableField
                                    />,

                                    <FormTextField
                                        name="comment"
                                        tableField
                                    />

                                   /*  <FormNumberField
                                        name="number_blocks"
                                        min={0}
                                        max={99999.99}
                                        notNullable
                                        tableField
                                    />, */
                                ] : [
                                    <FormSelect
                                        name="budget_type"
                                        options={parameters.employee_cost_category}
                                        tableField
                                        mapOptionToText={val=>t('parameters.employee_cost_category.'+val)}
                                    />,
                                    <FormNumberField
                                        name="amount"
                                        min={0}
                                        max={99999.99}
                                        endAdornment="€"
                                        notNullable
                                        tableField
                                    />,
                                    <FormNumberField
                                        name="number_blocks"
                                        min={0}
                                        max={99999.99}
                                        endAdornment=""
                                        notNullable
                                        tableField
                                    />,
                                    <FormNumberField

                                        name="year"

                                        max={9999}
                                        tableField
                                    />,
                                    <FormTextField
                                        name="comment"
                                        tableField
                                    />,
                                ]}

                                onAddClick={budgetForm.handleSubmit(handleBudgetSubmit)}
                                onRemoveClick={handleRemoveBudget}
                            />
                        </form>
                    </FormProvider>
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <Card
                    title={t('subfolders.title.edit-subfolder-bank-guarantees', 'Edit subfolder bank guarentees')}
                    hideButtons
                >
                    {showBankGuarantees && (
                        <FormProvider {...bankGuaranteeForm}>
                            <form style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormCategoryHeader>
                                    {t('subfolders.bank-guarantees', 'Bank guarantees')}
                                </FormCategoryHeader>
                                <FormTable
                                    columns={[
                                        t('forms.fields.bank-guarantee.amount.label', 'Amount'),
                                        t('forms.fields.bank-guarantee.constitution-date.label', 'Constitution date'),
                                        t('forms.fields.bank-guarantee.organism-reference.label', 'Organism reference'),
                                        t('forms.fields.bank-guarantee.release-date.label', 'Release date'),
                                    ]}
                                    items={bankGuarantees}
                                    mapToCellContents={item => [
                                        <FormattedNumber value={item.amount} suffix=" €" />,
                                        item.constitution_date && moment(item.constitution_date).format("DD/MM/YYYY"),
                                        item.organism_reference,
                                        item.release_date && moment(item.release_date).format("DD/MM/YYYY"),
                                    ]}
                                    formFields={[
                                        <FormNumberField
                                            name="amount"
                                            min={0}
                                            max={9999999999.99}
                                            endAdornment="€"
                                            notNullable
                                            tableField
                                        />,
                                        <FormDatePicker
                                            name="constitution_date"
                                            disableFuture
                                            tableField
                                        />,
                                        <FormTextField
                                            name="organism_reference"
                                            tableField
                                        />,
                                        <FormDatePicker
                                            name="release_date"
                                            tableField
                                        />,
                                    ]}
                                    onAddClick={bankGuaranteeForm.handleSubmit(handleBankGuaranteeSubmit)}
                                    onRemoveClick={handleRemoveBankGuarantee}
                                />
                            </form>
                        </FormProvider>
                    )}
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={5}>
                <Card
                    title={t('subfolders.title.edit-subfolder-associate', 'Edit subfolder associates')}
                    hideButtons
                >
                    {showAssociates && (
                        <FormProvider {...associateForm}>
                            <form style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormCategoryHeader>
                                    {t('subfolders.associates', 'Associates')}
                                </FormCategoryHeader>
                                <FormTable
                                    columns={[
                                        t('forms.fields.associate.name.label', 'Name'),
                                        t('forms.fields.associate.role-in-association.label', 'Role in the association'),
                                        t('forms.fields.associate.shares.label', 'Shares'),
                                    ]}
                                    items={associates}
                                    mapToCellContents={item => [
                                        companies.find(company => company.id === item.associate_company_id).name,
                                        item.associate_role_type,
                                        <FormattedNumber value={item.shares_percentage} suffix="%" />,
                                    ]}
                                    formFields={[
                                        <FormAutocomplete
                                            name="associate_company_id"
                                            options={companies}
                                            mapOptionToValue={company => company.id}
                                            mapOptionToText={company => company.name}
                                            tableField
                                            refreshClick={()=>{
                                                setFetchingCompanies(true);
                                                fetchCompanies().then(()=>{
                                                    setFetchingCompanies(false);
                                                })
                                            }}
                                            refreshLoading={fetchingCompanies}
                                        />,
                                        <FormSelect
                                            name="associate_role_type"
                                            options={parameters.association_role}
                                            tableField
                                            mapOptionToText={val=>t('parameters.association_role.'+val)}
                                        />,
                                        <FormNumberField
                                            name="shares_percentage"
                                            min={0}
                                            max={100}
                                            endAdornment="%"
                                            tableField
                                        />,
                                    ]}
                                    onAddClick={associateForm.handleSubmit(handleAssociateSubmit)}
                                    onRemoveClick={handleRemoveAssociate}
                                />
                            </form>
                        </FormProvider>
                    )}
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={6}>
                <Card
                    title={t('subfolders.title.edit-subfolder-subcontractor', 'Edit subfolder subcontractor')}
                    hideButtons
                >
                    {showSubcontractors && (
                        <FormProvider {...subcontractorForm}>
                            <form style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormCategoryHeader>
                                    {t('subfolders.subcontractors', 'Subcontractors')}
                                </FormCategoryHeader>
                                <FormTable
                                    columns={[
                                        t('forms.fields.subcontractor.company.label', 'Subcontractor'),
                                        t('forms.fields.subcontractor.role.label', 'Role'),
                                        t('forms.fields.subcontractor.contract-type.label', 'Contract type'),
                                        t('forms.fields.subcontractor.contractual-amount', 'Contractual amount'),
                                    ]}
                                    items={subcontractors}
                                    mapToCellContents={item => [
                                        companies.find(company => company.id === item.company_id).name,
                                        item.role,
                                        item.contract_type,
                                        <FormattedNumber value={item.contract_amount} suffix=" €" />,
                                    ]}
                                    formFields={[
                                        <FormAutocomplete
                                            name="company_id"
                                            options={companies}
                                            mapOptionToValue={company => company.id}
                                            mapOptionToText={company => company.name}
                                            tableField
                                            refreshClick={()=>{
                                                setFetchingCompanies(true);
                                                fetchCompanies().then(()=>{
                                                    setFetchingCompanies(false);
                                                })
                                            }}
                                            refreshLoading={fetchingCompanies}
                                        />,
                                        <FormTextField
                                            name="role"
                                            tableField
                                        />,
                                        <FormSelect
                                            name="contract_type"
                                            options={parameters.contract_type}
                                            tableField
                                            mapOptionToText={val=>t('parameters.contrat_type.'+val)}
                                        />,
                                        <FormNumberField
                                            name="contract_amount"
                                            min={0}
                                            max={9999999999.99}
                                            endAdornment="€"
                                            notNullable
                                            tableField
                                        />
                                    ]}
                                    onAddClick={subcontractorForm.handleSubmit(handleSubcontractorSubmit)}
                                    onRemoveClick={handleRemoveSubcontractor}
                                />
                            </form>
                        </FormProvider>
                     )}
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={8}>
                <Card
                    title={t('subfolders.title.edit-subfolder-stock', 'Edit stock')}
                    hideButtons
                >
                    <FormProvider {...stockForm}>
                        <form style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormCategoryHeader>
                                {t('subfolders.stock', 'Stock')}
                            </FormCategoryHeader>

                            <FormTable
                                columns={[
                                    t('forms.fields.subcontractor.stock-year.label', 'Year'),
                                    t('forms.fields.subcontractor.stock-trimesters.label', 'Number of trimesters'),
                                    t('forms.fields.subcontractor.stock-stock.label', 'Stock'),
                                    t('forms.fields.subcontractor.stock-comment.label', 'Comment'),
                                    t('forms.fields.subcontractor.stock-type.label', 'Type')
                                ]}
                                items={stocks}
                                mapToCellContents={item => [
                                    <TextField
                                        key={item.year}
                                        defaultValue={item.year}
                                        onBlur={(event)=>editSubfolderStock({
                                            id:item.id,
                                            data:{
                                                year:event.target.value
                                            },
                                            subfolder_id:subfolder.id
                                        })}
                                    />,
                                    <TextField
                                        key={item.trimester}
                                        defaultValue={item.trimester}
                                        onBlur={(event)=>editSubfolderStock({
                                            id:item.id,
                                            data:{
                                                trimester:event.target.value
                                            },
                                            subfolder_id:subfolder.id
                                        })}
                                    />,
                                    <TextField
                                        key={item.amount}
                                        defaultValue={item.amount}
                                        InputProps={{
                                            endAdornment: '€',
                                        }}
                                        onBlur={(event)=>editSubfolderStock({
                                            id:item.id,
                                            data:{
                                                amount:event.target.value
                                            },
                                            subfolder_id:subfolder.id
                                        })}
                                    />,
                                    <TextField
                                        key={item.comment}
                                        defaultValue={item.comment}
                                        onBlur={(event)=>editSubfolderStock({
                                            id:item.id,
                                            data:{
                                                comment:event.target.value
                                            },
                                            subfolder_id:subfolder.id
                                        })}
                                    />,
                                    <FormSelect
                                        defaultValue={item.stock_type}
                                        options={parameters.stock_type}
                                        mapOptionToText={val=>t('parameters.stock_type.'+val)}
                                        tableField
                                        changeEvent={(event)=>editSubfolderStock({
                                            id:item.id,
                                            data:{
                                                stock_type:event.target.value
                                            },
                                            subfolder_id:subfolder.id
                                        })}
                                    />
                                ]}
                                formFields={[
                                    <FormTextField
                                        name="year"
                                        label={t('forms.fields.subcontractor.stock-year.label', 'Year')}
                                    />,
                                    <FormNumberField
                                            name="trimester"
                                            min={0}
                                            max={9999999999.99}
                                            notNullable
                                            label={t('forms.fields.subcontractor.stock-trimesters.label', 'Number of trimesters')}
                                    />,
                                    <FormNumberField
                                            name="amount"
                                            min={-9999999999.99}
                                            max={9999999999.99}
                                            notNullable
                                            endAdornment="€"
                                            label={t('forms.fields.subcontractor.stock-stock.label', 'Stock')}
                                    />,
                                    <FormTextField
                                        name="comment"
                                        label={t('forms.fields.subcontractor.stock-comment.label', 'Comment')}
                                    />,
                                    <FormSelect
                                        name="stock_type"
                                        label={t('forms.fields.subcontractor.stock-type.label', 'Type')}
                                        options={parameters.stock_type}
                                        mapOptionToText={val=>t('parameters.stock_type.'+val)}
                                    />
                                ]}
                                onAddClick={stockForm.handleSubmit(handleStockSubmit)}
                                onRemoveClick={handleRemoveStock}
                            />
                        </form>
                    </FormProvider>
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={9}>
                <Card hideButtons noMarginTop style={{ maxHeight: 'calc(100vh - 170px)',minHeight: 'calc(100vh - 170px)'}} isGrey padding="0px" paddingTop="0px" paddingBottom="0px">
                    <InvoiceExplorer />
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={10}>
                <Card
                    title={t('subfolders.title.edit-subfolder-Contract', 'Edit Contracts')}
                    hideButtons
                >
                    <FormProvider {...contractForm}>
                        <form style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormCategoryHeader>
                                {t('subfolders.contracts', 'Contracts')}
                            </FormCategoryHeader>

                            <FormTable
                                columns={[
                                    t('forms.fields.subcontractor.contract-wbs.label', 'WBS'),
                                    t('forms.fields.subcontractor.contract-contract-date.label', 'Contract Date'),
                                    t('forms.fields.subcontractor.contract-contract-type.label', 'Contract Type'),
                                    t('forms.fields.subcontractor.contract-contract-amount.label', 'Amount'),
                                    t('forms.fields.subcontractor.contract-contract-description.label', 'Description'),
                                    t('forms.fields.subcontractor.contract-contract-comment.label', 'Comment'),
                                ]}
                                items={contracts}
                                mapToCellContents={item => [
                                    wbsPlanning.find(e=>e.id === item.wbs_task_id)?.task_code,
                                    moment(item.date).format('DD/MM/YYYY'),
                                    item.contract_type,
                                    item.amount+" €",
                                    item.description,
                                    item.comment
                                ]}
                                formFields={[
                                    <FormSelect
                                        name="wbs_task_id"
                                        label={t('forms.fields.subcontractor.contract-wbs.label', 'WBS')}
                                        options={wbsPlanning}
                                        mapOptionToText={val=>val.task_code+" - "+val.name}
                                        mapOptionToValue={val=>val.id}
                                        tableField
                                        required
                                    />,
                                    <FormDatePicker
                                        name="date"
                                        label={t('forms.fields.subcontractor.contract-contract-date.label', 'Contract Date')}
                                        tableField
                                    />,
                                    <FormSelect
                                        name="contract_type"
                                        label={t('forms.fields.subcontractor.contract-contract-type.label', 'Contract Type')}
                                        options={parameters.contract_type}
                                        mapOptionToText={val=>t('parameters.contract_type.'+val,val)}
                                        tableField
                                    />,
                                    <FormNumberField
                                        name="amount"
                                        min={0}
                                        max={9999999999.99}
                                        endAdornment="€"
                                        label={t('forms.fields.subcontractor.contract-contract-amount.label', 'Amount')}
                                        tableField
                                    />,
                                    <FormTextField
                                        name="description"
                                        label={t('forms.fields.subcontractor.contract-contract-description.label', 'Description')}
                                        tableField
                                    />,
                                    <FormTextField
                                        name="comment"
                                        label={t('forms.fields.subcontractor.contract-contract-comment.label', 'Comment')}
                                        tableField
                                    />,
                                ]}
                                onAddClick={contractForm.handleSubmit(handleContractSubmit)}
                                onRemoveClick={handleRemoveContract}
                                onEditClick={(item)=>{setContractEditOpen(true);setContractToEdit(item);}}
                            />
                        </form>
                    </FormProvider>
                </Card>
            </TabPanel>

            {competitorDialogOpen && <CompetitorDialog
                open={competitorDialogOpen}
                setOpen={setCompetitorDialogOpen}
                handleAdd={(v)=>handleCompetitorSubmit(v)}
                companies={companies || []}
             />}
            {competitorDialogEditOpen && <CompetitorDialog
                open={competitorDialogEditOpen}
                setOpen={setCompetitorDialogEditOpen}
                handleEdit={(v)=>handleEditCompetitor(v)}
                competitorToEdit={competitorToEdit}
                companies={companies || []}
             />}
             {contractEditOpen && <ContractEditDialog
                open={contractEditOpen}
                setOpen={setContractEditOpen}
                contractToEdit={contractToEdit}
                subFolderId={id}
                handleSetContract={(c)=>{
                    setContracts(contracts.map(e=>e.id === c.id ? c : e));
                }}
             />}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubfoldersEdit);
