import { useState, useCallback, useContext, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Tab, Tabs } from '@material-ui/core';
import { MdModeEdit } from 'react-icons/md';

import Card from '../../card/Card';
import ConsultTextField from '../../form/ConsultTextField';
import ConsultNumberField from '../../form/ConsultNumberField';
import ConsultDatePicker from '../../form/ConsultDatePicker';
import FormCategoryHeader from '../../form/FormCategoryHeader';
import FormRow from '../../form/FormRow';
import FormTable from '../../form/FormTable';
import { connect, mapStateToProps } from '../../../store/dispatchers';
import FormattedNumber from '../../FormattedNumber';
import InvoiceExplorer from '../../invoice/InvoiceExplorer';
import { a11yProps, TabPanel } from '../../navbar/TabsNavigation';
import UserContext from '../../../context/user';
import moment from 'moment';

const SubfoldersConsult = ({ folders, companies, users, wbsPlanning }) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const { hasPermission, user } = useContext(UserContext);
    const { t } = useTranslation();
    const history = useHistory();
    const { folderId, id } = useParams();
    const [folder] = useState(folders.find(folder => folder.id.toString() === folderId));
    const [subfolder] = useState(folder.subfolders.find(sf => sf.id.toString() === id));
    const [tab, setTab] = useState(+query.get('tab') || 0);


    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    const handleEditClick = useCallback((tab) => {
        history.push(`/folders/${folderId}/subfolders/${id}/edit`+ (tab ? `?tab=${tab}` : ""));
    }, [folderId, id, history]);

    const ProjectLeader = useMemo(() => {
        const pm = users.find(u => u.id === subfolder?.project_leader);
        if(pm) {
            return pm.user_user_extra_info?.code
                ? `${pm.user_user_extra_info.code} - ${pm.first_name} ${pm.last_name}`
                : `${pm.first_name} ${pm.last_name}`;
        }
        return pm;
    }, [ subfolder.project_leader, users ]);

    return (
        <>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                className="tab-item"
            >
                <Tab label="Informations générales" {...a11yProps(0)} />
                {/* <Tab label="Budget" {...a11yProps(3)} /> */}
                <Tab label="Contracts" {...a11yProps(9)} />
                { hasPermission("stock.read") && <Tab label="Stock" {...a11yProps(8)} />}
                { (subfolder?.project_leader === user.id || (hasPermission("invoices_all.read"))) && <Tab label="Prévisions de facturation" {...a11yProps(7)} />}
                {subfolder.subfolder_extra_info.bank_guarantee && <Tab label="Garanties bancaires" {...a11yProps(4)} />}
                {subfolder.subfolder_extra_info.associative_mission && <Tab label="Associés" {...a11yProps(5)} />}
                {subfolder.subfolder_extra_info.subcontracting &&  <Tab label="Sous-contractants" {...a11yProps(6)} />}
            </Tabs>
            <TabPanel value={tab} index={0}>
                <Card
                    title={t('subfolders.title.consult-subfolder', 'Subfolder information')}
                    hideButtons
                >
                    {(subfolder?.project_leader === user.id || hasPermission("subfolder_admin.update")) && <Button
                        color="primary"
                        style={{ position: 'absolute', top: 25, right: 25 }}
                        variant="contained"
                        startIcon={<MdModeEdit />}
                        onClick={()=>handleEditClick()}
                    >
                        {t('button.edit', 'Edit')}
                    </Button>}
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader smallMarginTop>
                            {t('subfolders.subfolder-information', 'Subfolder information')}
                        </FormCategoryHeader>
                        <FormRow>
                            <ConsultTextField
                                name="folder"
                                label={t('forms.fields.subfolder.folder.label', 'Folder')}
                                value={folder.name}
                            />
                            <ConsultNumberField
                                name="total_work_amount"
                                label={t('forms.fields.subfolder.total-work-amount.label', 'Total Work Amount')}
                                endAdornment="€"
                                value={subfolder.subfolder_extra_info.total_work_amount}
                            />
                        </FormRow>
                        <ConsultTextField
                            name="rank"
                            label={t('forms.fields.subfolder.rank.label', 'Code rank')}
                            value={folder.lead_code+'/'+subfolder.rank}
                        />
                        <ConsultTextField
                            name="code"
                            label={t('forms.fields.subfolder.code.label', 'Code')}
                            value={subfolder.code}
                        />
                        <ConsultTextField
                            name="status_type"
                            label={t('forms.fields.subfolder.status.label', 'Status')}
                            value={subfolder.status_type}
                        />
                        <ConsultTextField
                            name="customer_company_id"
                            label={t('forms.fields.subfolder.customer.label', 'Customer')}
                            value={companies.find(company => company.id === subfolder.customer_company_id)?.name}
                        />
                        <ConsultTextField
                            name="name"
                            label={t('forms.fields.subfolder.name.label', 'Name')}
                            value={subfolder.name}
                        />
                        <ConsultTextField
                                name="subfolder_type"
                                label={t('forms.fields.subfolder.type.label', 'Subfolder type')}
                                value={subfolder.subfolder_type}
                        />
                        <ConsultTextField
                            name="project_leader"
                            label={('forms.fields.subfolder.project_leader.label', 'Project leader')}
                            value={ProjectLeader}
                        />
                        <FormRow>
                            <ConsultTextField
                                name="service_workpackage_type"
                                label={t('forms.fields.subfolder.service_workpackage_type.label', 'Service Workpackage')}
                                value={subfolder.subfolder_extra_info.service_workpackage_type}
                            />
                            <ConsultTextField
                                name="service_subworkpackage_type"
                                label={t('forms.fields.subfolder.service_subworkpackage_type.label', 'Service subworkpackage')}
                                value={subfolder.subfolder_extra_info.service_subworkpackage_type}
                            />
                        </FormRow>
                        <FormRow>
                            <ConsultDatePicker
                                name="mission_start"
                                label={t('forms.fields.subfolder.mission-start.label', 'Mission start date')}
                                value={subfolder.subfolder_extra_info.mission_start}
                            />
                            <ConsultDatePicker
                                name="temporary_reception_date"
                                label={t('forms.fields.subfolder.temporary-reception-date.label', 'Temporary reception date')}
                                value={subfolder.subfolder_extra_info.temporary_reception_date}
                            />
                        </FormRow>
                        <FormRow>
                            <ConsultDatePicker
                                name="final_reception_date"
                                label={t('forms.fields.subfolder.final-reception-date.label', 'Final reception date')}
                                value={subfolder.subfolder_extra_info.final_reception_date}
                            />
                            <ConsultDatePicker
                                name="good_execution_attest_date"
                                label={t('forms.fields.subfolder.good-execution-attest-date.label', 'Good execution attest date')}
                                value={subfolder.subfolder_extra_info.good_execution_attest_date}
                            />
                        </FormRow>
                        <FormRow>
                            <ConsultTextField
                                name="bank_guarantee"
                                label={t('forms.fields.subfolder.bank-guarantee.label', 'Bank guarantee?')}
                                value={subfolder.subfolder_extra_info.bank_guarantee ? 'Yes' : 'No'}
                            />
                            <ConsultTextField
                                name="subcontracting"
                                label={t('forms.fields.subfolder.subcontracting.label', 'Subcontracting?')}
                                value={subfolder.subfolder_extra_info.subcontracting ? 'Yes' : 'No'}
                            />
                        </FormRow>
                        <FormRow>
                            <ConsultTextField
                                name="associative_mission"
                                label={t('forms.fields.subfolder.associative-mission.label', 'Associative mission?')}
                                value={subfolder.subfolder_extra_info.associative_mission ? 'Yes' : 'No'}
                            />
                            <ConsultTextField
                                name="association_company_id"
                                label={t('forms.fields.subfolder.association-name.label', 'Association name')}
                                value={companies.find(company => company.id === subfolder.association_company_id)?.name}
                            />
                        </FormRow>
                        {subfolder.subfolder_extra_info_prospect && (
                            <>
                                <FormCategoryHeader smallMarginTop={true}>
                                    {t('subfolders.prospect', 'Prospect')}
                                </FormCategoryHeader>
                                <ConsultTextField
                                    name="object"
                                    label={t('forms.fields.prospect.object.label', 'Object')}
                                    value={subfolder.subfolder_extra_info_prospect.object}
                                />
                                <FormRow>
                                    <ConsultNumberField
                                        name="opportunity_amount_low"
                                        label={t('forms.fields.prospect.opportunity-amount-low.label', 'Opportunity amount low')}
                                        value={subfolder.subfolder_extra_info_prospect.opportunity_amount_low}
                                        endAdornment="€"
                                    />
                                    <ConsultNumberField
                                        name="opportunity_amount_high"
                                        label={t('forms.fields.prospect.opportunity-amount-high.label', 'Opportunity amount high')}
                                        value={subfolder.subfolder_extra_info_prospect.opportunity_amount_high}
                                        endAdornment="€"
                                    />
                                </FormRow>
                                <ConsultTextField
                                    name="source_type"
                                    label={t('forms.fields.prospect.source-type.label', 'Source')}
                                    value={subfolder.subfolder_extra_info_prospect.source_type}
                                />
                                <ConsultTextField
                                    name="comment"
                                    label={t('forms.fields.prospect.comment.label', 'Comment')}
                                    value={subfolder.subfolder_extra_info_prospect.comment}
                                />
                                <ConsultDatePicker
                                    name="prospection_date"
                                    label={t('forms.fields.prospect.prospection-date.label', 'Date')}
                                    value={subfolder.subfolder_extra_info_prospect.prospection_date ? new Date(subfolder.subfolder_extra_info_prospect.prospection_date) : null}
                                    format="dd/MM/yyyy"
                                />
                            </>
                        )}
                        {subfolder.subfolder_extra_info_offer && (
                            <>
                                <FormCategoryHeader smallMarginTop={true}>
                                    {t('subfolders.offer', 'Offer')}
                                </FormCategoryHeader>
                                <ConsultTextField
                                    name="submission_done"
                                    label={t('forms.fields.offer.submission-done.label', 'Submission done?')}
                                    value={subfolder.subfolder_extra_info_offer.submission_done ? 'Yes' : 'No'}
                                />
                                <FormRow>
                                    <ConsultDatePicker
                                        name="submission_date"
                                        label={t('forms.fields.offer.submission-date.label', 'Submission date')}
                                        value={subfolder.subfolder_extra_info_offer.submission_date}
                                    />
                                    <ConsultNumberField
                                        name="submission_hour"
                                        label={t('forms.fields.offer.submission-hour.label', 'Submission hour')}
                                        value={subfolder.subfolder_extra_info_offer?.submission_hour}
                                        endAdornment="H"
                                    />
                                    <ConsultNumberField
                                        name="offer_amount"
                                        label={t('forms.fields.offer.offer-amount.label', 'Offer amount')}
                                        value={subfolder.subfolder_extra_info_offer.offer_amount}
                                        endAdornment="€ HTVA"
                                    />
                                </FormRow>
                                <ConsultDatePicker
                                    name="go_to_tender_date"
                                    label={t('forms.fields.offer.go-to-tender-date.label', 'Go to tender date')}
                                    value={subfolder.subfolder_extra_info_offer.go_to_tender_date}
                                />
                                <ConsultTextField
                                    name="tendering_mode_type"
                                    label={t('forms.fields.offer.tendering_mode_type-done.label', 'Tendering mode')}
                                    value={subfolder.subfolder_extra_info_offer.tendering_mode_type}
                                />
                                <ConsultDatePicker
                                    name="submission_result_date"
                                    label={t('forms.fields.offer.submission_result_date.label', 'Submission result date')}
                                    value={subfolder.subfolder_extra_info_offer.submission_result_date}
                                />
                                <ConsultTextField
                                    name="submission_result_type"
                                    label={t('forms.fields.offer.submission-result-type.label', 'Result')}
                                    value={subfolder.subfolder_extra_info_offer.submission_result_type}
                                />
                                <ConsultNumberField
                                    name="order_intake"
                                    label={t('forms.fields.offer.order-intake.label', 'Order intake')}
                                    value={subfolder.subfolder_extra_info_offer?.order_intake}
                                    endAdornment="€ HTVA"
                                />
                                <ConsultTextField
                                    name="submission_comment"
                                    label={t('forms.fields.offer.submission-comment.label', 'Submission comment')}
                                    value={subfolder.subfolder_extra_info_offer.submission_comment}
                                />
                                <ConsultTextField
                                    name="refusal_type"
                                    label={t('forms.fields.offer.refusal-type.label', 'Refusal reason')}
                                    value={subfolder.subfolder_extra_info_offer.refusal_type}
                                />
                                <ConsultTextField
                                    name="refusal_comment"
                                    label={t('forms.fields.offer.refusal-comment.label', 'Refusal comment')}
                                    value={subfolder.subfolder_extra_info_offer.refusal_comment}
                                />
                                <FormCategoryHeader>
                                    {t('subfolders.competitors', 'Competitors')}
                                </FormCategoryHeader>
                                <FormTable
                                    columns={[
                                        t('forms.fields.competitor.mandatary.label', 'Mandatary'),
                                        t('forms.fields.competitor.price.label', 'Price'),
                                        t('forms.fields.competitor.final-rating.label', 'Final rating'),
                                        t('forms.fields.competitor.comment.label', 'Comment')
                                    ]}
                                    items={subfolder.subfolder_competitors}
                                    mapToCellContents={item => [
                                        companies.find(company => company.id === item.partner1_company_id).name,
                                        item.price && <FormattedNumber value={item.price} suffix=" €"/>,
                                        item.final_rating && <FormattedNumber value={item.final_rating} suffix="/100"/>,
                                        item.comment,
                                    ]}
                                />
                            </>
                        )}
                    </Box>

                </Card>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <Card hideButtons title={t('subfolders.title.consult-budget', 'Subfolder budget')}>
                    {hasPermission("subfolder.write") && (subfolder?.project_leader === user.id || hasPermission("subfolder_admin.update")) && <Button
                            color="primary"
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            variant="contained"
                            startIcon={<MdModeEdit />}
                            onClick={()=>handleEditClick(3)}
                        >
                        {t('button.edit', 'Edit')}
                    </Button>}
                    <FormCategoryHeader>
                        {t('subfolders.budget', 'Budget')}
                    </FormCategoryHeader>
                    <FormTable
                        columns={folder.project_type !== "internal_project" ? [
                            t('forms.fields.budget.budget-type.label', 'Budget group'),
                            t('forms.fields.budget.number-blocks.label', 'Amount'),
                            t('forms.fields.budget.comment.label', 'Comment'),
                        ] : [
                            t('forms.fields.budget.budget-type.label', 'Budget group'),
                            t('forms.fields.budget.number-blocks.label', 'Amount'),
                            t('forms.fields.budget.number_block.label', 'Heures'),
                            t('forms.fields.budget.year.label', 'Year'),
                            t('forms.fields.budget.comment.label', 'Comment'),
                        ]}
                        items={subfolder.subfolder_budgets}
                        mapToCellContents={item => folder.project_type !== "internal_project" ? [
                            item.budget_type,
                            <FormattedNumber value={item.amount} suffix=" €" />,
                            item.comment
                        ] : [
                            item.budget_type,
                            <FormattedNumber value={item.amount} suffix=" €" />,
                            <FormattedNumber value={item.number_blocks} suffix="" />,
                            item.year,
                            item.comment
                        ]}
                    />
                </Card>
            </TabPanel>
            { subfolder.subfolder_extra_info.bank_guarantee && (<TabPanel value={tab} index={4}>
                <Card hideButtons title={t('subfolders.title.consult-bank-guarantees', 'Subfolder bank guarantees')}>
                    {hasPermission("subfolder.write") && (subfolder?.project_leader === user.id || hasPermission("subfolder_admin.update")) && <Button
                            color="primary"
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            variant="contained"
                            startIcon={<MdModeEdit />}
                            onClick={()=>handleEditClick(4)}
                        >
                        {t('button.edit', 'Edit')}
                    </Button>}
                    <>
                        <FormCategoryHeader>
                            {t('subfolders.bank-guarantees', 'Bank guarantees')}
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.bank-guarantee.amount.label', 'Amount'),
                                t('forms.fields.bank-guarantee.constitution-date.label', 'Constitution date'),
                                t('forms.fields.bank-guarantee.organism-reference.label', 'Organism reference'),
                                t('forms.fields.bank-guarantee.release-date.label', 'Release date'),
                            ]}
                            items={subfolder.subfolder_bank_guarantees}
                            mapToCellContents={item => [
                                <FormattedNumber value={item.amount} suffix=" €" />,
                                item.constitution_date && moment(item.constitution_date).format("DD/MM/YYYY"),
                                item.organism_reference,
                                item.release_date && moment(item.release_date).format("DD/MM/YYYY"),
                            ]}
                        />
                    </>
                </Card>
            </TabPanel>)}
            { subfolder.subfolder_extra_info.associative_mission && (<TabPanel value={tab} index={5}>
                <Card hideButtons title={t('subfolders.title.consult-associates', 'Subfolder associates')}>
                    {hasPermission("subfolder.write") && (subfolder?.project_leader === user.id || hasPermission("subfolder_admin.update")) && <Button
                            color="primary"
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            variant="contained"
                            startIcon={<MdModeEdit />}
                            onClick={()=>handleEditClick(5)}
                        >
                        {t('button.edit', 'Edit')}
                    </Button>}
                    <>
                        <FormCategoryHeader>
                            {t('subfolders.associates', 'Associates')}
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.associate.name.label', 'Name'),
                                t('forms.fields.associate.role-in-association.label', 'Role in the association'),
                                t('forms.fields.associate.shares.label', 'Shares'),
                            ]}
                            items={subfolder.subfolder_associates}
                            mapToCellContents={item => [
                                companies.find(company => company.id === item.associate_company_id).name,
                                item.associate_role_type,
                                <FormattedNumber value={item.shares_percentage} suffix="%" />,
                            ]}
                        />
                    </>
                </Card>
            </TabPanel>)}
             { subfolder.subfolder_extra_info.subcontracting && (
            <TabPanel value={tab} index={6}>
                <Card hideButtons title={t('subfolders.title.sub-contracts', 'Subfolder sub-contracts')}>
                    <>
                        {
                            hasPermission("subfolder.write") &&
                            (
                                subfolder?.project_leader === user.id ||
                                hasPermission("subfolder_admin.update")
                            ) && <Button
                        color="primary"
                        style={{ position: 'absolute', top: 25, right: 25 }}
                        variant="contained"
                        startIcon={<MdModeEdit />}
                        onClick={()=>handleEditClick(6)}
                    >
                        {t('button.edit', 'Edit')}
                    </Button>}
                        <FormCategoryHeader smallMarginTop={true}>
                            {t('subfolders.subcontractors', 'Subcontractors')}
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.subcontractor.company.label', 'Subcontractor'),
                                t('forms.fields.subcontractor.role.label', 'Role'),
                                t('forms.fields.subcontractor.contract-type.label', 'Contract type'),
                                t('forms.fields.subcontractor.contractual-amount', 'Contractual amount'),
                            ]}
                            items={subfolder.subfolder_subcontractors}
                            mapToCellContents={item => [
                                companies.find(company => company.id === item.company_id).name,
                                item.role,
                                item.contract_type,
                                <FormattedNumber value={item.contract_amount} suffix=" €" />,
                            ]}
                        />
                    </>
                </Card>
            </TabPanel>
         )}
            <TabPanel value={tab} index={7}>
                <Card hideButtons noMarginTop style={{ maxHeight: 'calc(100vh - 170px)',minHeight: 'calc(100vh - 170px)'}} isGrey padding="0px" paddingTop="0px" paddingBottom="0px">
                    <InvoiceExplorer />
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={8}>
            <Card hideButtons title={t('subfolders.title.consult-stock', 'Subfolder stock')}>
                    {
                        // hasPermission("subfolder.write") &&
                        (
                            subfolder?.project_leader === user.id ||
                            hasPermission("subfolder_admin.update")
                        ) && <Button
                            color="primary"
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            variant="contained"
                            startIcon={<MdModeEdit />}
                            onClick={()=>handleEditClick(8)}
                        >
                        {t('button.edit', 'Edit')}
                    </Button>}
                    <FormCategoryHeader>
                        {t('subfolders.stock', 'Stock')}
                    </FormCategoryHeader>
                    <FormTable
                        columns={[
                            t('forms.fields.subcontractor.stock-year.label', 'Year'),
                            t('forms.fields.subcontractor.stock-trimesters.label', 'Number of trimesters'),
                            t('forms.fields.subcontractor.stock-stock.label', 'Stock'),
                            t('forms.fields.subcontractor.stock-comment.label', 'Comment'),
                            t('forms.fields.subcontractor.stock-type.label', 'Type')
                        ]}
                        items={subfolder.subfolder_stocks}
                        mapToCellContents={item => [
                            item.year,
                            item.trimester,
                            item.amount+ " €",
                            item.comment,
                            item.stock_type
                        ]}
                    />
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={9}>
                {subfolder.subfolder_contracts && (
                    <Card hideButtons title={t('subfolders.title.consult-contracts', 'Subfolder contracts')}>
                      {
                          // hasPermission("subfolder.write") &&
                          (
                              subfolder?.project_leader === user.id ||
                              hasPermission("subfolder_admin.update")
                          ) && <Button
                            color="primary"
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            variant="contained"
                            startIcon={<MdModeEdit />}
                            onClick={()=>handleEditClick(10)}
                        >
                        {t('button.edit', 'Edit')}
                    </Button>}
                    <FormCategoryHeader>
                        {t('subfolders.contracts', 'Contracts')}
                    </FormCategoryHeader>
                    <FormTable
                        columns={[
                            t('forms.fields.subcontractor.contract-wbs.label', 'Wbs'),
                            t('forms.fields.subcontractor.contract-contract-date.label', 'Contract Date'),
                            t('forms.fields.subcontractor.contract-contract-type.label', 'Contract Type'),
                            t('forms.fields.subcontractor.contract-contract-amount.label', 'Amount'),
                            t('forms.fields.subcontractor.contract-contract-description.label', 'Description'),
                            t('forms.fields.subcontractor.contract-contract-comment.label', 'Comment'),
                        ]}
                        items={subfolder.subfolder_contracts}
                        mapToCellContents={item => [
                            wbsPlanning.find(e=>e.id === item.wbs_task_id)?.task_code,
                            moment(item.date).format('DD/MM/YYYY'),
                            item.contract_type,
                            item.amount+ " €",
                            item.description,
                            item.comment
                        ]}
                    />
                </Card>
                )}
            </TabPanel>
        </>
    );
};

export default connect(mapStateToProps)(SubfoldersConsult);
