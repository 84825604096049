import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
});

const CardTitle = ({ classes, children }) => {
    return (
        <Typography variant="h5" component="h2" className={`card-title ${classes.root}`}>{ children }</Typography>
    );
};

export default withStyles(styles)(CardTitle);