import { Button, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { IoClose } from 'react-icons/io5';
import { BiUndo } from 'react-icons/bi';
import { MdModeEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import TableHeadCell from '../table/TableHeadCell';

const FormTable = ({
    columns,
    items,
    mapToCellContents,
    formFields,
    onRemoveClick,
    onAddClick,
    onEditClick,
    theme,
    customTableHead
}) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        { onRemoveClick && !customTableHead &&
                            <TableHeadCell style={{ width: 60 }}>
                                { /* Remove action */ }
                            </TableHeadCell>
                        }
                        { !customTableHead && columns.map((column, index) => 
                            <TableHeadCell key={index} noPadding style={{width: (100/columns.length)+"%"}}>
                                { column }
                            </TableHeadCell>
                        ) }
                        { onAddClick && !customTableHead &&
                            <TableHeadCell style={{ width: 60 }}/>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { items.map((item, index) =>
                        <TableRow
                            key={index}
                            style={item.deleteOnSubmit ? { backgroundColor: theme.palette.error.light } : {}}
                        >
                            { onRemoveClick &&
                                <TableCell>
                                    <IconButton onClick={() => onRemoveClick(item)}>
                                        { item.deleteOnSubmit
                                            ? <BiUndo color={theme.palette.error.contrastText}/>
                                            : <IoClose color={theme.palette.error.main}/>
                                        }
                                    </IconButton>
                                </TableCell>
                            }
                            { mapToCellContents(item).map((content, index) =>
                                <TableCell key={index}>
                                    { content }
                                </TableCell>
                            ) }
                            { onAddClick && onEditClick && (<TableCell>
                                <IconButton onClick={() => onEditClick(item)}>
                                    <MdModeEdit color={theme.palette.primary.main}/>
                                </IconButton>
                            </TableCell>) }
                        </TableRow>
                    ) }
                    { onAddClick &&
                        <TableRow>
                            <TableCell/>
                            { formFields.map((field, index) =>
                                // The colSpan property is set to 1 or, if it's the last field, to a number corresponding to the remaining columns count
                                <TableCell
                                    key={index}
                                    colSpan={formFields.length < columns.length ? (index === formFields.length - 1 ? columns.length - formFields.length + 1 : 1) : 1}
                                >
                                    { field }
                                </TableCell>
                            ) }
                            <TableCell align="right">
                                <Button
                                    onClick={onAddClick}
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                >
                                    { t('button.add', 'Add') }
                                </Button>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default withTheme(FormTable);