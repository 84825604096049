import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import FormNumberField from "../form/FormNumberField";
import FormTextField from "../form/FormTextField";
import moment from "moment"
import {useCallback, useContext, useEffect, useMemo, useState} from "react"
import {FormProvider, useForm} from "react-hook-form";
import {MdAdd, MdChevronLeft, MdChevronRight} from "react-icons/md"
import MONTH from "../../enums/month";
import WEEK from "../../enums/week";
import AppDialog from '../dialogs/AppDialog';
import FormDatePicker from "../form/FormDatePicker";
import PlannerTaskSelectDialog from "./PlannerTaskSelectDialog";
import {useTheme} from '@material-ui/core/styles';
import {uppercaseFirst} from '../../utils/converters';
import SnackbarContext from '../../context/snackbar';
import useYupResolver from "../../hooks/useYupResolver";
import {userBookingUpdateSchema as schema} from '../../services/yup';
import {useTranslation} from 'react-i18next';
import {connect, mapDispatchToProps, mapStateToProps} from "../../store/dispatchers";

const useStyles = makeStyles(theme => ({
    root: ({ color }) => ({
        '&:hover': {
            backgroundColor: color +" !important",
            opacity:0.5 + " !important",
            cursor:"pointer"
        },
    }),
    tableBottom:{
        '&:last-child': {
            borderBottom:'1px solid lightgrey',
        }
    },
    dialogColorSpan:{
        color:theme.palette.primary.main
    }
}));

const NUMBER_BLOCK_BASE = 10;
const NUMBER_DAYS_TO_SHOW = 7;

function BlockInput(props) {
    const {
        disabledInputs,
        inputKey,
        foundValue,
        taskId,
        dayToAdd,
        foundUserBooking,
        setSelectedTask,
        setTaskIdEdit,
        setOpenEditDialog,
        setUserBookingIdEdit,
        handleChange,
        handleBlur
    } = props;

    // !!!!!!!!!!!! small dd count inputs
    return (
        <div style={{ position: "relative" }}>
            <input
                key={inputKey}
                className="wbs-planning-input"
                type="tel"
                min="0"
                max={NUMBER_BLOCK_BASE}
                style={{ width: '25px' }}
                disabled = {disabledInputs.includes(inputKey)}
                onFocus={() => setSelectedTask(taskId)}
                value={foundValue.value}
                onChange={(e) => handleChange(e, taskId, dayToAdd)}
                onBlur={(e) => {
                    handleBlur(e, taskId, dayToAdd, foundUserBooking ? foundUserBooking.id : null, inputKey)
                }}
                onDoubleClick={() => {
                    if (foundValue.userBookId) {
                        setSelectedTask(taskId);
                        setTaskIdEdit(taskId);
                        setOpenEditDialog(true);
                        setUserBookingIdEdit(foundValue.userBookId);
                    }
                }}
            />
        </div>
    )
}

const PlannerExplorer = connect(mapStateToProps, mapDispatchToProps)(({dateMonthRange,setDateMonthRange,userBookings,selectedTask,setSelectedTask,fetchFolders,createUserBooking,user,deleteUserBooking,updateUserBooking,editUserBooking,parametersNumerical,fetchUserBookingsTaskForUser, parameters, fetchParametersNumerical}) => {
    const classes = useStyles({ color:'#ffee11' });
    const { t } = useTranslation();
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);
    const [month,setMonth] = useState(new Date())
    const [filteredArray, setFilteredArray] = useState([]);
    const [filteredByMonth,setFilteredByMonth] = useState([]);
    const [open,setOpen] = useState(false);
    const [loadingFolders,setLoadingFolders] = useState(false);
    const [dayTotalCount,setDayTotalCount] = useState(null);
    const [inputValues,setInputValues] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [loading,setLoading] = useState(false);
    const [loadingEdit,setLoadingEdit] = useState(false);
    const palette = useTheme().palette;
    const [userBookingIdEdit,setUserBookingIdEdit] = useState(null);
    const [taskIdEdit,setTaskIdEdit] = useState(null);
    const [userBookingToEdit,setUserBookingToEdit] = useState(null);
    const [taskToEdit,setTaskToEdit] = useState(null);
    const limitWeekend = parametersNumerical.find(e=>e.class === "planner" && e.type==="planner_weekend_limits")?.numerical_value;
    const daysToShow = (limitWeekend === 0) ? NUMBER_DAYS_TO_SHOW - 2 : NUMBER_DAYS_TO_SHOW;

    const [disabledInputs, setDisabledInputs] = useState([]);

    const [currentUserBookingList, setCurrentUserBookingList] =useState(Array(NUMBER_BLOCK_BASE).fill({
        taskId:null,
    }));
    const form = useForm({
        defaultValues:{
            date:month
        }
    })
    const resolver = useYupResolver(schema);
    const formUserBooking  = useForm({
        resolver,
        defaultValues:{
            comment:'',
            number_blocks:0,
            distance:0,
            transport_price:0
        }
    });


     useEffect(()=>{
        // recuperer l'id , recuperer l'user_booking puis je dois completer le form, recuperer le useEffect dans PlannerSettings
        if(userBookingIdEdit !== null && taskIdEdit !== null){
            let foundTask = userBookings.find(e=>e.id === taskIdEdit);

            if(foundTask && foundTask.user_bookings){
                let foundUserBooking = foundTask.user_bookings.find(e=>e.id === userBookingIdEdit);
                if(foundUserBooking){
                    setUserBookingToEdit(foundUserBooking);
                    setTaskToEdit(foundTask);
                    formUserBooking.reset({
                        comment:foundUserBooking.comment,
                        starting_block:foundUserBooking.starting_block +1,
                        number_blocks:foundUserBooking.number_blocks,
                        distance:foundUserBooking.distance,
                        transport_price:foundUserBooking.transport_price
                    })
                }
            }
        }
        else{
            setTaskToEdit(null);
            setUserBookingToEdit(null);
        }
        // eslint-disable-next-line
    },[userBookingIdEdit,taskIdEdit,userBookings]);

    useEffect(()=>{
        if(open){
            setLoadingFolders(true)
            fetchFolders().then(()=>{
                setLoadingFolders(false)
            })
        }
    },[open,fetchFolders])

    useEffect(()=>{
        let userBooksOfTheDay = [];
        userBookings.forEach(e=>{
            e.user_bookings?.forEach(item=>{
                if(moment(item.date).isBetween(moment(dateMonthRange).startOf('day').toDate(),moment(dateMonthRange).add(6,'day').toDate(),undefined,'[]')){
                    userBooksOfTheDay.push({...item,color_code:e.color_code});
                }
            })
        });
        let blocksCopy = Array(NUMBER_BLOCK_BASE).fill({
            taskId:null,
           /*  number_blocks:null */
        });
        userBooksOfTheDay.forEach(userBook=>{
            for(let i = Math.floor(userBook.starting_block); i < ((Math.floor(userBook.starting_block))+Math.ceil(userBook.number_blocks)); i++){
                if(blocksCopy[i]){
                    let b = {...blocksCopy[i]};
                    b.taskId = userBook.wbs_task_id;
                   /*  b.userBookId = userBook.id; */
                   /*  b.number_blocks = 3; */
                    blocksCopy[i] = b;
                }
            }
        })
        setCurrentUserBookingList(blocksCopy);
    },[dateMonthRange,userBookings])

    useEffect(()=>{
        setMonth(dateMonthRange);
        //PANNEL BY MONTH (BOTTOM OF EXPLORER)
        let filteredByMonthList = userBookings.filter(e=>{
            let haveDate = false;
            let haveDatePlanned = false;
            e.user_bookings?.forEach(item=>{
                if(moment(item.date).startOf('month').format('YYYY-MM-DD') === moment(dateMonthRange).startOf('month').format('YYYY-MM-DD')){
                    haveDate = true;
                }
            })
            e.wbs_task_plannings?.forEach(item=>{
                if(moment(item.start_date).startOf('month').format('YYYY-MM-DD') === moment(dateMonthRange).startOf('month').format('YYYY-MM-DD')){
                    haveDatePlanned = true;
                }
            })
            return (haveDate || haveDatePlanned)
        });
        filteredByMonthList = filteredByMonthList.map(e=>{
            let filteredUserBook = e.user_bookings?.filter(item=>{
                return moment(item.date).startOf('month').format('YYYY-MM-DD') === moment(dateMonthRange).startOf('month').format('YYYY-MM-DD');

            })
            return {...e,user_bookings:filteredUserBook};
        })

        let filteredMonthByWbsId = {};
        for(const task of filteredByMonthList){
            if(filteredMonthByWbsId[task.wbs_id]){
                filteredMonthByWbsId[task.wbs_id].tasks.push(task)
            }else{
                filteredMonthByWbsId[task.wbs_id] = {
                    wb:task.wb,
                    tasks:[{...task}]
                }
            }
        }
        //transform wbs object to array
        let filteredMonthByWbsIdArray = [];
        // eslint-disable-next-line
        for(const [_key,val] of Object.entries(filteredMonthByWbsId)){
            filteredMonthByWbsIdArray.push(val);
        }
        setFilteredByMonth(filteredMonthByWbsIdArray);
        //FOLDER/TASK LIST (TOP OF EXPLORER)
        //remove task planning and not good date
        let totalDayNumberBlock = 0;
        let filteredOther = userBookings.filter(e=>{
            //if(e.wbs_task_plannings.length !== 0)return false;
            let haveDate = false;
            e.user_bookings?.forEach(item=>{
                    if(item.id === 9915769){
                    }
                if(moment(item.date).isBetween(moment(dateMonthRange).startOf('day').toDate(),moment(dateMonthRange).add(6,'day').toDate(),undefined,'[]')){
                    haveDate = true;
                }
            })
            return haveDate
        });
        //remove useless userBooking
        filteredOther = filteredOther.map(e=>{
            let filteredUserBook = e.user_bookings?.filter(item=>{
                if(moment(item.date).isBetween(moment(dateMonthRange).startOf('day').toDate(),moment(dateMonthRange).add(6,'day').toDate(),undefined,'[]') && item.user_id === user.id){
                    totalDayNumberBlock += item.number_blocks
                    return true
                }
                return false
            })
            return {...e,user_bookings:filteredUserBook};
        })
        setDayTotalCount(Math.round(totalDayNumberBlock*100)/100)
        //remove useless planning
        filteredOther = filteredOther.map(e=>{
            let filteredTaskPlanning = e.wbs_task_plannings?.filter(item=>{
                return moment(item.start_date).isBetween(moment(dateMonthRange).startOf('day').toDate(), moment(dateMonthRange).add(6, 'day').toDate(), undefined, '[]');
            })
            return {...e,wbs_task_plannings:filteredTaskPlanning};
        })
        /************************************ */
        let filter1 = userBookings.filter(item => {
            for(const task of filteredOther){
                if(task.id === item.id)return false
            }
            if(item.wbs_task_plannings?.length !== 0)return false
            if(item.user_bookings?.length !== 0){
                let toKeep = false;
                item.user_bookings?.forEach(e=>{
                    if(moment(e.date).unix() >= moment(dateMonthRange).subtract(3,'month').unix()){
                        toKeep = true;
                    }
                });
                return toKeep
            }else{
                return false;
            }

        })
        filter1 = filter1.map(e=>{
                return {...e,user_bookings:[]}
            })
        let comparedValue = filteredOther.concat(filter1)
        let filter2 = userBookings.filter(item=>{
            let toKeep = true;
            if(toKeep.length === 0)toKeep = false;
            let haveForDateRange = false;
            if(item.wbs_task_plannings && item.wbs_task_plannings?.length !== 0){
                for(const wbsTaskPlanning of item.wbs_task_plannings){
                    if(moment(wbsTaskPlanning.start_date).startOf('month').format('DD-MM-YYYY') === moment(dateMonthRange).startOf('month').format('DD-MM-YYYY')){
                        haveForDateRange = true;
                    }
                }
            }
            if(haveForDateRange){
                for(const t of comparedValue){
                    if(item.id === t.id){
                        toKeep = false;
                    }
                }
            }
            return (toKeep && haveForDateRange)
        })
        let filterNewList = filter2.map(e=>{
            return {...e,user_bookings:[]}
        })
        let joinNewList = comparedValue.concat(filterNewList)
        //Recuperer les planning task
        /*************************/
        let filteredOtherByAll = {};
        for(const task of joinNewList ){
            if(filteredOtherByAll[task.wbs_id]){

                filteredOtherByAll[task.wbs_id].tasks.push(task)
            }else{
                filteredOtherByAll[task.wbs_id] = {
                    wb:task.wb,
                    tasks:[{...task}]
                }
            }
        }
        let filteredOtherByAllArray = [];
        for(const [_key,val] of Object.entries(filteredOtherByAll)){

            filteredOtherByAllArray.push(val);
        }

        // remove user_bookings that not from actual user
        filteredOtherByAllArray.map(item => {
            if (item.tasks) {
                item.tasks = item.tasks.map(task => {
                    if (task.user_bookings) {
                        task.user_bookings = task.user_bookings.filter(booking => booking.user_id === user.id);
                    }
                    return task;
                });
            }
            return item;
        });

        //sort projects
        const sortedProjects = sortWbs(filteredOtherByAllArray)
        setFilteredArray(sortedProjects)
        const inputValuesFilled = fillInputsValues(filteredOtherByAllArray);
        if(inputValuesFilled.haveToRender)setInputValues(inputValuesFilled.newInputValue);
        //else setInputValues([]);

        form.reset({date:dateMonthRange})
        // eslint-disable-next-line

    },[dateMonthRange,userBookings])

    const sortWbs = useCallback((wbs) => {
        return wbs.sort((a,b)=>{
            return a.wb.folder.name.localeCompare(b.wb.folder.name)
        }).sort((a,b)=>{
            return a.wb.folder.project_type.localeCompare(b.wb.folder.project_type)
        })
    },[])

    const fillInputsValues = useCallback((fillterArray) => {
        const _inputValues = [...inputValues];
        let haveToRender = false;
        for(const wbs of fillterArray){
            for(const task of wbs.tasks){
                for(let i = 0; i < daysToShow; i++){
                    let foundUserBooking = task.user_bookings.find(e=>moment(dateMonthRange).add(i,'day').format('DD-MM-YYYY') === moment(e.date).format('DD-MM-YYYY'))
                    let foundValue = _inputValues.find(e => {
                        return e.uid === task.id+moment(dateMonthRange).add(i,'day').format('DD-MM-YYYY')
                    })
                    if(foundValue && foundValue.userBookId === null && foundUserBooking){
                        let indexOfFoundValue = _inputValues.map(e=>e.uid).indexOf(task.id+moment(dateMonthRange).add(i,'day').format('DD-MM-YYYY'));
                        if(indexOfFoundValue){
                            let _valueFound = _inputValues[indexOfFoundValue];
                            _valueFound.userBookId = foundUserBooking ? foundUserBooking.id : null;
                            _inputValues[indexOfFoundValue] = _valueFound;
                            haveToRender = true;
                        }
                    }
                    if(!foundValue){
                        let newValue = {userBookId:foundUserBooking?foundUserBooking.id:null,taskId:task.id,value:foundUserBooking?foundUserBooking.number_blocks : "",uid:task.id+moment(dateMonthRange).add(i,'day').format('DD-MM-YYYY')}
                        _inputValues.push(newValue)
                        haveToRender = true;
                    }
                }
            }
        }
        return {
            haveToRender,
            newInputValue:_inputValues
        }
    },[dateMonthRange,daysToShow,inputValues])

    const handleChange = useCallback((e,taskId,dayToAdd) => {
        e.preventDefault();
        if(isNaN(e.target.value) && e.target.value  !== "")return
        let copyInputValues = [...inputValues]
        let indexOfValues = copyInputValues.map(item => item.uid).indexOf(taskId+moment(dateMonthRange).add(dayToAdd,'day').format('DD-MM-YYYY'))
        if(indexOfValues !== -1){
            copyInputValues[indexOfValues].value = e.target.value
            setInputValues(copyInputValues)
        }

    },[dateMonthRange,inputValues])

    const getDayTotal = useCallback((dayToAdd) => {
        let total = 0;
        for(const task of userBookings){
            for(const userB of task.user_bookings){           
                if(moment(userB.date).format('DD-MM-YYYY') === moment(dateMonthRange).add(dayToAdd,'day').format('DD-MM-YYYY') && userB.user_id === user.id){
                    total += userB.number_blocks
                }
            }
        }
        return total
    },[dateMonthRange,userBookings])

    const handleBlur = useCallback((e,taskId,dayToAdd,userBookId = null, key = null) =>{
        e.preventDefault();
        let copyInputValues = [...inputValues]
        let indexOfValues = copyInputValues.map(item => item.uid).indexOf(taskId+moment(dateMonthRange).add(dayToAdd,'day').format('DD-MM-YYYY'))
        let limitWeekend = parametersNumerical.find(e=>e.class === "planner" && e.type==="planner_weekend_limits")?.numerical_value;
        let limitDailyValue = 10;
        let limitParamsFound = parametersNumerical.find(e=>e.class === "planner" && e.type==="planner_daily_limits");
        if(limitParamsFound)limitDailyValue = limitParamsFound.numerical_value;
        let dailyTotal = getDayTotal(dayToAdd);
        if(userBookId && e.target.value){
            let usertaskFound = userBookings.find(e=>e.id === copyInputValues[indexOfValues].taskId);
            if(usertaskFound) {
                let userBookFound = usertaskFound.user_bookings.find(e=>e.id === copyInputValues[indexOfValues].userBookId)
                if(userBookFound){
                    if(userBookFound.number_blocks !== +copyInputValues[indexOfValues].value){
                        let currentday = moment(dateMonthRange).add(dayToAdd,'day').day();
                        let isWeekend = currentday === 6 || currentday === 0;
                        if(limitWeekend === 0 && isWeekend){
                            copyInputValues[indexOfValues].value = userBookFound.number_blocks || null;
                            setInputValues(copyInputValues);
                            openErrorSnackbar(`You cannot add blocks during weekends`)
                            return true;
                        }
                        else if(isWeekend &&(dailyTotal + +copyInputValues[indexOfValues].value - userBookFound.number_blocks) > limitWeekend && limitWeekend !== null){
                            copyInputValues[indexOfValues].value = userBookFound.number_blocks;
                            setInputValues(copyInputValues);
                            openErrorSnackbar(`You cannot add more than ${limitWeekend} blocks during weekends`)
                            return true;
                        }
                        else if((dailyTotal + +copyInputValues[indexOfValues].value - userBookFound.number_blocks) > limitDailyValue){
                            copyInputValues[indexOfValues].value = userBookFound.number_blocks;
                            setInputValues(copyInputValues);
                            openErrorSnackbar(`You cannot add more than ${limitDailyValue} blocks per day`)
                        }else{
                            setDisabledInputs([...disabledInputs, key]);

                            editUserBooking({
                                id:userBookId,
                                taskId:+copyInputValues[indexOfValues].taskId,
                                userBooking:{
                                    number_blocks:+copyInputValues[indexOfValues].value
                                }
                            }).then(()=>{
                                setDisabledInputs([]);

                                fetchUserBookingsTaskForUser();
                                openSuccessSnackbar("Updated !");
                            })
                        }
                    }
                }
            }

        }else{
            if(copyInputValues[indexOfValues].value !== "" && e.target.value){
                let currentday = moment(dateMonthRange).add(dayToAdd,'day').day();
                let isWeekend = currentday === 6 || currentday === 0;
                if(limitWeekend === 0 && isWeekend){
                    copyInputValues[indexOfValues].value = '';
                    setInputValues(copyInputValues);
                    openErrorSnackbar(`You cannot add blocks during weekends`)
                    return true;
                }
                else if(isWeekend &&(dailyTotal + +copyInputValues[indexOfValues].value) >limitWeekend  && limitWeekend !== null){
                    copyInputValues[indexOfValues].value = '';
                    setInputValues(copyInputValues);
                    openErrorSnackbar(`You cannot add more than ${limitWeekend} blocks per day during weekend`)
                }else if((dailyTotal + +copyInputValues[indexOfValues].value) > limitDailyValue && !isWeekend){
                    copyInputValues[indexOfValues].value = '';
                    setInputValues(copyInputValues);
                    openErrorSnackbar(`You cannot add more than ${limitDailyValue} blocks per day`)
                }else{
                    let copyUserBookings = [...currentUserBookingList]

                    let sum = 0;
                    copyUserBookings.forEach(e=>{
                        if(e.taskId !== null)
                        {
                            sum++;
                        }
                    })
                    let starting_block = 0;
                    if(sum >= 10)sum = 0
                    if(copyUserBookings[sum].taskId === null){
                        starting_block = sum;
                    } else{
                        for(let i = 0; i < copyUserBookings.length; i++){
                            if(copyUserBookings[i].taskId === null){
                                starting_block = i;
                                break;
                            }
                        }
                    }

                    let taskList = [];
                    for(const wb of filteredArray){
                        for(const tk of wb.tasks){
                            taskList.push(tk)
                        }
                    }
                    let foundTask = taskList.find(e=>e.id === copyInputValues[indexOfValues].taskId);
                    let subfolderactive = true;
                    if(foundTask && foundTask.subfolder){
                        if(foundTask.subfolder.status_type !== "active"){
                            subfolderactive=false
                        }
                    }
                    if(subfolderactive){
                        if(sum < copyUserBookings.length){
                            setDisabledInputs([...disabledInputs, key]);

                            createUserBooking({
                                user_id:user.id,
                                wbs_task_id: selectedTask,
                                number_blocks: copyInputValues[indexOfValues].value ,
                                starting_block:starting_block,
                                comment:null,
                                date:moment(dateMonthRange).add(dayToAdd,'day').format('YYYY-MM-DD'),
                                invoiced: false,
                                will_not_invoice: false,
                                invoicing_forecast_detail_id:null,
                                invoice_detail_id:null

                            }).then(()=>{
                                setDisabledInputs([])

                                fetchUserBookingsTaskForUser();
                                openSuccessSnackbar('Sucessfully added');
                            })
                        }else{
                            openErrorSnackbar('You have reached the maximum number of blocks')
                        }
                    }else{
                        openErrorSnackbar(`Subfolder ${foundTask.subfolder.code} is linked to this task but his status is '${foundTask.subfolder.status_type}' and should be 'active'`)
                    }
                }
            }
        }
    },[disabledInputs, createUserBooking,currentUserBookingList,editUserBooking,fetchUserBookingsTaskForUser,filteredArray,inputValues,openErrorSnackbar,openSuccessSnackbar,selectedTask,user.id,dateMonthRange,getDayTotal,parametersNumerical,userBookings])

    const renderInput = useCallback((key, task,wbs,_index,dayToAdd = 0) => {

        let foundUserBooking = task.user_bookings.find(e=>moment(dateMonthRange).add(dayToAdd,'day').format('DD-MM-YYYY') === moment(e.date).format('DD-MM-YYYY'))
        let foundValue = inputValues.find(e => {
            return e.uid === task.id+moment(dateMonthRange).add(dayToAdd,'day').format('DD-MM-YYYY')
        })
        if(!foundValue){
            foundValue = {
                userBookId: foundUserBooking ? foundUserBooking.id : null,
                taskId: task.id,
                value: foundUserBooking ? foundUserBooking.number_blocks : "",
                uid: task.id + moment(dateMonthRange).add(dayToAdd, 'day').format('DD-MM-YYYY')
            }
        }

         return (
             <BlockInput
                 disabledInputs={disabledInputs}
                 inputKey={key}
                 foundValue={foundValue}
                 taskId={task.id}
                 dayToAdd={dayToAdd}
                 foundUserBooking={foundUserBooking}
                 setSelectedTask={setSelectedTask}
                 setTaskIdEdit={setTaskIdEdit}
                 setOpenEditDialog={setOpenEditDialog}
                 setUserBookingIdEdit={setUserBookingIdEdit}
                 handleChange={handleChange}
                 handleBlur={handleBlur}
            />
         )
    },[dateMonthRange,inputValues,handleChange,handleBlur,setSelectedTask])

    const handleCloseDialog = useCallback(() => {
        setOpenEditDialog(false);
    },[])

    const handleUpdateUserBooking = useCallback((values) => {
       setLoadingEdit(true);
        updateUserBooking({
            id:userBookingIdEdit,
            data:{
                ...values
            }
        }).then(()=>{
            setLoadingEdit(false);
            handleCloseDialog();
            openSuccessSnackbar('Sucessfully updated')
        })
    },[openSuccessSnackbar,updateUserBooking,userBookingIdEdit,handleCloseDialog])

    const handleDeleteUserBooking = useCallback(() => {
        setLoading(true);
        deleteUserBooking(userBookingIdEdit).then(()=>{
            handleCloseDialog()
            setLoading(false);

            openSuccessSnackbar('Sucessfully deleted')
        });
    },[deleteUserBooking,userBookingIdEdit,openSuccessSnackbar,handleCloseDialog])

    const getUserBookingsTotalForWbs = useCallback((wbs) => {
        let tot = 0;
        for(const task of wbs.tasks){
            for(const userBook of task.user_bookings){
                tot += userBook.number_blocks;
            }

        }
        return tot;
    },[])

    const handleEnterKey = useCallback((event) => {
        if (event.key === 'Enter') {
          let form = event.target.form
          let index = Array.prototype.indexOf.call(form, event.target)
          form.elements[index + 1].focus()
          event.preventDefault()
        }
    },[])

    const getSubfolderCode = useCallback((task) => {
        return  task.task_code+" "+task.name
    },[])

    const renderWbs = useCallback((wbs) => {
        let userBookingTotal = getUserBookingsTotalForWbs(wbs)
        //generateInputsValues(wbs)
         //get planned task
        return (
            <ListItem key={wbs.wb.id} style={{padding:"0 0px"}}>
                <Box display="flex" flexDirection="column"  width="100%">
                    <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                        <ListItemText style={{textAlign:"left",fontWeight:"bolder",color:palette.primary.main}} primary={wbs.wb?.folder?.code + " - "+wbs.wb?.folder?.name+" - "+wbs.wb?.folder?.project_type} />

                        {/*the total dd on the right of the line : */}
                        <ListItemText style={{textAlign:"right",fontWeight:"bolder",color:palette.primary.main,marginRight:10}} primary={userBookingTotal+" dd"} />
                    </Box>
                    <Box display="flex" flexDirection="column">
                        {wbs.tasks.map((task,index)=>{

                            return (/* (task.user_bookings && task.user_bookings.length !== 0) ? task.user_bookings.map(item=>renderBooking(item,task)) : */
                            <Box
                                display="flex"
                                flexDirection="row"
                                width={(daysToShow === 7) ? "100%" : "75%"}
                                marginLeft={(daysToShow === 5) ? "8.5%" : ""}
                                justifyContent="space-between"
                                key={index}
                            >
                                <Grid
                                    item xs={5}
                                    onClick={()=>setSelectedTask(task.id)}
                                    style={{
                                        textAlign:"left",
                                        fontWeight:"bold",
                                        fontSize:"13px",
                                        backgroundColor:selectedTask === task.id ? "white" : "transparent",
                                        cursor:"pointer",
                                        width:"100%"
                                }}>
                                    {getSubfolderCode(task)} - {task.wb?.folder?.code} {task?.subfolder?.code ? '('+task?.subfolder?.code+')' : null}
                                </Grid>
                                {
                                    Array(daysToShow).fill(0).map((x,i)=>{
                                    const key = '' + task.id + i;

                                    // call the input
                                    return (
                                        <Grid
                                            key={key}
                                            item
                                            xs={1}
                                            style={{textAlign:"center"}}
                                            onKeyDown={(event) => handleEnterKey(event)}
                                        >
                                            {renderInput(key, task,wbs,index,i)}
                                        </Grid>)
                                    })
                                }
                            </Box>)
                        })}
                    </Box>
                </Box>
            </ListItem>
        )
    },[getUserBookingsTotalForWbs,daysToShow,getSubfolderCode,renderInput,selectedTask,handleEnterKey,palette,setSelectedTask])

    const taskSelectFromDialog = useCallback((task) => {
        let _task = {...task};
        if(_task.user_bookings && _task.user_bookings.length > 0){
            _task.user_bookings = _task.user_bookings.filter(e=>e.user_id === user.id);
        }
        let wbsObject = {};
        for(const obj of filteredArray){
            if(!wbsObject[obj.wb.id])wbsObject[obj.wb.id] = {wb:obj.wb};
            wbsObject[obj.wb.id].tasks = obj.tasks;
        }
        if(!wbsObject[_task.wb.id])wbsObject[_task.wb.id] = {wb:_task.wb,tasks:[]};
        let findTask = wbsObject[_task.wb.id].tasks.find(e=>e.id === _task.id);
        if(!findTask)wbsObject[_task.wb.id].tasks.push(_task)
        else{
            openErrorSnackbar('Task Already selected');
            return;
        }
        let wbsObjectToArray = [];
        for(const [_key,val] of Object.entries(wbsObject)){
            wbsObjectToArray.push(val);
        }
        setFilteredArray(sortWbs(wbsObjectToArray));
        const filledVl = fillInputsValues(wbsObjectToArray);
        if(filledVl.haveToRender)setInputValues(filledVl.newInputValue);
        setOpen(false);
        //addUserBookingToList(_task);
        setSelectedTask(_task.id);
    },[filteredArray,openErrorSnackbar,sortWbs,fillInputsValues,setSelectedTask,user])

    const renderMonthTable = useMemo(() => {
        //total block = 100% of june
        // 100 / totalBlock * totalWbsBlock
        return (<Box height="300px" width="90%" marginBottom="10px" marginLeft="auto" marginRight="auto">
            {filteredByMonth.map((wbs)=>{
                let totalWbsBlock = 0;
                let totalPlanning = 0;
                let color = null;
                for(const task of wbs.tasks){

                    if(!color)color = "#"+task.color_code
                    if(task && task.user_bookings){
                        for(const userBook of task.user_bookings){
                            if (userBook.user_id === user.id) {
                                totalWbsBlock += userBook.number_blocks;
                            }
                        }
                    }
                    if(task && task.wbs_task_plannings){
                        for(const taskPlanning of task.wbs_task_plannings){
                            if(moment(taskPlanning.start_date).startOf('month').format('DD-MM-YYYY') === moment(dateMonthRange).startOf('month').format('DD-MM-YYYY') && taskPlanning.assigned_user_id === user.id){
                                totalPlanning += taskPlanning.number_blocks 
                            }
                           
                        }
                    }
                }
                if(!color)color = "#aaaaaa"
                let percentage = 100;
                if(totalPlanning !== 0){
                    percentage = (100/totalPlanning)*totalWbsBlock;
                    if(percentage > 100)percentage = 100;
                }
                let primaryDarkColor = color
                let primaryLightColor = "#cccccc"
                let background = "linear-gradient(to right,"+primaryDarkColor+" "+percentage+"%, "+primaryLightColor+" "+percentage+"%)";
                return (<Box display="flex" height="40px" style={{position:"relative"}}>
                    <Box display="flex" width="100%" style={{backgroundImage: background,opacity:0.6}} position="absolute" height="40px"></Box>
                    <span style={{padding:"10px",fontSize:"13px",color:"black",display:"flex",position:"absolute"}}>{wbs.wb?.folder?.code} <span style={{paddingLeft:"10px"}}>{totalPlanning !== 0 ? totalWbsBlock+" / "+totalPlanning+" dd" : totalWbsBlock + " dd" }</span></span>
                </Box>)
            })}
        </Box>)
    },[filteredByMonth,dateMonthRange])

    const getDay = useCallback((number) => {
        let weekDay = t('list.week.'+WEEK[number],WEEK[number])
        return uppercaseFirst(weekDay);
    },[t])

    const renderDays = useMemo(() => {
        let arr = Array(daysToShow).fill(0);
        for(const wbs of filteredArray){
            for(const task of wbs.tasks){
                for(const userBook of task.user_bookings){
                    arr.forEach((e,i)=>{
                        if(moment(userBook.date).format('DD-MM-YYYY') === moment(dateMonthRange).add(i,'day').format('DD-MM-YYYY')){
                            arr[i] += userBook.number_blocks;
                        }
                    })
                }
            }
        }
        return Array(daysToShow).fill(0).map((x,i)=>{
            return <Grid item xs={1} style={{textAlign:"center"}}>{getDay((moment(dateMonthRange).day()+i)%7)}<br></br>{moment(dateMonthRange).add(i,'day').dates()}<br></br>{arr[i]?arr[i]+" dd":null}</Grid>
        })
    },[daysToShow,filteredArray,dateMonthRange,getDay])

    return (
        <>
            <Box display="flex" flexDirection="column" justifyContent="start" height="100%" className="app-kit-overflow-y">
                <Box display="flex" flexDirection="row" fontSize="25px" width="300px" justifyContent="space-between">
                    <FormProvider {...form}>
                        <IconButton onClick={()=>{setDateMonthRange(moment(month).subtract(7, 'day').toDate())}} style={{fontSize:"40px",marginLeft:"10px",marginTop:"15px"}}>
                            <MdChevronLeft />
                        </IconButton>
                        <FormDatePicker
                            name="date"
                            onChangeEvent={(date)=>setDateMonthRange(moment(date).startOf('week').add(1,'day').toDate())}
                            style={{minWidth:150}}
                        />

                        <IconButton onClick={()=>{setDateMonthRange(moment(month).add(7, 'day').toDate())}} style={{fontSize:"40px",marginRight:"10px",marginTop:"15px"}}>
                            <MdChevronRight />
                        </IconButton>
                        {dayTotalCount !== 0 && <Box marginTop="auto" paddingRight="10px" paddingTop="10px" marginBottom="auto" style={{color:"grey"}} display="flex" whiteSpace="nowrap" fontSize="18px">
                            {dayTotalCount} dd
                        </Box>}
                    </FormProvider>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="start" height="100%" >
                        <Box>
                            <table style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th style={{position:"sticky",top:0,backgroundColor:"lightgrey",zIndex:1}}>
                                            <Grid container position="sticky" top="0">
                                                <Grid item xs={5}>
                                                    <Box display="flex" flexDirection="row" justifyContent="start" paddingRight="10px" paddingLeft="10px">
                                                        <span style={{paddingTop:"5px",fontSize:"20px"}}>Tasks</span>
                                                        <MdAdd onClick={()=>setOpen(true)} className="planner-add-recent-button-icon" style={{fontSize:"20px",marginTop:"auto",marginBottom:"auto",paddingTop:"8px",marginLeft:20}}/>
                                                    </Box>
                                                </Grid>
                                                {renderDays}
                                            </Grid>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <form>
                                                <List>
                                                    {filteredArray.map((e)=>renderWbs(e))}
                                                </List>
                                            </form>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>

                    <Box display="flex" flexDirection="column" width="100%">
                        <Box marginLeft="10px" fontSize="20px" marginBottom="10px">
                            {uppercaseFirst(MONTH[month.getMonth()])} - {t('planner.title.booked_planned','(Booked/Planned)')}
                        </Box>
                        {renderMonthTable}
                    </Box>
                </Box>

            </Box>
            <AppDialog
                open={open}
                setOpen={setOpen}
                title="Select a task"
                okLabel="Select"
                onOk={()=>{}}
                onCancel={()=>setOpen(false)}
                keepOpenAfterOk
                fullWidth
                maxWidth="lg"
            >
                <PlannerTaskSelectDialog
                            loadingFolders={loadingFolders}
                            onTaskSelect={taskSelectFromDialog}
                />
            </AppDialog>
            <Dialog
                open={openEditDialog}
                onClose={()=>handleCloseDialog()}
                fullWidth
                maxWidth="sm"
            >
                 <DialogTitle>Edit User Booking</DialogTitle>
                 <DialogContent style={{marginBottom:"30px",paddingLeft:"50px",paddingRight:"50px"}}>
                    <DialogContentText>
                        {taskToEdit && userBookingToEdit &&
                        <>Update booking of <span className={classes.dialogColorSpan}>{userBookingToEdit.number_blocks}</span> dd starting
                         at <span className={classes.dialogColorSpan}>{userBookingToEdit.starting_block+1}</span> for task <span className={classes.dialogColorSpan}>{taskToEdit.task_code}</span>
                        </>}
                    </DialogContentText>
                    <FormProvider {...formUserBooking}>
                        <FormNumberField
                            name="number_blocks"
                            label={"Number blocks (1-"+NUMBER_BLOCK_BASE+")"}
                            min={1}
                            max={NUMBER_BLOCK_BASE}
                        />
                        <Box display="flex" flexDirection="row">
                            <FormNumberField
                                name="distance"
                                endAdornment={"km"}
                                label={t('planner.title.transport_distance','Distance')}
                                style={{marginRight:20}}
                            />
                            <FormNumberField
                                name="transport_price"
                                label={t('planner.title.transport_price_title','Transport Price')}
                                endAdornment={"€"}
                            />
                        </Box>
                        <FormTextField
                            name="comment"
                            label="Comment"
                        />
                    </FormProvider>
                 </DialogContent>
                 <DialogActions>
                    {loading ? <CircularProgress color="primary" /> : <Button onClick={()=>handleDeleteUserBooking()} color="secondary">
                        Delete
                    </Button>}
                    {loadingEdit ? <CircularProgress color="primary" /> : <Button variant="contained" onClick={formUserBooking.handleSubmit(handleUpdateUserBooking)} color="primary">
                        Update
                    </Button>}
                    <Button onClick={()=>handleCloseDialog()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
})

export default PlannerExplorer
