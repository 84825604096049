import { Button,Box, Table, TableHead,TableBody, TableCell, TableContainer, TableRow, Tab } from "@material-ui/core"
import TableHeadCell from "../table/TableHeadCell";
import { FormProvider, useForm } from 'react-hook-form';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import FormNumberField from '../form/FormNumberField';
import FormDatePicker from '../form/FormDatePicker';
import { MdArrowUpward, MdModeEdit, MdDelete } from 'react-icons/md';
import { FaCalendarAlt } from 'react-icons/fa';
import { useTheme } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from "react";
import DialogEditForecast from "./DialogEditForecast";
import DialogTimeSheet from './DialogTimeSheet';
import DialogEditInvoicingForecast from './DialogEditInvoicingForecast';
import moment from "moment";
import UserContext from "../../context/user";
import AppDialog from "../dialogs/AppDialog";
import {useTranslation} from "react-i18next";
import {isValidVat} from "../../utils/isValidVat";
const tableHeader = [
    {
        label:"Date",
        width:16
    },
    {
        label:"Production",
        width:10
    },
    {
        label:"Sous-Traitance",
        width:10
    },
    {
        label:"Achats",
        width:10
    },
    {
        label:"WBS",
        width:10
    },
    {
        label:"Description",
        width:14
    },
    {
        label:"VAT rate",
        width:10
    }
]
const InvoiceForecastList = ({
    subfolder,
    editInvoicingForecastDetail,
    wbsPlanning,
    userbookings,
    createInvoice,
    createInvoiceDetail,
    editInvoicingForecast,
    removeInvoiceForecast,
    isUsed = false,
    invoicingEncodingType,
    currentInvoice,
    subFolderId,
    wbsPlannings,
    createInvoiceForecast,
    createInvoiceForecastDetail,
    folders,
    folderId,
    fetchSubfoldersInvoices
}) => {
    const { t } = useTranslation();
    const [editObject,setEditObject] = useState({})
    const [dialogOpen,setOpen] = useState(false);
    const [timeSheetOpen, setTimeSheetOpen] = useState(false);
    const [editForecastOpen,setEditForecastOpen] = useState(false);
    const [userBookingFiltered,setUserBookingFiltered] = useState([]);
    const [invoiceForecastId,setInvoiceForecastId] = useState(0);
    const [invoicingFolrecastList,setInvoicingFolrecastList] = useState([]);

    const [invoicingToEditValues,setInvoicingToEditValues] = useState(null);
    const palette = useTheme().palette;
    const { hasPermission } = useContext(UserContext);
    const [loadingMovingInvoice,setLoadingMovingInvoice] = useState(null);
    const [tasks,setTasks] = useState([]);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [invoiceToDelete, setInvoiceToDelete] = useState(null);

    const form = useForm({
        defaultValues:{
            date:new Date(),
            production_amount:null,
            purchase_amount:null,
            subcontracting_amount:null,
            wbs_task_id:'',
            memo:'',
            vat_rate:21
        }
    })

    useEffect(()=>{
        let invoicesListCopy = [];
        if(subfolder && subfolder.invoicing_forecasts && subfolder.invoicing_forecasts?.length !== 0){
            subfolder.invoicing_forecasts.forEach(item=>{
                //check invoice detail id
                let invoicingForecastDetails = [...item.invoicing_forecast_details];
                let prod = invoicingForecastDetails.find(e=>e.invoice_detail_id === null);
                let prodUsed = invoicingForecastDetails.find(e=>e.invoice_detail_id !== null);
                if(!isUsed){
                    if(prod)invoicesListCopy.push(item);
                    else return;
                }
                else{
                    if(prodUsed)invoicesListCopy.push(item);
                    else return
                }
            })
        }
        setInvoicingFolrecastList(invoicesListCopy.sort((a,b)=>new Date(b.forecast_date).getTime() - new Date(a.forecast_date).getTime()));
    },[isUsed,subfolder])

    useEffect(()=>{
        let tasksList = [];
        if(wbsPlannings && wbsPlannings.length && wbsPlannings.length > 0){
            tasksList = wbsPlannings.filter(task=>{
                if(!task.subfolder_id)return true
                else{
                    if(task.subfolder_id === subfolder.id)return true
                    return false
                }
            }).map(task=>{
                let name = task.name;
                return {wbs_task_id:task.id,wbs_task_name:name}
            });
        }
        setTasks(tasksList)
    },[wbsPlannings,folders,subfolder])

    const onEditClick = (invoicing,invForDetail) => {
        setEditObject({...invoicing,
            id: invoicing.id,
            amount: invForDetail.amount,
            vat_rate: invForDetail.vat_rate,
            detailId: invForDetail.id,
            wbsId: invoicing.wbs_task_id
        });
        setOpen(true)
    }

    const editAction = (val) => {
        editInvoicingForecast({
            id: val.id,
            invoicingForecast:val.data,
        }).then((res)=>{
                editInvoicingForecastDetail({
                    id:val.data.detailId,
                    invoicingForecastDetails:{
                        amount: val.data.amount,
                        vat_rate: val.data.vat_rate,
                    }
                }).then((res)=>{
                    fetchSubfoldersInvoices({subfolder_id:subFolderId,folder_id:folderId})
                        .then(()=>{
                            setLoadingMovingInvoice(null)
                    });
                })
        })
        setOpen(false)
    }

    const onDeleteClick = (invoicing) => {
        setInvoiceToDelete(invoicing);
        setShowDeleteDialog(true);
    }

    const handleDeleteConfirm = (confirmed) => {
        if (confirmed) {
            removeInvoiceForecast(invoiceToDelete.id).then(() => {
                fetchSubfoldersInvoices({ subfolder_id: subFolderId, folder_id: folderId });
            });
        }

        setInvoiceToDelete(null);
    };

    const fetchSubFolderReload = () => {
        fetchSubfoldersInvoices({subfolder_id:subFolderId,folder_id:folderId}).then(()=>{
            setTimeSheetOpen(false);
        })
    }

    const onTimeSheetClick = (wbsTaskId,invoiceId) => {
        setInvoiceForecastId(invoiceId)
        setUserBookingFiltered(userbookings.filter(e=>e.wbs_task_id === wbsTaskId))
        setTimeSheetOpen(true);
    }

    const moveToInvoice = (invoicing,invForDetail,invoicing_forecast_type) => {
        if(invForDetail.invoice_detail_id == null && loadingMovingInvoice !== invForDetail.id){
            if(currentInvoice === null){
                setLoadingMovingInvoice(invForDetail.id);
                //create invoice
                createInvoice({
                    status_class:"invoice_status",
                    status_type:"draft",
                    subfolder_id:subfolder.id,
                    memo:invoicing.memo,
                    number:null,
                    issue_date:invoicing.forecast_date,
                    term_date: moment(invoicing.forecast_date).add(1, 'month').format('YYYY-MM-DD'),
                    invoice_class:"invoicing_forecast",
                    invoice_type:invoicing_forecast_type
                }).then(invoice=>{
                    createInvoiceDetail({
                        invoice_id:invoice.payload.value.id,
                        wbs_task_id:invoicing.wbs_task_id,
                        amount:invForDetail.amount,
                        vat_rate:invForDetail.vat_rate,
                    }).then((invoiceDetail)=>{
                        editInvoicingForecastDetail({
                            id:invForDetail.id,
                            invoicingForecastDetails:{
                                invoice_detail_id:invoiceDetail.payload.value.id
                            }
                        }).then(()=>{
                            fetchSubfoldersInvoices({subfolder_id:subFolderId,folder_id:folderId}).then(()=>{
                                setLoadingMovingInvoice(null)
                            });
                        })
                    })
                })
            }else{
                setLoadingMovingInvoice(invForDetail.id);
                createInvoiceDetail({
                    invoice_id:currentInvoice.id,
                    wbs_task_id:invoicing.wbs_task_id,
                    amount:invForDetail.amount,
                    vat_rate:invForDetail.vat_rate,
                }).then((invoiceDetail)=>{
                    editInvoicingForecastDetail({
                        id:invForDetail.id,
                        invoicingForecastDetails:{
                            invoice_detail_id:invoiceDetail.payload.value.id
                        }
                    }).then(()=>{
                        fetchSubfoldersInvoices({subfolder_id:subFolderId,folder_id:folderId}).then(()=>{
                            setLoadingMovingInvoice(null)
                        });
                    })
                })
            }
        }
    }

    const editInvoicingFromDialog = (val) => {
        editInvoicingForecast({
            id:val.id,
            invoicingForecast:val.data
        }).then((res)=>{
            fetchSubfoldersInvoices({subfolder_id:subFolderId,folder_id:folderId});
            setEditForecastOpen(false)
        })
    }

    const handleCreateInvoiceForecast = (values) => {
        createInvoiceForecast({
            subfolder_id:subFolderId,
            forecast_date:values.date,
            memo:values.memo,
            wbs_task_id:values.wbs_task_id
        }).then((invoiceForecastCreated)=>{
            let basics = {
                invoicing_forecast_id:invoiceForecastCreated.payload.value.id,
                invoicing_forecast_class:"invoicing_forecast"
            }
            Promise.all([
                !values.production_amount ? ()=>{} :
                createInvoiceForecastDetail({
                    ...basics,
                    invoicing_forecast_type:"production",
                    amount:values.production_amount,
                    vat_rate:values.vat_rate
                }),
                !values.subcontracting_amount ? ()=>{} :
                createInvoiceForecastDetail({
                    ...basics,
                    invoicing_forecast_type:"subcontracting",
                    amount:values.subcontracting_amount,
                    vat_rate:values.vat_rate
                }),
                !values.purchase_amount ? ()=>{} :
                createInvoiceForecastDetail({
                    ...basics,
                    invoicing_forecast_type:"purchase",
                    amount:values.purchase_amount,
                    vat_rate:values.vat_rate
                }),
            ]).then(()=>{
                fetchSubfoldersInvoices({subfolder_id:subFolderId,folder_id:folderId}).then(()=>{
                    form.reset({
                        date:new Date(),
                        production_amount:null,
                        purchase_amount:null,
                        subcontracting_amount:null,
                        wbs_task_id:'',
                        memo:'',
                        vat_rate:21
                    })
                })

            })
        })
    }

    const isSuperAdmin = hasPermission("permissions_all.*");

    const renderInvoicingForecast = (invoicing) => {

        let invoicingsCopy = [...invoicing.invoicing_forecast_details];

        invoicingsCopy = invoicingsCopy.sort((a,b)=>{

            if(b.invoicing_forecast_type === "production") return 1
            if(b.invoicing_forecast_type === "subcontracting" && a.invoicing_forecast_type !== "production")return 1
            else return -1
        })
        return invoicingsCopy.map(invForDetail =>{
            if(!isUsed && invForDetail.invoice_detail_id !== null)return null
            if(isUsed && invForDetail.invoice_detail_id === null)return null

            const format = (n) => {
                n = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(n);
                return n;
                /* return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') */
            }

            const vatRate = isValidVat(invForDetail.vat_rate) ? (invForDetail.vat_rate/100) : 0.21;

            return (

                    <TableRow key={invForDetail.id} style={{paddingBottom:50}} >
                        <TableCell style={{borderBottom:'none',width:"10%"}}>{moment(invoicing.forecast_date).format("DD-MM-YYYY")}</TableCell>
                        <TableCell style={{width:"10%",borderBottom:'none'}}> {invForDetail.invoicing_forecast_type === 'production' ? format(invForDetail.amount) : " 0 "} </TableCell>
                        <TableCell style={{width:"10%",borderBottom:'none'}}> {invForDetail.invoicing_forecast_type === 'subcontracting' ? format(invForDetail.amount) : " 0 "} </TableCell>
                        <TableCell style={{width:"10%",borderBottom:'none'}} > {invForDetail.invoicing_forecast_type === 'purchase' ? format(invForDetail.amount) : " 0 "} </TableCell>
                        <TableCell style={{borderBottom:'none'}}> {wbsPlanning.find(e=>e.id === invoicing.wbs_task_id)?.name}</TableCell>
                        <TableCell style={{width:"10%",borderBottom:'none'}} > {invoicing.memo}</TableCell>
                        <TableCell style={{width:"10%",borderBottom:'none'}} > {`${vatRate * 100} %`}</TableCell>
                        <TableCell style={{borderBottom:'none',width:"10%"}}>{format(invForDetail.amount)}</TableCell>
                        <TableCell style={{borderBottom:'none',width:"10%"}}>{format(Math.round(((invForDetail.amount*vatRate)+invForDetail.amount)*100)/100)} </TableCell>
                         {!isUsed &&
                            <TableCell style={{borderBottom:'none'}}>
                                <Box display="flex" flexDirection="row" justifyContent="start" width="80px">
                                    <Box display="flex" flex={0.25}>
                                        <MdModeEdit
                                            style={{color:palette.primary.main}}
                                            onClick={()=>onEditClick(invoicing,invForDetail)}
                                            className="invoice-forecast-list-edit-icon"/>
                                    </Box>
                                    { hasPermission('user_booking.read') && hasPermission("invoice.delete")
                                    ?
                                        <Box display="flex" flex={0.25}>
                                            <MdDelete
                                                style={{color:palette.primary.main}}
                                                onClick={()=>onDeleteClick(invoicing)}
                                                className="invoice-forecast-list-edit-icon"
                                            />
                                        </Box>
                                    :
                                        null
                                    }
                                    <Box display="flex" flex={0.25}>
                                        { (invoicingEncodingType === null || invoicingEncodingType === invForDetail.invoicing_forecast_type) &&
                                            <MdArrowUpward
                                                style={{color:(invForDetail.invoice_detail_id || (loadingMovingInvoice === invForDetail.id)) ? "grey" : palette.primary.main}}
                                                onClick={()=>moveToInvoice(invoicing,invForDetail,invForDetail.invoicing_forecast_type)}
                                                className={(invForDetail.invoice_detail_id || (loadingMovingInvoice === invForDetail.id)) ? 'invoice-forecast-list-arrow-up-disabled': 'invoice-forecast-list-arrow-up'} />
                                        }
                                    </Box>
                                    <Box display="flex" flex={0.25}>
                                        {invForDetail.invoicing_forecast_type === "production" && hasPermission('user_booking.read') ?
                                            <FaCalendarAlt
                                                style={{color:palette.primary.main}}
                                                onClick={()=>onTimeSheetClick(invoicing.wbs_task_id,invForDetail.id)}
                                                className="invoice-forecast-list-edit-icon"/>
                                            :
                                            null
                                        }
                                    </Box>
                                </Box>
                            </TableCell>
                        }

                        {/* for the invoice forecast used part */}
                        {isUsed && isSuperAdmin &&
                            <TableCell style={{borderBottom:'none'}}>
                                <Box display="flex" flexDirection="row" justifyContent="start" width="80px">
                                    <Box display="flex" flex={0.25}>
                                        <MdModeEdit
                                            style={{color:palette.primary.main}}
                                            onClick={()=>{
                                                onEditClick(invoicing,invForDetail)
                                            }}
                                            className="invoice-forecast-list-edit-icon"/>
                                    </Box>
                                </Box>
                            </TableCell>
                        }

                        {/*modal to confirm delete row*/}
                        <AppDialog
                            open={showDeleteDialog}
                            setOpen={setShowDeleteDialog}
                            title={t('Invoice-forecast-list.delete.cancel.title', 'Are you sure that you want delete the invoice ?')}
                            cancelLabel={t('Invoice-forecast-list.delete.cancel.label', 'Cancel')}
                            okLabel={t('Invoice-forecast-list.delete.confirm.label', 'Confirm')}
                            onOk={()=>{handleDeleteConfirm(true)}}
                            onCancel={()=>{handleDeleteConfirm(false)}}
                        />

                    </TableRow>

            )
        })
    }

    return (
                <TableContainer style={{minHeight: 'calc(200px)'}} >
                     <TableHead>
                            <TableRow style={{padding:'5rem'}}>
                                {
                                tableHeader.map(e=><TableHeadCell style={{width:e.width+"%"}} key={e.label}>{e.label}</TableHeadCell>)
                                }
                                <TableHeadCell style={{width:"14%"}}>Montant HTVA
                                </TableHeadCell>
                                <TableHeadCell style={{width:"14%"}}>Montant TVAC
                                </TableHeadCell>
                                <TableHeadCell style={{width:"14%"}}>Actions
                                </TableHeadCell>
                            </TableRow>
                        </TableHead>
                        {
                         !isUsed && <TableBody >

                        <FormProvider {...form}>
                                <TableRow>
                                    <TableCell padding="none" style={{paddingLeft:5,paddingRight:5,border:'none'}}>
                                        <FormDatePicker
                                            name="date"
                                        />
                                    </TableCell>
                                    <TableCell padding="none" style={{paddingLeft:5,paddingRight:5,border:'none'}}>
                                        <FormNumberField
                                            name="production_amount"
                                            min={-999999.99}
                                            max={999999.99}
                                            endAdornment="€"
                                        />
                                    </TableCell>
                                    <TableCell padding="none" style={{paddingLeft:5,paddingRight:5,border:'none'}}>
                                        <FormNumberField
                                            name="subcontracting_amount"
                                            min={-999999.99}
                                            max={999999.99}
                                            endAdornment="€"
                                        />
                                    </TableCell>
                                    <TableCell padding="none" style={{paddingLeft:5,paddingRight:5,border:'none'}}>
                                        <FormNumberField
                                            name="purchase_amount"
                                            min={-999999.99}
                                            max={999999.99}
                                            endAdornment="€"
                                        />
                                    </TableCell>

                                    <TableCell padding="none" style={{paddingLeft:5,paddingRight:5,border:'none'}}>
                                        <FormSelect
                                            name="wbs_task_id"
                                            options={tasks || []}
                                            mapOptionToValue={task=>task.wbs_task_id}
                                            mapOptionToText={task=>task.wbs_task_name}
                                        />
                                    </TableCell>
                                    <TableCell padding="none" style={{paddingLeft:5,paddingRight:5,border:'none'}}>
                                        <FormTextField
                                            name="memo"
                                        />
                                    </TableCell>
                                    <TableCell padding="none" style={{paddingLeft:5,paddingRight:5,border:'none'}}>
                                        <FormNumberField
                                            name="vat_rate"
                                            max={100}
                                            endAdornment="%"
                                        />
                                    </TableCell>
                                    <TableCell style={{borderBottom:'none'}}>
                                    </TableCell>
                                    <TableCell style={{borderBottom:'none'}}>
                                    </TableCell>
                                    <TableCell style={{border:'none'}}>
                                        <Box display="flex" flexDirection="row" justifyContent="center" marginTop="auto" width="100%" height="100%">
                                            <Button onClick={form.handleSubmit(handleCreateInvoiceForecast)} color="primary" variant="contained">Save</Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </FormProvider>
                            </TableBody>
                        }

                        {
                        invoicingFolrecastList.sort((a,b)=>{
                            return moment(a.forecast_date).unix() - moment(b.forecast_date).unix()
                        }).map((invoicing,index) => {
                            return (
                                <TableBody className="invoice-forecast-list-table invoice-forecast-list-table-first-remove" style={{marginTop:20, borderBottom:'none'}} >
                                    {invoicing?.invoicing_forecast_details?.length !== 0 && renderInvoicingForecast(invoicing)}
                                </TableBody>

                                )

                        })
                    }

                {/* for showing the dialog for the invoice forecast used part */}
                {isUsed && isSuperAdmin && <>
                    <DialogEditForecast
                        wbsPlanning={wbsPlanning}
                        editAction={(val)=>editAction(val)}
                        editObject={editObject}
                        open={dialogOpen}
                        setOpen={(val)=>setOpen(val)}
                    />
                </>}

                {!isUsed && <>
                <DialogEditForecast
                    wbsPlanning={wbsPlanning}
                    editAction={(val)=>editAction(val)}
                    editObject={editObject}
                    open={dialogOpen}
                    setOpen={(val)=>setOpen(val)}
                />

                <DialogTimeSheet fetchSubFolderReload={fetchSubFolderReload} invoiceForecastDetailId={invoiceForecastId} userBookingFiltered={userBookingFiltered} open={timeSheetOpen} setOpen={(val)=>setTimeSheetOpen(val)} />
              <DialogEditInvoicingForecast wbsPlanning={wbsPlanning} open={editForecastOpen} setOpen={(val)=>setEditForecastOpen(val)} editAction={(val)=>editInvoicingFromDialog(val)} editObject={invoicingToEditValues}/> </>}
        </TableContainer>

    )
}

export default InvoiceForecastList
