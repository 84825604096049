import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { defaultLanguage, saveTranslation } from '../../services/i18n';
import CardTitle from "../card/CardTitle";

const styles = theme => ({
    breadcrumb: {
        color: theme.palette.primary.main,
    },
    reference: {
        backgroundColor: theme.palette.admin.main,
    },
});

const TranslationKeyTranslator = ({ translationKey, language, onPreviousClick, onNextClick, classes }) => {
    const { t, i18n } = useTranslation();
    const [ translation, setTranslation ] = useState();
    const initialTranslation = useRef();
    const translationRef = useRef();
    let translationInput;

    useEffect(() => {
        translationInput.focus();
    });

    useEffect(() => {
        const currentTrans = i18n.getResource(language, 'translation', translationKey);
        updateTranslation(currentTrans);
        initialTranslation.current = currentTrans;

        return () => {
            if(translationRef.current && translationRef.current !== initialTranslation.current) {
                saveTranslation(language, translationKey, translationRef.current);
            }
        }
    }, [ translationKey, i18n, language ]);

    const updateTranslation = (newTranslation) => {
        setTranslation(newTranslation);
        translationRef.current = newTranslation;
    };

    return (
        <Box>
            <Box>
                <CardTitle>{ t('translate.source', 'Source') }</CardTitle>
                <Box data-testid="translation-key-breadcrumb" className={`translation-breadcrumb ${classes.breadcrumb}`}>
                    { translationKey.replaceAll ? translationKey.replaceAll('.', ' > ') : translationKey }
                </Box>
                <Box className={`translation-reference ${classes.reference}`}>
                    { i18n.getResource(defaultLanguage, 'translation', translationKey) }
                </Box>
            </Box>
            <Box>
                <CardTitle>{ t('translate.translation', 'Translation') }</CardTitle>
                <TextField
                    variant="outlined"
                    inputRef={input => { translationInput = input }}
                    id="translation-input"
                    name="translation-input"
                    placeholder={t('translate.input', 'Enter your translation here')}
                    value={translation ?? ''}
                    onChange={event => updateTranslation(event.target.value.replace('\n', ''))}
                    onKeyUp={event => event.key === 'Enter' && onNextClick()}
                    multiline
                    rowsMax={Infinity}
                    fullWidth
                    inputProps={{ 'data-testid': 'translation-input' }}
                />
            </Box>
            <Box className="translation-translation-buttons">
                <Button variant="contained" onClick={onPreviousClick} disableElevation>
                    { t('button.previous', 'Previous') }
                </Button>
                <Button variant="contained" onClick={onNextClick} disableElevation>
                    { t('button.next', 'Next') }
                </Button>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(TranslationKeyTranslator);