import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useContext, useRef,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import { TextField } from '@material-ui/core';
import SnackbarContext from '../../context/snackbar';

const DialogUploadFileInvoice = ({ open , setOpen, uploadInvoicesFile, subfolderId, fetchSubfolder,invoiceId,subfolder,fetchSubfoldersInvoices,folderId}) => {
    const { openSuccessSnackbar } = useContext(SnackbarContext);
    const { t } = useTranslation();
    const file = useRef(null)

    const handleClose = () => {
        setOpen(false);
    };

    const handleValidate = () => {
        const payload = {
            file:file.current.files[0],
            invoice_id:invoiceId
        }
        
        setOpen(false);
        uploadInvoicesFile(payload).then((res)=>{
          //fetchSubfolder(subfolderId);
          fetchSubfoldersInvoices({subfolder_id:subfolderId,folder_id:folderId})
          setOpen(false);
          openSuccessSnackbar("Upload completed"); 
        });
    }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('folder.subfolder.invoice.upload.title', 'Upload invoice')}</DialogTitle>
        <DialogContent>
                <TextField inputRef={file} type="file" style={{marginTop:"40px",width:"100%"}}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
          <Button onClick={()=>handleValidate()} color="primary">
          {t('button.save',"Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default  connect(mapStateToProps, mapDispatchToProps)(DialogUploadFileInvoice)