import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchParametersRelations = createAsyncThunk(
    'parametersRelations/fetchParametersRelations',
    async () => {
        const response = await axios.get('/parameters/relations/all');
        return response.data;
    },
);

const addParametersRelation = createAsyncThunk(
    'parametersRelations/addParametersRelation',
    async (parameter) => {
        const response = await axios.post('/parameters/create/relation', parameter);
        if(response.data.status === 'error') throw response.data.value;
        const res = await axios.get('/parameters/relations/all');
        return res.data;
    },
);

const deleteParametersRelations = createAsyncThunk(
    'parametersRelations/deleteParametersRelations',
    async (id) => {
        const response = await axios.delete(`/parameters/relation/delete/${id}`);
        if(response.data.status === 'error') throw response.data.value;
        const res = await axios.get('/parameters/relations/all');
        return res.data;
    },
);

export const parametersRelationsMethods = {
    fetchParametersRelations,
    addParametersRelation,
    deleteParametersRelations
}

const parametersRelationSlice = createSlice({
    name: 'parametersRelations',
    initialState: {},
    extraReducers: {
        [fetchParametersRelations.fulfilled]: (_state, action) => action.payload,
        [addParametersRelation.fulfilled]: (_state, action) => action.payload,
        [deleteParametersRelations.fulfilled]: (_state, action) => action.payload,
    },
});

export default parametersRelationSlice;