import { Redirect } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Box, Button } from '@material-ui/core';
import UserContext from '../context/user';
import { useTheme } from '@material-ui/core/styles';
import { mapStateToProps, mapDispatchToProps, connect } from '../store/dispatchers';
import TemplateExplorer from '../components/template/TemplateExplorer';
import TemplateFormTypeDialog from '../components/template/TemplateFormTypeDialog';
import TemplateSettings from '../components/template/TemplateSettings'
import { useTranslation } from 'react-i18next';


const TemplatePdf = ({fetchTemplatePdf,template}) => {
    const { t } = useTranslation();
    const { hasPermission } = useContext(UserContext);
    const palette = useTheme().palette;
    const [openDialog,setOpenDialog] = useState(false);

    const [templateKeys,setTemplateKeys] = useState([]);
    const [focusedKey,setFocusedKey] = useState(0);

    useEffect(()=>{
        fetchTemplatePdf()
        // eslint-disable-next-line
    }, []);

    useEffect(()=> {
        let templateArray = template.map(item=>item.template_type);
        setTemplateKeys(templateArray);
    },[template])
    
    const styles = {
        explorer: {
            backgroundColor: palette.admin.main,
        },
        parametersSetting: {
            backgroundColor: palette.admin.light,
        },
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    if(!hasPermission('template.read')) {
        return <Redirect to="/"/>
    }

    return (
        <Box className="page">
            <Box className="cards-row" height="calc(100vh - 114px)">
                <Box style={styles.explorer} className={`card parameters-explorer`}>
                    {hasPermission('template.write') && <Button variant="contained" color="primary" onClick={()=>handleOpenDialog()}>{t('template.create','Create template')}</Button>}
                    <TemplateFormTypeDialog
                        open={openDialog}
                        setOpen={(open)=>setOpenDialog(open)}
                    />
                    <TemplateExplorer
                        focusedKey={focusedKey}
                        keys={templateKeys}
                        onKeyClick={(index)=>setFocusedKey(index)}
                    />
                </Box>
                <Box style={styles.parametersSetting} className={`card parameters-settings`}>
                    <TemplateSettings
                        templateKey={focusedKey}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default connect( mapStateToProps, mapDispatchToProps )(TemplatePdf);