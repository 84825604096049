import { Box } from '@material-ui/core';
import { withTheme,} from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import PlanningExport  from './planningExport';
import PlanningImport from './planningImport';


/**
 * A toolbar for exporting & importing.
 * 
 * @param {any[]} props.dataTree - The task tree.
 * @param {any[]} props.users - The users.
 * @param {string} props.excelSheetName - The name and title of the exported Excel file.
 * @param {object} props.importWbsPlanningsAsync - import Disptach
 * @param {func} props.fetchWbsPlanning - fetch methods to trigger re-render
 * @param {integer} props.wbsId - The id of current wbs
 */
const PlanningToolbar = ({
    children,
    dataTree,
    users,
    excelSheetName,
    importWbsPlanningsAsync,
    fetchWbsPlanning,
    wbsId
}) => {
    
    return (
        <Box height="100%" display="flex" flexDirection="row" justifyContent="start">
             { children }
            <PlanningExport
                dataTree={dataTree}
                users={users}
                excelSheetName={excelSheetName}
                wbsId={wbsId}
            />
            <PlanningImport
                importWbsPlanningsAsync={importWbsPlanningsAsync}
                fetchWbsPlanning={fetchWbsPlanning}
                wbsId={wbsId}
            />
        </Box>
    );
}

PlanningToolbar.propTypes = {
    dataTree: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    excelSheetName: PropTypes.string.isRequired,
    importWbsPlanningsAsync: PropTypes.func.isRequired,
    fetchWbsPlanning: PropTypes.func
}

export default withTheme(PlanningToolbar);