import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core"
import { useContext, useEffect, useState } from "react";
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import Card from '../card/Card';
import TableHeadCell from "../table/TableHeadCell";
import { useTranslation } from 'react-i18next';
import { MdNavigateNext, MdAdd, MdNavigateBefore } from 'react-icons/md';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import moment from "moment";
import { useTheme } from '@material-ui/core/styles';
import DialogCreateUserTaskPlanning from './planning/DialogCreateAssignedTaskPlanning';
import { renderMonth } from './planning/PlanningUtils';
import PlanningToolbar from './planning/PlanningToolbar';
import { useParams,useHistory } from 'react-router-dom';
import UserContext from "../../context/user";
import SnackbarContext from "../../context/snackbar";
const MONTH_TO_SHOW = 6;


const Planning = ({wbsPlanning, users, updateWbsPlanningSync, createNotification, updateWbsPlanningAsync, createWbsPlanningAsync, folders, validatePlanningUpdate, importWbsPlanningsAsync, fetchWbsPlanning, wbsId}) => {
    const { t } = useTranslation();
    const palette = useTheme().palette;
    const { id } = useParams();
    const { user } = useContext(UserContext);
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);
    const history = useHistory();

    let keyCounter = 0;

    const [startDate,setStartDate] = useState(new Date());
    const [startMonth,setStartMounth] = useState(0);
    const [hideArray,setHideArray] = useState([]);
    const [usersArray, setUsersArray] = useState([]);
    const [dialogAddUserPlanningIsOpen,setDialogAddUserPlanningIsOpen] = useState(false);
    const [unssignedInputValues,setUnssignedInputValues] = useState([]);
    const [assignedInputValues,setAssignedInputValues] = useState([]);
    const [taskIdToAddUser,setTaskIdToAddUser] = useState(0);
    const [temporaryUsers,setTemporaryUsers] = useState([]);
    const [wbsTree,setWbsTree] = useState(null);
    const [folder,setFolder] = useState({});

    const [isManager,setIsmanager] = useState(false);
    const [sendMessage,setSendMessage] = useState('');

    useEffect(()=>{
       
        let folderFound = folders.find(fold=>fold.id === +id);
        setIsmanager(user.id === folderFound.project_manager_id);
        setFolder(folderFound); 
    },[folders, user.id, id])

    useEffect(()=>{
        setStartMounth(startDate.getMonth());
        setUsersArray(users);
    },[startDate,users]);
   
    useEffect(()=>{
      let wbsPlanningCopy = [...wbsPlanning];
      wbsPlanningCopy = wbsPlanningCopy.sort((a,b)=>{
            if(a.parent_id === null)return -1
            return +1
        }).map(item=>{
            let itemCopy = {...item};

            if(itemCopy.parent_id === null){
                itemCopy.parent_id = 0;
            }
            return itemCopy
        })   
       
        let tree = list_to_tree(wbsPlanningCopy);
        if(tree.length > 1)tree = tree.filter(item=>
            {
                //si chaque item a un subfolder null ou non 
                if(item.subfolder_id !== null){
                    if(folder.subfolders){
                        let subfolder = folder.subfolders.find(subfold=>subfold.id === item.subfolder_id);
                        if(subfolder){
                            if(subfolder.status_type === 'intermediate' || subfolder.status_type === 'active'){
                                return true; 
                            }else return false
                        }else return false
                    }
                    else return false
                }
                return true
            });
            
            setWbsTree(tree);  
        },[wbsPlanning,folder])

    const list_to_tree = (list) => {
        let map = {}, node, roots=[],i;
        for(i = 0; i < list.length; i++){
            map[list[i].id] = i;
            list[i].wbs_tasks = [];
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parent_id !== 0) {
              // if you have dangling branches check that map[node.parentId] exists
              list[map[node.parent_id]].wbs_tasks.push(node);
            } else {
              roots.push(node);
            }
        }
        return roots;
    }

    const renderTaskRow = (tasks, level) => {
        if(tasks.length !== 0)
        return tasks.map(task=>{
            keyCounter++;
            return (
                <>
                    <TableRow key={'tr'+keyCounter+''+task.id} >
                        <TableCell>
                            <Box paddingLeft={2*level} display="flex" flexDirection="row" color={palette.primary.main} fontWeight="bold">
                                { task.bookable ? getArrownDownUp(task.id) : <Box display="block" width="20px"></Box> }
                                { task.task_code+" - "+task.name }
                            </Box>
                        </TableCell>
                        {getCellForRow(task,level)}
                    </TableRow>
                    {(task.wbs_tasks.length === 0 && task.wbs_task_plannings.length !== 0 && !hideArray.includes(task.id)) ? renderPlanningRow(task,level) : null}
                    {(task.wbs_tasks.length === 0 && task.wbs_task_plannings.length === 0 && !hideArray.includes(task.id)) ? renderDefaultUnnassigned(task,level) : null}
                    {(task.wbs_tasks.length === 0) ? null : hideArray.includes(task.id) ? null : renderTaskRow(task.wbs_tasks,level+1)}
                </>
            )
        })
        return null;
    }
    
    const renderDefaultUnnassigned = (task,level) => {
        keyCounter++;
        return task.bookable ? (
            <>
            <TableRow key={'u_'+keyCounter+''+task.id}>
                <TableCell key={'c8_'+keyCounter}>
                    <Box paddingLeft={2*level} display="flex" flexDirection="row" color={palette.primary.main}>
                        <Box display="block" width={20} marginRight={1}/>
                        {t('wbs.plannings.unassigned','Unassigned')}
                    </Box>
                </TableCell>
                {renderCellForDefaultUnassgined(task)}
            </TableRow>
            
            </>
        ) : null;
    }

    const renderCellForDefaultUnassgined = (task,level) => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        //(task.wbs_tasks.length === 0 && task.wbs_task_plannings.length === 0)
        return toMap.map(v=>{
            keyCounter++;
            const dateToCheck = moment(date).add(v,'month');
            return  (
                <TableCell key={'uc_'+keyCounter+''+task.id}>
                    <Box key={'bc_'+keyCounter+''+task.id} display="flex" justifyContent="center">
                        <input onBlur={(event)=>createFirstUnassignedTask(event,task.id,dateToCheck)} type="number" className="wbs-planning-input"></input>
                    </Box>
                </TableCell>
            )
        })
    }

    const createFirstUnassignedTask = (event,taskId,dateToCheck) => {
        if(!isNaN(event.target.valueAsNumber) && event.target.valueAsNumber !== 0){
            let startTime = dateToCheck.startOf('month').format("YYYY-MM-DD")
            let endTime = dateToCheck.endOf('month').format("YYYY-MM-DD")
            createWbsPlanningAsync({
                taskPlanning:{
                    wbs_task_id:taskId,
                    planning_level_class:"planning_level",
                    planning_level_type:"type",
                    start_date:startTime,
                    end_date:endTime,
                    number_blocks:0,
                    number_blocks_new:event.target.valueAsNumber,
                    assigned_user_id:null
                },
                wbsId:wbsId
            })
        }
    }

    const getArrownDownUp = (taskId) => {
        if(hideArray.includes(taskId)){
           return <FaCaretRight onClick={()=>hideWbslevel(taskId)} style={{width:20,cursor:"pointer",fontSize:20,color:"black"}}/>
        }
        return <FaCaretDown onClick={()=>hideWbslevel(taskId)} style={{width:20,cursor:"pointer",fontSize:20,color:"black"}}/>
        
    }
    
    const getCellForRow = (task,level) => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        //(task.wbs_tasks.length === 0 && task.wbs_task_plannings.length === 0)
        return toMap.map(v=>{
            keyCounter++;
            const dateToCheck = moment(date).add(v,'month');
            return (
                <TableCell key={'c_'+keyCounter+''+task.id}>
                    <Box  display="flex" justifyContent="center" className={(task.wbs_tasks.length === 0 && level !== 0) ? '' : 'wbs-planning-category'}>
                        {(task.parent_id === 0 || task.wbs_tasks.length === 0) ? computeTotalLeft(task,dateToCheck) : null}
                    </Box>
                </TableCell>
            )
        })
    }

    const renderPlanningRow = (task,level) => {
        return (
            <>
            {renderUserPlanning(task,level)}
            {renderTemporaryUserPlanning(task.id,level,task.task_code)}
            {renderUnassigned(task,level)}
            {renderAddUserPlanning(task,level)}
            </>
        )
    }

    const renderUserPlanning = (task,level) => {
        if(task.wbs_task_plannings.length !== 0){
            let renderedUserIds = [];
            return task.wbs_task_plannings.map(taskPlanningItem=>{
                keyCounter++;
                if(taskPlanningItem.assigned_user_id !== null){
                    if(renderedUserIds.includes(taskPlanningItem.assigned_user_id))return null
                    renderedUserIds.push(taskPlanningItem.assigned_user_id)
                    
                    return (
                        <>
                        <TableRow key={'up_'+keyCounter}>
                            <TableCell key={'c2_'+keyCounter}>
                                <Box paddingLeft={2*level} display="flex" flexDirection="row" color={palette.primary.main} className="hover-black hover-pointer" onClick={()=>history.push("/users/"+taskPlanningItem.assigned_user_id+"?tab=2")}>
                                    <Box display="block" width={20} marginRight={1}/>
                                    {usersArray.find(userItem=>userItem.id === taskPlanningItem.assigned_user_id).first_name} {usersArray.find(userItem=>userItem.id === taskPlanningItem.assigned_user_id).last_name}
                                </Box>
                            </TableCell>
                            {getCellForRowUserPlanning(taskPlanningItem)}
                        </TableRow>
                        </>
                    )
                }
                return null
            })
        }
        return null;
    }

    const renderTemporaryUserPlanning = (taskId,level,taskCode) => {
        return temporaryUsers.filter(t=>t.taskId === taskId).map(user=>{
            keyCounter++;
            return (
                <>
                <TableRow key={'tup_'+keyCounter}>
                    <TableCell key={'c3_'+keyCounter}>
                        <Box paddingLeft={2*level} display="flex" flexDirection="row" color={palette.primary.main}>
                            <Box display="block" width={20} marginRight={1}/>
                            {usersArray.find(userItem=>userItem.id === user.user_id).first_name} {usersArray.find(userItem=>userItem.id === user.user_id).last_name}
                        </Box>
                    </TableCell>
                    {renderTemporaryUserCell(taskId,user.user_id,taskCode)}
                </TableRow>
                </>
            )
        })
    }

    const renderTemporaryUserCell = (taskId,userId,taskCode) => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        return toMap.map(v=>{
            keyCounter++;
            const dateToCheck = moment(date).add(v,'month');
            return (
                <TableCell key={'tuc_'+keyCounter}>
                    <Box display="flex" justifyContent="center">
                        {showInputTemporaryUser(taskId,dateToCheck,userId,taskCode)}
                    </Box>
                </TableCell>
            )
        })
    }

    const showInputTemporaryUser = (taskId,dateToCheck,userId,taskCode) => {
        //find if an unassigned task planning exist for the date and task id
        let foundTask = wbsPlanning.find(e=>e.id === taskId);
        if(foundTask){
            if(foundTask.wbs_task_plannings.length !== 0){
                let foundTaskPlanning = foundTask.wbs_task_plannings.find(e=>moment(e.start_date).startOf('month').format('YYYY-MM-DD') === dateToCheck.startOf('month').format('YYYY-MM-DD') && e.assigned_user_id === null);
                if(foundTaskPlanning){
                    return <input onBlur={(event)=>onQuitTemporaryUserInput(event,taskId,userId,dateToCheck)}  type="number" className="wbs-planning-input"></input>
                }
            }
        }
        return null
    }

    const onQuitTemporaryUserInput = (event,taskId,userId,dateToCheck) => {
        //create RealUser
        if(!isNaN(event.target.valueAsNumber) && event.target.valueAsNumber !== 0){
            let startTime = dateToCheck.startOf('month').format("YYYY-MM-DD")
            let endTime = dateToCheck.endOf('month').format("YYYY-MM-DD")
            //update unssigned
            let wbsPlanningCopy = [...wbsPlanning];
            let indexOfTask = wbsPlanningCopy.map(i=>i.id).indexOf(taskId);
            if(indexOfTask !== -1 && wbsPlanningCopy[indexOfTask].wbs_task_plannings !== 0){
                let indexOfPlanningUnssigned = wbsPlanningCopy[indexOfTask].wbs_task_plannings.map(e=>{
                    if(e.assigned_user_id === null){
                        return moment(e.start_date).startOf('month').format('YYYY-MM-DD');
                    }
                    return null
                }).indexOf(dateToCheck.startOf('month').format('YYYY-MM-DD'));
                if(indexOfPlanningUnssigned !== -1){
                    let assignedValue = event.target.valueAsNumber;
                    let previousUnnasignedValue = wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks_new || wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks;
                    let newUnassginedValue = previousUnnasignedValue - assignedValue;
                    if(newUnassginedValue < 0){
                        openErrorSnackbar('Cannot assigne more blocks than unassigned blocks left')
                    }
                    else{
                        createWbsPlanningAsync({
                            taskPlanning:{
                                wbs_task_id:taskId,
                                planning_level_class:"planning_level",
                                planning_level_type:"type",
                                start_date:startTime,
                                end_date:endTime,
                                number_blocks:0,
                                number_blocks_new:event.target.valueAsNumber,
                                assigned_user_id:userId
                            },
                            wbsId:wbsId
                        })
                        updateWbsPlanningSync({
                            idTask:indexOfTask,
                            idPlanning:indexOfPlanningUnssigned,
                            valueNew:newUnassginedValue,
                            value:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks
                        });
                        updateWbsPlanningAsync({
                            taskPlanning:{
                                number_blocks_new:newUnassginedValue,
                                number_blocks:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks
                            },
                            taskPlanningId:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].id
                        })
                        //update usassignedValue
                        updateUnssignedInput(taskId,dateToCheck,newUnassginedValue)
                        //remove temp user
                        let tmpUserCopy = [...temporaryUsers];
                        const indexOfTmpUser = tmpUserCopy.map(tmpUser=>tmpUser.taskId+""+tmpUser.user_id).indexOf(taskId+""+userId)
                        if(indexOfTmpUser >= 0){
                            tmpUserCopy.splice(indexOfTmpUser,1);
                            setTemporaryUsers(tmpUserCopy)
                        }
                    }
                }
            }
        }
    }

    const getCellForRowUserPlanning = (taskPlanning) => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        return toMap.map(v=>{
            const dateToCheck = moment(date).add(v,'month');
            keyCounter++;
            return (
                <TableCell key={'crup_'+keyCounter}>
                    <Box display="flex" justifyContent="center">
                    {renderInputUserPlanning(taskPlanning,dateToCheck)}
                    </Box>
                </TableCell>
            )
        })
    }

    const renderInputUserPlanning = (taskPlanning,dateToCheck) => {
        if(moment(taskPlanning.start_date).startOf('month').format('YYYY-MM-DD') === dateToCheck.startOf('month').format('YYYY-MM-DD')){
            let assignedInputValuesCopy = [...assignedInputValues];
            const itemValue = {
                val:taskPlanning.number_blocks,
                uid:taskPlanning.id+dateToCheck.startOf('month').format("YYYY-MM-DD")+taskPlanning.assigned_user_id,
                disable:taskPlanning.number_blocks_new !== null
            }
            let founditemvalue = assignedInputValuesCopy.filter(valToFind=>{
                if(valToFind.uid === itemValue.uid)return true;
                return false
            });
            if(founditemvalue.length === 0){
                assignedInputValuesCopy.push(itemValue);
                founditemvalue = itemValue;
                setAssignedInputValues(assignedInputValuesCopy);
            }else{
                founditemvalue = founditemvalue[0]
            }
            return (
                <Box display="flex" flexDirection="row" position="relative" onDoubleClick={()=>onDoubleClickAssigned(founditemvalue.uid)}>
                    
                        <input disabled={founditemvalue.disable} onBlur={(event)=>onQuitAnssignedFocus(event,taskPlanning,dateToCheck)} onChange={(event)=>changeInputAssignedValue(event,taskPlanning,dateToCheck)} value={founditemvalue.val} type="number" className="wbs-planning-input"></input>
                    
                    {taskPlanning.number_blocks_new !== null ?<Box className="planning-new-value">
                        {taskPlanning.number_blocks_new}
                    </Box> : null }
                </Box>
            )
        }
        //check if another task planning exists

        //if not assigned => check if for this periods a task is defined
        let wbsPlanningCopy = [...wbsPlanning];
        let indexOfTask = wbsPlanningCopy.map(i=>i.id).indexOf(taskPlanning.wbs_task_id);
        if(indexOfTask !== -1 && wbsPlanningCopy[indexOfTask].wbs_task_plannings !== 0){
            let findOther = wbsPlanningCopy[indexOfTask].wbs_task_plannings.find(e=>moment(e.start_date).startOf('month').format('YYYY-MM-DD') === dateToCheck.startOf('month').format('YYYY-MM-DD') && e.assigned_user_id === taskPlanning.assigned_user_id);
            if(findOther){
                let assignedInputValuesCopy = [...assignedInputValues];
                const itemValue = {
                    val:findOther.number_blocks,
                    uid:findOther.id+dateToCheck.startOf('month').format("YYYY-MM-DD")+findOther.assigned_user_id,
                    disable:findOther.number_blocks_new !== null
                }
                let founditemvalue = assignedInputValuesCopy.filter(valToFind=>{
                    if(valToFind.uid === itemValue.uid)return true;
                    return false
                });
                if(founditemvalue.length === 0){
                    assignedInputValuesCopy.push(itemValue);
                    founditemvalue = itemValue;
                    setAssignedInputValues(assignedInputValuesCopy);
                }else{
                    founditemvalue = founditemvalue[0]
                }
                return (
                    <Box display="flex" flexDirection="row" position="relative" onDoubleClick={()=>onDoubleClickAssigned(founditemvalue.uid)}>
                        
                            <input disabled={founditemvalue.disable} onBlur={(event)=>onQuitAnssignedFocus(event,findOther,dateToCheck)} onChange={(event)=>changeInputAssignedValue(event,findOther,dateToCheck)} value={founditemvalue.val} type="number" className="wbs-planning-input"></input>
                        
                        {findOther.number_blocks_new !== null ? <Box className="planning-new-value">
                            {findOther.number_blocks_new}
                        </Box> : null }
                    </Box>
                )
            }
            if(wbsPlanningCopy[indexOfTask].wbs_task_plannings.filter(e=>moment(e.start_date).startOf('month').format('YYYY-MM-DD') === dateToCheck.startOf('month').format('YYYY-MM-DD') && e.assigned_user_id === null).length > 0){
                return <input onBlur={(event)=>onQuitassignedNewUser(event,taskPlanning,dateToCheck)} type="number" className="wbs-planning-input"></input>;
            }
        }

        return null
    }

    const onDoubleClickAssigned = (itemUid) => {
        let assignedInputValuesCopy = [...assignedInputValues];
        const indexOfObject = assignedInputValuesCopy.map((e)=>e.uid).indexOf(itemUid);
        if(indexOfObject !== -1){
            assignedInputValuesCopy[indexOfObject].disable = false;
            setAssignedInputValues(assignedInputValuesCopy);
        }
    }

    const onQuitassignedNewUser = (event,taskPlanning,dateToCheck) => {
        if(!isNaN(event.target.valueAsNumber) && event.target.valueAsNumber !== 0){
            let startTime = dateToCheck.startOf('month').format("YYYY-MM-DD")
            let endTime = dateToCheck.endOf('month').format("YYYY-MM-DD")
            
            //update unssigned
            let wbsPlanningCopy = [...wbsPlanning];
            let indexOfTask = wbsPlanningCopy.map(i=>i.id).indexOf(taskPlanning.wbs_task_id);
            if(indexOfTask !== -1 && wbsPlanningCopy[indexOfTask].wbs_task_plannings !== 0){
                let indexOfPlanningUnssigned = wbsPlanningCopy[indexOfTask].wbs_task_plannings.map(e=>{
                    if(e.assigned_user_id === null){
                        return moment(e.start_date).startOf('month').format('YYYY-MM-DD');
                    }
                    return null
                }).indexOf(dateToCheck.startOf('month').format('YYYY-MM-DD'));
                if(indexOfPlanningUnssigned !== -1){
                    let assignedValue = event.target.valueAsNumber;
                    let previousUnnasignedValue = wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks_new || wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks;
                    let newUnassginedValue = previousUnnasignedValue - assignedValue;
                    if(newUnassginedValue < 0){
                        openErrorSnackbar('Cannot assigne more blocks than unassigned blocks left')
                    }
                    else{
                        createWbsPlanningAsync({
                            taskPlanning:{
                                wbs_task_id:taskPlanning.wbs_task_id,
                                planning_level_class:"planning_level",
                                planning_level_type:"type",
                                start_date:startTime,
                                end_date:endTime,
                                number_blocks:0,
                                number_blocks_new:event.target.valueAsNumber,
                                assigned_user_id:taskPlanning.assigned_user_id
                            },
                            wbsId:wbsId
                        })
                        updateWbsPlanningSync({
                            idTask:indexOfTask,
                            idPlanning:indexOfPlanningUnssigned,
                            valueNew:newUnassginedValue,
                            value:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks
                        });
                        updateWbsPlanningAsync({
                            taskPlanning:{
                                number_blocks_new:newUnassginedValue,
                                number_blocks:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks
                            },
                            taskPlanningId:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].id
                        })
                        updateUnssignedInput(taskPlanning.wbs_task_id,dateToCheck,wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks)
                    }
                }
            }
        }
    }

    const onQuitAnssignedFocus = (event,taskPlanning,dateToCheck) => {
        let wbsPlanningCopy = [...wbsPlanning];
        let indexOfTask = wbsPlanningCopy.map(i=>i.id).indexOf(taskPlanning.wbs_task_id);
        if(indexOfTask !== -1 && wbsPlanningCopy[indexOfTask].wbs_task_plannings !== 0){
            let indexOfPlanning = wbsPlanningCopy[indexOfTask].wbs_task_plannings.map(e=>e.id).indexOf(taskPlanning.id)
            if(indexOfPlanning !== -1 && !isNaN(event.target.valueAsNumber)){
                let indexOfPlanningUnssigned = wbsPlanningCopy[indexOfTask].wbs_task_plannings.map(e=>{
                    if(e.assigned_user_id === null){
                        return moment(e.start_date).startOf('month').format('YYYY-MM-DD');
                    }
                    return null
                }).indexOf(dateToCheck.startOf('month').format('YYYY-MM-DD'));
                if(indexOfPlanningUnssigned !== -1 && event.target.valueAsNumber !== wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks_new){

                    let previousAssignedValue = wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks_new || wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks;
                    let previousUnnasignedValue = wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks_new || wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks;
                    let newAssignedValue = event.target.valueAsNumber;
                    let newUnassginedValue = previousUnnasignedValue - (newAssignedValue - previousAssignedValue);
                    if(newUnassginedValue < 0){
                        openErrorSnackbar('Cannot assigne more blocks than unassigned blocks left')
                    }
                    else{
                        updateWbsPlanningSync({
                            idTask:indexOfTask,
                            idPlanning:indexOfPlanning,
                            valueNew:newAssignedValue,
                            value:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks
                        });
                        updateWbsPlanningAsync({
                            taskPlanning:{
                                number_blocks_new:newAssignedValue,
                                number_blocks:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks
                            },
                            taskPlanningId:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].id
                        });
                        
                        //update unassigned too ! /!\ not if previous value was the same
                        updateWbsPlanningSync({
                            idTask:indexOfTask,
                            idPlanning:indexOfPlanningUnssigned,
                            valueNew:newUnassginedValue,
                            value:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks
                        });
                        updateWbsPlanningAsync({
                            taskPlanning:{
                                number_blocks_new:newUnassginedValue,
                                number_blocks:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks
                            },
                            taskPlanningId:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].id
                        })
                        updateUnssignedInput(taskPlanning.wbs_task_id,dateToCheck,wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanningUnssigned].number_blocks)
                        let assignedInputValuesCopy = [...assignedInputValues];
                        const indexOfObject = assignedInputValuesCopy.map((e)=>e.uid).indexOf(taskPlanning.id+dateToCheck.startOf('month').format("YYYY-MM-DD")+taskPlanning.assigned_user_id);
                        assignedInputValuesCopy[indexOfObject].val = wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks;
                        assignedInputValuesCopy[indexOfObject].disable = true;
                        setAssignedInputValues(assignedInputValuesCopy);
                    }
                    
                }
            }
        }
    }

    const updateUnssignedInput = (wbsTaskId,dateToCheck,newUnassginedValue) => {
        let unssignedInputValuesCopy = [...unssignedInputValues];
        const indexOfObject = unssignedInputValuesCopy.map((e)=>e.uid).indexOf(wbsTaskId+dateToCheck.format("YYYY-MM-DD"));
        unssignedInputValuesCopy[indexOfObject].val = newUnassginedValue;
        unssignedInputValuesCopy[indexOfObject].disable = true;
        setUnssignedInputValues(unssignedInputValuesCopy);
    }

    const changeInputAssignedValue = (event,taskPlanning,dateToCheck) => {
        let assignedInputValuesCopy = [...assignedInputValues];
        const indexOfObject = assignedInputValuesCopy.map((e)=>e.uid).indexOf(taskPlanning.id+dateToCheck.startOf('month').format("YYYY-MM-DD")+taskPlanning.assigned_user_id);
        assignedInputValuesCopy[indexOfObject].val = event.target.value;
        setAssignedInputValues(assignedInputValuesCopy);
    }

    const renderUnassigned = (task,level) => {
        keyCounter++;
        return (
            <>
            <TableRow key={'u2_'+keyCounter}>
                <TableCell key={'c4_'+keyCounter}>
                    <Box paddingLeft={2*level} display="flex" flexDirection="row" color={palette.primary.main}>
                        <Box display="block" width={20} marginRight={1}/>
                        Unassigned
                    </Box>
                </TableCell>
                {getCellForRowUnassigned(task)}
            </TableRow>
            
            </>
        )
    }

    const getCellForRowUnassigned = (task) => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        return toMap.map(v=>{
            const dateToCheck = moment(date).add(v,'month');
            keyCounter++;
            return (
                <TableCell key={'cru_'+keyCounter}>
                    <Box display="flex" justifyContent="center">
                        {computeUnassigned(task,dateToCheck)}
                    </Box>
                </TableCell>
            )
        })
    }

    const renderAddUserPlanning = (task,level) => {
        keyCounter++;
        return (
            <>
            <TableRow key={'aup_'+keyCounter}>
                <TableCell key={'c5_'+keyCounter}>
                    <Box paddingLeft={2*level} display="flex" flexDirection="row">
                        <Box display="block" width={20} marginRight={1}/>
                        <Box display="flex" onClick={()=>addUserPlanning(task)}  style={{backgroundColor:palette.primary.main}} className="wbs-planning-button">
                            <MdAdd style={{marginBottom:"auto",marginTop:"auto"}}/>
                        </Box>
                    </Box>
                </TableCell>
                {renderEmptyCell()}
            </TableRow>
            </>
        )
    }

    const addUserPlanning = (task) => {
        setDialogAddUserPlanningIsOpen(true)
        setTaskIdToAddUser(task.id);
    }

    const renderEmptyCell = () => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        return toMap.map(v=>{
            keyCounter++;
            return (
                <TableCell key={'ec_'+keyCounter}>
                    <Box display="flex" justifyContent="center">
                    </Box>
                </TableCell>
            )
        })
    }

    const computeUnassigned = (task,dateToCheck) => {
        //check if render
        let taskPlanningUnassigned = 0;
        let taskPlanningUnassignedNew = 0;
        let unassignedNull = false;
        let renderTask = false;
        if(task.wbs_task_plannings.length !== 0){
            task.wbs_task_plannings.forEach(taskPlanning=>{
                if(moment(taskPlanning.start_date).startOf('month').format('YYYY-MM-DD') === dateToCheck.startOf('month').format('YYYY-MM-DD')){
                    if(taskPlanning.assigned_user_id === null){
                        taskPlanningUnassigned += taskPlanning.number_blocks;
                        taskPlanningUnassignedNew += taskPlanning.number_blocks_new;
                        if(taskPlanning.number_blocks_new === null) unassignedNull = true;
                        renderTask = true;
                    }
                }
            })
        }
        if(!renderTask)return <input key={task.id} onBlur={(event)=>createFirstUnassignedTask(event,task.id,dateToCheck)} type="number" className="wbs-planning-input"></input>
        else{
            let unssignedInputValuesCopy = [...unssignedInputValues];
            const itemValue = {
                val:taskPlanningUnassigned,
                uid:task.id+dateToCheck.startOf('month').format("YYYY-MM-DD"),
                disable:!unassignedNull
            }
            let founditemvalue = unssignedInputValuesCopy.filter(valToFind=>{
                if(valToFind.uid === itemValue.uid)return true;
                return false
            });
            if(founditemvalue.length === 0){
                unssignedInputValuesCopy.push(itemValue);
                founditemvalue = itemValue;
                setUnssignedInputValues(unssignedInputValuesCopy);
            }else{
                founditemvalue = founditemvalue[0]
            }
            return (
                <Box display="flex"  onDoubleClick={()=>onDoubleClickUnassigned(founditemvalue.uid)} flexDirection="row" position="relative">
                    <input disabled={founditemvalue.disable} key={task.id+1000} onBlur={(event)=>onQuitUnssignedFocus(event,task.id,dateToCheck)} onChange={(event)=>changeInputUnassignedValue(event,task.id,dateToCheck)} value={founditemvalue.val} type="number" className="wbs-planning-input"></input>
                    {!unassignedNull && <Box className="planning-new-value">{taskPlanningUnassignedNew}</Box>}
                </Box>
            );
        }
        
    }

    const onDoubleClickUnassigned = (itemUid) => {
        let unssignedInputValuesCopy = [...unssignedInputValues];
        const indexOfObject = unssignedInputValuesCopy.map((e)=>e.uid).indexOf(itemUid);
        if(indexOfObject !== -1){
            unssignedInputValuesCopy[indexOfObject].disable = false;
            setUnssignedInputValues(unssignedInputValuesCopy);
        }
    }

    const onQuitUnssignedFocus = (event,taskId,dateToCheck) => {
        let wbsPlanningCopy = [...wbsPlanning];
        let indexOfTask = wbsPlanningCopy.map(i=>i.id).indexOf(taskId);
        if(indexOfTask !== -1 && wbsPlanningCopy[indexOfTask].wbs_task_plannings !== 0){
            let indexOfPlanning = wbsPlanningCopy[indexOfTask].wbs_task_plannings.map(e=>{
                if(e.assigned_user_id === null){
                    return moment(e.start_date).startOf('month').format('YYYY-MM-DD');
                }
                return null
            }).indexOf(dateToCheck.startOf('month').format('YYYY-MM-DD'));
            if(indexOfPlanning !== -1 && !isNaN(event.target.valueAsNumber)  && wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks_new !== event.target.valueAsNumber){
                updateWbsPlanningSync({
                    idTask:indexOfTask,
                    idPlanning:indexOfPlanning,
                    valueNew:event.target.valueAsNumber,
                    value:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks
                });
                updateWbsPlanningAsync({
                    taskPlanning:{
                        number_blocks_new:event.target.valueAsNumber,
                        //number_blocks:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks_new
                    },
                    taskPlanningId:wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].id
                })
                updateUnssignedInput(wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].wbs_task_id,dateToCheck,wbsPlanningCopy[indexOfTask].wbs_task_plannings[indexOfPlanning].number_blocks)
            }
        }
    }

    const changeInputUnassignedValue = (event,taskId,dateToCheck) => {
        let unssignedInputValuesCopy = [...unssignedInputValues];
        const indexOfObject = unssignedInputValuesCopy.map((e)=>e.uid).indexOf(taskId+dateToCheck.format("YYYY-MM-DD"));
        unssignedInputValuesCopy[indexOfObject].val = event.target.value;
        setUnssignedInputValues(unssignedInputValuesCopy);
        
    }

    const getTotalLeftNew = (task,dateToCheck,acc = 0,bookAcc = 0,isNewNotNull = true) => {
        if(task.wbs_task_plannings.length !== 0){
            task.wbs_task_plannings.forEach(taskPlanningItem => {
                if(moment(taskPlanningItem.start_date).startOf('month').format('YYYY-MM-DD') === dateToCheck.startOf('month').format('YYYY-MM-DD')){
                    if(taskPlanningItem.assigned_user_id !== null){
                        bookAcc += taskPlanningItem.number_blocks_new ? taskPlanningItem.number_blocks_new : taskPlanningItem.number_blocks;
                    }
                    else{
                        acc += taskPlanningItem.number_blocks_new ? taskPlanningItem.number_blocks_new : taskPlanningItem.number_blocks;
                    }
                    if(taskPlanningItem.number_blocks_new !== null){
                        isNewNotNull = false;
                    }
                }
            });
        }
        //check if task with parentId = task.id
        if(wbsPlanning.filter(e=>e.parent_id === task.id).length !== 0){
            wbsPlanning.filter(e=>e.parent_id === task.id).forEach(taskItem=>{
                acc = getTotalLeftNew(taskItem,dateToCheck,acc,bookAcc,isNewNotNull).acc;
                bookAcc = getTotalLeftNew(taskItem,dateToCheck,acc,bookAcc,isNewNotNull).bookAcc;
                isNewNotNull = getTotalLeftNew(taskItem,dateToCheck,acc,bookAcc,isNewNotNull).isNewNotNull;
            })
            return {acc,bookAcc,isNewNotNull} ;
        }
        return  {acc,bookAcc,isNewNotNull} ;
    }

    const getTotalLeft = (task,dateToCheck,acc = 0,bookAcc = 0) => {
        if(task.wbs_task_plannings.length !== 0){
            task.wbs_task_plannings.forEach(taskPlanningItem => {
                if(moment(taskPlanningItem.start_date).startOf('month').format('YYYY-MM-DD') === dateToCheck.startOf('month').format('YYYY-MM-DD')){
                    if(taskPlanningItem.assigned_user_id !== null){
                        bookAcc += taskPlanningItem.number_blocks;
                    }
                    else{
                        acc += taskPlanningItem.number_blocks;
                    }
                }
            });
        }
        //check if task with parentId = task.id
        if(wbsPlanning.filter(e=>e.parent_id === task.id).length !== 0){
            wbsPlanning.filter(e=>e.parent_id === task.id).forEach(taskItem=>{
                acc = getTotalLeft(taskItem,dateToCheck,acc,bookAcc).acc;
                bookAcc = getTotalLeft(taskItem,dateToCheck,acc,bookAcc).bookAcc;
            })
            return {acc,bookAcc} ;
        }
        return  {acc,bookAcc} ;
    }

    const computeTotalLeft = (task,dateToCheck) => {
        let totalNew = getTotalLeftNew(task,dateToCheck)
        let total = getTotalLeft(task,dateToCheck)
        if(totalNew.acc === 0 && totalNew.bookAcc === 0){
            return null
        }
            
        return (
            <Box display="flex" position="relative">
                <Box width="30px" textAlign="center">
                    {total.bookAcc+'/'+(total.acc+total.bookAcc)}
                </Box>
                {totalNew.isNewNotNull ? null :<Box className="planning-new-value">
                    {totalNew.bookAcc+'/'+(totalNew.acc+totalNew.bookAcc)}
                </Box>}
            </Box>
        );
    }

    const hideWbslevel = (taskId) => {
        let hideArrayCopy = [...hideArray];
        if(hideArrayCopy.includes(taskId)){
            let indexOfId = hideArrayCopy.indexOf(taskId);
            hideArrayCopy.splice(indexOfId,1);
        }else{
            hideArrayCopy.push(taskId);
        }
        setHideArray(hideArrayCopy);
    }

    const createTemporaryUser = (userId) => {
        let temporaryUsersCopy = [...temporaryUsers];
        let tempUser = {
            taskId:taskIdToAddUser,
            user_id:userId.user
        }
        temporaryUsersCopy.push(tempUser);
        setTemporaryUsers(temporaryUsersCopy);
        setDialogAddUserPlanningIsOpen(false);
    }

    const goToNextTime = () => {
        let dateCopy = moment(startDate).add(MONTH_TO_SHOW,"month");
        setStartMounth(dateCopy.month());
        setStartDate(dateCopy.toDate());
        //setStartDate
    }

    const goToPreviousTime = () => {
        let dateCopy = moment(startDate).add(-MONTH_TO_SHOW,"month");
        setStartMounth(dateCopy.month());
        setStartDate(dateCopy.toDate());
    }

    const validateUpdates = () => {
        validatePlanningUpdate(wbsId).then(()=>{
            setUnssignedInputValues([])
            setAssignedInputValues([])
            openSuccessSnackbar("Modifications sucessfully updated !")
        })
    }

    const sendVerification = () => {
        createNotification({
            user_id: folder.project_manager_id,
            title:"Request planning validation",
            message:sendMessage,
            url:`/folders/${folder.id}/wbs/${wbsId}/planning`
        }).then(()=>{
            openSuccessSnackbar("Modifications send to validation !")
        })
    }
    
    return (
        <Card
            hideButtons
            noMarginTop
        >
            <TableContainer style={{ maxHeight: 'calc(100vh - 224px)',minHeight: 'calc(100vh - 224px)',border:"1px solid rgba(224, 224, 224, 1)" }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableHeadCell style={{minWidth: 300}}>
                                <Box  display="flex" flexWrap="no-wrap" justifyContent="start">
                                    <Box onClick={()=>goToPreviousTime()} className="wbs-planning-button-text" display="flex" flexWrap="no-wrap">
                                        <MdNavigateBefore className="wbs-planning-chevron"/> {t('wbs.plannings.previous','Previous')}
                                    </Box>
                                    <Box onClick={()=>goToNextTime()} className="wbs-planning-button-text" display="flex" flexWrap="no-wrap">
                                    {t('wbs.plannings.next','Next')} <MdNavigateNext className="wbs-planning-chevron"/>
                                    </Box>
                                </Box>
                            </TableHeadCell>
                            {renderMonth(MONTH_TO_SHOW,startDate,t)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {wbsTree ? renderTaskRow(wbsTree,0): null}
                    </TableBody>
                </Table>
            </TableContainer>
                <Box display="flex" flexDirection="rox" justifyContent="space-between" width="100%" height="48px" marginTop="10px" >
                    <PlanningToolbar 
                        dataTree={wbsTree}
                        users={users}
                        excelSheetName={`${'Folder ' + id}`}
                        importWbsPlanningsAsync={importWbsPlanningsAsync}
                        fetchWbsPlanning={fetchWbsPlanning}
                        wbsId={wbsId}
                    />
                    <Box height="48px" display="flex" flexDirection="row" justifyContent="flex-end">
                        {isManager ? <Button onClick={()=>validateUpdates()} color="primary">Valider les modifications</Button> :
                        <> 
                            <TextField value={sendMessage} onChange={(event)=>{setSendMessage(event.target.value)}} label="Message"/>
                            <Button onClick={()=>sendVerification()} color="primary" style={{marginTop:"auto"}}>Envoyer en vérification</Button>
                        </>}
                    </Box>
                </Box>
            <DialogCreateUserTaskPlanning taskId={taskIdToAddUser} open={dialogAddUserPlanningIsOpen} setOpen={(val)=>setDialogAddUserPlanningIsOpen(val)} createAction={(val)=>{createTemporaryUser(val)}}/>
        </Card>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Planning) 