import { useCallback, useState, useMemo } from 'react';
import { TextField, Box, IconButton, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { MdSync } from 'react-icons/md';

const useStyles = makeStyles({
    root: tableField => ({
        width: '100%',
        marginTop: tableField ? 0 : 30,
    }),
});

const FormAutocomplete = ({
    name,
    label,
    options,
    mapOptionToValue = option => option,
    mapOptionToText = option => option,
    tableField = false,
    allowNull,
    onChangeEvent,
    refreshClick,
    refreshLoading,
    disabled
}) => {
    const classes = useStyles(tableField);
    const { t } = useTranslation();
    const { control, errors } = useFormContext();
    const [ inputValue, setInputValue ] = useState('');
    const optionsMap = useMemo(() => {
        const entries = options.map(option => [ mapOptionToValue(option), mapOptionToText(option) ]);
        return Object.fromEntries(entries);
    }, [ options, mapOptionToValue, mapOptionToText ]);

    const translate = useCallback(key => {
        if(!key) return;
        if(typeof key === 'string') return t(key);
        return t(key.key, key.values);
    }, [ t ]);

    return (
        <Box display="flex" flexDirection="row" width="100%">
            <Controller
                control={control}
                name={name}
                render={({ onChange, value }) => (
                    <Autocomplete
                        disabled={disabled}
                        value={value ?? null}
                        inputValue={inputValue}
                        className={classes.root}
                        options={options.map(mapOptionToValue).sort((a, b) => optionsMap[a].localeCompare(optionsMap[b]))}
                        getOptionLabel={option => optionsMap[option]}
                        onChange={(_event, selected) => {
                            if(selected) {
                                onChange(selected);
                                setInputValue(optionsMap[selected])
                            } else {
                                onChange(allowNull ? null : undefined);

                                if(selected !== 0)setInputValue('');
                            }
                            if(onChangeEvent)onChangeEvent(selected)
                        }}
                        onInputChange={(event, val) => {
                            if(!val && val !== 0) {
                                onChange(allowNull ? null : undefined);
                            }
                            setInputValue(val ?? '');
                        }}
                        renderInput={params => (
                            <TextField
                                { ...params }
                                label={label}
                                error={errors[name] && Boolean(errors[name])}
                                helperText={errors[name] && translate(errors[name].message)}
                            />
                        )}
                    />
                )}
            />
            {refreshClick && <Box marginTop="auto">
                <IconButton onClick={()=>refreshClick()} marginTop="auto" size="small">{ refreshLoading ? <CircularProgress style={{width:13,height:13}}/>:<MdSync/>}</IconButton>
            </Box>}
        </Box>
    );
}

export default FormAutocomplete;
