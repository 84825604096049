import Card from '../card/Card';
import { useParams } from 'react-router-dom';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import { useEffect, useState } from 'react';
// import InvoiceForecastCreate from './InvoiceForecastCreate';
import InvoiceForecastList from './InvoiceForecastList';
import InvoiceList from './InvoiceList';
import { Box } from '@material-ui/core';
// import LoadingFallback from '../../views/LoadingFallback';

const InvoiceForecast = ({folders,
    wbsPlanning,
    createInvoiceForecast,
    createInvoiceForecastDetail,
    fetchSubfolder,
    editInvoicingForecastDetail,
    fetchUserBookingsForSubfolder,
    userBookings,
    createInvoice,
    createInvoiceDetail,
    editInvoiceDetail,
    removeInvoice,
    companies,
    editInvoice,
    editInvoicingForecast,
    removeInvoiceForecast,
    emitInvoices,
    removeInvoiceDetail,
    dowloadInvoiceFile,
    template,
    fetchTemplatePdf,
    setTab,
    fetchSubfoldersInvoices
}) => {
    const { folderId,id } = useParams();

    const [subfolder,setSubfolder] = useState({});
    const [folder,setFolder] = useState({});
    const [invoicingEncodingType,setInvoicingEncodingType] = useState(null);
    const [currentInvoice,setCurrentInvoice] = useState(null);

    useEffect(()=>{
        fetchUserBookingsForSubfolder(id);
        fetchTemplatePdf();
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        let fold =  folders.find(f=>f.id.toString() === folderId);
        setFolder(fold);
        let subfold = fold.subfolders.find(sf=>sf.id.toString() === id);
        setSubfolder(subfold);
        fetchUserBookingsForSubfolder(id);
    },[folders,fetchUserBookingsForSubfolder,folderId,id])

    return (
        <Box className="invoices-explorer-main-card app-kit-overflow-y" style={{ maxHeight: 'calc(100vh - 220px)',minHeight: 'calc(100vh - 220px)'}}>
            <Card
                hideButtons
                title={"Encodage/Emission de facture"}
            >
                <InvoiceList
                    folder={folder}
                    emitInvoices={emitInvoices}
                    companies={companies}
                    fetchSubfolder={fetchSubfolder}
                    removeInvoice={removeInvoice}
                    editInvoiceDetail={editInvoiceDetail}
                    wbsPlannings={wbsPlanning}
                    subfolder={subfolder}
                    invoicingEncodingType={invoicingEncodingType}
                    setInvoicingEncodingType={setInvoicingEncodingType}
                    currentInvoice={currentInvoice}
                    setCurrentInvoice={setCurrentInvoice}
                    removeInvoiceDetail={removeInvoiceDetail}
                    editInvoice={editInvoice}
                    dowloadInvoiceFile={dowloadInvoiceFile}
                    template={template}
                    setTab={setTab}
                    fetchSubfoldersInvoices={fetchSubfoldersInvoices}
                />
            </Card>
            <Card
                hideButtons
                style={{marginLeft:0,marginTop:10}}
                title={"Prévisions de facturation"}
            >
            {/*  <InvoiceForecastCreate
                    subFolderId={id}
                    wbsPlannings={wbsPlanning}
                    fetchSubfolder={fetchSubfolder}
                    createInvoiceForecast={createInvoiceForecast}
                    createInvoiceForecastDetail={createInvoiceForecastDetail}
                /> */}
                <InvoiceForecastList
                    subFolderId={id}
                    folderId={folderId}
                    wbsPlannings={wbsPlanning}
                    fetchSubfolder={fetchSubfolder}
                    createInvoiceForecast={createInvoiceForecast}
                    createInvoiceForecastDetail={createInvoiceForecastDetail}
                    removeInvoiceForecast={removeInvoiceForecast}
                    editInvoicingForecast={editInvoicingForecast}
                    createInvoiceDetail={createInvoiceDetail}
                    createInvoice={createInvoice}
                    userbookings={userBookings}
                    wbsPlanning={wbsPlanning}
                    editInvoicingForecastDetail={editInvoicingForecastDetail}
                    subfolder={subfolder}
                    invoicingEncodingType={invoicingEncodingType}
                    setInvoicingEncodingType={setInvoicingEncodingType}
                    currentInvoice={currentInvoice}
                    setCurrentInvoice={setCurrentInvoice}
                    folders={folders}
                    fetchSubfoldersInvoices={fetchSubfoldersInvoices}
                />
            </Card>
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForecast)
