import { useCallback, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import Card from '../card/Card';
import FormCategoryHeader from '../form/FormCategoryHeader';
import FormChipsField from '../form/FormChipsField';
import FormTable from '../form/FormTable';
import FormTextField from '../form/FormTextField';
import FormAddressAutocomplete from '../form/FormAddressAutocomplete';
import FormPhoneField from '../form/FormPhoneField';
import FormSelect from '../form/FormSelect';
import FormRow from '../form/FormRow';
import { mapFieldKeys } from '../../utils/converters';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import useYupResolver from '../../hooks/useYupResolver';
import axios from '../../services/axios';
import Map from '../Map';
import MapZoom from '../../enums/MapZoom';
import { handleResponse } from '../../utils/http';
import SnackbarContext from '../../context/snackbar';
import DuplicateDialog from '../dialogs/DuplicateDialog';

import {
    companySchema as schema,
    companyAddressSchema as addressSchema,
    companyContactSchema as contactSchema,
} from '../../services/yup';

const CompaniesCreate = ({ parameters, addCompany }) => {
    const { t, i18n: { language } } = useTranslation();
    const history = useHistory();
    const [ addresses, setAddresses ] = useState([]);
    const [ contacts, setContacts ] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ duplicates, setDuplicates ] = useState([]);
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);
    const mapLocation = useMemo(() => {
        let mapAddress = addresses.find(address => address.address_type === 'head_office' && address.complete);
        if(!mapAddress) mapAddress = addresses.find(address => address.complete);
        return mapAddress && { lat: mapAddress.latitude, lng: mapAddress.longitude };
    }, [ addresses ]);
    const [ loading, setLoading ] = useState(false);

    // COMPANY
    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            relation_types: [],
            name: '',
            company_name_second:'',
            reference: '',
            vat_number: '',
            vat_prefix:'BE',
            private: true,
            url: '',
            remarks: '',
        },
    });

    // ADDRESS
    const addressResolver = useYupResolver(addressSchema);
    const addressForm = useForm({
        resolver: addressResolver,
        defaultValues: {
            address_type: '',
            street_address: '',
            place_id: null,
            is_street_address: true,
            session_token: '',
        },
    });

    const handleAddressSubmit = useCallback(async (address) => {
        if(address.place_id) {
            const result = await axios.get(`/address/details/${address.place_id}`, {
                params: {
                    session_token: address.session_token,
                    language,
                },
            });
            const formattedAddress = {
                complete: true,
                address_type: address.address_type,
                street_name: result.data.value.street_name,
                street_number: result.data.value.street_number,
                zip_code: result.data.value.zip_code,
                city: result.data.value.locality,
                country: result.data.value.country,
                latitude: result.data.value.location.lat,
                longitude: result.data.value.location.lng,
            };
            setAddresses([ ...addresses, formattedAddress ]);
        } else {
            setAddresses([ ...addresses, { complete: false, address_type: address.address_type } ]);
        }
        addressForm.reset();
        addressForm.clearErrors();
    }, [ addresses, addressForm, language ]);

    const handleRemoveAddress = useCallback(toRemove => {
        setAddresses(addresses.filter(address => address !== toRemove));
    }, [ addresses ]);

    // CONTACT
    const contactResolver = useYupResolver(contactSchema);
    const contactForm = useForm({
        resolver: contactResolver,
        defaultValues: {
            contact_type: '',
            firstname: '',
            lastname: '',
            email: '',
            phone_number: '',
            phone_number_country: '',
        },
    });

    const handleContactSubmit = useCallback(contact => {
        setContacts([ ...contacts, contact ]);
        contactForm.reset();
        contactForm.clearErrors();
    }, [ contacts, contactForm ]);

    const handleRemoveContact = useCallback(toRemove => {
        setContacts(contacts.filter(contact => contact !== toRemove));
    }, [ contacts ]);

    const handleUnhandledError = useCallback((message, value) => {
        setLoading(false);
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
    }, [ t, openErrorSnackbar ]);

    const handleWarning = useCallback((_message, value) => {
        setLoading(false);
        setDuplicates(value);
        setDialogOpen(true);
    }, []);

    const handleSuccess = useCallback(async (value) => {
        setLoading(false);
        openSuccessSnackbar(t('success.company_created', 'Company successfully created'));
        history.push('/companies/' + value.id);
    }, [ history, openSuccessSnackbar,t ]);
    
    const handleSubmit = useCallback(async (values, _event, bypassDuplicateCheck = false) => {
        setLoading(true);
        const mappedValues = mapFieldKeys(values);
        const result = await addCompany({
            company: {
                company: {
                    ...mappedValues
                },
                accountCompanies: mappedValues.relation_types.map(relation_type => ({
                    account_id: 1,
                    relation_type,
                })),
                companyAddresses: addresses,
                companyContacts: contacts,
            },
            bypassDuplicateCheck,
        });

        handleResponse(result.payload, form, handleSuccess, handleUnhandledError, handleWarning);
    }, [ form, addresses, contacts, addCompany, handleSuccess, handleUnhandledError, handleWarning ]);

    return (
        <Card
            title={ t('companies.title.create-a-company', 'Create a company') }
            doneLabel={ t('button.submit', 'Submit') }
            onDoneClick={form.handleSubmit(handleSubmit)}
            loading={loading}
        >
            <FormProvider {...form}>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormCategoryHeader smallMarginTop>
                        { t('companies.basic-information', 'Basic information') }
                    </FormCategoryHeader>
                    <FormRow>
                        <Box display="flex" flexDirection="column">
                            <FormChipsField
                                name="relation_types"
                                label={t('forms.fields.company.relation-type.label', 'Type')}
                                options={parameters.company_relation_type.map(item=>t('parameters.company_relation_type.'+item))}
                                allowCustom={false}
                                getOptionLabel={val=>t('parameters.company_relation_type.'+val)}
                            />
                            <FormTextField
                                name="name"
                                label={t('forms.fields.company.name.label', 'Name')}
                            />
                            <FormTextField
                                name="company_name_second"
                                label={t('forms.fields.company.namesecond.label', 'Company name 2')}
                            />
                            <FormTextField
                                name="reference"
                                label={t('forms.fields.company.referencecode.label', 'Company initials')}
                            />
                            <Box display="flex" flexDirection="row">
                                <Box flex="0.1">
                                    <FormTextField
                                        name="vat_prefix"
                                        label={t('forms.fields.company.VAT_prefix.label', 'Prefix')}
                                    />
                                </Box>
                                <Box flex="0.9">
                                    <FormTextField
                                        name="vat_number"
                                        label={t('forms.fields.company.VAT.label', 'Vat')}
                                    />
                                </Box>
                            </Box>
                            
                            {/* TODO: use a checkbox */}
                            <FormSelect
                                name="private"
                                label={t('forms.fields.company.private.label', 'Private?')}
                                options={[ false, true ]}
                                mapOptionToText={option => option
                                    ? t('forms.fields.company.private.options.yes', 'Yes')
                                    : t('forms.fields.company.private.options.no', 'No')
                                }
                            />
                            <FormTextField
                                name="url"
                                label={t('forms.fields.company.url.label', 'Website')}
                            />
                            <FormTextField
                                name="remarks"
                                label={t('forms.fields.company.remarks.label', 'Remarks')}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                            { mapLocation && (
                                <Map
                                    containerStyle={{ height: 640, width: 640 }}
                                    center={mapLocation}
                                    zoom={MapZoom.BUILDINGS}
                                />
                            ) }
                        </Box>
                    </FormRow>
                </form>
            </FormProvider>
            <FormProvider {...addressForm}>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormCategoryHeader>
                        { t('companies.addresses', 'Addresses') }
                    </FormCategoryHeader>
                    <FormTable
                        columns={[
                            t('forms.fields.address.type.label', 'Type'),
                            t('forms.fields.address.street_name.label', 'Street Name'),
                            t('forms.fields.address.stree_number.label', 'Street Number'),
                            t('forms.fields.address.zip_code.label', 'ZIP Code'),
                            t('forms.fields.address.locality.label', 'Locality'),
                            t('forms.fields.address.country.label', 'Country'),
                        ]}
                        items={addresses}
                        mapToCellContents={item => [
                            item.address_type,
                            item.street_name,
                            item.street_number,
                            item.zip_code,
                            item.city,
                            item.country,
                        ]}
                        formFields={[
                            <FormSelect
                                name="address_type"
                                options={parameters.address_type}
                                allowNull
                                tableField
                                mapOptionToText={val=>t('parameters.address_type.'+val)}
                            />,
                            <FormAddressAutocomplete
                                name="street_address"
                                placeIdName="place_id"
                                isStreetAddressName="is_street_address"
                                sessionTokenName="session_token"
                                tableField
                            />
                        ]}
                        onAddClick={addressForm.handleSubmit(handleAddressSubmit)}
                        onRemoveClick={handleRemoveAddress}
                    />
                </form>
            </FormProvider>
            <FormProvider {...contactForm}>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormCategoryHeader>
                    { t('companies.contacts', 'Contacts') }
                    </FormCategoryHeader>
                    <FormTable
                        columns={[
                            t('forms.fields.contact.type.label', 'Type'),
                            t('forms.fields.contact.first_name.label', 'First Name'),
                            t('forms.fields.contact.last_name.label', 'Last Name'),
                            t('forms.fields.contact.email.label', 'Email'),
                            t('forms.fields.contact.phone_number.label', 'Phone Number'),
                        ]}
                        items={contacts}
                        mapToCellContents={item => [
                            item.contact_type,
                            item.firstname,
                            item.lastname,
                            item.email,
                            item.phone_number,
                        ]}
                        formFields={[
                            <FormSelect
                                name="contact_type"
                                options={parameters.employee_function}
                                allowNull
                                tableField
                                mapOptionToText={val=>t('parameters.employee_function.'+val)}
                            />,
                            <FormTextField
                                name="firstname"
                                tableField
                            />,
                            <FormTextField
                                name="lastname"
                                tableField
                            />,
                            <FormTextField
                                name="email"
                                tableField
                            />,
                            <FormPhoneField
                                name="phone_number"
                                tableField
                            />,
                        ]}
                        onAddClick={contactForm.handleSubmit(handleContactSubmit)}
                        onRemoveClick={handleRemoveContact}
                    />
                </form>
            </FormProvider>
            <DuplicateDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                description={t('dialog.duplicate.companies', 'The following companies have similar names to the one you wrote.')}
                duplicates={duplicates.map(duplicate => `${duplicate.name} (${duplicate.reference})`)}
                onContinue={() => handleSubmit(form.getValues(), null, true)}
            />
        </Card>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesCreate);