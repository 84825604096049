import { createSlice } from '@reduxjs/toolkit'

const getLocalStoredValue = (val) => {
    let storedValue = localStorage.getItem(val)
    if(storedValue == null)storedValue = "main"
    else storedValue = JSON.parse(storedValue)
    return storedValue
}

const appParamsSlice = createSlice({
    name: 'appParams',
    initialState: { drawerOpen:true, theme: getLocalStoredValue("themeSelected") },
    reducers: {
        setDrawer: (state, action) => {
            state.drawerOpen = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        }
    }
})

export const appParamsMethods = {
    setDrawer: appParamsSlice.actions.setDrawer,
    setTheme: appParamsSlice.actions.setTheme
}

export default appParamsSlice;