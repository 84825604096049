import { Box, makeStyles, Dialog, DialogTitle,DialogContent,DialogActions,Button,DialogContentText,CircularProgress } from "@material-ui/core";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import SnackbarContext from '../../context/snackbar';
import FormNumberField from "../form/FormNumberField";
import FormTextField from "../form/FormTextField";
import { userBookingUpdateSchema as schema} from '../../services/yup';
import useYupResolver from "../../hooks/useYupResolver";

const NUMBER_BLOCK_BASE = 10;

const useStyles = makeStyles(theme => ({
    root: ({ color }) => ({
        '&:hover': {
            backgroundColor: color +" !important",
            opacity:0.5 + " !important",
            cursor:"pointer"
        },
    }),
    tableBottom:{
        '&:last-child': {
            borderBottom:'1px solid lightgrey',
        }
    },
    dialogColorSpan:{
        color:theme.palette.primary.main
    }
}));

const PlannerSettings = ({dateMonthRange,selectedTask,userBookings,setSelectedTask,setDateMonthRange,createUserBooking,user,deleteUserBooking,updateUserBooking,folders}) => {

    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

    const [color,setColor] = useState('#ffee11');
    const classes = useStyles({ color });
    const [blocks,setBlocks] = useState(Array(NUMBER_BLOCK_BASE).fill({
        taskId:null,
        pending:false,
        userBookId:null
    }))
    const [pending,setPending] = useState(false);

    const [openEditDialog,setOpenEditDialog] = useState(false);
    const [userBookingIdEdit,setUserBookingIdEdit] = useState(null);
    const [taskIdEdit,setTaskIdEdit] = useState(null);
    const [userBookingToEdit,setUserBookingToEdit] = useState(null);
    const [taskToEdit,setTaskToEdit] = useState(null);
    const [loading,setLoading] = useState(false);
    const [loadingEdit,setLoadingEdit] = useState(false);



    useEffect(()=>{
        if(userBookingIdEdit !== null && taskIdEdit !== null){
            let foundTask = userBookings.find(e=>e.id === taskIdEdit);
            if(foundTask && foundTask.user_bookings){
                let foundUserBooking = foundTask.user_bookings.find(e=>e.id === userBookingIdEdit);
                if(foundUserBooking){
                    setUserBookingToEdit(foundUserBooking);
                    setTaskToEdit(foundTask);
                    formUserBooking.reset({
                        comment:foundUserBooking.comment,
                        starting_block:foundUserBooking.starting_block +1,
                        number_blocks:foundUserBooking.number_blocks
                    })
                }
            }
        }
        else{
            setTaskToEdit(null);
            setUserBookingToEdit(null);
        }
        // eslint-disable-next-line
    },[userBookingIdEdit,taskIdEdit,userBookings]);

    const resolver = useYupResolver(schema);

    const formUserBooking  = useForm({
        resolver,
        defaultValues:{
            comment:'',
            starting_block:0,
            number_blocks:0
        }
    });

    useEffect(()=>{
        let taskFound = userBookings.find(e=>e.id === +selectedTask);
        setColor((taskFound && taskFound.color_code) ? "#"+taskFound.color_code : '#aaaaaa');
    },[selectedTask,userBookings])

    useEffect(()=>{
        let userBooksOfTheDay = [];
        userBookings.forEach(e=>{
            e.user_bookings.forEach(item=>{
                if(moment(item.date).format('YYYY-MM-DD') === moment(dateMonthRange).format('YYYY-MM-DD')){
                    userBooksOfTheDay.push({...item,color_code:e.color_code});
                }
            })
        });
        let blocksCopy = Array(NUMBER_BLOCK_BASE).fill({
            taskId:null,
            pending:false,
            userBookId:null,
           /*  number_blocks:null */
        });
        userBooksOfTheDay.forEach(userBook=>{
            for(let i = Math.floor(userBook.starting_block); i < ((Math.floor(userBook.starting_block))+Math.ceil(userBook.number_blocks)); i++){
                if(blocksCopy[i]){
                    let b = {...blocksCopy[i]};
                    b.taskId = userBook.wbs_task_id;
                    b.userBookId = userBook.id;
                   /*  b.number_blocks = 3; */
                    blocksCopy[i] = b;
                }
            }
        })
        setBlocks(blocksCopy);

    },[dateMonthRange,userBookings])

    const handleClick = (block,index) => {
      
        let blocksCopy = [...blocks];
        let bcItem = {...blocksCopy[index]};
        setPending(!pending);
        if(pending){
            //found pending item
            let indexOfPending = blocks.map(e=>{
                if(e.taskId !== null && e.pending === true)return true;
                return false;
            }).indexOf(true);
            if(indexOfPending !== -1){
                //check if there is no blocks between index and indexOfPending
                let isABlockBetween = false;
                if(index >= indexOfPending){
                    for(let i = indexOfPending; i <= index; i++){
                        if(blocks[i].taskId !== null && !blocks[i].pending)isABlockBetween = true;
                    }
                }
                else{
                    for(let i = indexOfPending; i >= index; i--){
                        if(blocks[i].taskId !== null && !blocks[i].pending)isABlockBetween = true;
                    }
                }
                
                //if no blocks create a userBooking
                if(!isABlockBetween){
                    //update state to show blocks waiting for real from api
                    if(index >= indexOfPending){
                        for(let i = indexOfPending; i <= index; i++){
                            let itc = {...blocksCopy[i]};
                            itc.taskId = selectedTask;
                            itc.pending = false;
                            itc.userBookId = null;
                            blocksCopy[i] = itc
                        }
                    }
                    else{
                        for(let i = indexOfPending; i >= index; i--){
                            let itc = {...blocksCopy[i]};
                            itc.taskId = selectedTask;
                            itc.pending = false;
                            itc.userBookId = null;
                            blocksCopy[i] = itc
                        }
                    }
                    bcItem.taskId = selectedTask;
                    //create userbooking
                    createUserBooking({
                        user_id:user.id,
                        wbs_task_id:selectedTask,
                        number_blocks:  index >= indexOfPending ? (index+1) - indexOfPending : (indexOfPending+1) - index  ,
                        starting_block: index >= indexOfPending ? indexOfPending : index,
                        comment:null,
                        date:moment(dateMonthRange).format('YYYY-MM-DD'),
                        invoiced: false,
                        will_not_invoice: false,
                        invoicing_forecast_detail_id:null,
                        invoice_detail_id:null
                    }).then((res)=>{
                        openSuccessSnackbar('Sucessfully added')
                    })
                }else{
                    //error
                    openErrorSnackbar("Block(s) already exists in selection")
                    blocksCopy = blocksCopy.map(it=>{
                        if(it.pending)it.taskId = null;
                        return {...it,pending:false}
                    })
                }
            }
            bcItem.pending = false;
            blocksCopy = blocksCopy.map(i=>{
                return {...i,pending:false}
            })
        }else{
            
            bcItem.taskId = selectedTask;
            bcItem.pending = true;
        }
        blocksCopy[index] = bcItem;
        
        setBlocks(blocksCopy);
    }

    const hoverEvent = (index) => {
        let indexOfPending = blocks.map(e=>{
            if(e.taskId !== null && e.pending === true)return true;
            return false;
        }).indexOf(true);
        if(indexOfPending !== -1){
            //remove temp pending on refresh
            let blocksCopy = [...blocks].map((e,ide)=>{
                if(ide !== indexOfPending)e.pending = false;
                return e;
            })
            if(index >= indexOfPending){
                for(let i = indexOfPending; i <= index; i++){
                    let itCopy = {...blocksCopy[i]}
                    if(itCopy.taskId === null)itCopy.pending = true;
                    blocksCopy[i] = itCopy;
                }
            }else{
                for(let i = indexOfPending; i >= index; i--){
                    let itCopy = {...blocksCopy[i]}
                    if(itCopy.taskId === null)itCopy.pending = true;
                    blocksCopy[i] = itCopy;
                }
            }
            setBlocks(blocksCopy)
        }
        else{
            let blocksCopy = [...blocks].map((e,ide)=>{
                if(ide !== indexOfPending)e.pending = false;
                return e;
            })
            setBlocks(blocksCopy)
        }
    }

    const cancelPending = () => {
        //find indexOfPending
        let indexOfPending = blocks.map(e=>{
            if(e.taskId !== null && e.pending === true)return true;
            return false;
        }).indexOf(true);
        if(indexOfPending !== -1){
            let blocksCopy = [...blocks];
            let itemBlock = blocksCopy[indexOfPending]
            itemBlock.taskId = null;
            blocksCopy[indexOfPending] = itemBlock;
            blocksCopy = blocksCopy.map(item=>{
                return {...item,pending:false}
            })
            setBlocks(blocksCopy);
        }else{
            setSelectedTask(null);
        }
        setPending(false);
    }

    useEffect(()=>{
        const eventHandler = (event) => {
          if(event.keyCode === 27){
            cancelPending()
          }
        }
        document.body.addEventListener('keydown', eventHandler);
        return () => document.body.removeEventListener("keydown", eventHandler);
        // eslint-disable-next-line
      },[blocks,pending,selectedTask]);

      const handleDoubleClick = (block,index) => {
        if(block.userBookId && block.taskId){
            //open edit dialog
            setOpenEditDialog(true);
            setUserBookingIdEdit(block.userBookId);
            setTaskIdEdit(block.taskId);
        }
      }

      const handleCloseDialog = () => {
        setOpenEditDialog(false);
        setTaskIdEdit(null);
        setUserBookingIdEdit(null);
      }

      const getSubfolderCode = useCallback((task,onlyCode = false) => {
          if(!task)return null
        if(task.subfolder_id){
            const fold = folders.find(f=>{
                return f.id === task.wb.folder_id
            });
            if(fold){
                if(fold.subfolders){
                    const subfold = fold.subfolders.find(sf=>sf.id === task.subfolder_id);
                    if(subfold)return onlyCode ? subfold.code : subfold.code + " - "+ subfold.name
                    return "?"
                }else{
                    //fetchSubfolders(fold.id);
                    return "?"
                }
            }
            return "Not found"
        }
        else{
            return task.task_code
        }
    },[folders])


    const renderBlock = (block,index) => {

        let colorBlock = "#ffffff00"
        let opacity = 1;
        if(block.taskId !== null){
            let taskFound = userBookings.find(e=>e.id === +block.taskId);
            if(taskFound && taskFound.color_code)colorBlock = '#' + taskFound.color_code;
            else if(taskFound) colorBlock = "#aaaaaa"
        }else{
            if(block.pending){
                let taskFound = userBookings.find(e=>e.id === +selectedTask);
                if(taskFound && taskFound.color_code)colorBlock = '#' + taskFound.color_code;
                else if(taskFound) colorBlock = "#aaaaaa"
            }
        }
        let userBook = userBookings.find(e=>e.id === block.taskId);
        if(block.pending)opacity = 0.5;

        return (
            <Box onMouseEnter={()=>hoverEvent(index)} onDoubleClick={()=>{if(block.taskId !== null && !pending)handleDoubleClick(block,index)}} display="flex" flexDirection="row" height={(100/NUMBER_BLOCK_BASE)+"%"} border="1px solid lightgrey" borderBottom="none" >
                <Box display="flex" width="50px" borderRight="1px solid lightgrey" borderTop="none">
                    <span className="no-select-text" style={{margin:"auto"}}>{index+1}</span>
                </Box>
                
                <Box onClick={()=>{
                    if(selectedTask !== null && 
                    (block.taskId === null|| (block.pending && block.taskId !== null)))handleClick(block,index)}} justifyContent="center" className={block.taskId || selectedTask === null ? '' : classes.root} display="flex" width="100%" style={{opacity:opacity,backgroundColor:colorBlock}}>
                    {block.taskId !== null && <Box className="no-select-text" style={{margin:"auto"}}>{userBook?.wb?.folder?.code} - {getSubfolderCode(userBook)} </Box>}
                </Box>
            </Box>
        )
    }
  /*   console.log("test" , userBookingIdEdit) */
    const handleDeleteUserBooking = () => {
        setLoading(true);
        deleteUserBooking(userBookingIdEdit).then((rep)=>{
            handleCloseDialog()
            setLoading(false);
            openSuccessSnackbar('Sucessfully deleted')
        });
    }

    const handleUpdateUserBooking = (values) => {
        //check if there is a block between, exept booking with id userBookingIdEdit
        let isABlockBetween = false;
        for(let i = Math.floor(values.starting_block); i <= (Math.ceil(values.number_blocks) + (Math.floor(values.starting_block)-1)); i++){
            if(blocks[i].taskId !== null && blocks[i].userBookId !== userBookingIdEdit)isABlockBetween = true;
        }
        //if a block error
        if(isABlockBetween){
            openErrorSnackbar('Block(s) already exists in selection range')
            return;
        }
        //if no block : update
        setLoadingEdit(true);
        updateUserBooking({
            id:userBookingIdEdit,
            data:{
                ...values
            }
        }).then(()=>{
            setLoadingEdit(false);
            handleCloseDialog();
        })
    }
    
    return (
        <Box display="flex" flexDirection="column" height="100%" marginTop="25px">
            <Box borderBottom="1px solid lightgrey" display="flex" flexDirection="column" height="70%" marginLeft="20px" marginRight="20px">  
                {blocks.map((block,index)=>renderBlock(block,index))}
            </Box>
            <Dialog
                open={openEditDialog}
                onClose={()=>handleCloseDialog()}
                fullWidth
                maxWidth="sm"
            >
                 <DialogTitle>Edit User Booking</DialogTitle>
                 <DialogContent style={{marginBottom:"30px",paddingLeft:"50px",paddingRight:"50px"}}>
                    <DialogContentText>
                        {taskToEdit && userBookingToEdit && 
                        <>Update booking of <span className={classes.dialogColorSpan}>{userBookingToEdit.number_blocks}</span> dd starting
                         at <span className={classes.dialogColorSpan}>{userBookingToEdit.starting_block+1}</span> for task <span className={classes.dialogColorSpan}>{taskToEdit.task_code}</span>
                        </>}
                    </DialogContentText>
                    <FormProvider {...formUserBooking}>
                        <FormNumberField
                            name="starting_block"
                            label={"Starting block (1-"+NUMBER_BLOCK_BASE+")"}
                            min={1}
                            max={NUMBER_BLOCK_BASE}
                        />
                        <FormNumberField
                            name="number_blocks"
                            label={"Number blocks (1-"+NUMBER_BLOCK_BASE+")"}
                            min={1}
                            max={NUMBER_BLOCK_BASE}
                        />
                        <FormTextField
                            name="comment"
                            label="Comment"
                        />
                    </FormProvider>
                 </DialogContent>
                 <DialogActions>
                    {loading ? <CircularProgress color="primary" /> : <Button onClick={()=>handleDeleteUserBooking()} color="secondary">
                        Delete
                    </Button>}
                    {loadingEdit ? <CircularProgress color="primary" /> : <Button variant="contained" onClick={formUserBooking.handleSubmit(handleUpdateUserBooking)} color="primary">
                        Update
                    </Button>}
                    <Button onClick={()=>handleCloseDialog()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default PlannerSettings