import { useCallback, useMemo, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import { userSchema, userLoginSchema } from '../../services/yup'
import Card from '../card/Card';
import FormCategoryHeader from '../form/FormCategoryHeader';
import FormRow from '../form/FormRow';
import FormDatePicker from '../form/FormDatePicker';
import FormImageField from '../form/FormImageField';
import FormNumberField from '../form/FormNumberField';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import { mapFieldKeys } from '../../utils/converters';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import useYupResolver from '../../hooks/useYupResolver';
import { handleResponse } from '../../utils/http';
import SnackbarContext from '../../context/snackbar';

const UsersCreate = ({ roles, parameters, addUser }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { openSuccessSnackbar, openErrorSnackbar, openWarningSnackbar } = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [userRoles, setUserRoles] = useState({});
    const selectableRoles = useMemo(() => Object.keys(roles).filter(role => role !== 'super-admin'), [ roles ]);

    useEffect(() => {
        let rolesFiltered = {};
        for (const [key] of Object.entries(roles)) {
            if(key !== "super-admin")rolesFiltered[key] = true;
        }
        setUserRoles(rolesFiltered)
    }, [roles])
    const schema = useMemo(() => userSchema.concat(userLoginSchema), []);
    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            // User
            first_name: "",
            last_name: "",
            birthdate: null,
            blocks_availibility_per_week: 50,
            picture: null,
            // User account
            role: 'default',
            // User extra info
            code: "",
            status_type: "",
            function_type: "",
            payroll_type: "",
            external_folder_link: "",
            remarks: "",
            graduation_date: null,
            degree: "",
            entry_date: new Date(),
            exit_Date: null,
            last_evaluation_date: null,
            next_evaluation_date: null,
            seniority: null,
            absence_rate: null,
            number_holidays:null,
            powerbi_identifier: null,
            // User login
            email: "",
            password: "",
            password_confirm: "",
            //User cost
            cost_type: "",
            date_start: null,
            date_end :null,
            //User cost specifique
            date_start_speci: null,
            date_end_speci: null,
            daily_rate:null,
            subfolder_id: null,
            //User target
            client_project_target: null,
            internal_project_target: null
        },
    });

    const watchWorkStartDate = form.watch('date_start');
    const watchWorkEndDate = form.watch('date_end');

    const handleUnhandledError = useCallback((message, value) => {
        setLoading(false);
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
    }, [t, openErrorSnackbar]);

    const handleSuccess = useCallback(value => {
        setLoading(false);
        openSuccessSnackbar(t('success.user_created', 'User successfully created'));
        history.push('/users/' + value.id);
    }, [t, history, openSuccessSnackbar]);

    const handleWarning = useCallback((message, value) => {
        setLoading(false);
        openWarningSnackbar(t('warning.' + message));
        console.warn(message, value);
        history.push('/users/' + value.id);
    }, [t, history, openWarningSnackbar]);

    const handleSubmit = useCallback(async (values) => {
        setLoading(true);
        const mappedValues = mapFieldKeys(values);
        const result = await addUser({
            user: {
                ...mappedValues,
            },
            userAccount: {
                role: mappedValues.role,
                account_id: 1,
            },
            userExtraInfo: {
                ...mappedValues,
            },
            userLogin: {
                ...mappedValues,
                login_type: 'email',
            },
            userCost: {
                ...mappedValues,
                cost_class:"employee_cost_category",
            },
            userTarget: {
                ...mappedValues,
                client_project_target:mappedValues.client_project_target,
                internal_project_target:mappedValues.internal_project_target
            }
        })

        handleResponse(result.payload, form, handleSuccess, handleUnhandledError, handleWarning);
    }, [form, addUser, handleSuccess, handleUnhandledError, handleWarning]);

    return (
        <Card
            title={t('users.title.create-a-user', 'Create a user')}
            doneLabel={t('button.submit', 'Submit')}
            onDoneClick={form.handleSubmit(handleSubmit)}
            loading={loading}
        >
            <FormProvider {...form}>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormCategoryHeader smallMarginTop>
                        {t('users.basic-information', 'Basic information')}
                    </FormCategoryHeader>
                    <FormRow>
                        <Box display="flex" flexDirection="column">
                            <FormTextField
                                name="first_name"
                                label={t('forms.fields.user.first-name.label', 'First Name')}
                            />
                            <FormTextField
                                name="last_name"
                                label={t('forms.fields.user.last-name.label', 'Last Name')}
                            />
                            <FormTextField
                                name="code"
                                label={t('forms.fields.user.code.label', 'Code')}
                            />
                            <FormNumberField
                                name="blocks_availibility_per_week"
                                label={t('forms.fields.user.blocks-availability-per-week.label', 'Blocks Availability Per Week')}
                                min={0}
                                max={50}
                                notNullable
                            />
                            <FormSelect
                                name="role"
                                label={t('forms.fields.user.role.label', 'Role')}
                                options={selectableRoles}
                            />
  
                            <FormSelect
                                name="status_type"
                                label={t('forms.fields.user.status-type.label', 'Status')}
                                options={parameters.employee_status}
                                allowNull
                                mapOptionToText={val=>t('parameters.employee_status.'+val)}
                            />
                            <FormSelect
                                name="function_type"
                                label={t('forms.fields.user.function-type.label', 'Function')}
                                options={parameters.employee_function}
                                mapOptionToText={val=>t('parameters.employee_function.'+val)}
                            />
                            <FormSelect
                                name="payroll_type"
                                label={t('forms.fields.user.payroll-type.label', 'Payroll')}
                                options={parameters.employee_payroll}
                                allowNull
                                mapOptionToText={val=>t('parameters.employee_payroll.'+val)}
                            />
                            <FormTextField
                                name="external_folder_link"
                                label={t('forms.fields.user.external-folder-link.label', 'M-Files Link')}
                            />
                            <FormTextField
                                name="remarks"
                                label={t('forms.fields.user.remarks.label', 'Remarks')}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                            <FormImageField
                                name="picture"
                                uploadLabel={t('forms.fields.user.picture.upload-label', 'Upload a picture')}
                                editLabel={t('forms.fields.user.picture.edit-label', 'Upload another picture')}
                                height={400}
                                width={400}
                            />
                        </Box>
                    </FormRow>
                    <FormRow>
                        <FormDatePicker
                            name="birthdate"
                            label={t('forms.fields.user.birthdate.label', 'Birthdate')}
                            disableFuture
                        />
                        <FormDatePicker
                            name="graduation_date"
                            label={t('forms.fields.user.graduation-date.label', 'Graduation Date')}
                            disableFuture
                        />
                    </FormRow>
                    <FormRow>
                        <FormDatePicker
                            name="entry_date"
                            label={t('forms.fields.user.entry-date.label', 'Entry Date')}
                            clearable={false}
                        />
                        <FormDatePicker
                            name="exit_Date"
                            label={t('forms.fields.user.exit-date.label', 'Exit Date')}
                        />
                    </FormRow>
                    <FormRow>
                    <FormTextField 
                        name="degree"
                        label={t('forms.fields.user.Degree.label', 'Degree')}
                    />
                    </FormRow>
                    <FormRow>
                        <FormDatePicker
                            name="last_evaluation_date"
                            label={t('forms.fields.user.last-evaluation.label', 'Last Evaluation')}
                            disableFuture
                        />
                        <FormDatePicker
                            name="next_evaluation_date"
                            label={t('forms.fields.user.next-evaluation.label', 'Next Evaluation')}
                        />
                    </FormRow>
                    <FormRow>
                        <FormNumberField
                            name="seniority"
                            label={t('forms.fields.user.seniority.label', 'Seniority')}
                            min={0}
                            max={99.9}
                            decimalPlaces={1}
                            readOnly
                        />
                        <FormNumberField
                            name="absence_rate"
                            label={t('forms.fields.user.absence-rate.label', 'Absence Rate')}
                            min={0}
                            max={100}
                            readOnly
                        />
                    </FormRow>
                    <FormRow>
                        <FormNumberField
                            name="number_holidays"
                            label={t('forms.fields.user.number_holidays.label', 'Number holidays')}
                            min={0}
                            max={9999999}
                        />
                        <FormTextField
                            name="powerbi_identifier"
                            label={t('forms.fields.user.powerbi-identifier.label', 'PowerBI Identifier')}
                        />
                    </FormRow>
                    <FormCategoryHeader>
                        {t('users.occupation_target', 'Occupation target')}
                    </FormCategoryHeader>
                    <FormRow>
                        <FormNumberField
                           name="client_project_target"
                           label={t('forms.fields.user.occupation_target_client.label', 'Customer project')}
                           min={0}
                           max={100}
                           endAdornment="%"
                        />
                        <FormNumberField
                              name="internal_project_target"
                              label={t('forms.fields.user.occupation_target_internal.label', 'Internal project')}
                              min={0}
                              max={100}
                              endAdornment="%"
                        />
                    </FormRow>
                    <FormCategoryHeader>
                        {t('users.login-information', 'Login information')}
                    </FormCategoryHeader>
                    <FormTextField
                        name="email"
                        label={t('forms.fields.user.email.label', 'Email')}
                    />
                    <FormTextField
                        name="password"
                        label={t('forms.fields.user.password.label', 'Password')}
                        hideText
                    />
                    <FormTextField
                        name="password_confirm"
                        label={t('forms.fields.user.password-confirm.label', 'Confirm Password')}
                        hideText
                    />
                    <FormCategoryHeader>
                        {t('users.costs-category', 'Costs category')}
                    </FormCategoryHeader>
                    <FormRow>
                        <FormSelect
                            name="cost_type"
                            label={t('forms.fields.user.cost-type.label', 'Cost')}
                            options={parameters.employee_cost_category}
                            allowNull
                        />
                        <FormDatePicker
                            name="date_start"
                            label={t('forms.fields.user.cost-date-start.label', 'Date start')}
                            maxDate={watchWorkEndDate}
                        />
                        <FormDatePicker
                            name="date_end"
                            label={t('forms.fields.user.cost-date-end.label', 'Date end')}
                            minDate={watchWorkStartDate}
                        />
                    </FormRow>
                </form>
            </FormProvider>
        </Card>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersCreate);