import { Box, Button, DialogActions, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@material-ui/core"
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import FormAutocomplete from "../form/FormAutocomplete";

const DialogEmitInvoice = ({open,setOpen,template,invoices,folder,companies,generatePdf,emitInvoice,fetchSubfoldersInvoices,uploadInvoicesFile,subfolder,invoiceEmittedId}) => {

    const [documentMethod,setDocumentMethod] = useState(null);
    const [isFileUploaded,setIsFileUploaded] = useState(false);
    const file = useRef(null)

    useEffect(()=>{
        setIsFileUploaded(false)
    },[documentMethod])

    const form = useForm({
        defaultValues:{
            companyId:folder ? folder.end_user_company_id : null,
            invoice_type:'invoice_fr'
        }
    })

    const handleEmit = (withoutDoc) => {
        if(withoutDoc){
            emitInvoice(form,invoices)
            setOpen(false);
        }
        else{
            const payload = {
                file:file.current.files[0],
                invoice_id:invoices[0].id
            }
            uploadInvoicesFile(payload).then((res)=>{
                //fetchSubfolder(subfolderId);
                fetchSubfoldersInvoices({subfolder_id:subfolder.id,folder_id:folder.id}).then((res)=>{
                    if(!invoiceEmittedId)emitInvoice(form,invoices)
                    setOpen(false);
                })
            });
        }
    }

    return (<div>
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth="true"
        >
            <DialogTitle id="form-dialog-title">Emit invoice</DialogTitle>
            <DialogContent>
                <Box marginBottom="20px">
                <FormProvider {...form}>
                    {!invoiceEmittedId && <><Typography variant="h6">Company</Typography>
                    <Box display="flex" flexDirection="row" marginBottom="10px">
                        <Box display="flex" flex={0.5} marginLeft="10px">
                        {invoices[0]?.invoice_type === "production" &&
                            <FormAutocomplete
                                style={{maxWidth:400,minWidth:400,display:"flex"}}
                                name="companyId"
                                label="Choisir un Client"
                                options={companies}
                                mapOptionToValue={company => company.id}
                                mapOptionToText={company => company.name}
                                tableField
                            />
                        }
                        </Box>
                    </Box></>}
                    <Typography variant="h6">Invoice document</Typography>
                    <Box marginLeft="10px">
                        <RadioGroup onChange={(e)=>setDocumentMethod(e.target.value)}>
                            <Box display="flex" flexDirection="row">
                                <FormControlLabel value="upload" control={<Radio />} label="Upload document" />
                                <FormControlLabel value="generate" control={<Radio />} label="Generate pdf" />
                            </Box>
                        </RadioGroup>
                    </Box>
                    {documentMethod && documentMethod === "generate" && 
                        (<Box display="flex" flexDirection="row">
                            <Box display="flex" flex={0.6} marginLeft="10px">
                                    {invoices[0]?.invoice_type === "production" &&
                                    <FormAutocomplete
                                        style={{maxWidth:400,minWidth:400,display:"flex"}}
                                        name="invoice_type"
                                        label="template"
                                        options={template.filter(temp=>{
                                            let str = temp.template_type.split('_');
                                            if(str[0] && str[0] === "invoice")return true
                                            return false
                                        })}
                                        mapOptionToValue={templ => templ.template_type}
                                        mapOptionToText={templ => templ.template_type}
                                    />}
                            </Box>
                            <Box flex={0.4} display="flex">
                                <Button onClick={()=>generatePdf(form,invoices)} variant="outlined" style={{width:"100%",marginTop:"auto",marginLeft:"10px"}}>Generate PDF</Button>
                            </Box>
                        </Box>)
                    }
                    {documentMethod && documentMethod === "upload" && 
                        (<Box display="flex" flexDirection="row">
                            <Box display="flex" flex={0.6} marginLeft="10px">
                                <TextField onChange={()=>setIsFileUploaded(true)} inputRef={file} type="file" style={{marginTop:"40px",width:"100%"}}/>
                            </Box>
                            <Box display="flex" flex={0.4} marginLeft="10px">
                                {isFileUploaded && <Button onClick={()=>handleEmit()} variant="outlined" style={{width:"100%",marginTop:"auto",marginLeft:"10px"}}>Emit invoice</Button>}
                            </Box>
                        </Box>)
                    }
                </FormProvider>
                </Box>
            </DialogContent>
            <DialogActions>
                     {!isFileUploaded && !invoiceEmittedId && <Button onClick={()=>handleEmit(true)} color="primary">Emit without document</Button>}
                    {invoiceEmittedId && <Button onClick={()=>setOpen(false)} color="primary">Close</Button>}
            </DialogActions>
        </Dialog>
    </div>)
}

export default DialogEmitInvoice