import React, {useState,useEffect,useCallback, useMemo, useContext} from 'react';
import { connect, mapDispatchToProps, mapStateToProps } from '../../store/dispatchers';
import { Box } from '@material-ui/core';
import ExplorerTable from '../explorer/ExplorerTable';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { uppercaseFirst } from '../../utils/converters';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const NotificationConsult = ({notificationsAll,fetchNotificationsAll}) => {

    const history = useHistory();
    const [ filteredNotifications, setFilteredNotifications ] = useState(notificationsAll);;

    const { t } = useTranslation();

    const columns = [
        { name: t('table.notifications.columns.read', 'Read'), width: 5 },
        { name: t('table.notifications.columns.title', 'Title'), width: 20 },
        { name: t('table.notifications.columns.message', 'Message'), width: 40 },
        { name: t('table.notifications.columns.Date',"Date"), width: 15 },
        { name: t('table.notifications.columns.url',"Link"), width: 15 },
    ];

    const mapToRow = useCallback(notifs => [
        <Box display="flex" height="10px" width="10px" marginLeft="10px" borderRadius="10px" border={("3px solid "+(notifs.notification_read ? "lightgrey" : "red"))} style={{background:"white"}}></Box>/* notifs.notification_read ?  */,
        uppercaseFirst(notifs.title),
        notifs.message,
        moment(notifs.created_at).format('DD/MM/YYYY HH:mm'),
        <Link onClick={()=>{
            history.push(notifs.url)
            }} to={notifs.url}>/{notifs.url}</Link>
    ], []);

    useEffect(() => {
        fetchNotificationsAll().then((res)=>{
            setFilteredNotifications(res.payload.data);
        });
    },[])
    
    return (
        <Box  style={{ minHeight: 'calc(100vh - 112px)' }}>
            <Box style={{paddingBottom:'1rem',}}>
                <Typography variant="h5" component="h1">
                Notifications Consult
                </Typography>
            </Box>
            <ExplorerTable 
            columns={columns} 
            items={filteredNotifications}
            mapToCellContents={mapToRow}
            />
        </Box>
    );
};

export default connect(mapStateToProps,mapDispatchToProps)(NotificationConsult);