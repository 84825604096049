import { createContext, useState } from 'react';

import { checkPermissions } from '../services/permissions';

import axios from '../services/axios';

const UserContext = createContext();

export const UserProvider = (props) => {
    const [ user, setUser ] = useState(props.user);

    const [ incarnator, setIncarnator ] = useState(null);

    const hasPermission = permission => {
        let role = 'default';
        if(user?.role){
            role = user.role;
        }else if(user?.user_accounts[0]?.role){
            role = user.user_accounts[0].role;
        }
        return user.is_super_admin || checkPermissions(role, permission)
    };

    const updateUser = (user) => {
        setIncarnator(null);
        setUser(user);
        sessionStorage.removeItem('incarnatedUser');
    };

    const incarnateUser = incarnated => {
        setIncarnator(user);
        setUser(incarnated);
        sessionStorage.setItem('incarnatedUser', incarnated.id);
        axios.interceptors.request.use(config => {
            const incarnatedUserId = sessionStorage.getItem('incarnatedUser');
            if(incarnatedUserId) {
                config.headers = {
                    ...config.headers,
                    userId: incarnatedUserId,
                };
            }
            return config;
        });
    };

    return (
        <UserContext.Provider value={{ 
            user,
            incarnator,
            hasPermission,
            updateUser,
            incarnateUser,
        }}>
            { props.children }
        </UserContext.Provider>
    );
};

export default UserContext;