import React from 'react';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
const DialogThirdParties = ({ open , setOpen, invoice, handleClearFact, handleBillit}) => {
    const { t } = useTranslation();
    const [loadingBillit, setLoadingBillit] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClearFactButton = () => {
        handleClearFact(invoice.id)
        setOpen(false);
    }

    const handleBillitButton = () => {
        handleBillit(invoice.id)
        setLoadingBillit(true);
        //setOpen(false);
    }

    const getColorBillit = () => {
      if(invoice.billit_id === null || invoice.billit_id === undefined)return ""
      if(invoice.billit_id === 0) return "red";
      return "green";
    }
  

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('folder.subfolder.invoice.tp.thirdParties', 'Upload invoice to third parties')}</DialogTitle>
        <DialogContent>
            <Box display="flex" flexDirection="row" justifyContent="space-around" >
                <Button onClick={()=>handleClearFactButton()} variant="contained" color="primary">ClearFacts</Button>
                {!loadingBillit ? <Button onClick={()=>handleBillitButton()} variant="contained" color="primary" style={{backgroundColor:getColorBillit(), color:"white"}}>Billit</Button> : <CircularProgress/>}
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogThirdParties