import { useContext, useCallback, useState } from 'react';

import { Redirect } from 'react-router-dom';
import { Box, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import axios from '../services/axios';
import yup from '../services/yup';
import UserContext from '../context/user';
import SnackbarContext from '../context/snackbar';
import FormTextField from '../components/form/FormTextField';
import useYupResolver from '../hooks/useYupResolver';
import { handleResponse } from '../utils/http';
import { connect, mapStateToProps } from '../store/dispatchers';

const schema = yup.object().shape({
    email: yup.string().email().required(),
});

const RecoverPassword = () => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const { openErrorSnackbar } = useContext(SnackbarContext);
    const [ emailSent, setEmailSent ] = useState(false);

    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            email: '',
        },
    });

    const handleUnhandledError = useCallback((message, value) => {
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
    }, [ t, openErrorSnackbar ]);

    const handleSuccess = useCallback(() => {
        setEmailSent(true);
    }, [ setEmailSent ]);

    const handleSubmit = useCallback(async (values) => {
        const response = await axios.post('/recover-password', values);
        handleResponse(response.data, form, handleSuccess, handleUnhandledError)
    }, [ form, handleSuccess, handleUnhandledError ]);

    if(user) {
        return <Redirect to="/"/>
    }

    return (
        <Box style={{ display: 'flex', height: '100vh', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography component="h1" variant="h4" style={{ marginBottom: 20 }}>
                Recover Password
            </Typography>
            { emailSent
                ? (
                    <Typography>
                        An email has been spent to your email address with steps to reset your password.
                    </Typography>
                )
                : (
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)} style={{ display: 'flex', flexDirection: 'column', width: 600 }}>
                            <FormTextField
                                name="email"
                                label={t('forms.fields.user.email.label', 'Email')}
                            />
                            <Button variant="contained" color="primary" type="submit" className="form-input">
                                { t('button.recovery', 'Email me a recovery link') }
                            </Button>
                        </form>
                    </FormProvider>
                )
            }
        </Box>
    );
}

export default connect(mapStateToProps)(RecoverPassword);