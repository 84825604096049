import { useContext, useEffect, useState } from 'react'
import { Box, TextField, Button, Typography, CircularProgress } from "@material-ui/core"
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import TemplateCKEditor from './TemplateCKEditor';

import { mapStateToProps, mapDispatchToProps, connect } from '../../store/dispatchers';
import templateMethods from '../../store/modules/Template';
import UserContext from '../../context/user';

const TemplateSettings = ({templateKey,template, updateTemplatePdf}) => {

    const { t } = useTranslation();
    const { hasPermission } = useContext(UserContext);

    const [templateContent,setTemplate] = useState('');
    const [header,setHeader] = useState('');
    const [footer, setFooter] = useState('');
    const [loading, setLoading] = useState(false);

    const palette = useTheme().palette;

    useEffect(()=>{
        if(template.length > 0){
            setTemplate(template[templateKey].body)
            setHeader(template[templateKey].header)
            setFooter(template[templateKey].footer)
        }
    },[template,templateKey])

    const saveTemplate = () => {
        const id = template[templateKey].id;
        const toSend = {
            id:id,
            template:{
                body:templateContent,
                header:header,
                footer:footer
            }
        }
        updateTemplatePdf(toSend)
    }

    const testTemplateGen = () => {
        setLoading(true);
        templateMethods.testTemplate({
            content:templateContent,
            header:header,
            footer:footer
        }).then(response=>{
            
            //dowload + rename pdf

            /* const files = new File([response],"test.pdf", { type: "application/pdf" });
            const fileURL = URL.createObjectURL(files);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = 'pdf_name';
            fileLink.click();
            setLoading(false); */

            //show temp pdf in new windows

            const file = new Blob([response], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
            setLoading(false);
        });
    }

    return (
            <Box className="template-box" bgcolor={palette.admin.contrastText}>
                    <Typography 
                        variant="h4" 
                        component="h1" 
                        style={{borderLeft:"5px solid "+palette.primary.main}}
                    >{t('template.settings.title','Edit {{type}} pdf template',{type:template[templateKey]?.template_type})}</Typography>
                    
                    <TemplateCKEditor 
                        template={templateContent} 
                        setTemplate={(val)=>setTemplate(val)} 
                    />
                    <Box>
                        <Typography variant="h5" component="h2">{t('template.header','Template Header')}</Typography>
                        <TextField 
                            className="template-input" 
                            onChange={(val)=>setHeader(val.target.value)} 
                            value={header} 
                            multiline 
                            rows={3} 
                            rowsMax={3} 
                            variant="outlined"
                        />
                    </Box>
                    <Box>
                        <Typography variant="h5" component="h2">{t('template.footer','Template Footer')}</Typography>
                        <TextField 
                            className="template-input" 
                            onChange={(val)=>setFooter(val.target.value)} 
                            value={footer}
                            multiline 
                            rows={3} 
                            rowsMax={3} 
                            variant="outlined"
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        { hasPermission('template.update') && <Button variant="contained" color="primary" onClick={()=>saveTemplate()}>{t('button.save','Save')}</Button>}
                        { hasPermission('template.write') &&  (loading ? <Box marginTop="auto" marginRight="60px"><CircularProgress color="white" /></Box> :<Button variant="contained" color="primary" onClick={()=>testTemplateGen()}>{t('template.button.generate_test','Test template')}</Button>)}
                    </Box>
                    
            </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSettings);