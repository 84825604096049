import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        '& > div': {
            flex: 1,
            marginRight: 100,
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
});

const FormRow = ({ children }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            { children }
        </Box>
    );
}

export default FormRow;