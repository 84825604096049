import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchCompanies = createAsyncThunk(
    'companies/fetchCompanies',
    async (_, { rejectWithValue }) => {
        const response = await axios.get('/company/all');
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addCompany = createAsyncThunk(
    'companies/addCompany',
    async ({ company, bypassDuplicateCheck }, { rejectWithValue }) => {
        const queryParams = bypassDuplicateCheck ? '?bypassDuplicateCheck' : '';
        const response = await axios.post(`/company/create${queryParams}`, company);
        if(response.data.status !== ResponseStatus.SUCCESS) return rejectWithValue(response.data);
        return response.data;
    }
);

const addCompanyAddress = createAsyncThunk(
    'companies/addCompanyAddress',
    async (address, { rejectWithValue }) => {
        const response = await axios.post('/company/address/create', address);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addCompanyContact = createAsyncThunk(
    'companies/addCompanyContact',
    async (contact, { rejectWithValue }) => {
        const response = await axios.post('/company/contact/create', contact);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addCompanyAccount = createAsyncThunk(
    'companies/addCompanyAccount',
    async (account, { rejectWithValue }) => {
        const response = await axios.post('/company/account/create', account);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteCompany = createAsyncThunk(
    'companies/deleteCompany',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/company/delete/' + id);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteCompanyAddress = createAsyncThunk(
    'companies/deleteCompanyAddress',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/company/address/delete/' + id);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteCompanyContact = createAsyncThunk(
    'companies/deleteCompanyAccount',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/company/contact/delete/' + id);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editCompany = createAsyncThunk(
    'companies/editCompany',
    async ({ id, company, bypassDuplicateCheck }, { rejectWithValue }) => {
        const queryParams = bypassDuplicateCheck ? '?bypassDuplicateCheck' : '';
        const response = await axios.put(`/company/${id}${queryParams}`, company);
        if(response.data.status !== ResponseStatus.SUCCESS) return rejectWithValue(response.data);
        return response.data;
    },
);

const editCompanyAccount = createAsyncThunk(
    'companies/editCompanyAccount',
    async ({ id, account }, { rejectWithValue }) => {
        const response = await axios.put('/company/edit/account/' + id, account);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

export const companiesMethods = {
    fetchCompanies,
    editCompanyAccount,
    addCompany,
    addCompanyAddress,
    addCompanyContact,
    addCompanyAccount,
    deleteCompany,
    deleteCompanyAddress,
    deleteCompanyContact,
    editCompany
};

const companiesSlice = createSlice({
    name: 'companies',
    initialState: [],
    extraReducers: {
        [fetchCompanies.fulfilled]: (_state, action) => action.payload.value,
        [addCompany.fulfilled]: (state, action) => [ ...state, action.payload.value ],
        [addCompanyAddress.fulfilled]: (state, action) => state.map(company => {
            if(company.id === action.payload.value.company_id) {
                return {
                    ...company,
                    company_addresses: [
                        ...company.company_addresses,
                        action.payload.value,
                    ],
                };
            }
            return company;
        }),
        [addCompanyContact.fulfilled]: (state, action) => state.map(company => {
            if(company.id === action.payload.value.company_id) {
                return {
                    ...company,
                    company_contacts: [
                        ...company.company_contacts,
                        action.payload.value,
                    ],
                };
            }
            return company;
        }),
        [addCompanyAccount.fulfilled]: (state, action) => state.map(company => {
            if(company.id === action.payload.value.company_id) {
                return {
                    ...company,
                    account_companies: [
                        ...company.account_companies,
                        action.payload.value,
                    ],
                };
            }
            return company;
        }),
        [deleteCompany.fulfilled]: (state, action) => state.filter(company => company.id !== action.meta.arg),
        [deleteCompanyAddress.fulfilled]: (state, action) => state.map(company => {
            if(company.company_addresses.some(a => a.id === action.meta.arg)) {
                return {
                    ...company,
                    company_addresses: company.company_addresses.filter(a => a.id !== action.meta.arg),
                };
            }
            return company;
        }),
        [deleteCompanyContact.fulfilled]: (state, action) => state.map(company => {
            if(company.company_contacts.some(c => c.id === action.meta.arg)) {
                return {
                    ...company,
                    company_contacts: company.company_contacts.filter(a => a.id !== action.meta.arg),
                };
            }
            return company;
        }),
        [editCompany.fulfilled]: (state, action) => state.map(company => company.id === action.payload.value.id ? action.payload.value : company),
    },
});

export default companiesSlice;