import OAuth from './oauth';

const clearfacts = OAuth.createOrGet(
    'clearfacts',
    process.env.REACT_APP_CLEARFACTS_ISSUER,
    process.env.REACT_APP_CLEARFACTS_CLIENT_ID,
    [ 'upload_document' ],
    process.env.REACT_APP_REDIRECT_URI,
);

export default clearfacts;