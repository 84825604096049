import { useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, FilledInput } from '@material-ui/core';
import { List as VirtualizedList, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import TranslationKeyButton from './TranslationKeyButton';
import { supportedLanguages } from '../../services/i18n';
import { deaccentString } from '../../utils/converters';

const TranslationKeysExplorer = ({ keys, language, focusedKey, onKeyClick }) => {
    const { t, i18n } = useTranslation();
    const [ filter, setFilter ] = useState('');
    const cellMeasurerCache = useRef(new CellMeasurerCache({
        fixedHeight: true,
        defaultHeight: 51,
    }));

    const filteredKeys = useMemo(() => keys.filter(key => {
        const treatedFilter = deaccentString(filter.toLowerCase());
        if(key.includes(treatedFilter)) return true;
        for(const lang of supportedLanguages) {
            const trans = deaccentString(i18n.getResource(lang, 'translation', key)?.toLowerCase());
            if(trans?.includes(treatedFilter)) return true;
        }
        return false;
    }), [ keys, filter, i18n ]);

    return (
        <>
            <FilledInput
                id="translation-searchbar"
                inputProps={{ 
                    role: 'searchbox'
                }}
                name="translation-searchbar"
                placeholder={t('translate.search', 'Search')}
                value={filter}
                onChange={event => setFilter(event.target.value)}
                fullWidth
                disableUnderline
                className="translation-explorer-input"
            />
            <Box className="translation-explorer-list">
                <AutoSizer>
                    {({ height, width }) => (
                        <VirtualizedList
                            height={height}
                            width={width}
                            rowHeight={cellMeasurerCache.current.rowHeight}
                            rowCount={filteredKeys.length}
                            rowRenderer={({ key, index, style, parent }) => (
                                <CellMeasurer
                                    key={key}
                                    cache={cellMeasurerCache.current}
                                    parent={parent}
                                    columnIndex={0}
                                    rowIndex={index}
                                >
                                    <TranslationKeyButton
                                        focused={focusedKey === filteredKeys[index]}
                                        language={language}
                                        translationKey={filteredKeys[index]}
                                        onClick={onKeyClick}
                                        style={style}
                                    />
                                </CellMeasurer>
                            )}
                        />
                    )}
                </AutoSizer>
            </Box>
        </>
    );
};

export default TranslationKeysExplorer;