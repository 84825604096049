import { useCallback } from 'react';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

/**
 * A button for exporting to xlsx.
 * 
 * @param {any[]} props.dataTree - The task tree.
 * @param {any[]} props.users - The users.
 * @param {string} props.excelSheetName - The name and title of the exported Excel file.
 * @param {integer} props.wbsId - The id of current wbs
 */
const PlanningExport = ({
  dataTree,
  users,
  excelSheetName,
  wbsId
}) => {
  const { t } = useTranslation();
  let row = [];

  const resetRow = () => row = [];


  const handleExportClick = useCallback(() => {
    resetRow();
    buildRows(dataTree);


    const workbook = new ExcelJS.Workbook({useStyles: true});

    workbook.creator = 'AtOsborne';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.views = [
        {
          x: 0, y: 0, width: 10000, height: 20000,
          firstSheet: 0, activeTab: 1, visibility: 'visible'
        }
      ]

    // create a sheet with the the 2 first frozen
    const worksheet = workbook.addWorksheet(`${'WBS ' + wbsId}`, {
        properties:{tabColor:{argb:'FFC0000'}},
        views: [{state: 'frozen', xSplit: 0, ySplit: 2}], //Freeze 2 first lines when scrolling
    }); 

    worksheet.properties.defaultRowHeight = 15;
    worksheet.properties.defaultColWidth = 5;


      worksheet.mergeCells('F1:Q1');
      worksheet.mergeCells('R1:AC1');
      worksheet.mergeCells('A1:A2');
      worksheet.mergeCells('B1:B2');
      worksheet.mergeCells('C1:C2');
      worksheet.mergeCells('D1:D2');
      worksheet.mergeCells('E1:E2');

      worksheet.getRows(1,2).forEach((row) => row.height = 20);

      worksheet.columns = [
        { header: 'Technical ID', key: 'techId', width: 15 },
        { header: 'Task Name', key: 'part', width: 50 },
        { header: 'Order Number', key: 'order', width: 15 },
        { header: 'Task description', key: 'description', width: 50 },
        { header: 'Who ?', key: 'who', width: 15 },
        { header: moment().year(), key: 'year1', width: 5 },
      ];

      worksheet.getColumn('R').header = moment().year()+1;
      worksheet.getColumn('R').key = 'year2';
      worksheet.getColumn('R').width = 5;

      //Apply center & border to row 1's cells
      [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29].map(x=> {
        worksheet.getCell(1,x).alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        worksheet.getCell(1,x).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        return null;
      });
      //Apply center, border & value to month headers (row 2)
      const months = ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec'];
      [6,7,8,9,10,11,12,13,14,15,16,17].map(x=> {
        worksheet.getCell(2,x).alignment = {
          vertical: 'middle',
          horizontal: 'center',
          textRotation: '90'
        };
        worksheet.getCell(2,x).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        worksheet.getCell(2,x).value = months[parseInt(x,10)-6];
        return null;
      });
      [18,19,20,21,22,23,24,25,26,27,28,29].map(x=> {
        worksheet.getCell(2,x).alignment = {
          vertical: 'middle',
          horizontal: 'center',
          textRotation: '90'
        };
        worksheet.getCell(2,x).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        worksheet.getCell(2,x).value = months[parseInt(x,10)-18];
        return null;
      });



      //Populate
      let currentLevel = 0;
      for (let i=0;i<row.length;i++){
        if(row[i][0] === 'level'){
          currentLevel = row[i][1];
        }
        else {
          let r = worksheet.addRow(row[i]);
          switch(currentLevel){
            case 0: [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29].map(x=> {
                    r.getCell(x).fill = {
                      type: 'pattern',
                      pattern:'solid',
                      fgColor:{argb:'2b3c6d'},
                      bgColor:{argb:'000000'}
                    };
                    r.getCell(x).font = {
                      color: {argb:'FFFFFF'},
                      bold: true,
                      size: 14
                    };
                    return null;
                    });
                    r.getCell(1).font = {
                      bold: true,
                      size: 14
                    };
                    r.getCell(1).name = 'level0';
                    r.getCell(1).alignment = {
                      vertical: 'middle',
                      horizontal: 'right',
                    };
                    r.getCell(5).font = {
                      color: {argb:'FFFFFF'},
                      size: 10
                    };
                    break;
            case 1: r.getCell(4).font = {
                      color: {argb: "2b3c6d"},
                      underline: true,
                      bold: true,
                      size: 12
                    };
                    r.getCell(1).font = {
                      bold: true,
                      size: 12
                    };
                    r.getCell(1).name = 'level1';
                    r.getCell(1).alignment = {
                      vertical: 'middle',
                      horizontal: 'right',
                    };
                    r.getCell(5).font = {
                      color: {argb: "2b3c6d"},
                      size: 10
                    };
                    break;
            case 2: r.getCell(4).font = {
                      color: {argb: "2b3c6d"},
                      size: 10
                    };
                    r.getCell(1).font = {
                      size: 10
                    };
                    r.getCell(1).name = 'level2';
                    r.getCell(1).alignment = {
                      vertical: 'middle',
                      horizontal: 'right',
                    };
                    r.getCell(5).font = {
                      color: {argb: "2b3c6d"},
                      size: 10
                    };
                    break;
            default:
              r.getCell(1).name = 'level3+';
          }
        }
        if(row[i][1] === 0){
          worksheet.addRow([]);
        }
      }
    const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: blobType }); 
      saveAs(blob, excelSheetName+'.xlsx');
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dataTree ]);


  //move an element of the array to another index
  function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
  };

  const extractTaskPlanningUniqueAssignedUsers = (task) => {
      if(task.wbs_task_plannings !== null) {
          let all = [];
          let rowRes = [];
          let rows = []
          if(task.wbs_task_plannings.length !== 0){
              task.wbs_task_plannings.map(planning => {
                  if (moment(planning.start_date).year() === moment().year() || moment(planning.start_date).year() === moment().year()+1){
                      all.push(planning.assigned_user_id);
                  }
                  return null;
              });
          }
          else {
              all.push(null);
          }
          let uniqueUsers = [...new Set(all)];
          if(uniqueUsers[0] !== null){
              array_move(uniqueUsers, uniqueUsers.indexOf(null),0)
          }        

          uniqueUsers.forEach( (user,index)=> {
                  rowRes =
                  [
                      task.id,
                      (index===0)?(task.task_code?task.task_code:task.task_class):'',
                      (index===0)?'':'', //number order?
                      (index===0)?task.description:'',
                      (users.find(u => u.id === user)) ? users.find(u => u.id === user).user_user_extra_info?.code ?? 'Missing_code' : 'XoX'   
                  ]
                  //Current year
                  for(let j=0; j<12; j++){
                      rowRes.push(
                          task.wbs_task_plannings.find(u => (u.assigned_user_id === user && moment(u.start_date).month() === j && moment(u.start_date).year() === moment().year()))?.number_blocks
                      )
                  }
                  //Next year
                  for(let j=0; j<12; j++){
                      rowRes.push(
                          task.wbs_task_plannings.find(u => (u.assigned_user_id === user && moment(u.start_date).month() === j && moment(u.start_date).year() === moment().year()+1))?.number_blocks
                      )
                  }
                  rows.push(rowRes);
          });
          return rows;
      }
  }

  const buildRows = (tasks, level=0) => {
      if(tasks.length !== 0) {
          tasks.map(task=>{
              row.push(['level',level]);
              extractTaskPlanningUniqueAssignedUsers(task)?.forEach(line => row.push(line))
              
              if (task.wbs_tasks.length !== 0) {
                  buildRows(task.wbs_tasks, level+1)
              };
              return null;   
          })
      }
  }
      
  return (
    <Button
        color="primary"
        variant="contained"
        style={{ height: 38, minWidth: 170, marginTop: 'auto', marginRight: 10 }}
        onClick={handleExportClick}
        startIcon={<FaDownload size={16}/>}
    >
        { t('button.export', 'Export') }
    </Button>
  );
}

PlanningExport.propTypes = {
  dataTree: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  excelSheetName: PropTypes.string.isRequired,
}

export default PlanningExport;