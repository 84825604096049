import React, { useEffect, useState, useContext } from 'react';
import Switch from '@material-ui/core/Switch';
import useYupResolver from '../../../hooks/useYupResolver';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import Card from '../../card/Card';
import FormSelect from '../../form/FormSelect';
import FormTextField from '../../form/FormTextField';
import { taskSchema as schema } from '../../../services/yup';

import { connect, mapStateToProps, mapDispatchToProps } from '../../../store/dispatchers';
import FormNumberField from '../../form/FormNumberField';
import { Box, FormControlLabel, InputAdornment } from '@material-ui/core';
import SnackbarContext from '../../../context/snackbar';

const FormWbsEdit = ({ parameters, closemodal, taskItem, wbsId, fetchWbsPlanning,editTask,folder }) => {
    const { t } = useTranslation();
    const resolver = useYupResolver(schema);
    const [bookable, setBookable] = useState(true);
    const [subfoldersList,setSubfolderList] = useState([])
    const { openErrorSnackbar } = useContext(SnackbarContext);
    const form = useForm({
        resolver,
        defaultValues: {
            task_class: '',
            task_type: '',
            task_code: '',
            parent_id: '',
            name: '',
            description: '',
            external_folder_link: '',
            rank: '',
            bookable: false,
            color_code: '',
            number_blocks:10,
            subfolder_id:null
        },
    });
    useEffect(()=>{
        if(folder && folder.subfolders && folder.subfolders.length > 0){
            setSubfolderList([...folder.subfolders,{id:null,name:'None'}])
        }
    },[folder])

    useEffect(()=>{
        form.reset({
            task_class: taskItem.task_class || '',
            task_type: taskItem.task_type || '',
            task_code: taskItem.task_code || '',
            parent_id: taskItem.parent_id || '',
            name: taskItem.name || '',
            description: taskItem.description || '',
            external_folder_link: taskItem.external_folder_link || '',
            rank: taskItem.rank || '',
            bookable: taskItem.bookable || false,
            color_code: taskItem.color_code ? '#'+taskItem.color_code :  '',
            number_blocks:taskItem.number_blocks|| 10,
            subfolder_id:taskItem.subfolder_id ||null,
            //contract_amount:taskItem.contract_amount || 0,
        })
        setBookable(taskItem.bookable);
        // eslint-disable-next-line
    },[taskItem])

    const getBookable = (_e, val) => {
        setBookable(val);
    }

    const handleSubmit = useCallback(async (values) => {
        let updatedTask = {...values,bookable:bookable}
        let colorCode = values.color_code.split('');
        colorCode.shift();
        colorCode = colorCode.join('');
        updatedTask.color_code = colorCode;
        editTask({
            id:taskItem.id,
            task:updatedTask
        }).then((res)=>{
            if (res.error){
                openErrorSnackbar(t('error: ' + res.error.message));
            }
            fetchWbsPlanning(wbsId);
            closemodal()
        })
    }, [bookable,editTask,fetchWbsPlanning,closemodal,taskItem.id,wbsId]);

    return (
        <Card
            title={t('tasks.title.edit-a-task', 'Edit a task')}
            doneLabel={t('button.submit', 'Submit')}
            onDoneClick={form.handleSubmit(handleSubmit)}
            noMarginTop
        >
            <FormProvider {...form}>
                <form style={{ display: 'flex', flexDirection: 'column', width: '500px' }}>
                    <FormSelect
                        name="task_type"
                        label={t('forms.fields.tasks.task-type.label', 'Task type')}
                        options={parameters.task}
                        mapOptionToText={val=>t('parameters.task.'+val)}
                    />
                    <FormSelect
                        name="subfolder_id"
                        label={t('forms.fields.tasks.subfolder_id.title', 'Subfolder')}
                        options={subfoldersList}
                        mapOptionToText={val=>val.name}
                        mapOptionToValue={val=>val.id}
                    />
                        {/* <FormTextField
                            name="subfolder_id"
                            label={t('forms.fields.tasks.subfolder_id.label', 'Subfolder Id')}
                        /> */}
                        <FormTextField
                            name="task_code"
                            label={t('forms.fields.tasks.taskcode.label', 'Task code')}
                            defaultValue='88888'
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position='start'>
                                        {taskItem.parent_code}{(taskItem.parent_code) ? "." : ""}
                                    </InputAdornment>
                              }}
                        />

                        <FormTextField
                            name="name"
                            label={t('forms.fields.tasks.task-name.label', 'Task name')}
                        />
                    
                    <FormTextField
                        name="description"
                        label={t('forms.fields.tasks.task-description.label', 'Task description')}
                    />
                    <FormNumberField
                        name="number_blocks"
                        label={t('forms.fields.tasks.task-number-block.label', 'Number blocks')}
                        min={0}
                        max={1000}
                        notNullable
                    />
                    {/* <FormNumberField
                        name="contract_amount"
                        label={t('forms.fields.tasks.task-contract_amount.label', 'ContractAmount')}
                        notNullable
                    /> */}
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="column">
                            <label style={{ display: "block",marginTop:"10px" }}>Color Picker:</label>
                            <FormTextField
                                name="color_code"
                                type="color"
                                defaultValue="#2f5e78"
                                className="input-color-form-wbs"
                                style={{maxWidth:"100px",minWidth:"100px"}}
                            />
                        </Box>
                        <FormControlLabel
                            style={{marginTop:"10px"}}
                            control={
                            <Switch
                                onChange={(e,val)=>getBookable(e,val)}
                                name="bookable"
                                color="primary"
                                checked={bookable}
                            />
                            }
                            label="Bookable"
                        />
                    </Box>
                </form>
            </FormProvider>
        </Card>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(FormWbsEdit);
