import { useState, useEffect } from 'react';
import { Button, IconButton, TextField, Box, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';

import { kebabCase } from '../../utils/converters';
import Card from '../card/Card';
import { supportedLanguages } from '../../services/i18n';
import { connect, mapDispatchToProps, mapStateToProps } from '../../store/dispatchers';

const BannerCard = ({ banner, setBannerMessage, resetBannerMessage, addPreset, removePreset }) => {
    const [ messageKey, setMessageKey ] = useState(null);
    const [ inputValue, setInputValue ] = useState('');
    const [ startDate, setStartDate ] = useState(null);
    const [ endDate, setEndDate ] = useState(null);
    const [ firstBuild, setFirstBuild ] = useState(true);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(firstBuild && banner.message) {
            setMessageKey(banner.message.key);
            setInputValue(t(banner.message.key));
            setStartDate(new Date(banner.message.start));
            setEndDate(new Date(banner.message.end));
            setFirstBuild(false);
        }
    }, [ firstBuild, banner, t, setMessageKey, setStartDate, setEndDate, setFirstBuild ]);

    const handleSave = () => {
        setBannerMessage({
            key: messageKey,
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        }).then(res => {
        });
    };

    const handleClear = () => {
        setMessageKey(null);
        setInputValue('');
        setStartDate(null);
        setEndDate(null);
    };

    return (
        <Card
            title={t('banner.title', 'Banner message')}
            explanation={t('banner.explanation', 'Set a new banner message from presets or fill the input with a new message.'
                + ' Click on the "Add as a preset" option that will appear to turn your message into a preset.'
                + ' You can also set the start and end dates for your message. Don\'t forget to save the changes.')}
           
            doneLabel={t('button.save', 'Save')}
            doneDisabled={!inputValue || !startDate || !endDate || startDate >= endDate}
            onDoneClick={handleSave}
            leftAlignedButtons={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClear}
                >
                    { t('button.clear', 'Clear') }
                </Button>
            }
            rightAlignedButtons={
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!(banner?.message)}
                    onClick={resetBannerMessage}
                >
                    { t('button.reset', 'Reset') }
                </Button>
            }
        >
            <Autocomplete
                value={messageKey}
                onChange={async (_event, value) => {
                    if(typeof value === 'string') {
                        t(value, inputValue);
                        setMessageKey(value);
                    } else if(value?.inputValue) {
                        const presetKey = 'banner.message.' + kebabCase(value.inputValue);
                        t(presetKey, value.inputValue);
                        addPreset(presetKey);
                        setMessageKey(presetKey);
                    } else {
                        setMessageKey(null);
                    }
                }}
                inputValue={inputValue}
                onInputChange={(event, value) => {
                    if(event) {
                        setInputValue(value);
                    }
                }}
                options={banner.presets}
                getOptionLabel={option => {
                    if(typeof option === 'string') {
                        return t(option);
                    }
                    return option.inputValue;
                }}
                filterOptions={(options, params) => {
                    let filtered = options;
                    let kcInput;
                    if(params.inputValue) {
                        kcInput = kebabCase(params.inputValue);
                        filtered = [];
                        for(const option of options) {
                            const kcOptionTranslations = [];
                            for(const lang of supportedLanguages) {
                                const trans = i18n.getResource(lang, 'translation', option);
                                if(trans) {
                                    kcOptionTranslations.push(kebabCase(trans));
                                }
                            }
                            if(kcOptionTranslations.some(kcot => kcot.includes(kcInput))) {
                                filtered.push(option);
                            }
                        }
                    }

                    if(kcInput && !options.some(option => kcInput === option)) {
                        filtered.push({
                            inputValue: params.inputValue,
                            title: t('banner.add-as-preset', 'Add "{{inputValue}}" as a preset', { inputValue: params.inputValue }),
                        });
                    }
                    return filtered;
                }}
                renderOption={option => 
                    <Box className="other-banner-option">
                        { option.title || t(option) }
                        { !option.title &&
                            <IconButton
                                className="other-banner-option-remove"
                                onClick={event => {
                                    event.stopPropagation();
                                    if(option === messageKey) {
                                        setMessageKey(null);
                                    }
                                    removePreset(option);
                                }}
                            >
                                <IoClose size={20}/>
                            </IconButton>
                        }
                    </Box>
                } 
                renderInput={params => (
                    <TextField {...params} placeholder={t('banner.placeholder', 'No banner message set.')} variant="outlined"/>
                )}
                freeSolo
                selectOnFocus
                handleHomeEndKeys
                fullWidth
            />
            { inputValue &&
                <Box className="other-banner-dates">
                    <KeyboardDateTimePicker
                        variant="inline"
                        value={startDate}
                        onChange={setStartDate}
                        format="dd/MM/yyyy HH:mm"
                        ampm={false}
                        disablePast
                    />
                    <Typography component="span">to</Typography>
                    <KeyboardDateTimePicker
                        variant="inline"
                        value={endDate}
                        onChange={setEndDate}
                        format="dd/MM/yyyy HH:mm"
                        ampm={false}
                        disablePast
                    />
                </Box>
            }
        </Card>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerCard);