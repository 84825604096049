import { useCallback, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaFolderPlus } from 'react-icons/fa';
import UserContext from '../../context/user';

import Card from '../card/Card';
import ExplorerToolbar from '../explorer/ExplorerToolbar';
import ExplorerTable from '../explorer/ExplorerTable';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';

const CompaniesExplorer = ({ companies, deleteCompany }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [ filteredCompanies, setFilteredCompanies ] = useState(companies);
    const { hasPermission } = useContext(UserContext);
    const mapToRow = useCallback(company => [
        company.id,
        company.name,
        company.reference,
        company.company_name_second,
    ], []);

    const companiesFields = useMemo(() => companies.map(mapToRow), [ companies, mapToRow ]);

    const handleConsultClick = useCallback(company => {
        history.push('/companies/' + company.id);
    }, [ history ]);

    const handleCreateClick = useCallback(() => {
        history.push('/companies/create');
    }, [ history ]);

    const handleEditClick = useCallback(company => {
        history.push(`/companies/${company.id}/edit`)
    }, [ history ]);

    const handleDeleteClick = useCallback(company => {
        deleteCompany(company.id);
    }, [ deleteCompany ]);

    const columns = [
        { name: t('table.companies.columns.id', 'Number'), width: 30 },
        { name: t('table.companies.columns.name', 'Name'), width: 30 },
        { name: t('table.companies.columns.reference', 'Reference'), width: 30 },
        { name: t('table.companies.columns.second_name', 'Second Name'), width: 30 },
    ];

    return (
        <Card
            hideButtons
            style={{ minHeight: 'calc(100vh - 114px)' }}
            header={
                <ExplorerToolbar
                    data={companies}
                    dataFields={companiesFields}
                    createLabel={t('button.create-a-company', 'Create a company')}
                    createIcon={<FaFolderPlus/>}
                    onCreateClick={hasPermission('company.write') ? handleCreateClick : null}
                    onFilter={setFilteredCompanies}
                    mapToExcelRow={mapToRow}
                    excelColumnNames={columns.map(column => column.name)}
                    excelSheetName={t('table.companies.title', 'Companies')}
                />
            }
        >
            <ExplorerTable
                columns={columns}
                items={filteredCompanies}
                mapToCellContents={mapToRow}
                onConsultClick={handleConsultClick}
                onEditClick={hasPermission('company.update') ? handleEditClick : null}
                onDeleteClick={hasPermission('company.delete') ? handleDeleteClick : null}
            />
        </Card>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesExplorer);