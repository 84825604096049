import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchSpecificCost = createAsyncThunk(
    'usersSpecificCost/fetchSpecificCost',
    async (costId) => {
        const response = await axios.get(`/users/specificCosts/getByUserId/${costId}`);
        return response.data;
    },
);

const addSpecificCost = createAsyncThunk(
    'usersSpecificCost/addSpecificCost',
    async (cost, { rejectWithValue }) => {
        const response = await axios.post('/users/specificCosts/create', cost);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);


const deleteSpecificCost = createAsyncThunk(
    'usersSpecificCost/deleteSpecificCost',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/users/specificCosts/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

export const userSpecificCostMethods = {
    fetchSpecificCost,
    addSpecificCost,
    deleteSpecificCost
};

const usersSpecificCostSlice = createSlice({
    name: 'usersSpecificCost',
    initialState: [],
    extraReducers: {
        [fetchSpecificCost.fulfilled]: (_state, action) => action.payload,
        [addSpecificCost.fulfilled]: (state, action) => [...state, action.payload.value],
        [deleteSpecificCost.fulfilled]: (state, action) => state.filter(cost => cost.id !== action.meta.arg),
    },
});

export default usersSpecificCostSlice;