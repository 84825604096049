import { useEffect } from 'react';
import { Box } from '@material-ui/core';

import ThirdPartyCard from '../components/card/ThirdPartyCard';
import MFilesCard from '../components/card/MFilesCard';
import OutlookCard from '../components/card/OutlookCard';
import { connect, mapStateToProps, mapDispatchToProps } from '../store/dispatchers';
import clearfacts from '../services/clearfacts';

const ThirdPartyServices = ({ fetchThirdPartiesStates }) => {
    useEffect(() => {
        fetchThirdPartiesStates();
    }, [ fetchThirdPartiesStates ]);

    return (
        <Box className="page">
            <Box className="cards-row">
                <ThirdPartyCard oauth={clearfacts}/>
                <MFilesCard/>
                <OutlookCard/>
                <Box flex="1"/>
            </Box>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyServices);