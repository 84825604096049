import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { FormProvider, useForm } from "react-hook-form";
import FormSelect from "../../form/FormSelect";
import FormDatePicker from "../../form/FormDatePicker";
import FormNumberField from "../../form/FormNumberField";
import FormTextField from "../../form/FormTextField";
import { connect, mapStateToProps, mapDispatchToProps } from '../../../store/dispatchers'
import { useTranslation } from "react-i18next";

const ContractEditDialog = ({contractToEdit,wbsPlanning,parameters,open,setOpen,editSubfolderContract,subFolderId, handleSetContract}) => {

    const { t } = useTranslation();

    const form = useForm({
        defaultValues: {
            wbs_task_id:contractToEdit?.wbs_task_id || null,
            date:contractToEdit?.date || null,
            contract_type:contractToEdit?.contract_type || null,
            amount:contractToEdit?.amount || null,
            description:contractToEdit?.description || null,
            comment:contractToEdit?.comment || null,
        }
    })

    const handleEditClick = (values) => {
        const payload = {
            id:contractToEdit?.id,
            data:{...values},
            subfolderId:+subFolderId
        }
        editSubfolderContract(payload).then((res)=>{
            handleSetContract(res.payload.value)
            setOpen(false)
        });
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>
                Edit Contract
            </DialogTitle>
            <DialogContent>
                <FormProvider {...form}>
                    <form onSubmit={handleEditClick}>
                        <Box marginBottom={"20px"} width={"400px"}>
                            <FormSelect
                                name="wbs_task_id"
                                label={t('forms.fields.subcontractor.contract-wbs.label', 'Wbs')}
                                options={wbsPlanning}
                                mapOptionToText={val=>val.task_code+" - "+val.name}
                                mapOptionToValue={val=>val.id}
                                tableField
                                required
                            />
                        </Box>
                        <Box marginBottom={"20px"} width={"400px"}>
                            <FormDatePicker
                                name="date"
                                label={t('forms.fields.subcontractor.contract-contract-date.label', 'Contract Date')}
                                tableField
                            />
                        </Box>
                        <Box marginBottom={"20px"} width={"400px"}>
                            <FormSelect
                                name="contract_type"
                                label={t('forms.fields.subcontractor.contract-contract-type.label', 'Contract Type')}
                                options={parameters.contract_type}
                                mapOptionToText={val=>t('parameters.contract_type.'+val,val)}
                                tableField
                            />
                        </Box>
                        <Box marginBottom={"20px"} width={"400px"}>
                            <FormNumberField
                                name="amount"
                                min={0}
                                max={9999999999.99}
                                endAdornment="€"
                                label={t('forms.fields.subcontractor.contract-contract-amount.label', 'Amount')}
                                tableField
                            />
                        </Box>
                        <Box marginBottom={"20px"} width={"400px"}>
                            <FormTextField
                                name="description"
                                label={t('forms.fields.subcontractor.contract-contract-description.label', 'Description')}
                                tableField
                            />
                        </Box>
                        <Box marginBottom={"20px"} width={"400px"}>
                            <FormTextField
                                name="comment"
                                label={t('forms.fields.subcontractor.contract-contract-comment.label', 'Comment')}
                                tableField
                            />
                        </Box>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Cancel</Button>
                <Button color="primary" onClick={form.handleSubmit(handleEditClick)}>Edit</Button>
            </DialogActions>
        </Dialog>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(ContractEditDialog);