import React, { useEffect, useState, useContext } from 'react';
import Switch from '@material-ui/core/Switch';
import useYupResolver from '../../../hooks/useYupResolver';
import { FormProvider, useForm } from 'react-hook-form';
import { mapFieldKeys } from '../../../utils/converters';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import Card from '../../card/Card';
import FormSelect from '../../form/FormSelect';
import FormTextField from '../../form/FormTextField';
import { taskSchema as schema } from '../../../services/yup';

import { connect, mapStateToProps, mapDispatchToProps } from '../../../store/dispatchers';
import FormNumberField from '../../form/FormNumberField';
import { Box, FormControlLabel } from '@material-ui/core';
import SnackbarContext from '../../../context/snackbar';


const TaskCreate = ({ wbsData, parameters, addTask, closemodal, wbs, taskItem, wbsId, fetchWbsPlanning,folder,wbsPlanning }) => {
    const { t } = useTranslation();
    const resolver = useYupResolver(schema);
    const [bookable, setBookable] = useState(true);
    const [subfoldersList,setSubfolderList] = useState([])
    const { openErrorSnackbar } = useContext(SnackbarContext);

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    useEffect(()=>{
        if(folder && folder.subfolders && folder.subfolders.length > 0){
            setSubfolderList([...folder.subfolders,{id:null,name:'None'}])
        }
    },[folder])

    const form = useForm({
        resolver,
        defaultValues: {
            task_class: '',
            task_type: '',
            task_code: '',
            parent_id: '',
            name: '',
            description: '',
            external_folder_link: '',
            rank: '',
            bookable: true,
            color_code: getRandomColor(),
            number_blocks:10,
        },
    });

    const getBookable = (_e, val) => {
        setBookable(val);
    }

    const handleSubmit = useCallback(async (values) => {
        const mappedValues = mapFieldKeys(values);
        let colorCode = mappedValues.color_code.split('');
        colorCode.shift();
        colorCode = colorCode.join('');
        let rank = 0;
        if(!taskItem){
            rank = wbsData.length;
        }else{
            if(taskItem.id){
                let taskList = wbsPlanning.filter(item => item.parent_id === taskItem.id);
                rank = taskList.length;
            }
        }
        addTask({
            wbstask: {
                ...mappedValues,
                wbs_id: +wbsId,
                color_code:colorCode,
                bookable: bookable,
                task_class: 'task',
                rank: rank,
                parent_id: taskItem ? taskItem.id : null,
            },
            wbsId: wbsId,
        }).then((res) => {
            if (res.error){
                openErrorSnackbar(t('error: ' + res.error.message));
            }
            fetchWbsPlanning(wbsId);
            closemodal()
        })
        closemodal()
    }, [addTask,bookable,closemodal,fetchWbsPlanning,taskItem,wbsId,wbsData.length]);

    return (
        <Card
            title={t('tasks.title.create-a-task', 'Create a task')}
            doneLabel={t('button.submit', 'Submit')}
            onDoneClick={form.handleSubmit(handleSubmit)}
            noMarginTop
        >
            <FormProvider {...form}>
                <form style={{ display: 'flex', flexDirection: 'column', width: '500px' }}>
                    <FormSelect
                        name="task_type"
                        label={t('forms.fields.tasks.task-type.label', 'Task type')}
                        options={parameters.task}
                        mapOptionToText={val=>t('parameters.task.'+val)}
                    />
                    <FormSelect
                        name="subfolder_id"
                        label={t('forms.fields.tasks.subfolder_id.title', 'Subfolder')}
                        options={subfoldersList}
                        mapOptionToText={val=>val.name}
                        mapOptionToValue={val=>val.id}
                    />
                    <FormTextField
                        name="task_code"
                        label={t('forms.fields.tasks.taskcode.label', 'Task code')}
                        defaultValue='88888'
                    />
                    <FormTextField
                        name="name"
                        label={t('forms.fields.tasks.task-name.label', 'Task name')}
                    />
                    <FormTextField
                        name="description"
                        label={t('forms.fields.tasks.task-description.label', 'Task description')}
                    />
                    <FormNumberField
                        name="number_blocks"
                        label={t('forms.fields.tasks.task-number-block.label', 'Number blocks')}
                        min={0}
                        max={1000}
                        notNullable
                    />
                    {/* <FormNumberField
                        name="contract_amount"
                        label={t('forms.fields.tasks.task-contract_amount.label', 'ContractAmount')}
                        notNullable
                    /> */}
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="column">
                            <label style={{ display: "block",marginTop:"10px" }}>Color Picker:</label>
                            <FormTextField
                                name="color_code"
                                type="color"
                                defaultValue="#2f5e78"
                                className="input-color-form-wbs"
                                style={{maxWidth:"100px",minWidth:"100px"}}
                            />
                        </Box>
                        <FormControlLabel
                            style={{marginTop:"10px"}}
                            control={
                            <Switch
                                onChange={getBookable}
                                name="bookable"
                                color="primary"
                                checked={bookable}
                            />
                            }
                            label="Bookable"
                        />
                    </Box>
                    
                    
                    
                </form>
            </FormProvider>
        </Card>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskCreate);
