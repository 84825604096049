import { Box, Switch } from "@material-ui/core"
import { Controller } from "react-hook-form"

const FormSwitch = ({control,name,label,color}) => {
    return (
        <Box display="flex" flexDirection="row" marginLeft="-10px" marginTop="40px">
            <Controller control={control} render={(props) => (
                <Switch
                color={color}
                onChange={(e) => props.onChange(e.target.checked)}
                />
            )} name={name} defaultValue={false}
            />
            <Box display="flex" marginTop="auto" marginBottom="auto" fontSize="17px" style={{color:"grey"}}>
                {label}
            </Box>
        </Box>
    )
}

export default FormSwitch