import axios from '../../services/axios'

const actions = {
    testTemplate(template){
        return new Promise((resolve,reject)=>{
            axios.post('/template/test',template,{ responseType: 'blob' }).then(res=>resolve(res.data));
        })
    },
    getInvoiceTemplate(invoiceId){
        return new Promise((resolve,reject)=>{
            axios.post('/template/invoices',invoiceId,/* { responseType: 'blob' } */).then(res=>resolve(res.data));
        })
    },
    fetchTemplate(){
        return new Promise((resolve,reject)=>{
            axios.get('/templatepdf').then(res=>resolve(res.data));
        })
    },

    setTempalte(template){
        return new Promise((resolve,reject)=>{
            axios.post('/templatepdf',template).then(res=>resolve(res.data));
        })
    },
    generateInvoicePdf(template){
        return new Promise((resolve,reject)=>{
            axios.post('/template/geninvoices',template,{ responseType: 'blob' }).then(res=>resolve(res.data));
        })
    }

}

export default actions