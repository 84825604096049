import { Box, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect, mapDispatchToProps, mapStateToProps } from "../../store/dispatchers";
import RolesExplorer from './RolesExplorer';
import RolesSettings from './RolesSttings';

const Roles = ({rolesAll, updateRoles}) => {

    const [roles,setRoles] = useState({});
    const [groups,setGroups] = useState({});

    const [ rolesKeys, setRolesKeys ] = useState([]);
    const [ focusedKeyIndex, setFocusedKeyIndex ] = useState(0);

    useEffect(()=>{
        const rolesList = rolesAll.roles ? {...rolesAll.roles} : {};
        const groupsList = rolesAll.groups ? {...rolesAll.groups} : {};
        setRoles(rolesList);
        setGroups(groupsList);
        let keys = [];
        for (const [key] of Object.entries(rolesList)) {
            keys.push(key);
        }
        setRolesKeys(keys);
    },[rolesAll]);

    const palette = useTheme().palette;

    const styles = {
        explorer: {
            backgroundColor: palette.admin.main,
        },
        parametersSetting: {
            backgroundColor: palette.admin.light,
        },
    }

    return (
        <Box className="page">
            <Box className="cards-row" height="calc(100vh - 184px)">
                <Box style={styles.explorer} className={`card parameters-explorer`}>
                    <RolesExplorer
                        keys={rolesKeys}
                        focusedKey={focusedKeyIndex}
                        onKeyClick={(index)=>setFocusedKeyIndex(index)}
                        updateRoles={updateRoles}
                    />
                </Box>
                <Box style={styles.parametersSetting} className={`card parameters-settings`}>
                    <RolesSettings
                        focusedKey={focusedKeyIndex}
                        keys={rolesKeys}
                        groups={groups}
                        roles={roles}
                        updateRoles={updateRoles}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default connect( mapStateToProps, mapDispatchToProps )(Roles);