import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: loading => ({
        color: loading ? 'rgba(0,0,0,0)' : theme.palette.primary.contrastText,
        position: 'relative',
    }),
    spinner: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        '& *': {
            color: theme.palette.primary.contrastText,
        },
    },
}));

const LoadingButton = ({ children, loading, ...props }) => {
    const classes = useStyles(loading);

    return (
        <Button
            className={classes.root}
            {...props}
        >
            { loading && (
                <Box className={classes.spinner}>
                    <CircularProgress size={20}/>
                </Box>
            ) }
            { children }
        </Button>
    );
}

export default LoadingButton;