import TemplateCKEditor from '../template/TemplateCKEditor';
import { connect, mapStateToProps , mapDispatchToProps} from '../../store/dispatchers';
import { useContext, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import templateMethods from '../../store/modules/Template';
import { useHistory, useParams } from 'react-router';
import SnackbarContext from '../../context/snackbar';

const InvoiceValidate = ({currentInvoice,emitInvoices,fetchSubfoldersInvoices}) => {

    const [loading, setLoading] = useState(false);
    const [newTemplate,setNewTemplate] = useState(currentInvoice.contentGenerated);
    const { t } = useTranslation();
    const history = useHistory();
    const { folderId,id } = useParams();
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

    const testTemplateGen = () => {
        setLoading(true);
        templateMethods.testTemplate({
            content:newTemplate,
            header:currentInvoice.headerGenereted,
            footer:currentInvoice.footerGenereted
        }).then(response=>{
            //show temp pdf in new windows
            const file = new Blob([response], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
            setLoading(false);
        });
    }

    const validateTemplate = () => {
        emitInvoices({id:currentInvoice.id,recipient_company_id:currentInvoice.recipient_company_id}).then(()=>{
            //get genereted pdf with template invoice
            templateMethods.generateInvoicePdf({id:currentInvoice.id,template:{content:newTemplate,footer:currentInvoice.footerGenereted,header:currentInvoice.headerGenereted}}).then((response)=>{
                const files = new File([response],"invoice.pdf", { type: "application/pdf" });
                const fileURL = URL.createObjectURL(files);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.download = 'invoice';
                fileLink.click();
                fetchSubfoldersInvoices({subfolder_id:id,folder_id:folderId})
                openSuccessSnackbar('Repport generated !')
                history.push('/folders/'+folderId+'/subfolders/'+id+"?tab=7&tab2=1")
            })
        })
    }

    return <>
        <TemplateCKEditor template={currentInvoice.contentGenerated} setTemplate={(e)=>{setNewTemplate(e)}}/>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
        {loading ? <Box marginTop="auto" marginRight="60px"><CircularProgress color="white" /></Box> :<Button variant="contained" color="primary" onClick={()=>testTemplateGen()}>{t('template.button.generate_test','Test template')}</Button>}
        <Button variant="contained" color="primary" onClick={()=>{validateTemplate()}}>Validate pdf</Button>
        </Box>
    </>
}

export default connect(mapStateToProps,mapDispatchToProps)(InvoiceValidate)