import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async (_, { rejectWithValue }) => {
        const response = await axios.get('/users/all');
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const fetchUsersSimple = createAsyncThunk(
    'users/fetchUsersSimple',
    async (_, { rejectWithValue }) => {
        const response = await axios.get('/users/simple/all');
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const fetchCost = createAsyncThunk(
    'usersCost/fetchCost',
    async (_, { rejectWithValue }) => {
        const response = await axios.get('/users/costs/all');
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addCost = createAsyncThunk(
    'usersCost/addCost',
    async (cost, { rejectWithValue }) => {
        const response = await axios.post('/users/costs/create', cost);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editCost = createAsyncThunk(
    'usersCost/editCost',
    async ({ id, user }, { rejectWithValue }) => {
        const response = await axios.put('/users/costs/update/2/' + id, user);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addUser = createAsyncThunk(
    'users/addUser',
    async (user, { rejectWithValue }) => {
        const response = await axios.post('/users/create/user', user);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addUserLogin = createAsyncThunk(
    'users/addUserLogin',
    async (login, { rejectWithValue }) => {
        const response = await axios.post('/users/create/login', login);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editUser = createAsyncThunk(
    'users/editUser',
    async ({ id, user }, { rejectWithValue }) => {
        const response = await axios.put('/users/edit/user/' + id, user);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editUserLogin = createAsyncThunk(
    'users/editUserLogin',
    async ({ id, userLogin }, { rejectWithValue }) => {
        const response = await axios.put('/users/edit/login/' + id, userLogin);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/users/delete/user/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const createUserAvailability = createAsyncThunk(
    'users/createUserAvailability',
    async (userAv, { rejectWithValue }) => {
        const response = await axios.post('/users/availability/create',userAv);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editUserAvailability = createAsyncThunk(
    'users/editUserAvailability',
    async (data, { rejectWithValue }) => {
        const response = await axios.put('/users/availability/update/'+data.id,data.data);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const createUserAbsence = createAsyncThunk(
    'users/createUserAbsence',
    async (userAbs, { rejectWithValue }) => {
        const response = await axios.post('/users/abscence/create',userAbs);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editUserAbsence = createAsyncThunk(
    'users/editUserAbsence',
    async (data, { rejectWithValue }) => {
        const response = await axios.put('/users/abscence/update/'+data.id,data.data);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const fetchUsersSeniorityAbscencesRate = createAsyncThunk(
    'users/fetchUsersSeniorityAbscencesRate',
    async (id, { rejectWithValue }) => {
        const response = await axios.get('/users/abscencesRateSenority/'+id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editUserAccount = createAsyncThunk(
    'users/editUserAccount',
    async (data, { rejectWithValue }) => {
        const response = await axios.put('/users/account/update/'+data.id,data.data);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

export const userMethods = {
    fetchUsers,
    addUser,
    addUserLogin,
    editUser,
    editUserLogin,
    deleteUser,
    fetchCost,
    addCost,
    editCost,
    createUserAvailability,
    editUserAvailability,
    createUserAbsence,
    editUserAbsence,
    fetchUsersSeniorityAbscencesRate,
    editUserAccount,
    fetchUsersSimple
};

const usersSlice = createSlice({
    name: 'users',
    initialState: [],
    extraReducers: {
        [fetchUsers.fulfilled]: (_state, action) => action.payload.value,
        [fetchUsersSimple.fulfilled]: (_state, action) => action.payload,
        [addUser.fulfilled]: (state, action) => [...state, action.payload.value],
        [addUserLogin.fulfilled]: (state, action) => state.map(user => user.id === action.payload.value.id ? action.payload.value : user),
        [editUser.fulfilled]: (state, action) => state.map(user => user.id === action.payload.value.id ? action.payload.value : user),
        [editUserLogin.fulfilled]: (state, action) => state.map(user => user.id === action.payload.value.id ? action.payload.value : user),
        [deleteUser.fulfilled]: (state, action) => state.filter(user => user.id !== action.meta.arg),
        [fetchCost.fulfilled]: (_state, action) => action.payload.value,
        [addCost.fulfilled]: (state, action) => [...state, action.payload.value],
        [editCost.fulfilled]: (state, action) => state.map(user => user.id === action.payload.value.id ? action.payload.value : user),
        [createUserAvailability.fulfilled]: (state, action) => state.map(user=>{
            if(user.id !== action.meta.arg.user_id) 
                return {
                    ...user,
                    user_user_availabilities:[...user.user_user_availabilities,action.payload.value]
                }
            return user;
        }),
        [createUserAbsence.fulfilled]: (state, action) => state.map(user=>{
            if(user.id !== action.meta.arg.user_id) 
                return {
                    ...user,
                    user_user_absences:[...user.user_user_absences,action.payload.value]
                }
            return user;
        }),
        [editUserAvailability.fulfilled]: (state, action) => state.map(user=>{
            if(user.id !== action.meta.arg.data.user_id)
                return {
                    ...user,
                    user_user_availabilities:user.user_user_availabilities.map(av=>{
                        if(av.id === action.meta.arg.id){
                            return action.payload.value
                        }
                        return av
                    })
                }
            return user;
        }),
        [fetchUsersSeniorityAbscencesRate.fulfilled]: (state, action) => state.map(user=>{
            if(user.id === +action.meta.arg)
                return {
                    ...user,
                    user_user_extra_info:{
                        ...user.user_user_extra_info,
                        ...action.payload
                    }
                }
            return user;
        }),
        [editUserAbsence.fulfilled]: (state, action) => state.map(user=>{
            if(user.id !== action.meta.arg.data.user_id)
                return {
                    ...user,
                    user_user_absences:user.user_user_absences.map(av=>{
                        if(av.id === action.meta.arg.id){
                            return action.payload.value
                        }
                        return av
                    })
                }
            return user;
        }),
    },
});

export default usersSlice;
