import { useEffect, useState } from 'react'
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import UsersConsult from '../components/users/UsersConsult';
import UsersCreate from '../components/users/UsersCreate';
import UsersEdit from '../components/users/UsersEdit';
import UsersExplorer from '../components/users/UsersExplorer';

import { connect, mapStateToProps, mapDispatchToProps } from '../store/dispatchers';

import LoadingFallback from './LoadingFallback';

const RoutesWithUser = connect(mapStateToProps,mapDispatchToProps)(({ users,fetchCost,fetchSpecificCost,fetchUserBookingsTaskForUserId,fetchUsersSeniorityAbscencesRate }) => {

    const { id } = useParams();

    useEffect(()=>{
        fetchCost(id);
        fetchSpecificCost(id);
        fetchUserBookingsTaskForUserId(id);
        fetchUsersSeniorityAbscencesRate(id);
        // eslint-disable-next-line
    },[])

    if(!users.some(user => user.id.toString() === id)) {
        return <Redirect to="/users"/>;
    }

    return (
        <Switch>
            <Route exact path="/users/:id" component={UsersConsult}/>
            <Route exact path="/users/:id/edit" component={UsersEdit}/>
        </Switch>
    );
});

const Users = ({users,fetchUsers}) => {

    const [usersIsEmpty,setUsersIsEmpty] = useState(true);

    useEffect(() => {
        if(users.length !== 0)setUsersIsEmpty(false);
        fetchUsers().then(()=>setUsersIsEmpty(false))
        // eslint-disable-next-line
    }, []);

    if(usersIsEmpty)return (
        <LoadingFallback/>
    )

    return (
        <Box className="page">
            <Switch>
                <Route exact path="/users" component={UsersExplorer}/>
                <Route exact path="/users/create" component={UsersCreate}/>
                <Route path="/users/:id" component={RoutesWithUser}/>
            </Switch>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);