import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchRolesAll = createAsyncThunk(
    'rolesAll/fetchRolesAll',
    async () => {
        const response = await axios.get(`/permissions_all/roles`);
        return response.data;
    },
);

const updateRoles = createAsyncThunk(
    'rolesAll/updateRoles',
    async (payload) => {
        const response = await axios.put(`/permissions_all/update_roles`,payload);
        return response.data;
    },
);

const updatePermissionsGroup = createAsyncThunk(
    'rolesAll/updatePermissionsGroup',
    async (payload) => {
        const response = await axios.put(`/permissions_all/update_groups`,payload);
        return response.data;
    },
);

export const rolesAllMethods = {
    fetchRolesAll,
    updateRoles,
    updatePermissionsGroup
}

const rolesAllSlice = createSlice({
    name: 'rolesAll',
    initialState: {},
    extraReducers: {
        [fetchRolesAll.fulfilled]: (_state, action) => action.payload,
        [updateRoles.fulfilled]: (_state, action) => action.payload,
        [updatePermissionsGroup.fulfilled]: (_state, action) => action.payload,
    },
});

export default rolesAllSlice;