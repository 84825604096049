import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormDatePicker from '../form/FormDatePicker';
import FormTextField from '../form/FormTextField';
import moment from 'moment';
import FormAutocomplete from "../form/FormAutocomplete";
import FormNumberField from '../form/FormNumberField';
import {toNumber} from "../../utils/toNumber";
const DialogEditInvoice = ({ open , setOpen, editAction, editObject,subfolder,companies }) => {
    const { t } = useTranslation();
    const [value,setValue] = useState(null);

    
    const form = useForm({    
      defaultValues:{
          amount:0,
          comment:'',
          
      }
  })

    useEffect(()=>{
      if(editObject){
        setValue(editObject);
            form.reset({
              amount: editObject ? editObject.amount : '',
              comment:editObject ? editObject.comment : '',
              companyId: editObject ? editObject.companyId : '',
              vat_rate: editObject ? (editObject.vat_rate) : 21,
            })
        }
        // eslint-disable-next-line
    },[editObject])


    const handleClose = () => {
        setOpen(false);
    };
  
    const handleValidate = (val) => {
      editAction({
        id:value.id,
        data:{
          amount: toNumber(val.amount),
          comment:val.comment,
          // 0 is minimum
          vat_rate: val.vat_rate === null || val.vat_rate === undefined || val.vat_rate === false ?
            21 : (val.vat_rate < 0 ? 0 : val.vat_rate),
          invId: editObject.invId,
          companyId: val.companyId
        }
      })
    }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('folder.subfolder.invoice.edit.titleEdit', 'Edit invoice')}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
              {value ? <Box display="flex" flexDirection="column">
                <FormTextField name="amount" label="amount"/>
                <FormNumberField name="vat_rate" label="VAT rate"/>
                <FormTextField
                  id="outlined-multiline-static"
                  label="Commentaire interne"
                  name="comment"
                  multiline
                  rows={4}
                  />
                  <FormAutocomplete
                    style={{maxWidth:400,minWidth:400,display:"flex"}}
                    name="companyId"
                    label="Choisir un Client"
                    options={companies}
                    mapOptionToValue={company => company.id}
                    mapOptionToText={company => company.name} />
            
              </Box>:null}
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
          <Button onClick={form.handleSubmit(handleValidate)} color="primary">
          {t('button.save',"Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogEditInvoice