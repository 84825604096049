import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect,useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormNumberField from '../form/FormNumberField';
import FormDatePicker from '../form/FormDatePicker';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import moment from 'moment'
import {isValidVat} from "../../utils/isValidVat";
const DialogEditForecast = ({ open , setOpen, editAction, editObject,wbsPlanning}) => {
    const { t } = useTranslation();
    const [value,setValue] = useState(null);
    const [tasks,setTasks] = useState([]);
    const form = useForm({
      defaultValues:{
          amount:0,
          memo:'',
          date:new Date(),
           wbs_task_id:value ? value.wbs_task_id : '',
      }
    })
      useEffect(()=>{
        let tasksList = [];
        if(wbsPlanning && wbsPlanning.length && wbsPlanning.length > 0){
            tasksList = wbsPlanning.map(task=>{return {wbs_task_id:task.id,wbs_task_name:task.name}});
        }
        setTasks(tasksList)
    },[wbsPlanning])
    useEffect(()=>{
      if(editObject){
        setValue(editObject)
        form.reset({amount:editObject.amount,
          memo:  editObject.memo,
          date:  editObject ? editObject.forecast_date : new Date(),
          wbs_task_id:editObject ? editObject.wbs_task_id : '',
          vat_rate: isValidVat(editObject.vat_rate) ? editObject.vat_rate : 21
          //id: editObject.id
          });

      }
      // eslint-disable-next-line
    },[editObject])

    const handleClose = () => {
        setOpen(false);
    };


    const handleValidate = (val) => {
      editAction({
          id:value.id,
          data:{
            detailId: editObject.detailId,
            memo:val.memo,
            amount:val.amount,
            wbs_task_id:val.wbs_task_id,
            vat_rate:val.vat_rate,
            forecast_date:moment(val.date).format('YYYY-MM-DD')
          }
      })
    }




  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <FormProvider {...form}>
          <DialogTitle id="form-dialog-title">{t('folder.subfolder.invoice.edit.title', 'Edit amount')}</DialogTitle>
          <DialogContent>
              <FormNumberField
                label="amount"
                name="amount"
                min={-999999.99}
                max={999999.99}
                endAdornment="€"
              />
                {value? <Box display="flex" flexDirection="column">
                <FormTextField label="Description" name="memo"/>
                <FormNumberField label="VAT" name="vat_rate" min={0} max={100} endAdornment="%" />
                <FormDatePicker label="date" name="date"/>
                 <FormSelect
                  name="wbs_task_id"
                  options={tasks}
                  mapOptionToValue={task=>task.wbs_task_id}
                  mapOptionToText={task=>task.wbs_task_name}
                />
              </Box>:null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('button.cancel',"Cancel")}
            </Button>
            <Button onClick={form.handleSubmit(handleValidate)} color="primary">
              {t('button.save',"Save")}
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
}

export default DialogEditForecast
