import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import axios from '../services/axios';

const Map = ({ staticMap, ...props }) => {
    if(staticMap) {
        return (
            <StaticMap {...props}/>
        );
    }

    return (
        <DynamicMap {...props}/>
    );
};

const DynamicMap = ({ center, zoom, containerStyle }) => {
    const [ coordinates, setCoordinates ] = useState(null);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    useEffect(() => {
        if(typeof center === 'string') {
            (async () => {
                const response = await axios.get('/address/locate/' + center);
                setCoordinates(response.data.value.location);
            })();
        } else {
            setCoordinates(center);
        }
    }, [ center, setCoordinates ]);

    if(loadError) {
        return <Box>Map cannot be loaded right now, sorry.</Box>;
    }

    if(!isLoaded || !coordinates) {
        return <Box>Loading...</Box>;
    }
    
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={coordinates}
            zoom={zoom}
        />
    );
};

const StaticMap = ({ center, zoom, containerStyle }) => {
    const urlCenter = typeof center === 'string' ? center : `${center.lat},${center.lng}`;
    const { width, height } = containerStyle;

    return (
        <img
            alt={`Map for ${urlCenter}`}
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${urlCenter}&zoom=${zoom}&size=${width}x${height}&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
        />
    );
}

export default Map;