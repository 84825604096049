import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchBannerData = createAsyncThunk(
    'banner/fetchBannerData',
    async () => {
        const response = await axios.get('/banner-message');
        return response.data;
    },
);

const setBannerMessage = createAsyncThunk(
    'banner/updateBannerMessage',
    async (message) => {
        await axios.put('/banner-message', message);
        return message;
    },
);

const resetBannerMessage = createAsyncThunk(
    'banner/resetBannerMessage',
    async () => {
        await axios.delete('/banner-message');
    },
);

const addPreset = createAsyncThunk(
    'banner/addPreset',
    async (preset) => {
        await axios.post(`/banner-message/preset/${preset}`);
        return preset;
    },
);

const removePreset = createAsyncThunk(
    'banner/removePreset',
    async (preset) => {
        await axios.delete(`/banner-message/preset/${preset}`);
        return preset;
    },
);

export const bannerMethods = {
    fetchBannerData,
    setBannerMessage,
    resetBannerMessage,
    addPreset,
    removePreset
};

const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        message: null,
        presets: [],
    },
    extraReducers: {
        [fetchBannerData.fulfilled]: (_state, action) => action.payload,
        [setBannerMessage.fulfilled]: (state, action) => ({ ...state, message: action.payload }),
        [resetBannerMessage.fulfilled]: state => ({ ...state, message: null }),
        [addPreset.fulfilled]: (state, action) => ({ ...state, presets: [ ...state.presets, action.payload ] }),
        [removePreset.fulfilled]: (state, action) => ({ ...state, presets: state.presets.filter(preset => preset !== action.payload) }),
    },
});

export default bannerSlice;