import { Box, CircularProgress, Typography } from '@material-ui/core';

const LoadingFallback = () => {
    return (
        <Box className="loading-fallback">
            <Typography component="span" variant="h4" className="loading-fallback-text">
                Loading...
            </Typography>
            <CircularProgress size={32}/>
        </Box>
    );
};

export default LoadingFallback;