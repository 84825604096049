import { useCallback, useState, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaFolderPlus } from 'react-icons/fa';

import Card from '../../card/Card';
import ExplorerToolbar from '../../explorer/ExplorerToolbar';
import ExplorerTable from '../../explorer/ExplorerTable';
import { connect, mapStateToProps, mapDispatchToProps } from '../../../store/dispatchers';
import UserContext from '../../../context/user';
import SnackbarContext from '../../../context/snackbar';

const SubfoldersExplorer = ({ folder, companies, deleteSubfolder, users }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [ filteredSubfolders, setFilteredSubfolders ] = useState(folder.subfolders ? folder.subfolders : []);
    const { hasPermission, user } = useContext(UserContext);
    const { openWarningSnackbar } = useContext(SnackbarContext);

    const mapToRow = useCallback(subfolder => [
        folder.lead_code+'/'+subfolder.rank,
        subfolder.code,
        subfolder.name,
        users.find(user=>user.id === subfolder.project_leader) ? users.find(user=>user.id === subfolder.project_leader).first_name + " "+ users.find(user=>user.id === subfolder.project_leader).last_name : '',
        subfolder.status_type,
        companies.find(c => c.id === subfolder.customer_company_id)?.name,
        // eslint-disable-next-line
    ], [ companies,users ]);

    const subfoldersFields = useMemo(() => folder.subfolders?.map(subfolder => [
        folder.lead_code+'/'+subfolder.rank,
        subfolder.code,
        subfolder.name,
        subfolder.status_type,
        companies.find(c => c.id === subfolder.customer_company_id)?.name ?? '',
        // eslint-disable-next-line
    ]) || [], [ folder.subfolders, companies ]);

    const handleConsultClick = useCallback(subfolder => {
        history.push(`/folders/${folder.id}/subfolders/${subfolder.id}`);
    }, [ history, folder ]);

    const handleCreateClick = useCallback(() => {
        history.push(`/folders/${folder.id}/subfolders/create`);
    }, [ history, folder ]);

    const handleEditClick = useCallback(subfolder => {
        history.push(`/folders/${folder.id}/subfolders/${subfolder.id}/edit`);
    }, [ history, folder ]);

    const handleDeleteClick = useCallback(subfolder => {
        deleteSubfolder(subfolder.id).then(res=>{
            if(res.payload.status === 'warning')openWarningSnackbar(res.payload.message);
        });
    }, [ deleteSubfolder ]);

    const columns = [
        { name: t('table.subfolders.columns.rank_code', 'Code'), width: 5 },
        { name: t('table.subfolders.columns.code', 'Subfolder code'), width: 5 },
        { name: t('table.subfolders.columns.name', 'Name'), width: 20 },
        { name: t('table.subfolders.columns.project_leader', 'Project leader'), width: 20 },
        { name: t('table.subfolders.columns.status', 'Status'), width: 10 },
        { name: t('table.subfolders.columns.customer', 'Customer'), width: 20 },
    ];

    return (
        <Card
            hideButtons
            style={{ minHeight: 'calc(100vh - 114px)', marginLeft: 0 }}
            title={t('table.subfolders.title', 'Subfolders')}
            header={
                <ExplorerToolbar
                    data={folder.subfolders ? folder.subfolders : []}
                    dataFields={subfoldersFields}
                    createLabel={t('button.create-a-subfolder', 'Create a subfolder')}
                    createIcon={<FaFolderPlus/>}
                    onCreateClick={hasPermission('subfolder.write') ? handleCreateClick : null}
                    onFilter={setFilteredSubfolders}
                    mapToExcelRow={mapToRow}
                    excelColumnNames={columns.map(column => column.name)}
                    excelSheetName={t('table.subfolders.title-for-sheet', '{{folderName}}', { folderName: folder.name })}
                />
            }
        >
            <ExplorerTable
                columns={columns}
                items={filteredSubfolders}
                mapToCellContents={mapToRow}
                onConsultClick={handleConsultClick}
                conditionalEdit={(subfolder)=>(subfolder?.project_leader === user.id || hasPermission("subfolder_admin.update"))}
                onEditClick={handleEditClick}
                onDeleteClick={hasPermission('subfolder.delete') ? handleDeleteClick : null}
            />
        </Card>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SubfoldersExplorer);