import { Select, MenuItem } from '@material-ui/core';
import { useTranslation } from "react-i18next";

import { supportedLanguages, saveTranslation } from '../services/i18n';

const LanguagePicker = ({ language, onLanguagePick, styleColor }) => {
    const { i18n } = useTranslation();


    return (
        <Select
            style={{color:(styleColor ? styleColor : "#e2002b")}}
            id="language-select"
            value={language ?? ''}
            onChange={event => onLanguagePick(event.target.value)}
            
        >
            {
                supportedLanguages.map(lang => {
                    const trans = i18n.getResource(lang, 'translation', 'language');
                    if(!trans) {
                        saveTranslation(lang, 'language', lang);
                    }
                    return <MenuItem key={lang} value={lang}>{ trans || lang }</MenuItem>;
                })
            }
        </Select>
    );
};

export default LanguagePicker;