import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchPermList = createAsyncThunk(
    'permList/fetchPermList',
    async () => {
        const response = await axios.get(`/permissions_all/list`);
        return response.data;
    },
);

export const permListMethods = {
    fetchPermList,
}

const permListSlice = createSlice({
    name: 'permList',
    initialState: {},
    extraReducers: {
        [fetchPermList.fulfilled]: (_state, action) => action.payload,
    },
});

export default permListSlice;