import React, { useState, useEffect, useContext } from 'react';
import { Box, Dialog } from "@material-ui/core";
import { BsFillCaretDownFill, BsFillCaretRightFill } from "react-icons/bs";
import { BsCalendar } from "react-icons/bs";
import { listToTree } from '../../utils/converters';
import FormWbs from './wbs/FormWbs';
import ButtonAddTask from './wbs/ButtonAddTask';
import ButtonEditTask from './wbs/ButtonEditTask';
import ButtonDeleteTask from './wbs/ButtonDeleteTask';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {Button} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import Card from '../card/Card';
import { useTranslation } from 'react-i18next';
import FormWbsEdit from './wbs/FormWbsEdit';
import ParentBookableDialog from './wbs/ParentBookableDialog';
import { MdMenu } from 'react-icons/md';
import UserContext from '../../context/user';

const Display = ({ wbsPlanning, editTaskMulti, fetchWbsPlanning, wbsId, folder , deleteWbsPlanning, editTask }) => {

    const [hideArray, setHideArray] = useState([]);
    const [wbsData, setWbs] = useState(null)
    const [taskItem,setTaskItem] = useState(null);
    const [taskToEdit,setTaskToEdit] = useState({});
    const {t} = useTranslation();
    const [openTaskDialog,setOpenTaskDialog] = useState(false);
    const [openEditTaskDialog,setOpenEditTaskDialog] = useState(false);
    const [openParentBookable,setOpenParentBookable] = useState(false);
    const [show,setShow] = useState(false);
    const [openPopup,setOpenPopup] = useState(false);
    const [taskToDelete,setTaskToDelete] = useState(null);
    const { hasPermission, user } = useContext(UserContext);

    useEffect(() => {
        let wbsPlanningCopy = [...wbsPlanning];
        wbsPlanningCopy = wbsPlanningCopy.map(item => {
            let itemCopy = {...item};
            if (itemCopy.parent_id === null)itemCopy.parent_id = 0;
            return itemCopy
        })
        setWbs(listToTree(wbsPlanningCopy).sort((a,b)=>a.rank-b.rank));
    }, [wbsPlanning])

    const hideWbslevel = (taskId) => {
        let hideArrayCopy = [...hideArray];
        if (hideArrayCopy.includes(taskId)) {
            let indexOfId = hideArrayCopy.indexOf(taskId);
            hideArrayCopy.splice(indexOfId, 1);
        } else {
            hideArrayCopy.push(taskId);
        }
        setHideArray(hideArrayCopy);
    }

    const getArrownDownUp = (taskId) => {
        if (hideArray.includes(taskId)) {
            return <BsFillCaretRightFill onClick={() => hideWbslevel(taskId)} style={{marginTop:"5px",display:"block",width:15,cursor:"pointer",fontSize: 15,color:"black"}} />
        }
        return <BsFillCaretDownFill onClick={() => hideWbslevel(taskId)} style={{marginTop:"5px", width: 15, cursor: "pointer", fontSize: 15, color: "black" }} />
    }

    const onEditClick = (task) => {
        setTaskToEdit(task);
        setOpenEditTaskDialog(true);
    }

    const getSubfolderName = (task) => {
        return task.task_code+" "+task.name
    }
    /******/

    const onDeleteClick = () => {
        if(taskToDelete){
            let task = {...taskToDelete}
            if(task.wbs_tasks.length === 0){
                let tasksListCopy = null;
                //find all tasks with same parent_id than task and id not equal to task.id
                let tasksList = wbsPlanning.filter(item => item.parent_id === task.parent_id && item.id !== task.id);
                if (tasksList.length > 0){
                    //map tasksList to match rank with index
                        tasksListCopy = tasksList.sort((a,b)=>a.rank-b.rank).map((item, index) => {
                        let itemCopy = { ...item };
                        itemCopy.rank = index;
                        return itemCopy;
                    })
                }
                deleteWbsPlanning(task.id)
                .then(res => {
                    if(tasksListCopy){
                        editTaskMulti(tasksListCopy.map(item => ({id:item.id,rank:item.rank}))).then(res => {
                            fetchWbsPlanning(wbsId);
                        })
                    }
                })
            }else{
                // render a div with a message
                setShow(!show);
            }
        }
    }

    const onClickAddButton = (task) => {
        if(!task)setTaskItem(null);
        else setTaskItem(task);
        if(task && task.bookable){
            setOpenParentBookable(true);
        }else{
            setOpenTaskDialog(true);
        }
    }

    const editParentBookable = () => {
        editTask({
            id:taskItem.id,
            task:{bookable:false}
        }).then((res)=>{
            fetchWbsPlanning(wbsId);
            setOpenParentBookable(false);
            setOpenTaskDialog(true);
        });
    }

    const onDragEndTest = (event) => {
        let parentId = null;
        if(event && event.destination && event.destination.droppableId){
            if (event.destination.index === event.source.index) {
                return;
            }
            if(event.destination.droppableId !== event.source.droppableId)return;
            let id = event.destination.droppableId.split('-')[1];
            if(id !== "base"){
                parentId = +id;
            }
            //fin all task with parentId
            let tasksList = wbsPlanning.filter(e=>e.parent_id === parentId);
            //exchange rank value of task with same rank than items
            let itemSource = tasksList.find(e=>e.rank === event.source.index);
            let itemDestination = tasksList.find(e=>e.rank === event.destination.index);
            tasksList = reorder(tasksList.sort((a,b)=>a.rank-b.rank), event.source.index, event.destination.index);
            if(itemSource && itemDestination){
                let toSend = tasksList.map((e,i)=>{return {id:e.id,rank:i}});
                let wbsPlanningCopy = [...wbsPlanning];
                wbsPlanningCopy = wbsPlanningCopy.map(item => {
                    let itemCopy = {...item};
                    if (itemCopy.parent_id === null)itemCopy.parent_id = 0;
                    let found = toSend.find(e=>e.id === itemCopy.id);
                    if(found)itemCopy.rank = found.rank;
                    return itemCopy
                })
                setWbs(listToTree(wbsPlanningCopy).sort((a,b)=>a.rank-b.rank));
                editTaskMulti(toSend).then(()=>{
                    fetchWbsPlanning(wbsId)
                })
            }else{
            }
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragUpdate = () => {

    }

    const renderTaskTest = (task) => {
        return (
            <Box className="structure-task">
                <Box className="structure-task-content">
                    {task.wbs_tasks.length !== 0 ? getArrownDownUp(task.id) : <Box display="block" width="20px"></Box>}
                    {task.wbs_tasks.length === 0 && (
                        <Box marginTop="7px" marginRight="3px" display="flex" height="10px" width="10px"  borderRadius="2px" style={{backgroundColor:task.color_code ? '#'+task.color_code : 'grey'}}></Box>
                    )}
                    <span className="structure-task-code">{getSubfolderName(task)}</span>
                    { (task?.subfolder?.project_leader === user.id || hasPermission("admin.read" )||hasPermission("wbs_all.update")) && <>
                        <Box >
                            <ButtonAddTask OnClickFunction={()=>{onClickAddButton(task)}}/>
                        </Box>
                        <Box >
                            <ButtonEditTask OnClickFunction={()=>{onEditClick(task)}}/>
                        </Box>
                        <Box>
                            <ButtonDeleteTask OnClickFunction={() => {setOpenPopup(!openPopup);setTaskToDelete(task)}}/>
                        </Box>
                    </>}
                    { task.bookable && <BsCalendar className="structure-task-icon" />}
                    {/*<ProgressBarTask />*/}
                </Box>
            </Box>
        )
    }

    const renderLevel2 = (task,index) => {
        if(task.wbs_tasks){
            return (<Droppable droppableId={`droppable-${task.id}`} type={`${task.id == "base" ? 99999 : task.id}`}>
                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    > 
                        {task.wbs_tasks.sort((a,b)=>a.rank-b.rank).map((tsk,i)=>{
                            let t = {...tsk};
                            t.parent_code = (task.parent_code || "") + ((task.parent_code) ? ("." + task.task_code || "") : (task.task_code || ""));
                            
                            return (
                                <Draggable key={`${task.id == "base" ? 99999 : task.id}${i}`} draggableId={`${task.id == "base" ? 99999 : task.id}${i}`} index={i}>
                                    {(provided, snapshot) => (
                                        <div 
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        >
                                            <Box display="flex" width="30px" marginTop="5px"></Box>
                                            <Box {...provided.dragHandleProps} style={{ marginLeft: '30px',borderRadius:"5px", border:"1px solid rgba(224, 224, 224, 1)",marginBottom:"10px",backgroundColor:"white",paddingLeft:"5px", paddingTop:"20px", marginRight:"30px"}}>
                                                {renderTaskTest(t)}
                                                {(t.wbs_tasks && t.wbs_tasks.length > 0 && !hideArray.includes(t.id)) && renderLevel2(t,i)}
                                            </Box>
                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>)
        }
        return null
    }

    return (
        <Card noMarginTop hideButtons title={t('wbs.consult-subfolder.title', 'Work breakdown structure')}>
            <Box className="wbs-body-structure" style={{ maxHeight: 'calc(100vh - 223px)',minHeight: 'calc(100vh - 223px)'}}>
                <Box border="1px solid rgba(224, 224, 224, 1)" marginLeft="30px" marginRight="30px" marginBottom="10px" borderRadius="5px" padding="10px 15px" paddingBottom="10px">
                    <ButtonAddTask OnClickFunction={()=>{onClickAddButton()}}/>
                </Box>
                <Box >
                    <DragDropContext onDragEnd={onDragEndTest} onDragUpdate={onDragUpdate}>
                        {wbsData && wbsData.length > 0 &&  renderLevel2({id:'base',wbs_tasks:wbsData},9999)}
                    </DragDropContext>
                </Box>
            </Box>
            { show &&
                <Dialog
                    open={show}
                    onClose={() => setShow(!show)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {"Alert Message"}
                </DialogTitle>
                <DialogContent>
                    <div style={{color:'red'}}>You can't delete because of a subTask</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShow(!show)}>Close</Button>
                </DialogActions>
            </Dialog>
            }
           
            <Dialog open={openTaskDialog} onClose={()=>setOpenTaskDialog(false)} aria-labelledby="form-dialog-title">
                <FormWbs folder={folder} wbsData={wbsData} taskItem={taskItem} wbsId={wbsId} closemodal={() => setOpenTaskDialog(false)} />
            </Dialog>
            <Dialog open={openEditTaskDialog} onClose={()=>setOpenEditTaskDialog(false)} aria-labelledby="form-dialog-title">
                <FormWbsEdit folder={folder} taskItem={taskToEdit} wbsId={wbsId} closemodal={() => setOpenEditTaskDialog(false)}l/>
            </Dialog>
            <Dialog open={openParentBookable} onClose={()=>setOpenParentBookable(false)} aria-labelledby="form-dialog-title">
                <ParentBookableDialog editParentBookable={()=>editParentBookable()} onClose={()=>setOpenParentBookable(false)}/>
            </Dialog>
            {   openPopup && (
                <Dialog
                    open={openPopup}
                    onClose={() => setOpenPopup(!openPopup)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                      {"Alert Message"}
                    </DialogTitle>
                    <DialogContent>
                      <div style={{color:'red'}}>Do you really want to delete this task?</div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenPopup(!openPopup)}>Disagree</Button>
                      <Button onClick={()=>{onDeleteClick(); setOpenPopup(!openPopup)}} style={{color:'white' ,backgroundColor:'red'}} autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                </Dialog>
            )}
            
        </Card>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Display);