import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import AppDialog from './AppDialog';

const DuplicateDialog = ({ open, setOpen, description, duplicates, onContinue, onCancel }) => {
    const { t } = useTranslation();
    
    return (
        <AppDialog
            open={open}
            setOpen={setOpen}
            title={t('dialog.duplicate.title', 'Possible duplicate(s) detected')}
            okLabel={t('button.continue', 'Continue')}
            onOk={onContinue}
            onCancel={onCancel}
        >
            <Typography>
                { description }
            </Typography>
            <ul>
                { duplicates.map((duplicate, index) => (
                    <li key={index}>
                        <Typography>
                            { duplicate }
                        </Typography>
                    </li>
                )) }
            </ul>
        </AppDialog>
    );
}

export default DuplicateDialog;