const CONFIG_TOOLBAR = [
    "heading",
    "undo", 
    "redo", 
    "|",
    "fontSize", 
    "fontColor",
    "bold", 
    "italic", 
    "strikethrough", 
    "underline", 
    "blockQuote", 
    "link", 
    "|",
    "alignment",
    "|",
    "numberedList", 
    "bulletedList", 
    "|",
    "insertTable", 
    "tableColumn", 
    "tableRow", 
    "mergeTableCells",
    "|",
    "imageUpload",  
    "imageStyle:full", 
    "imageStyle:alignLeft", 
    "imageStyle:alignRight", 
    "indent", 
    "outdent"
]

export default CONFIG_TOOLBAR;