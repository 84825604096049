import { Table, TableBody, TableCell, TableRow, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { TableHead } from '@material-ui/core';
import { Box, TableContainer } from '@material-ui/core';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import TableHeadCell from '../table/TableHeadCell';
import { useParams } from 'react-router-dom';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import SnackbarContext from '../../context/snackbar';
import MONTH from '../../enums/month';
const MONTH_TO_SHOW = 6;
const BASE_NUMBER_BLOCK = 10;

const UserDisponibility = ({user,createUserAvailability,editUserAvailability,createUserAbsence,editUserAbsence,userBookings,consultOnly = null}) => {

    const { t } = useTranslation();
    const { id } = useParams();
    const [startMonth,setStartMounth] = useState(0);
    const [startDate,setStartDate] = useState(new Date());
    const [inputAvailabilityValues,setInputAvailabilityValues] = useState([]);
    const [inputAbsencesValues,setInputAbsencesValues] = useState([]);
    const [abscenceToComfirm,setAbscenceToComfirm] = useState([]);
    const [foldersPlanningList,setFoldersPlanningList] = useState({client_project:[],internal_project:[]});
    const [totalClient,setTotalClient] = useState(Array(MONTH_TO_SHOW).fill(0))
    const [totalInternal,setTotalInternal] = useState(Array(MONTH_TO_SHOW).fill(0))
    const [hideListClient,setHideListClient] = useState([]);
    const [hideListInternal,setHideListInternal] = useState([]);
    const [capacityListClient,setCapacityListClient] = useState(Array(MONTH_TO_SHOW).fill(0));
    const [TotalAllClient,setTotalAllClient] = useState(Array(MONTH_TO_SHOW).fill(0))
    const [TotalAllInternal,setTotalAllInternal] = useState(Array(MONTH_TO_SHOW).fill(0))
    const palette = useTheme().palette;
    const history = useHistory();
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

    useEffect(()=>{
        //sort tasks by folders
        let wbsList = {client_project:{},internal_project:{}};
        let wbsListToArray = {client_project:[],internal_project:[]};
        userBookings.forEach(task=>{
            // /TODO!\
            //check if otherTask have parent_id == task.id => remove because is not end task
            const isFolderTask = userBookings.find(otherTask=>otherTask.parent_id === task.id);
            if(!isFolderTask){
                if(task.wb.folder.project_type === "operations"){
                    if(!wbsList.client_project[task.wb.id])wbsList.client_project[task.wb.id] = {
                        tasks:[task],
                        folder:task.wb.folder
                    };
                    else{
                        let copy =  wbsList.client_project[task.wb.id]
                        copy.tasks.push(task);
                        wbsList.client_project[task.wb.id] = copy;
                    }
                } 
                else if(task.wb.folder.project_type !== "absence"){
                    if(!wbsList.internal_project[task.wb.id])wbsList.internal_project[task.wb.id] = {
                        tasks:[task],
                        folder:task.wb.folder
                    };
                    else{
                        let copy =  wbsList.internal_project[task.wb.id]
                        copy.tasks.push(task);
                        wbsList.internal_project[task.wb.id] = copy;
                    }
                }
            }
        })
        // eslint-disable-next-line
        for(const [_key,val] of Object.entries(wbsList.client_project)){
            wbsListToArray.client_project.push(val)
        }
        // eslint-disable-next-line
        for(const [_key,val] of Object.entries(wbsList.internal_project)){
            wbsListToArray.internal_project.push(val)
        }
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        let arrClient = {};
        let arrInternal = {};
        let allClient = Array(MONTH_TO_SHOW).fill(0);
        let allInternal = Array(MONTH_TO_SHOW).fill(0);
        toMap.forEach(v=>{
            const dateToCheck = moment(date).add(v,'month')
            wbsListToArray.client_project.forEach(wbs=>{
                if(!arrClient[wbs.folder.id]){
                    arrClient[wbs.folder.id] = Array(MONTH_TO_SHOW).fill(0)
                }
                let copy = [...arrClient[wbs.folder.id]]
                wbs.tasks.forEach(task=>{
                    task.wbs_task_plannings.forEach(wbsTaskPlanning=>{
                        if(moment(wbsTaskPlanning.start_date).startOf('month').format('DD-MM-YYYY') === moment(dateToCheck).startOf('month').format('DD-MM-YYYY')){
                            copy[v] += (wbsTaskPlanning.number_blocks/BASE_NUMBER_BLOCK)
                            allClient[v] += (wbsTaskPlanning.number_blocks/BASE_NUMBER_BLOCK)
                        }
                    })
                })
                arrClient[wbs.folder.id] = copy
            })
            wbsListToArray.internal_project.forEach(wbs=>{
                if(!arrInternal[wbs.folder.id]){
                    arrInternal[wbs.folder.id] = Array(MONTH_TO_SHOW).fill(0)
                }
                let copy = [...arrInternal[wbs.folder.id]]
                wbs.tasks.forEach(task=>{
                    task.wbs_task_plannings.forEach(wbsTaskPlanning=>{
                        if(moment(wbsTaskPlanning.start_date).startOf('month').format('DD-MM-YYYY') === moment(dateToCheck).startOf('month').format('DD-MM-YYYY')){
                            copy[v] += (wbsTaskPlanning.number_blocks/BASE_NUMBER_BLOCK)
                            allInternal[v] += (wbsTaskPlanning.number_blocks/BASE_NUMBER_BLOCK)
                        }
                    })
                })
                arrInternal[wbs.folder.id] = copy
            })
        })
        setTotalAllInternal(allInternal);
        setTotalAllClient(allClient);
        setTotalClient(arrClient);
        setTotalInternal(arrInternal);
        setFoldersPlanningList(wbsListToArray)
    },[userBookings,startDate])

    useEffect(()=>{
        if(user.user_user_extra_info.number_holidays){
            let currentMonth = moment().month()
            let year = moment().year();
            let arrayToEndOfYear = Array(11-(currentMonth)).fill(0).map((x,i)=>{
                return {
                    month:i+currentMonth+1,
                    year:year,
                    val:0
                };
            });
            //get absences total for current year
            
            let totalAbscence = 0
            user.user_user_absences?.forEach((val)=>{
                if(moment(val.start_date).year() === year){
                    totalAbscence += (val.number_blocks / BASE_NUMBER_BLOCK)
                }
            })
            let absencesToComfirm = user.user_user_extra_info.number_holidays - totalAbscence;
            if(absencesToComfirm >= 0){
                while(absencesToComfirm > 0){
                    if(arrayToEndOfYear.length === 0)absencesToComfirm = 0;
                    for(let i = 0; i< arrayToEndOfYear.length; i++){
                        let copy = {...arrayToEndOfYear[i]}
                        copy.val += 1;
                        arrayToEndOfYear[i] = copy;
                        absencesToComfirm --;
                        if(absencesToComfirm === 0)break;
                    }
                }
            }
            setAbscenceToComfirm(arrayToEndOfYear)
        }
        
    },[user])

    const goToNextTime = () => {
        let dateCopy = moment(startDate).add(MONTH_TO_SHOW,"month");
        setStartMounth(dateCopy.month());
        setStartDate(dateCopy.toDate());
        //setStartDate
    }

    const goToPreviousTime = () => {
        let dateCopy = moment(startDate).add(-MONTH_TO_SHOW,"month");
        setStartMounth(dateCopy.month());
        setStartDate(dateCopy.toDate());
    }

    useEffect(()=>{
        setStartMounth(startDate.getMonth());
    },[startDate]);

    const onBlurCreateUserAvailability = (event,dateToCheck) => {
        if(event.target.valueAsNumber){
            createUserAvailability({
                user_id:id,
                percentage:event.target.valueAsNumber,
                month:moment(dateToCheck).month()+1,
                year:moment(dateToCheck).year()
            }).then(()=>{
                openSuccessSnackbar("saved")
            });
        }
    }

    const renderDisponibility = () => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        return (
            <TableRow>
                <TableCell>
                    Disponibilité (%)
                </TableCell>
                {toMap.map(v=>{
                    const dateToCheck = moment(date).add(v,'month');
                    return (
                        <TableCell>
                            <Box display="flex" justifyContent="center">
                                {renderDisponibilityRow(dateToCheck)}
                            </Box>
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    }

    const onBlurEditUserAvailability = (foundItem) => {
        if(foundItem.val){
            editUserAvailability({
                id:foundItem.id,
                data:{
                    percentage:foundItem.val,
                    user_id:id
                }
            }).then(()=>{
                openSuccessSnackbar("updated")
            });
        }
    }

    const onChangeEditUserAvailability = (event,foundItem) => {
        let inputValsCopy = [...inputAvailabilityValues];
        let indexOfAv = inputValsCopy.map(e=>e.id).indexOf(foundItem.id);
        if(indexOfAv !== -1){
            let foundItemCopy = inputValsCopy[indexOfAv];
            foundItemCopy.val = event.target.valueAsNumber ?? null;
            inputValsCopy[indexOfAv] = foundItemCopy;
            setInputAvailabilityValues(inputValsCopy)
        }
    }

    const toTwoDigits = (num) => {
        return num < 10 ? `0${num}` : num;
    }

    const renderDisponibilityRow = (dateToCheck) => {
        let availabilityFound = user.user_user_availabilities?.find(av=>(+av.month-1) === moment(dateToCheck).month() && av.year === moment(dateToCheck).year());
        
        if(availabilityFound){
            let inputValsCopy = [...inputAvailabilityValues];
            let foundItem = inputValsCopy.find(e=>e.id === availabilityFound.id);
            if(!foundItem){
                foundItem = {
                    id:availabilityFound.id,
                    val:availabilityFound.percentage
                }
                inputValsCopy.push(foundItem);
                setInputAvailabilityValues(inputValsCopy)
            }
            return consultOnly ? foundItem.val :<div key={foundItem.id}><input id={foundItem.id} onChange={(event)=>onChangeEditUserAvailability(event,foundItem)} onBlur={(event)=>onBlurEditUserAvailability(foundItem)} value={foundItem.val} type="number" className="wbs-planning-input"></input></div>
        }
        else{
            let availabilityFounds = user.user_user_availabilities?.filter(av=>{
                return moment('01'+toTwoDigits(av.month)+av.year,'DDMMYYYY').isBefore(moment(dateToCheck))
            }).sort((a,b)=>{
                return moment('01'+toTwoDigits(a.month)+a.year,'DDMMYYYY').isBefore(moment('01'+toTwoDigits(b.month)+b.year,'DDMMYYYY')) ? 1 : -1
            });
            let defaultValue = '';
            if(availabilityFounds && availabilityFounds.length > 0)defaultValue = availabilityFounds[0].percentage;
            return consultOnly ? defaultValue : <div key={dateToCheck.format('DD-MMMM-YYYYY')+availabilityFounds.length}><input defaultValue={defaultValue} onBlur={(event)=>onBlurCreateUserAvailability(event,dateToCheck)} id={dateToCheck.format('DD-MM-YYYY')} type="number" className="wbs-planning-input"></input></div>
        }
        
    }

    const renderApprovedAbsence = () => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        return (
            <TableRow>
                <TableCell>
                    Absences confirmées (jour)
                </TableCell>
                {toMap.map(v=>{
                    const dateToCheck = moment(date).add(v,'month');
                    return (
                        <TableCell>
                            <Box display="flex" justifyContent="center">
                                {renderApprovedAbsenceRow(dateToCheck)}
                            </Box>
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    }

    const renderMonth = (numberOfMonth) => {
        const monthArray = Array(numberOfMonth).fill(0).map((x,i)=>(i+startMonth)%12)
        const year = moment(startDate);
        return monthArray.map((month,i)=>{
            year.add(i == 0? 0 : 1,'month');
            return (
                <TableHeadCell key={month}>
                    <Box display="flex" justifyContent="center">
                        {t('list.month.'+MONTH[month],MONTH[month])}
                    </Box>
                    <Box display="flex" justifyContent="center">
                        {year.year()}
                    </Box>
                </TableHeadCell>
            )
        })
    }

    const renderApprovedAbsenceRow = (dateToCheck) => {
        let absenceFound = user.user_user_absences?.find(absence=>moment(absence.start_date).startOf('month').format('DD/MM/YYYY') === moment(dateToCheck).startOf('month').format('DD/MM/YYYY'));
        if(absenceFound){
            let inputValsCopy = [...inputAbsencesValues];
            let foundItem = inputValsCopy.find(e=>e.id === absenceFound.id);
            if(!foundItem){
                foundItem = {
                    id:absenceFound.id,
                    val:(absenceFound.number_blocks/BASE_NUMBER_BLOCK),
                    approved:absenceFound.approved
                }
                inputValsCopy.push(foundItem);
                setInputAbsencesValues(inputValsCopy)
            }
            return consultOnly ? foundItem.val : <div key={foundItem.id}><input style={{color:foundItem.approved ? 'black' : 'grey'}} onChange={(event)=>onChangeEditUserAbsences(event,foundItem)} onBlur={()=>onBlurEditUserAbsences(foundItem)} value={(foundItem.val)} type="number" className="wbs-planning-input"></input></div>
        }
        return consultOnly ? null : <div key={dateToCheck.format('DD-MM-YYYY')}><input onBlur={(event)=>{onBlurCreateAbscence(event,dateToCheck)}} type="number" className="wbs-planning-input"></input></div>
    }

    const onChangeEditUserAbsences = (event,foundItem) => {
        let inputValsCopy = [...inputAbsencesValues];
        let indexOfAv = inputValsCopy.map(e=>e.id).indexOf(foundItem.id);
        if(indexOfAv !== -1){
            let foundItemCopy = inputValsCopy[indexOfAv];
            foundItemCopy.val = event.target.valueAsNumber ?? null;
            inputValsCopy[indexOfAv] = foundItemCopy;
            setInputAbsencesValues(inputValsCopy)
        }
    }

    const onBlurEditUserAbsences = (foundItem) => {
        if(foundItem.val || foundItem.val === 0){
            editUserAbsence({
                id:foundItem.id,
                data:{
                    number_blocks:(foundItem.val*BASE_NUMBER_BLOCK),
                    user_id:id
                }
            }).then(()=>{
                openSuccessSnackbar("updated")
            });
        }
        
    }

    const onBlurCreateAbscence = (event,dateToCheck) => {
        if(event.target.valueAsNumber){
            createUserAbsence({
                user_id:id,
                number_blocks:(event.target.valueAsNumber * BASE_NUMBER_BLOCK),
                planning_level_class:"planning_level",
                planning_level_type:"month",
                start_date:moment(dateToCheck).startOf('month').toDate(),
                end_date:moment(dateToCheck).endOf('month').toDate(),
                approved:0
            }).then(()=>{
                openSuccessSnackbar("saved")
            });
        }
    }
    
    const renderNoneApprovedAbsence = () => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        return (
            <TableRow>
                <TableCell>
                    Absences à confirmer (jour)
                </TableCell>
                {toMap.map(v=>{
                    const dateToCheck = moment(date).add(v,'month');
                    let copyAbscenceToComfirm = [...abscenceToComfirm];
                    let toRender = copyAbscenceToComfirm.find(e=>e.month === moment(dateToCheck).month() && e.year === moment(dateToCheck).year());

                    return (
                        <TableCell>
                            <Box display="flex" justifyContent="center">
                                {toRender && toRender.val !== 0 && toRender.val}
                            </Box>
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    }

    const renderCapacity = () => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        let toSave = false;
        let capacityListClientCopy = [...capacityListClient];
        let capacityList = [];
        toMap.forEach(v=>{
            const dateToCheck = moment(date).add(v,'month');
            let computedCapacity = computeCapacity(moment(dateToCheck.toDate()))
            if(capacityListClientCopy[v] !== computedCapacity){
                capacityListClientCopy[v] = computedCapacity;
                toSave = true;
            }
            capacityList.push(computedCapacity);
        })
        if(toSave)setCapacityListClient(capacityListClientCopy);
        return (
            <TableRow>
                <TableCell>
                    Capacité (jour)
                </TableCell>
                {toMap.map(v=>{
                    return (
                        <TableCell style={{borderTop:'1px solid black'}}>
                            <Box display="flex" justifyContent="center">
                                {capacityList[v]}
                            </Box>
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    }

    const computeCapacity = (dateToCheck) => {
        let month = dateToCheck.month();
        let year = dateToCheck.year();
        dateToCheck.startOf('month');
        let numberDays = dateToCheck.daysInMonth();
        let numberWorkingDay = 0;
        for(let i = 0 ; i < (numberDays) ; i++){
            if(((dateToCheck.get('day')+1)%7 !== 0 && (dateToCheck.get('day')+1)%7 !== 1) && dateToCheck.get('month') === month)numberWorkingDay += 1
            dateToCheck.add(1,'day') 
        }
        let availabilityFound = user.user_user_availabilities?.find(e=>e.year === year && (e.month-1) === month);
        let absenceFound = user.user_user_absences?.find(e=>moment(e.start_date).month() === month && moment(e.start_date).year() === year) || {number_blocks:0};
        if(!availabilityFound){
            
            let availabilityFounds = user.user_user_availabilities?.filter(av=>{
                return moment('01'+toTwoDigits(av.month)+av.year,'DDMMYYYY').isBefore(moment(dateToCheck))
            }).sort((a,b)=>{
                return moment('01'+toTwoDigits(a.month)+a.year,'DDMMYYYY').isBefore(moment('01'+toTwoDigits(b.month)+b.year,'DDMMYYYY')) ? 1 : -1
            });
            if(availabilityFounds && availabilityFounds.length > 0)availabilityFound = {percentage:+availabilityFounds[0].percentage};
            else availabilityFound = {percentage:0};
        }
        dateToCheck.subtract(3,'day') 
        let abscenceToConfirmFound = abscenceToComfirm.find(e=>e.month === moment(dateToCheck).month() && e.year === moment(dateToCheck).year()) || {val:0}
        let computedCapacity = (Math.floor((numberWorkingDay*availabilityFound.percentage) / 100)) - (absenceFound.number_blocks / BASE_NUMBER_BLOCK) - abscenceToConfirmFound.val
        //capacityListClient,setCapacityListClient
        return computedCapacity === 0 ? null : computedCapacity
    }

    const hideClient = (folderId) => {
        let hideListClientCopy = [...hideListClient];
        if(!hideListClientCopy.includes(folderId)){
            hideListClientCopy.push(folderId)
        }
        else{
            let indexOfFolderId = hideListClientCopy.indexOf(folderId);
            hideListClientCopy.splice(indexOfFolderId,1);
        }
        setHideListClient(hideListClientCopy);
    }


    const hideInternal = (folderId) => {
        let hideListInternalCopy = [...hideListInternal];
        if(!hideListInternalCopy.includes(folderId)){
            hideListInternalCopy.push(folderId)
        }
        else{
            let indexOfFolderId = hideListInternalCopy.indexOf(folderId);
            hideListInternalCopy.splice(indexOfFolderId,1);
        }
        setHideListInternal(hideListInternalCopy);
    }

    const renderClientProject = () => {
        return foldersPlanningList.client_project.map(clientProject=>{
            //hideListClient,setHideListClient
            return (<>
                <TableRow>
                    <TableCell style={{fontSize:"16px",fontWeight:"bold",color:palette.primary.main}} colSpan={7}>
                        <Box display="flex" flexDirection="row" className="hover-black hover-pointer" onClick={()=>history.push("/folders/"+clientProject.folder.id+"?tab=3")}>
                            {hideListClient.includes(clientProject.folder.id) ? 
                            <FaCaretRight onClick={()=>hideClient(clientProject.folder.id)} style={{marginTop:"auto",marginBottom:"auto",cursor:"pointer"}}/>
                            : <FaCaretDown onClick={()=>hideClient(clientProject.folder.id)} style={{marginTop:"auto",marginBottom:"auto",cursor:"pointer"}}/>}
                            <span>{clientProject.folder.code} - {clientProject.folder.name}</span>
                        </Box>
                        
                        
                    </TableCell>
                </TableRow>
                {clientProject.tasks.map(task=>{
                    let now = moment(startDate).toDate();
                    let isInDateRange = false;
                    task.wbs_task_plannings.forEach(wbsTPlanning=>{
                        if(moment(wbsTPlanning.start_date).isAfter(moment(now).startOf('month').subtract(1,'day')) && moment(wbsTPlanning.start_date).isBefore(moment(now).endOf('month').add(MONTH_TO_SHOW-1,'month')))isInDateRange = true;
                    })
                    return (<>
                        {isInDateRange && !hideListClient.includes(clientProject.folder.id) && <TableRow>
                            <TableCell>
                                {task.task_code} - {task.name}
                            </TableCell>
                            {renderTaskPlannings(task,'client_project')}
                        </TableRow>}</>
                    )
                })}
                <TableRow>
                    <TableCell style={{fontSize:"16px",fontWeight:"bold",color:palette.primary.main}} >
                            Total
                    </TableCell>
                    {totalClient[clientProject.folder.id] && totalClient[clientProject.folder.id].map(i=>{
                        return (
                            <TableCell style={{fontSize:"16px",fontWeight:"bold",color:palette.primary.main}}>
                                <Box display="flex" justifyContent="center">
                                    {i !== 0 && i}
                                </Box>
                            </TableCell>
                        )
                    })}
                </TableRow>
            </>)
        })
    }

    const renderInternalProject = () => {
        return foldersPlanningList.internal_project.map(InternalProject=>{
            //hideListInternal,setHideListInternal
            return (<>
                <TableRow>
                    <TableCell style={{fontSize:"16px",fontWeight:"bold",color:palette.primary.main}} colSpan={7}>
                    <Box display="flex" flexDirection="row" className="hover-black hover-pointer" onClick={()=>history.push("/folders/"+InternalProject.folder.id+"?tab=3")}>
                            {hideListClient.includes(InternalProject.folder.id) ? 
                            <FaCaretRight onClick={()=>hideInternal(InternalProject.folder.id)} style={{marginTop:"auto",marginBottom:"auto",cursor:"pointer"}}/>
                            : <FaCaretDown onClick={()=>hideInternal(InternalProject.folder.id)} style={{marginTop:"auto",marginBottom:"auto",cursor:"pointer"}}/>}
                            <span>{InternalProject.folder.code} - {InternalProject.folder.name}</span>
                        </Box>
                    </TableCell>
                </TableRow>
                {InternalProject.tasks.map(task=>{
                    //check if task have taskPlanning in date range
                    
                    let now = new Date();
                    let isInDateRange = false;
                    task.wbs_task_plannings.forEach(wbsTPlanning=>{
                        if(moment(wbsTPlanning.start_date).isAfter(moment(now).startOf('month').subtract(1,'day')) && moment(wbsTPlanning.start_date).isBefore(moment(now).endOf('month').add(MONTH_TO_SHOW,'month')))isInDateRange = true;
                    })
                    return (<>
                        {isInDateRange && !hideListClient.includes(InternalProject.folder.id) && <TableRow>
                            <TableCell>
                                {task.task_code} - {task.name}
                            </TableCell>
                            {renderTaskPlannings(task,'internal_project')}
                        </TableRow>}</>
                    )
                })}
                <TableRow>
                    <TableCell style={{fontSize:"16px",fontWeight:"bold",color:palette.primary.main}} >
                            Total
                    </TableCell>
                    {totalInternal[InternalProject.folder.id] && totalInternal[InternalProject.folder.id].map(i=>{
                        return (
                            <TableCell style={{fontSize:"16px",fontWeight:"bold",color:palette.primary.main}}>
                                <Box display="flex" justifyContent="center">
                                    {i !== 0 && i}
                                </Box>
                            </TableCell>
                        )
                    })}
                </TableRow>
                
            </>)
        })
    }
    
    const renderTaskPlannings = (task,type) => {
        const toMap = Array(MONTH_TO_SHOW).fill(0).map((x,i)=>i);
        const date = startDate.getTime();
        return  toMap.map(v=>{
            const dateToCheck = moment(date).add(v,'month');
            let foundTaslPlanning = task.wbs_task_plannings.find(e=>moment(dateToCheck).startOf('month').format('DD-MM-YYYY') === moment(e.start_date).startOf('month').format('DD-MM-YYYY'));
                return (
                   <TableCell>
                       <Box display="flex" justifyContent="center">
                           {foundTaslPlanning ? (foundTaslPlanning.number_blocks/BASE_NUMBER_BLOCK) : null}
                       </Box>
                   </TableCell>
            )
        })
    }

    return (
    <Box display="flex" width="100%">
        <TableContainer style={{ maxHeight: 'calc(100vh - 230px)',minHeight: 'calc(100vh - 230px)',border:"1px solid rgba(224, 224, 224, 1)" }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableHeadCell style={{minWidth: 300}}>
                                <Box  display="flex" flexWrap="no-wrap" justifyContent="start">
                                    <Box onClick={()=>goToPreviousTime()} className="wbs-planning-button-text" display="flex" flexWrap="no-wrap">
                                        <MdNavigateBefore className="wbs-planning-chevron"/> {t('wbs.plannings.previous','Previous')}
                                    </Box>
                                    <Box onClick={()=>goToNextTime()} className="wbs-planning-button-text" display="flex" flexWrap="no-wrap">
                                    {t('wbs.plannings.next','Next')} <MdNavigateNext className="wbs-planning-chevron"/>
                                    </Box>
                                </Box>
                            </TableHeadCell>
                            {renderMonth(MONTH_TO_SHOW)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderDisponibility()}
                        {renderApprovedAbsence()}
                        {renderNoneApprovedAbsence()}
                        {renderCapacity()}
                        <TableRow>
                            <TableHeadCell>
                                Projet Client
                            </TableHeadCell>
                            <TableHeadCell colSpan={6}>
                                Target : {user.user_user_project_targets?.find(e=>e.project_type === "operations")?.target ?? ''} %
                            </TableHeadCell>
                        </TableRow>
                        {renderClientProject()}
                        <TableRow>
                            <TableCell style={{fontWeight:"bold",color:palette.primary.main}} >
                                    Occupation cumulée
                            </TableCell>
                            {TotalAllClient && TotalAllClient.map((tot,i)=>{
                                let userTargetClient = user.user_user_project_targets?.find(e=>e.project_type === "operations")?.target ?? null
                                let backgroundColor = "transparent"
                                if(userTargetClient){
                                    let percent = (tot*100)/capacityListClient[i];
                                    if(userTargetClient < percent)backgroundColor = "red"
                                }
                                return (
                                    <TableCell style={{color:palette.primary.main}}>
                                        <Box display="flex" justifyContent="center" style={{backgroundColor:backgroundColor}}>
                                            {tot !== 0 && tot}{capacityListClient[i] === 0 ? '' : '/'+capacityListClient[i]}
                                        </Box>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                        <TableRow>
                            <TableHeadCell>
                                Projet Interne
                            </TableHeadCell>
                            <TableHeadCell colSpan={6}>
                                Target : {user.user_user_project_targets?.find(e=>e.project_type === "support")?.target ?? ''} %
                            </TableHeadCell>
                        </TableRow>
                        {renderInternalProject()}
                        <TableRow>
                            <TableCell style={{fontWeight:"bold",color:palette.primary.main}} >
                                    Occupation cumulée
                            </TableCell>
                            {TotalAllInternal && TotalAllInternal.map((tot,i)=>{
                                let userTargetInternal = user.user_user_project_targets?.find(e=>e.project_type === "support")?.target ?? null
                                let backgroundColor = "transparent"
                                if(userTargetInternal){
                                    let percent = (tot*100)/capacityListClient[i];
                                    if(userTargetInternal < percent)backgroundColor = "red"
                                }
                                return (
                                    <TableCell style={{color:palette.primary.main}}>
                                        <Box display="flex" justifyContent="center" style={{backgroundColor:backgroundColor}}>
                                            {tot !== 0 ? (capacityListClient[i] === 0 ? tot : tot+'/'+capacityListClient[i]) : null}
                                        </Box>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
    </Box>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(UserDisponibility)