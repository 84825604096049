import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Box, Badge, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { FaBell } from 'react-icons/fa';
import { VscCircleFilled, VscCircleOutline } from 'react-icons/vsc';
import { IoCheckmarkDone } from 'react-icons/io5';

import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import NotificationDialog from '../notifications/NotificationDialog';

const useStyles = makeStyles(theme => ({
    bell: {
        color: theme.palette.secondary.main,
    },
    notifications: {
        boxShadow: theme.shadows[4],
        color: theme.palette.common.black,
    },
    indicator: {
        color: theme.palette.secondary.main,
    },
}));

const NotificationsDropdown = ({ notifications, readNotification, readNotifications, users }) => {
    const [ open, setOpen ] = useState(false);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogNotification, setDialogNotification ] = useState(null);
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const pending = notifications.filter(notification => !notification.notification_read);
    const sorted = notifications.slice().sort((n1, n2) => n1.notification_read === n2.notification_read ? 0 : (n1.notification_read ? 1 : -1));

    const handleNotificationClick = notification => {
        if(notification.notification_type === 'dialog') {
            setDialogNotification(notification);
            setDialogOpen(true);
        } else {
            setOpen(false);
            readNotification(notification.id);
            if(notification.message[0] === '/') {
                history.push(notification.message);
            } else {
                window.open(notification.message);
            }
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        readNotification(dialogNotification.id);
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => open && !dialogOpen && setOpen(false)}>
                <Box className="navbar-dropdown mr-20">
                    <Box className="navbar-dropdown-button">
                        <IconButton onClick={() => setOpen(!open)}>
                            <Badge badgeContent={pending.length} color="error">
                                <FaBell className={classes.bell} size={20}/>
                            </Badge>
                        </IconButton>
                        { open &&
                            <List
                                className={`navbar-dropdown-items navbar-notifications-dropdown-buttons ${classes.notifications}`}
                                subheader={
                                    <ListSubheader component="div">
                                        { t('navbar.notifications.pending', '{{count}} pending notification', { count: pending.length }) }
                         {/*                <Button
                                            className="navbar-notifications-mark-all-read"
                                            onClick={() => readNotifications(pending.map(notification => notification.id))}
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            disableRipple
                                            disableElevation
                                        >
                                            <IoCheckmarkDone size={20}/>
                                        </Button> */}
                                    </ListSubheader>
                                }
                            >
                                {
                                    sorted.map((notification, index) => (
                                        <ListItem button key={index} onClick={() => handleNotificationClick(notification)}>
                                            <ListItemIcon className="navbar-notifications-dropdown-button-icon">
                                                { notification.notification_read ? <VscCircleOutline/> : <VscCircleFilled className={classes.indicator}/> }
                                            </ListItemIcon>
                                            <ListItemText className="ellipse">
                                                { notification.title ?? notification.message }
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        }
                    </Box>
                </Box>
            </ClickAwayListener>
            <NotificationDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                notification={dialogNotification}
                users={users}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDropdown);