import { Box, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField, Typography, withTheme } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react"
import { IoClose } from "react-icons/io5"

const GroupsSetting = ({updatePermissionsGroup, focusedKey, keys, groups, theme, permList}) => {

    const [permission, setPermission] = useState('');

    const [permsListe, setPermsList] = useState([]);

    useEffect(()=>{
        if(!groups[keys[focusedKey]])setPermsList([]);
        else{
            let keysList = [...groups[keys[focusedKey]]]
            setPermsList(keysList.sort())
        }
    },[focusedKey,keys,groups])

    const handleAddPerms = () => {
        if(groups[keys[focusedKey]] && permission && permission.name){
            let permissionsGroupCopy;
            if(!groups[keys[focusedKey]]) permissionsGroupCopy = [permission]
            else permissionsGroupCopy = [...groups[keys[focusedKey]].map(e=>e.name),permission.name]
            let payload = {
                group:keys[focusedKey],
                permissionsGroup:permissionsGroupCopy
            }
            updatePermissionsGroup(payload).then(()=>setPermission(''));
        }
    }

    const handleRemovePerms = (permKey) => {
        let permissionsGroupCopy = [...groups[keys[focusedKey]]].map(e=>e.name);
        //find index of groupPermissionsKey
        let indexOfGroupPermission = permissionsGroupCopy.indexOf(permKey);
        if(indexOfGroupPermission >= 0)permissionsGroupCopy.splice(indexOfGroupPermission,1);
        let payload = {
            group:keys[focusedKey],
            permissionsGroup:permissionsGroupCopy
        }
        updatePermissionsGroup(payload);
    }

    return (
        <Box display="flex" flexDirection="column"  height="100%" >
                <Typography style={{marginBottom:30}} variant="h6">Permissions list</Typography>
                <Box display="flex" flexDirection="column" height="90%">
                    <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" marginBottom="30px">
                        {/* <TextField onChange={(event)=>setPermission(event.target.value)} value={permission} style={{width:"70%"}}></TextField> */}
                        <Autocomplete
                        style={{width:"100%",marginRight:"10px"}}
                            value={permission}
                            options={permList.filter(e=> !permsListe.find(p=>p.name === e.name)).sort((a,b)=>a.name.localeCompare(b.name))}
                            getOptionLabel={(option) => (option.name)}
                            renderOption={(props,option) => {
                                return(<Box display="flex" width="100%" flexDirection="row" justifyContent="space-between"><Box style={{whiteSpace:"nowrap"}}marginRight="20px">{props.name}</Box><Box whiteSpace="nowrap">{props.comment}</Box></Box>)
                            }}
                            onChange={(e,s)=>setPermission(s)}
                            renderInput={(params) => <TextField {...params} label="Permission" variant="outlined" />}
                         />
                        <Button onClick={()=>handleAddPerms()} variant="contained" color="primary" style={{width:"20%"}}>Add</Button>
                    </Box>
                    <List className="permissions-group-list">  
                        {permsListe.map(gl=>{
                            return (
                            <ListItem key={gl.name}>
                                <ListItemIcon>
                                    <IconButton onClick={()=>handleRemovePerms(gl.name)}><IoClose color={theme.palette.error.main}/></IconButton>
                                </ListItemIcon>
                                <ListItemText primary={gl.name}/>
                                <ListItemText primary={gl.comment} style={{textAlign:"right"}}/>
                            </ListItem>
                            )}
                        )}
                    </List>
                </Box>
        </Box>
    )
}

export default withTheme(GroupsSetting)
