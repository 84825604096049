import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
    root: tableField => ({
        width: '100%',
        marginTop: tableField ? 0 : 30,
    }),
});

const FormChipsField = ({ name, label, options = [], allowCustom = true, tableField = false,getOptionLabel = (val)=>val ? val : "" }) => {
    const classes = useStyles(tableField);
    const { t } = useTranslation();
    const { control, errors } = useFormContext();

    const translate = useCallback(key => {
        if(!key) return;
        if(typeof key === 'string') return t(key);
        return t(key.key, key.values);
    }, [ t ]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ onChange, ...props }) => (
                <Autocomplete
                    { ...props }
                    className={classes.root}
                    multiple
                    freeSolo={allowCustom}
                    options={options}
                    onChange={(_event, value) => onChange(value)}
                    getOptionLabel={getOptionLabel}
                    renderInput={params => (
                        <TextField
                            { ...params }
                            label={label}
                            error={errors[name] && Boolean(errors[name])}
                            helperText={errors[name] && translate(errors[name].message)}
                        />
                    )}
                />
            )}
        />
    );
}

export default FormChipsField;