import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchWbsPlanning = createAsyncThunk(
    'wbsPlanning/fetchWbsPlanning',
    async (wbsId) => {
        const response = await axios.get(`/wbs/task_planning/get_by_wbs_id/${wbsId}`);
        return response.data;
    },
);

const importWbsPlanningsAsync = createAsyncThunk(
    'wbsPlanning/importWbsPlannings',
    async (data) => {
        const formData = new FormData();
        formData.append('file',data.file);
        formData.append('wbsId',data.wbsId);

        const response = await axios.post('/wbs/task_plannings/import', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
        })
        if (response.data.status === 'error') throw response.data.value;

        return response.data;
    }
)

const updateWbsPlanningAsync = createAsyncThunk(
    'wbsPlanning/updateWbsPlanning',
    async (taskPlanning) => {
        const response = await axios.put(`/wbs/task_planning/edit/${taskPlanning.taskPlanningId}`, taskPlanning.taskPlanning);
        if (response.data.status === 'error') throw response.data.value;
        return response.data;
    },
);

const createWbsPlanningAsync = createAsyncThunk(
    'wbsPlanning/createWbsPlanningAsync',
    async (taskPlanning) => {
        const response = await axios.post('/wbs/task_planning/create', taskPlanning.taskPlanning);
        if (response.data.status === 'error') throw response.data.value;
        const responseData = await axios.get(`/wbs/task_planning/get_by_wbs_id/${taskPlanning.wbsId}`);
        return responseData.data;
    },
);

const validatePlanningUpdate = createAsyncThunk(
    'wbsPlanning/validatePlanningUpdate',
    async (wbsId) => {
        const response = await axios.put(`/wbs/task_planning/validate/${wbsId}`,{});
        if (response.data.status === 'error') throw response.data.value;
        const responseData = await axios.get(`/wbs/task_planning/get_by_wbs_id/${wbsId}`);
        return responseData.data;
    },
);

const deleteWbsPlanning = createAsyncThunk(
    'wbsPlanning/deleteWbsPlanning',
    async (wbsTaskId) => {
        const response = await axios.delete(`/wbs/task/${wbsTaskId}`);
        if (response.data.status === 'error') throw response.data.value;
        return response.data;
    },
);

const wbsPlanningSlice = createSlice({
    name: 'wbsPlanning',
    initialState: [],
    extraReducers: {
        [fetchWbsPlanning.fulfilled]: (_state, action) => action.payload,
        [createWbsPlanningAsync.fulfilled]: (_state, action) => action.payload,
        [validatePlanningUpdate.fulfilled]: (_state, action) => action.payload,
        [importWbsPlanningsAsync.fulfilled]: (_state, action) => action.payload,
        [deleteWbsPlanning.fulfilled]: (state,action) => state.filter(wb => wb.id !== action.meta.arg)
    },
    reducers:{
        updateWbsPlanningSync(state,action) {
            const {idTask,idPlanning,value,valueNew} = action.payload
            state[idTask].wbs_task_plannings[idPlanning].number_blocks_new = valueNew
            state[idTask].wbs_task_plannings[idPlanning].number_blocks= value
        }
    }
});

export const { updateWbsPlanningSync } = wbsPlanningSlice.actions;

export const wbsPlanningMethods = {
    fetchWbsPlanning,
    updateWbsPlanningAsync,
    createWbsPlanningAsync,
    importWbsPlanningsAsync,
    validatePlanningUpdate,
    deleteWbsPlanning
}

export default wbsPlanningSlice;