import React from 'react';
import { Box } from '@material-ui/core';
import NotificationConsult from '../components/notifications/NotificationConsult';
const Notifications = () => {
    
    return (
        <Box className="page">
        <Box display="flex" flexDirection="row" className="cards-row" height="calc(100vh - 90px)" width="100%">
            <Box width="100%" height="100%" borderRadius="5px"  marginRight="10px">
                <NotificationConsult/>
            </Box>
        </Box>
        </Box>
    );
};

export default Notifications;