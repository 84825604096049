import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormDatePicker from '../form/FormDatePicker';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import moment from 'moment'

const DialogEditInvoicingForecast = ({ open , setOpen, editAction, editObject, wbsPlanning}) => {
    const { t } = useTranslation();
    const [value,setValue] = useState(null);

    const [tasks,setTasks] = useState([]);

    const form = useForm({
      defaultValues:{
          date:new Date(),
          wbs_task_id:value ? value.wbs_task_id : '',
          memo:''
      }
  })

    useEffect(()=>{
        setTasks(wbsPlanning.map(task=>{return {wbs_task_id:task.id,wbs_task_name:task.name}}))
    },[wbsPlanning])


    useEffect(()=>{
        if(editObject){
            setValue(editObject);
            form.reset({
              wbs_task_id:editObject ? editObject.wbs_task_id : '',
              memo:editObject ? editObject.memo : '',
              date: editObject ? editObject.forecast_date : new Date()
            })
        }
        // eslint-disable-next-line
    },[editObject])

    

    const handleClose = () => {
        setOpen(false);
    };
  
    const handleValidate = (val) => {
      editAction({
        id:value.id,
        data:{
          memo:val.memo,
          wbs_task_id:val.wbs_task_id,
          forecast_date:moment(val.date).format('YYYY-MM-DD')
        }
      })
    }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('folder.subfolder.invoicing.edit.title', 'Edit invoicing forecast')}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
              {value ? <Box display="flex" flexDirection="column">
                <FormTextField name="memo"/>
                <FormDatePicker name="date"/>
                <FormSelect
                  name="wbs_task_id"
                  options={tasks}
                  mapOptionToValue={task=>task.wbs_task_id}
                  mapOptionToText={task=>task.wbs_task_name}
                />
              </Box>:null}
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
          <Button onClick={form.handleSubmit(handleValidate)} color="primary">
          {t('button.save',"Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogEditInvoicingForecast