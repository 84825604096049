import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const readNotifications = createAsyncThunk(
    'notifications/readNotifications',
    async (ids) => {
        await axios.put('/notifications', ids);
        return ids;
    },
);

const readNotification = createAsyncThunk(
    'notifications/readNotification',
    async (id) => {
        await axios.put(`/notifications/${id}`);
        return id;
    },
);

const createNotification = createAsyncThunk(
    'notifications/createNotification',
    async (notification) => {
        const response = await axios.post(`/notifications/send`,notification);
        return response;
    },
);

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: [],
    reducers: {
        addNotification: (state, action) => {
            const notificationIndex = state.findIndex(notification => notification.id === action.payload.id);
            if(notificationIndex === -1) {
                return [ ...state, action.payload ];
            }
            const newState = state.slice();
            newState[notificationIndex] = action.payload;
            return newState;
        },
    },
    extraReducers: {
        [readNotification.fulfilled]: (state, action) => state.map(notification => notification.id === action.payload ? { ...notification, notification_read: true } : notification),
        [readNotifications.fulfilled]: (state, action) => state.map(notification => action.payload.includes(notification.id) ? { ...notification, notification_read: true } : notification),
    },
});

export const notificationsMethods = {
    ...notificationsSlice.actions,
    readNotifications,
    readNotification,
    createNotification
};

export default notificationsSlice;