import { useCallback } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
    root: tableField => ({
        width: '100%',
        marginTop: tableField ? 0 : 30,
    }),
});

const NumberFormatCustom = ({ inputRef, onChange, min, max, decimalPlaces,noSeparator, ...props }) => {
    return (
        <NumberFormat
            {...props}
            getInputRef={inputRef}
            onValueChange={({ floatValue }) => onChange(floatValue)}
            thousandSeparator={noSeparator ? "" : " "}
            allowedDecimalSeparators={['.',',']}
            decimalScale={decimalPlaces}
            isNumericString
            isAllowed={({ value, floatValue }) => value === '' || (min < 0 && value === "-") || ((isNaN(min) || floatValue >= min) && (isNaN(max) || floatValue <= max))}
        />
    );
};

const FormNumberField = ({
    readOnly,
    name,
    label,
    min,
    max,
    startAdornment,
    endAdornment,
    decimalPlaces = 2,
    notNullable,
    noSeparator,
    tableField = false,
    ...props
}) => {
    const classes = useStyles(tableField);
    const { t } = useTranslation();
    const { control, errors } = useFormContext();

    const translate = useCallback(key => {
        if(!key) return;
        if(typeof key === 'string') return t(key);
        return t(key.key, key.values);
    }, [ t ]);

    return (
        <>
            {name ? <Controller
               
                control={control}
                name={name}
                render={({ onChange, value }) => (
                    <TextField
                        className={classes.root}
                        {...props}
                        InputProps={{
                            inputProps: { min, max, decimalPlaces,noSeparator, readOnly },
                            startAdornment: startAdornment && (
                                <InputAdornment position="start">
                                    { startAdornment }
                                </InputAdornment>
                            ),
                            endAdornment: endAdornment && (
                                <InputAdornment position="end">
                                    { endAdornment }
                                </InputAdornment>
                            ),
                            inputComponent: NumberFormatCustom,
                        }}
                        name={name}
                        label={label}
                        value={value ?? ''}
                        onChange={value => onChange(isNaN(value) ? (notNullable ? undefined : null) : +value)}
                        error={errors[name] && Boolean(errors[name])}
                        helperText={errors[name] && translate(errors[name].message)}
                    />
                )}
            /> : <TextField
            readOnly
            className={classes.root}
            {...props}
            InputProps={{
                inputProps: { min, max, decimalPlaces,noSeparator },
                startAdornment: startAdornment && (
                    <InputAdornment position="start">
                        { startAdornment }
                    </InputAdornment>
                ),
                endAdornment: endAdornment && (
                    <InputAdornment position="end">
                        { endAdornment }
                    </InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
            }}
            label={label}
        />}
        </>
    );
};

export default FormNumberField;