import { Redirect } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Box, Tab, Tabs } from '@material-ui/core';
// import { withStyles } from '@material-ui/core/styles';
import UserContext from '../context/user';
import { useTheme } from '@material-ui/core/styles';
import { mapStateToProps, mapDispatchToProps, connect } from '../store/dispatchers';
import ParametersExplorer from '../components/parameters/ParametersExplorer';
import ParametersSettings from '../components/parameters/ParametersSettings';
import ParametersRelationsSettings from '../components/parameters/ParametersRelationsSettings';
import { a11yProps, TabPanel } from '../components/navbar/TabsNavigation';


const Parameters = ({parameters}) => {
    const { hasPermission } = useContext(UserContext);
    const palette = useTheme().palette;

    const [parametersKeys,setParametersKey] = useState([]);
    const [ focusedKeyIndex, setFocusedKeyIndex ] = useState(0);
    const [tab, setTab] = useState(0);
    const [filter,setFilter] = useState('');

    const handleChangeTab = (_event, newValue) => {
        setTab(newValue);
    };

    useEffect(()=>{
        let keys = [];
        for (const [key] of Object.entries(parameters)) {
            keys.push(key);
        }
        setParametersKey(keys);
    },[parameters]);
    
    const styles = {
        explorer: {
            backgroundColor: palette.admin.main,
        },
        parametersSetting: {
            backgroundColor: palette.admin.light,
        },
    }

    if(!hasPermission('parameters.read')) {
        return <Redirect to="/"/>
    }

    return (
        <Box className="page">
             <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                className="tab-item"
                style={{marginBottom:20}}
            >    
                <Tab label="Parameters" {...a11yProps(0)}/>
                <Tab label="Parameters Relations" {...a11yProps(1)}/>
            </Tabs>
            
            <Box className="cards-row" height="calc(100vh - 184px)">
                <Box style={styles.explorer} className={`card parameters-explorer`}>
                    <ParametersExplorer
                        keys={parametersKeys}
                        focusedKey={focusedKeyIndex}
                        onKeyClick={(index)=>setFocusedKeyIndex(index)}
                        setFilter={setFilter}
                    />
                </Box>
                <Box style={styles.parametersSetting} className={`card parameters-settings`}>
                    <TabPanel value={tab} index={0} >
                        <ParametersSettings
                            focusedKey={focusedKeyIndex}
                            keys={parametersKeys}
                            filter={filter}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={1} >
                        <ParametersRelationsSettings
                            focusedKey={focusedKeyIndex}
                            keys={parametersKeys}
                        />
                    </TabPanel>
                </Box>
            </Box>
        </Box>
    );
};

export default connect( mapStateToProps, mapDispatchToProps )(Parameters);