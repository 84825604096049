import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: smallMarginTop => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginTop: smallMarginTop ? 15 : 45,
        padding: 15,
    }),
}));

const FormCategoryHeader = ({ children, smallMarginTop = false }) => {
    const classes = useStyles(smallMarginTop);

    return (
        <Typography className={classes.root}>
            <Box width="100%">
                {children}
            </Box>
        </Typography>
    );
};

export default FormCategoryHeader;