import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchThirdPartiesStates = createAsyncThunk(
    'third-parties/fetchThirdPartiesStates',
    async () => {
        const response = await axios.get('/third-parties/states');
        return response.data;
    },
)

const updateThirdPartiesMfiles = createAsyncThunk(
    'third-parties/updateThirdPartiesMfiles',
    async (payload) => {
        const response = await axios.put('/third-parties/mfiles',payload);
        return response.data;
    },
)

const updateThirdPartiesOutlook = createAsyncThunk(
    'third-parties/updateThirdPartiesOutlook',
    async (payload, { rejectWithValue }) => {
        const response = await axios.put('/third-parties/outlook', payload);
        if (response.data.status === "error")
            return rejectWithValue(response.data);
        return response.data;
    }
);


const thirdPartiesSlice = createSlice({
    name: 'third-parties',
    initialState: {
        clearfacts: null,
    },
    reducers: {
        setThirdPartyState: (state, action) => {
            return {
                ...state,
                [action.payload.service]: action.payload.expiryDate,
            }
        }
    },
    extraReducers: {
        [fetchThirdPartiesStates.fulfilled]: (_state, action) => action.payload.value,
        [updateThirdPartiesMfiles.fulfilled]: (_state, action) => action.payload.value,
        [updateThirdPartiesOutlook.fulfilled]: (_state, action) => action.payload.value,
    },
})

export const thirdPartiesMethods = {
    ...thirdPartiesSlice.actions,
    fetchThirdPartiesStates,
    updateThirdPartiesMfiles,
    updateThirdPartiesOutlook
}

export default thirdPartiesSlice;