import { createSlice } from '@reduxjs/toolkit';

const currentInvoiceSlice = createSlice({
    name: 'currentInvoice',
    initialState: 0,
    reducers: {
        setCurrentInvoiceState: (state,action) => action.payload,
    },
});

export const { setCurrentInvoiceState } = currentInvoiceSlice.actions;


export default currentInvoiceSlice;