import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { FormProvider, useForm } from "react-hook-form"
import FormAutocomplete from "../../form/FormAutocomplete"
import FormNumberField from "../../form/FormNumberField"
import FormRow from "../../form/FormRow"
import FormSelect from "../../form/FormSelect"
import FormTextField from "../../form/FormTextField"
import FormSwitch from "../../form/FormSwitch"
import useYupResolver from '../../../hooks/useYupResolver';
import {
    subfolderCompetitorSchema as competitorSchema,
} from '../../../services/yup';

const CompetitorDialog = ({open,setOpen,handleAdd,handleEdit,competitorToEdit,companies}) => {
    const competitorResolver = useYupResolver(competitorSchema);

    const form = useForm({
        resolver: competitorResolver,
        defaultValues: {
            partner1_company_id: competitorToEdit?.partner1_company_id || null,
            partner2_company_id: competitorToEdit?.partner2_company_id || null,
            partner3_company_id: competitorToEdit?.partner3_company_id || null,
            partner4_company_id: competitorToEdit?.partner4_company_id || null,
            reference_rate: competitorToEdit?.reference_rate || null,
            daily_rate: competitorToEdit?.daily_rate || null,
            hourly_date: competitorToEdit?.hourly_date || null,
            honorary_percentage: competitorToEdit?.honorary_percentage || null,
            price_rating: competitorToEdit?.price_rating || null,
            team_rating: competitorToEdit?.team_rating || null,
            methodology_rating: competitorToEdit?.methodology_rating || null,
            final_rating: competitorToEdit?.final_rating || null,
            ranking: competitorToEdit?.ranking || null,
            excluded: competitorToEdit?.excluded || false,
            price: competitorToEdit?.price || null,
            comment: competitorToEdit?.comment || null,
        }
    })

    const handleClick = (values) => {
        if(handleEdit){
            handleEdit({...values,id:competitorToEdit.id})
        }
        else{
            handleAdd(values)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>
                {handleEdit ? "Edit Competitor" : "Add Competitor"}
            </DialogTitle>
            <DialogContent>
                <FormProvider {...form}>
                    <form onSubmit={handleEdit ? handleEdit : handleAdd}>
                        <FormAutocomplete
                            label="Mandataire"
                            name="partner1_company_id"
                            options={companies}
                            mapOptionToValue={company => company.id}
                            mapOptionToText={company => company.name}                   
                        />
                        <FormAutocomplete
                            label="Associé 2"
                            name="partner2_company_id"
                            options={companies}
                            mapOptionToValue={company => company.id}
                            mapOptionToText={company => company.name}
                            allowNull
                        />
                        <FormAutocomplete
                            label="Associé 3"
                            name="partner3_company_id"
                            options={companies}
                            mapOptionToValue={company => company.id}
                            mapOptionToText={company => company.name}
                            allowNull
                        />
                        <FormAutocomplete
                            label="Associé 4"
                            name="partner4_company_id"
                            options={companies}
                            mapOptionToValue={company => company.id}
                            mapOptionToText={company => company.name}
                            allowNull
                        />
                        <FormNumberField
                            label="Taux de référence"
                            name="reference_rate"
                            endAdornment={"€/jour"}
                        />
                        <FormRow>
                            <FormNumberField
                                label="Taux journalier"
                                name="daily_rate"
                                endAdornment={"€/jour"}
                            />
                            <FormNumberField
                                label="Taux horaire"
                                name="hourly_date"
                                endAdornment={"€/heure"}
                            />
                        </FormRow>
                        
                        <FormNumberField
                            label="Pourcentage d'honoraires"
                            name="honorary_percentage"
                            endAdornment={"%"}
                        />
                        <FormRow>
                            <FormNumberField
                                label="Cotation prix"
                                name="price_rating"
                                endAdornment={"/100"}
                            />
                            <FormNumberField
                                label="Cotation équipe"
                                name="team_rating"
                                endAdornment={"/100"}
                            />
                        </FormRow>
                        
                        <FormRow>
                            <FormNumberField
                                label="Cotation méthodologie"
                                name="methodology_rating"
                                endAdornment={"/100"}
                            />
                            <FormNumberField
                                label="Cotation totale"
                                name="final_rating"
                                endAdornment={"/100"}
                            />
                        </FormRow>
                        <FormSwitch 
                            name="excluded"
                            label="Exclu"
                            control={form.control}
                            color="primary"
                        />
                        <FormNumberField
                            label="Classement"
                            name="ranking"
                        />
                        <FormTextField
                            label="Commentaire"
                            name="comment"
                        />
                        <FormNumberField
                            label="Prix"
                            name="price"
                            endAdornment={"€"}
                        />

                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={form.handleSubmit(handleClick)}>{handleEdit ? "Edit" : "Add"}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CompetitorDialog