import { useContext } from 'react';

import { AppBar, Toolbar, Grid, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { changeLanguage } from '../../services/i18n';
import LanguagePicker from '../LanguagePicker';
import NotificationsDropdown from './NotificationsDropdown';
import UserDropdown from './UserDropdown';
import UserContext from '../../context/user';
import { useTheme } from '@material-ui/core/styles';
import Path from './Path';
import { connect, mapStateToProps, mapDispatchToProps } from "../../store/dispatchers";
import { MdMenu } from 'react-icons/md';
import ThemeToggler from './ThemeToggler';
const NavBar = ({appParams,setDrawer}) => {
    const { i18n } = useTranslation();
    const { user } = useContext(UserContext);

    const palette = useTheme().palette;




    return (
        <AppBar style={{ background: palette.admin.navbar.main }} position="sticky" className="no-select">
            <Toolbar>
                <Grid container className="app-navbar">
                    <Grid item >{!appParams.drawerOpen && (<IconButton onClick={()=>setDrawer(true)} style={{marginRight:"10px",marginLeft:"-20px"}}><MdMenu/></IconButton>)}<span style={{color: palette.secondary.main, fontWeight:"bold"}}>AT Osborne</span></Grid>
                    <Grid item ><Path /></Grid>
                    <Grid item className="app-navbar-right">
                        <ThemeToggler />
                        <LanguagePicker styleColor={palette.secondary.main} language={i18n.language} onLanguagePick={changeLanguage} />
                        {user &&
                            <>
                                <NotificationsDropdown />
                                <UserDropdown />
                            </>
                        }
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);