import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import Card from '../card/Card';
import FormCategoryHeader from '../form/FormCategoryHeader';
import FormRow from '../form/FormRow';
import FormAutocomplete from '../form/FormAutocomplete';
import FormChipsField from '../form/FormChipsField';
import FormDatePicker from '../form/FormDatePicker';
import FormNumberField from '../form/FormNumberField';
import FormAddressAutocomplete from '../form/FormAddressAutocomplete';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import { folderSchema as schema } from '../../services/yup';
import { mapFieldKeys } from '../../utils/converters';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import useYupResolver from '../../hooks/useYupResolver';
import Map from '../Map';
import MapZoom from '../../enums/MapZoom';
import { handleResponse } from '../../utils/http';
import SnackbarContext from '../../context/snackbar';
import { removeDuplicates } from '../../utils/arrays';

const FoldersCreate = ({ companies, users, parameters, keywords, addFolder, parametersRelations, fetchKeywords, fetchCompanies }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { openSuccessSnackbar, openErrorSnackbar, openWarningSnackbar } = useContext(SnackbarContext);
    const [ loading, setLoading ] = useState(false);
    const [paramsProduct, setParamsProduct] = useState([]);
    const [sectorRelation, setSectorRelation] = useState({});
    const [fetchingCompanies, setFetchingCompanies] = useState(false);

    useEffect(()=>{
        fetchKeywords()
    },[])

    useEffect(()=>{
        setSectorRelation(parametersRelations.sector ? parametersRelations.sector : {})
    },[parametersRelations])

    const onChangeTypeTarget = (targetValue) => {
        let toReturn = [];
        if(sectorRelation[targetValue] && sectorRelation[targetValue].product && sectorRelation[targetValue].product.length){
            toReturn = sectorRelation[targetValue].product.map(item=>item.type);
        }
        setParamsProduct(toReturn);
    }

    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            // Folder
            lead_code: null,
            code: '',
            project_type:'client_project',
            name: '',
            description: '',
            location_input: '',
            location: '',
            session_token: '',
            language_type:'fr',
            // Keywords
            keywords: [],
            // Folder extra info
            work_budget: null,
            investment_budget: null,
            work_start_date: null,
            work_end_date: null,
            project_gfa: null,
            procurement_type: '',
            building_sector_type: '',
            building_subsector_type: ''
        },
    });

    const watchWorkStartDate = form.watch('work_start_date');
    const watchWorkEndDate = form.watch('work_end_date');
    const location = form.watch('location');

    const handleUnhandledError = useCallback((message, value) => {
        setLoading(false);
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
    }, [ t, openErrorSnackbar ]);

    const handleSuccess = useCallback(value => {
        setLoading(false);
        openSuccessSnackbar(t('success.folder_created', 'Folder successfully created'));
        history.push('/folders/' + value.id);
    }, [ t, history, openSuccessSnackbar ]);

    const handleWarning = useCallback((message, value) => {
        setLoading(false);
        openWarningSnackbar(t('warning.' + message));
        console.warn(message, value);
        history.push('/folders/' + value.id);
    }, [ t, history, openWarningSnackbar ]);


    const handleSubmit = useCallback(async (values) => {
        setLoading(true);
        const mappedValues = mapFieldKeys(values);
        const result = await addFolder({
            folder: {
                ...mappedValues,
                project_class:"project_type"
            },
            folderExtraInfo: {
                ...mappedValues,
            },
            keywords: mappedValues.keywords,
        });
        handleResponse(result.payload, form, handleSuccess, handleUnhandledError, handleWarning);
    }, [ form, addFolder, handleSuccess, handleUnhandledError, handleWarning ]);
    
    return (
        <Card
            title={ t('folders.title.create-a-folder', 'Create a folder') }
            doneLabel={ t('button.submit', 'Submit') }
            onDoneClick={form.handleSubmit(handleSubmit)}
            loading={loading}
        >
            <FormProvider {...form}>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormCategoryHeader smallMarginTop>
                        { t('folders.folder-information', 'Folder information') }
                    </FormCategoryHeader>
                    <FormRow>
                        <Box display="flex" flexDirection="column">
                            {/* <FormNumberField
                                name="lead_code"
                                label={t('forms.fields.folder.lead_code.label', 'Code prospect')}
                                min={0}
                                max={99999}
                                noSeparator
                            /> */}
                            {/* <FormTextField
                                name="code"
                                label={t('forms.fields.folder.code.label', 'Folder code')}
                            /> */}
                            <FormSelect
                                name="project_type"
                                label={t('forms.fields.folder.project_type.label', 'Project type')}
                                options={parameters.project_type}
                                mapOptionToText={val=>t('parameters.project_type.'+val)}
                            />
                            <FormSelect
                                name="language_type"
                                label={t('forms.fields.folder.project_language.label', 'Project language')}
                                options={parameters.project_language}
                            />
                            <FormAutocomplete
                                name="project_owner_company_id"
                                label={t('forms.fields.folder.project-owner.label', 'Project Owner')}
                                options={companies}
                                mapOptionToValue={company => company.id}
                                mapOptionToText={company => company.name}
                                refreshClick={()=>{
                                    setFetchingCompanies(true);
                                    fetchCompanies().then(()=>{
                                        setFetchingCompanies(false);
                                    })
                                }}
                                refreshLoading={fetchingCompanies}
                            />
                            <FormAutocomplete
                                name="end_user_company_id"
                                label={t('forms.fields.folder.project-end-user.label', 'Project End User')}
                                options={companies}
                                mapOptionToValue={company => company.id}
                                mapOptionToText={company => company.name}
                                allowNull
                            />
                            <FormTextField
                                name="name"
                                label={t('forms.fields.folder.name.label', 'Name')}
                            />
                            <FormAddressAutocomplete
                                name="location_input"
                                label={t('forms.fields.folder.location.label', 'Location')}
                                descriptionName="location"
                                sessionTokenName="session_token"
                            />
                            <FormAutocomplete
                                name="project_manager_id"
                                label={t('forms.fields.folder.project-managaer.label', 'Account Manager')}
                                options={users}
                                mapOptionToValue={user => user.id}
                                mapOptionToText={user => user.user_user_extra_info?.code
                                    ? `${user.user_user_extra_info.code} - ${user.first_name} ${user.last_name}`
                                    : `${user.first_name} ${user.last_name}`
                                }
                            />
                            <FormChipsField
                                name="keywords"
                                label={t('forms.fields.folder.keywords.label', 'Keywords')}
                                options={removeDuplicates(keywords.map(keyword => keyword.label))}
                            />
                            <FormTextField
                                name="description"
                                label={t('forms.fields.folder.description.label', 'Description')}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                            { location && (
                                <Map
                                    containerStyle={{ height: 640, width: 640 }}
                                    center={location}
                                    zoom={MapZoom.STREETS}
                                />
                            ) }
                        </Box>
                    </FormRow>
                    <FormRow>
                        <FormNumberField
                            name="work_budget"
                            label={t('forms.fields.folder.work-budget.label', 'Work budget')}
                            min={0}
                            max={9999999999.99}
                            endAdornment="€ HTVA"
                        />
                        <FormNumberField
                            name="investment_budget"
                            label={t('forms.fields.folder.investment-budget.label', 'Investment budget')}
                            min={0}
                            max={9999999999.99}
                            endAdornment="€ TTC"
                        />
                    </FormRow>
                    <FormRow>
                        <FormDatePicker
                            name="work_start_date"
                            label={t('forms.fields.folder.work-start-date.label', 'Work start date')}
                            maxDate={watchWorkEndDate}
                        />
                        <FormDatePicker
                            name="work_end_date"
                            label={t('forms.fields.folder.work-end-date.label', 'Work end date')}
                            minDate={watchWorkStartDate}
                        />
                    </FormRow>
                    <FormRow>
                        <FormNumberField
                            name="project_gfa"
                            label={t('forms.fields.folder.project-gfa.label', 'Project GFA')}
                            min={0}
                            max={999999.99}
                            endAdornment="m²"
                        />
                        <FormSelect
                            name="procurement_type"
                            label={t('forms.fields.folder.procurement-type.label', 'Procurement type')}
                            options={parameters.procurement_type}
                            allowNull
                            mapOptionToText={val=>t('parameters.procurement_type.'+val)}
                        />
                    </FormRow>
                    <FormRow>
                        <FormSelect
                            name="building_sector_type"
                            label={t('forms.fields.folder.building-sector-type.label', 'Building Sector')}
                            options={parameters.sector}
                            allowNull
                            changeEvent={(event)=>onChangeTypeTarget(event.target.value)}
                            mapOptionToText={val=>t('parameters.sector.'+val)}
                        />
                        <FormSelect
                            name="building_subsector_type"
                            label={t('forms.fields.folder.building-subsector.label', 'Building Subsector')}
                            options={paramsProduct}
                            allowNull
                            mapOptionToText={val=>t('parameters.product.'+val)}
                        />
                    </FormRow>
                </form>
            </FormProvider>
        </Card>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FoldersCreate);