import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, FormControl, InputLabel, MenuItem, FormHelperText, Box, IconButton, CircularProgress  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { MdSync } from 'react-icons/md'

const useStyles = makeStyles({
    root: tableField => ({
        width: '100%',
        marginTop: tableField ? 0 : 30,
    }),
    select: {
        paddingRight: '0 !important',
    },
});


const FormSelect = ({
    name, 
    label,
    options,
    mapOptionToValue = option => option,
    mapOptionToText = option => option,
    allowNull,
    tableField = false,
    changeEvent,
    refreshClick,
    refreshLoading,
    ...props
    
}) => {
    const classes = useStyles(tableField);
    const { t } = useTranslation();
    const { control, errors } = useFormContext();
    
    const translate = useCallback(key => {
        if(!key) return;
        if(typeof key === 'string') return t(key);
        return t(key.key, key.values);
    }, [ t ]);

    const labelId = `input-${name}-label`;

    return (
        <Box display="flex" flexDirection="row" width="100%">
            <FormControl className={classes.root} {...props}>
                { label &&
                    <InputLabel id={labelId}>
                        { label }
                    </InputLabel>
                }
                { name ? <Controller
                    control={control}
                    name={name}
                    render={({ onChange, value }) => (
                        <Select
                            classes={{ select: classes.select }}
                            labelId={label && labelId}
                            value={value ?? ''}
                            onChange={(event)=>{
                                onChange(event)
                                if(changeEvent)changeEvent(event) 
                            }}
                            error={errors[name] && Boolean(errors[name])}
                        >
                            { allowNull &&
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                            }
                            { options?.slice().sort((a, b) => mapOptionToText(a)?.localeCompare(mapOptionToText(b))).map(option => {
                                const value = mapOptionToValue(option);
                                return (
                                    <MenuItem key={value} value={value}>
                                        { mapOptionToText(option) }
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                /> : 
                <Select
                    classes={{ select: classes.select }}
                    labelId={label && labelId}
                    error={errors[name] && Boolean(errors[name])}
                    //value={props.value ?? ''}
                    defaultValue={props.defaultValue ?? ''}
                    onChange={(event)=>{
                        if(changeEvent)changeEvent(event) 
                    }}
                >
                    { allowNull &&
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    }
                    { options?.slice().sort((a, b) => mapOptionToText(a).localeCompare(mapOptionToText(b))).map(option => {
                        const value = mapOptionToValue(option);
                        return (
                            <MenuItem key={value} value={value}>
                                { mapOptionToText(option) }
                            </MenuItem>
                        );
                    })}
                </Select>}
                { errors[name] &&
                    <FormHelperText error>
                        { translate(errors[name].message) }
                    </FormHelperText>
                }
            </FormControl>
            {refreshClick && <Box marginTop="auto">
                <IconButton onClick={()=>refreshClick()} marginTop="auto" size="small">{ refreshLoading ? <CircularProgress style={{width:13,height:13}}/>:<MdSync/>}</IconButton>
            </Box>}
        </Box>
    );
}

export default FormSelect;