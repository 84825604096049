import { useContext, useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import NavBar from './components/navbar/NavBar';
import SideNavbar from './components/navbar/SideNavbar';
import Other from './views/Other';
import Translation from './views/Translation';
import TemplatePdf from './views/TemplatePdf';
import Users from './views/Users';
import Folders from './views/Folders';
import Login from './views/Login';
import Callback from './views/Callback';
import Companies from './views/Companies';
import Parameters from './views/Parameters';
import RecoverPassword from './views/RecoverPassword';
import ResetPassword from './views/ResetPassword';

import Permissions from './views/Permisisons';
import UserContext from './context/user';
import Planner from './views/Planner';
import ThirdPartyServices from './views/ThirdPartyServices';
import Reports from './views/Reports';
import Notifications from './views/Notifications';
import Account from './views/Account';

import { connect ,mapStateToProps, mapDispatchToProps } from './store/dispatchers';
import { styled } from '@material-ui/styles';

const CustomTransistion = styled(Box)(({theme}) => ({
    transition:theme.transitions.create(["margin-left"], {duration: "0.2s"}),
}))


const DefaultContainer = connect(mapStateToProps, mapDispatchToProps)(({appParams}) => {
    const { hasPermission } = useContext(UserContext);
    const theme = useTheme();

    return (
        <Box bgcolor={theme.palette.admin.navbar.main}>
            <CustomTransistion bgcolor={theme.palette.background.admin} marginLeft={appParams.drawerOpen ? "225px" : "0px"}>
                <NavBar />
                <SideNavbar />
                <Box padding="25px" paddingLeft="25px" minHeight="calc(100vh - 114px)">
                    <Route exact path="/" render={() => <Redirect to="/users" />} />
                    {hasPermission('company.read') && <Route path="/companies" component={Companies} />}
                    {hasPermission('parameters.read') &&  <Route path="/parameters" component={Parameters}/>}
                    {hasPermission('users.read') &&<Route path="/users" component={Users} />}
                    {hasPermission('translate.write') && <Route path="/translation" component={Translation} />}
                    {hasPermission('folders.read') && <Route path="/folders" component={Folders} />}
                    {hasPermission('template.read') && <Route path="/template" component={TemplatePdf} />}
                    {hasPermission('banner-message.write') && <Route path="/banner" component={Other} />}
                    {hasPermission('permissions_all.write') && <Route path="/permissions" component={Permissions} />}
                    {hasPermission('planner.write') && <Route path="/planner" component={Planner} />}
                    {hasPermission('third-party.write') && <Route path="/third-party-services" component={ThirdPartyServices}/>}
                    {hasPermission('reports.read') && <Route path="/viewReports" component={Reports}/>}
                    {hasPermission('notifications.read') && <Route path="/notifications" component={Notifications} />}
                    {hasPermission('account.read') && <Route path="/account" component={Account} />}
                </Box>
            </CustomTransistion>
        </Box>
    )
});

const AppRouter = ({ isFirstLogin }) => {
    const { user } = useContext(UserContext);
    const [hasBeenRedirectedOnFirstLogin, setHasBeenRedirectedOnFirstLogin] = useState(false);

    // Has to be used instead of simply setting it in order to avoid getting an error in the console
    useEffect(() => {
        setHasBeenRedirectedOnFirstLogin(true);
    }, [setHasBeenRedirectedOnFirstLogin]);

    return (
        <Router basename={process.env.APP_ROUTER_BASENAME}>
            <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/callback" component={Callback}/>
                    <Route path="/recover-password" component={RecoverPassword}/>
                    <Route path="/reset-password" component={ResetPassword}/>
                    <Route render={() => {
                        if (!user) {
                            return <Redirect to="/login" />
                        }
                        if (isFirstLogin && !hasBeenRedirectedOnFirstLogin) {
                            return <Redirect to="/users" />;
                        }
                        return (
                            <Route component={DefaultContainer} />
                        );
                    }} />
                </Switch>
            </QueryParamProvider>
        </Router>
    )
}

export default AppRouter;