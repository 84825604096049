import { createMuiTheme } from '@material-ui/core/styles';

const main = createMuiTheme({
    palette: {
        primary: {
            light: '#6f5dd0',
            main: '#e2002b',
            dark: '#6f5dd0',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#e2002b',
            dark: '#ba000d',
            contrastText: '#000',
        },
        admin: {
            navbar: {
                light: '#f1f1f1',
                main: '#fff',
                dark: '#e2002b',
                contrastText: '#e2002b',
            },
            light: '#fff',
            main: '#f1f1f1',
            dark: '#e8e8e8',
        },
        background: {
            default: '#fff',
            admin: '#f8f8f8',
        },
        white: {
            light: '#fff',
            main: '#fff',
            dark: '#444444',
            contrastText: '#fff',
        }
    },
});

const classic = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#2f5e78',
            dark: '#23536d',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#fff',
            dark: '#fff',
            contrastText: '#000',
        },
        admin: {
            navbar: {
                main: '#222d32',
                dark: '#1e282c',
                light: '#f1f1f1',
                contrastText: '#fff',
            },
            light: '#fff',
            main: '#f1f1f1',
            dark: '#e8e8e8',
        },
        background: {
            default: '#fff',
            admin: '#f3f4f9',
        },
        white: {
            light: '#fff',
            main: '#fff',
            dark: '#2f5e78',
            contrastText: '#fff',
        }
    },

});

const themes = {
    main,
    classic
}

export default themes;