import { Box, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Card from "../../card/Card"

const ParentBookableDialog = ({onClose,editParentBookable}) => {

    const { t } = useTranslation();

    return (<Card
        title={t('tasks.title.taskParentBookable', 'Parent task is bookable')}
        hideButtons
    >
        <Typography>
        {t('tasks.title.taskParentBookableContent', 'The parrent task cannot be bookable. Update bookable attribute of parent task or cancel the creation')}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="end" marginTop="30px">
            <Button onClick={()=>editParentBookable()} variant="contained" color="primary">Update parent bookable to false</Button>
            <Button onClick={()=>onClose()} variant="contained" color="primary" style={{marginLeft:"20px"}}>Cancel</Button>
        </Box>
    </Card>)
}

export default ParentBookableDialog