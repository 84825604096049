import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import FormSelect from '../../form/FormSelect';
import { useTranslation } from 'react-i18next';
import { connect, mapStateToProps , mapDispatchToProps} from '../../../store/dispatchers';
import { useEffect, useState } from 'react';

const DialogCreateUserTaskPlanning = ({ open , setOpen, users, createAction}) => {
  const [filteredUsers,setFilteredUsers] = useState([]);

    useEffect(()=>{
      if(users){
        setFilteredUsers(users.filter(user => !user.user_user_extra_info?.exit_Date));
      }
    },[users])

    const { t } = useTranslation();
    const form = useForm({
        defaultValues:{
            name:''
        }
    })

    const handleClose = () => {
        setOpen(false);
    }; 


  const handleCreateTaskPlanning = (values) => {
    createAction(values);
  }
  

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('wbs.plannings.add_user.title', 'Assigne User')}</DialogTitle>
        <DialogContent>
            <FormProvider {...form}>
              {t('wbs.plannings.add_user.description', 'Assigne User to task planning')}
                <FormSelect
                    name="user"
                    label={t('forms.fields.task_planning.user.label', 'User')}
                    options={filteredUsers}
                    mapOptionToValue={user=>user.id}
                    mapOptionToText={user=>user.first_name+" "+user.last_name}
                />
            </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
          <Button onClick={form.handleSubmit(handleCreateTaskPlanning)} color="primary">
          {t('button.add',"Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(DialogCreateUserTaskPlanning)