import { useMemo, useEffect, useState } from 'react';
import { Box, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Card from '../card/Card';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';

const useStyles = makeStyles(theme => ({
    expiryDate: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const MFilesCard = ({thirdParties, updateThirdPartiesMfiles}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [create,setCreate] = useState(thirdParties.mfiles?.create || false)
    const [update,setUpdate] = useState(thirdParties.mfiles?.update || false)

    useEffect(()=>{
        if(thirdParties.mfiles){
            setCreate(thirdParties.mfiles.create)
            setUpdate(thirdParties.mfiles.update)
            
        }
    },[thirdParties])

    const updateMfilesCreate = () =>{
        let newState = {...thirdParties.mfiles};
        newState.create = !create;
        updateThirdPartiesMfiles(newState)
    }

    const updateMfilesUpdate = () =>{
        let newState = {...thirdParties.mfiles};
        newState.update = !update;
        updateThirdPartiesMfiles(newState)
    }

    return (
        <Card
            title={t('mfiles.card.title',"MFiles")}
            explanation={t('mfiles.card.content',"Enable/Disable MFiles sync")}
            hideButtons
        >
            <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                <Typography component="p" variant="body1" color="textPrimary" style={{marginTop:"auto",marginBottom:"auto"}}>
                    Create 
                </Typography>
                <Box display="flex">
                    <Switch value={create} checked={create} onChange={()=>updateMfilesCreate()} color="primary"/>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                <Typography component="p" variant="body1" color="textPrimary" style={{marginTop:"auto",marginBottom:"auto"}}>
                    Update 
                </Typography>
                <Box display="flex">
                    <Switch value={update} checked={update} onChange={()=>updateMfilesUpdate()} color="primary"/>
                </Box>
            </Box>
            
            {/* <Typography component="p" variant="body1" color="textPrimary">
                { t('oauth.card.expiry-date', 'Expiry Date: ') }
                <Typography component="span" variant="body1" color={'primary'} className={classes.expiryDate}>
                    test
                </Typography>
            </Typography> */}
        </Card>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MFilesCard);