import { Box, Tab, Tabs } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import { a11yProps, TabPanel } from '../navbar/TabsNavigation';
import { useEffect, useState } from 'react';

import InvoiceForecast from './InvoiceForecast';
import Card from '../card/Card';
import InvoiceList from './InvoiceList';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import InvoiceForecastList from './InvoiceForecastList';
import LoadingFallback from '../../views/LoadingFallback';

const InvoiceExplorer = ({
    companies,
    wbsPlanning,
    folders,
    dowloadInvoiceFile,
    fetchSubfoldersInvoices,
    fetchThirdPartiesStates,
    editInvoicingForecast,
    editInvoicingForecastDetail,
}) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const { folderId, id } = useParams();
    const [tab, setTab] = useState(+query.get('tab2') || 0);

    const [subfolder,setSubfolder] = useState({});
    const [folder,setFolder] = useState({});
    const [fetchedInvoices,setFetchedInvoices] = useState(false);

    useEffect(()=>{
        let fold =  folders.find(f=>f.id.toString() === folderId);
        setFolder(fold);
        let subfold = fold.subfolders.find(sf=>sf.id.toString() === id);
        setSubfolder(subfold);
    },[folders,folderId,id])

    useEffect(()=>{
        fetchSubfoldersInvoices({subfolder_id:id,folder_id:folderId}).then(res=>{
            setFetchedInvoices(true);
        });
        

    },[])

    useEffect(() => {
        fetchThirdPartiesStates();
    }, [ fetchThirdPartiesStates ]);

    const handleChangeTab = (_event, newValue) => {
        setTab(newValue);
    };

    if(!fetchedInvoices) return <LoadingFallback/>

    return (
        <>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                className="tab-item"
            >
                <Tab style={{minWidth:"300px"}} label="Invoicing/Encoding Forecast" {...a11yProps(0)} />
                <Tab label="Invoice issued" {...a11yProps(1)} />
                <Tab label="Invoicing forecast used" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={tab} index={0}>
                <InvoiceForecast setTab={setTab}/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Card
                    hideButtons
                    title={"Factures émises"}
                >
                    <Box style={{ maxHeight: 'calc(100vh - 300px)',minHeight: 'calc(100vh - 300px)'}}  className="app-kit-overflow-y">
                        <InvoiceList 
                            isIssued
                            folder={folder}
                            companies={companies} 
                            wbsPlannings={wbsPlanning} 
                            subfolder={subfolder}
                            dowloadInvoiceFile={dowloadInvoiceFile}
                            setTab={setTab}
                        />
                    </Box>
                </Card>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <Card
                    hideButtons
                    style={{marginLeft:0}}
                    title={"Prévisions de facturation utilisées"}
                >
                    <Box style={{ maxHeight: 'calc(100vh - 300px)',minHeight: 'calc(100vh - 300px)'}}  className="app-kit-overflow-y">
                        <InvoiceForecastList
                            isUsed
                            wbsPlanning={wbsPlanning}
                            subfolder={subfolder}
                            editInvoicingForecast={editInvoicingForecast}
                            editInvoicingForecastDetail={editInvoicingForecastDetail}
                            fetchSubfoldersInvoices={fetchSubfoldersInvoices}
                            subFolderId={id}
                            folderId={folderId}
                        />
                    </Box>
                </Card>
            </TabPanel>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceExplorer)
