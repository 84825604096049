import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: (hasValue) => ({
        '& .MuiInputBase-root.Mui-disabled': {
            color: hasValue ? theme.palette.text.primary : theme.palette.text.hint,
            fontStyle: hasValue ? 'normal' : 'italic',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: theme.palette.text.primary,
        },
    }),
}));

const ConsultDatePicker = ({ value, ...props }) => {
    const classes = useStyles(value != null);
    return (
        <DatePicker
            className={`form-input ${classes.root}`}
            value={value}
            emptyLabel="None"
            format="dd/MM/yyyy"
            disabled
            { ...props }
        />
    );
}

export default ConsultDatePicker;