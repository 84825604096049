import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import api from '../services/axios';

const parseStates = () => {
    const localStorageStates = localStorage.getItem('oauth-states');
    if(!localStorageStates) return {};
    const parsedStates = JSON.parse(localStorageStates);
    const toReturn = {};
    for(const state of Object.keys(parsedStates)) {
        toReturn[state] = OAuth.fromJson(state, parsedStates[state]);
    }
    return toReturn;
}

export default class OAuth {
    static states = parseStates();

    constructor(service, issuer, clientId, scopes, redirectUri, fromJson = false) {
        this.service = service;
        this.issuer = issuer;
        this.clientId = clientId;
        this.scope = fromJson ? scopes : ['openid', ...scopes].join('+');
        this.redirectUri = redirectUri;
        if(!fromJson) {
            this.state = uuidv4();
            this.configured = false;
            this.memorizeInstance();
        }
    }

    static get(state) {
        return OAuth.states[state];
    }

    static createOrGet(service, issuer, clientId, scopes, redirectUri) {
        return Object.values(OAuth.states).find(oauth => oauth.issuer === issuer) ?? new OAuth(service, issuer, clientId, scopes, redirectUri);
    }

    static fromJson(state, json) {
        const instance = new OAuth(json.service, json.issuer, json.clientId, json.scope, json.redirectUri, true);
        instance.state = state;
        instance.redirectUri = json.redirectUri;
        instance.configured = json.configured;
        instance.authEndpoint = json.authEndpoint;
        instance.tokenEndpoint = json.tokenEndpoint;
        instance.userInfoEndpoint = json.userinfoEndpoint;
        return instance;
    }

    memorizeInstance() {
        OAuth.states[this.state] = this;
        localStorage.setItem('oauth-states', JSON.stringify(OAuth.states));
    }

    async authenticate() {
        if(!this.configured) {
            /* const response = await axios.get(this.issuer.replace(/\/$/, '') + '/.well-known/openid-configuration',
                {
                    withCredentials: true,
                }); */
            /* this.authEndpoint = response.data.authorization_endpoint;
            this.tokenEndpoint = response.data.token_endpoint;
            this.userInfoEndpoint = response.data.userinfo_endpoint; */
            this.authEndpoint = process.env.REACT_APP_CLEARFACTS_ISSUER+"oauth2-server/authorize";
            this.tokenEndpoint = process.env.REACT_APP_CLEARFACTS_ISSUER+"oauth2-server/token";
            this.userInfoEndpoint = process.env.REACT_APP_CLEARFACTS_ISSUER+"oauth2-server/userinfo";

            this.configured = true;
            this.memorizeInstance();
        }
        window.location.replace(this.authEndpoint
            + '?client_id=' + this.clientId
            + '&response_type=code'
            + '&scope=' + this.scope
            + '&redirect_uri=' + this.redirectUri
            + '&state=' + this.state
        );
    }

    async getToken(code) {
        const response = await api.post('/third-parties/token', {
            code,
            service: this.service,
        });
        return response.data;
    }
}