import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilledInput, Box } from '@material-ui/core';

import { deaccentString } from '../../utils/converters';

import ParametersButtonKey from './ParametersButtonKey';

const ParametersExplorer = ({ keys, focusedKey, onKeyClick, setFilter }) => {
    const { t } = useTranslation();
    const [ filter, setFilters ] = useState('');

    const filteredKeys = keys.filter(key => {
        const treatedFilter = deaccentString(filter.toLowerCase());
        if(!treatedFilter)return true;
        if(key.includes(treatedFilter)) return true;
        return false;
    });

    return (
        <>
            <FilledInput
                id="parameters-searchbar"
                inputProps={{ 
                    role: 'searchbox'
                }}
                name="parameters-searchbar"
                placeholder={t('translate.search', 'Search')}
                value={filter}
                onChange={event =>{ setFilters(event.target.value);setFilter(event.target.value); }}
                fullWidth
                disableUnderline
                className="parameters-explorer-input"
            />
            <Box className="parameters-explorer-list">
                {
                    filteredKeys.map((key,index) => (
                        <ParametersButtonKey
                        key={key}
                        keyName={key}
                        focused={focusedKey === index ? true : false}
                        onClick={()=>onKeyClick(index)}
                        />
                    ))
                }
            </Box>
        </>
    );
};

export default ParametersExplorer;