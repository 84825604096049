import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
    root: (hasValue) => ({
        '& .MuiInputBase-root.Mui-disabled': {
            color: hasValue ? theme.palette.text.primary : theme.palette.text.hint,
            fontStyle: hasValue ? 'normal' : 'italic',
            '& input::placeholder': {
                fontStyle: 'italic',
            },
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: theme.palette.text.primary,
        },
    }),
}));

const NumberFormatCustom = ({ inputRef, decimalPlaces, ...props }) => {
    return (
        <NumberFormat
            {...props}
            getInputRef={inputRef}
            thousandSeparator=" "
            decimalScale={decimalPlaces}
            isNumericString
        />
    );
};

const ConsultNumberField = ({
    value,
    startAdornment,
    endAdornment,
    ...props
}) => {
    const classes = useStyles(value != null && value !== '');

    return (
        <TextField
            className="form-input"
            InputProps={{
                startAdornment: startAdornment && (
                    <InputAdornment position="start">
                        { startAdornment }
                    </InputAdornment>
                ),
                endAdornment: endAdornment && (
                    <InputAdornment position="end">
                        { endAdornment }
                    </InputAdornment>
                ),
                inputComponent: value ? NumberFormatCustom : undefined,
            }}
            value={value === 0 ? '0' : (value ?? 'None')}
            classes={classes}
            disabled
            { ...props }
        />
    );
};

export default ConsultNumberField;