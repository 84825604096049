import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchWbsAllForFolderId = createAsyncThunk(
    'wbsAll/fetchWbsForFolderId',
    async (folderId) => {
        const response = await axios.get(`/wbs/by_folder_id/${folderId}`);
        return response.data;
    },
);

export const wbsAllMethods = {
    fetchWbsAllForFolderId
}

const wbsAllSlice = createSlice({
    name: 'wbsAll',
    initialState: [],
    extraReducers: {
        [fetchWbsAllForFolderId.fulfilled]: (_state, action) => action.payload,
    },
});

export default wbsAllSlice;