import { Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import UserContext from '../../context/user';
import { useContext, useState } from 'react';
import { AiFillApi } from 'react-icons/ai';
import { MdAssignment, MdLibraryBooks, MdVerifiedUser, MdBookmark, MdList, MdContentCopy, MdExpandLess, MdExpandMore, MdPeopleOutline, MdStore, MdFolderOpen, MdTranslate, MdChevronLeft } from 'react-icons/md';
import {IoIosNotificationsOutline} from 'react-icons/io';
import { connect, mapStateToProps, mapDispatchToProps} from '../../store/dispatchers'
const menuItems = [
    {
        text: 'Users',
        icon: <MdPeopleOutline/>,
        link: '/users',
        permission: 'users.read'
    },
    {
        text: 'Companies',
        icon: <MdStore/>,
        link: '/companies',
        permission: 'company.read'
    },
    {
        text: 'Folders',
        icon: <MdFolderOpen/>,
        link: '/folders',
        permission: 'folders.read'
    },
    {
        text: 'Planner',
        icon: <MdAssignment/>,
        link: '/planner',
        permission: 'planner.write'
    },
    {
        text: 'Reports',
        icon: <MdLibraryBooks/>,
        link: '/viewReports',
        permission: 'reports.read'
    },
    {
        text: 'Others',
        children:[
            {
                text: 'Notifications',
                icon: <IoIosNotificationsOutline/>,
                link: '/notifications',
                permission: 'notifications.read'
            },
            {
                text: 'Translations',
                icon: <MdTranslate/>,
                link: '/translation',
                permission: 'translate.write'
            },
            {
                text: 'Templates',
                icon: <MdContentCopy/>,
                link: '/template',
                permission: 'template.read'
            },
            {
                text: 'Parameters',
                icon: <MdList/>,
                link: '/parameters',
                permission: 'parameters.read'
            },
            {
                text: 'Banner',
                icon: <MdBookmark/>,
                link: '/banner',
                permission: 'banner.write'
            },
            {
                text: 'Permissions',
                icon: <MdVerifiedUser/>,
                link: '/permissions',
                permission: 'permissions_all.update'
            },
            {
                text: 'Third-Party Services',
                icon: <AiFillApi/>,
                link: '/third-party-services',
                permission: 'third-party.write'
            }
        ]
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.admin.navbar.contrastText,
        backgroundColor: theme.palette.admin.navbar.main,
    },
    active: {
        backgroundColor: theme.palette.primary.main + "55",
        color: '#fff',
        //borderLeft: '3px solid ' + theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main,
            cursor:"pointer"
        }
    },
    child: {
        paddingLeft: 40
    },
    icon: {
        float:'right',
        textAlign:'right',
        minWidth:0,
        color: theme.palette.secondary.main,
        fontSize:20,
    },
    drawerPaper: {
        width: 225,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.admin.navbar.main,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}));

const SideNavbar = ({appParams,setDrawer}) => {
    const classes = useStyles();
    const palette = useTheme().palette;
    const { hasPermission } = useContext(UserContext);
    const [open,setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    }

    const renderDropdownMenu = (menu) => {
        return(
            <Box key={menu.text}>
                <ListItem
                    key={menu.text + "menu"}
                    button
                    onClick={()=>handleClick()}
                >
                    <ListItemText primary={menu.text} />
                    <ListItemIcon className={classes.icon}>
                        {open ? <MdExpandLess/> : <MdExpandMore/> }
                    </ListItemIcon>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit key={menu.text + "menuCol"}>
                    <List disablePadding>
                        {menu.children.map(child=>renderLink(child,true))}
                    </List>
                </Collapse>
            </Box>
        )
        
    }

    const handleDrawerClose = () => {
        setDrawer(false)
    }

    const renderLink = (menu,isChild = false) => {
        if(menu.permission && !hasPermission(menu.permission))return null
        if(menu.children && menu.children.length >= 1){
            return renderDropdownMenu(menu);
        }
        else
        return (
            <ListItem
                key={menu.text +"link"}
                button
                component={menu.link ? NavLink : null}
                to={menu.link ? menu.link : null}
                onClick={()=>{
                    if(menu.hrefLink){
                        let url = "https://newvp.atosborne.be" + menu.hrefLink;
                        // let url = "http://localhost:3001" + menu.hrefLink;
                        let link = document.createElement('a');
                        link.href = url;
                        link.click();
                    }
                }}
                activeClassName={classes.active}
                className={isChild ? classes.child : ''}
            >
                
                <ListItemText primary={menu.text} />
                {menu.icon ?<ListItemIcon className={classes.icon}>
                    {menu.icon}
                </ListItemIcon> : null}
            </ListItem>
        )
    }

    return (
    <>
        <Drawer
            variant="persistent"
            anchor="left"
            open={appParams.drawerOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    {<MdChevronLeft style={{color:palette.secondary.main}}/> }
                </IconButton>
            </div>
            <Divider />
            {/* <Box width={225} minWidth={225} paddingTop="64px" height="100%" position="fixed" zIndex="1" top="0" left="0" className={classes.root}> */}
                <List disablePadding>
                    { menuItems.map((menu)=>renderLink(menu)) }
                    <ListItem
                        button
                    >

                    </ListItem>
                </List>
            {/* </Box>  */}
        </Drawer>
    </>
        
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavbar);