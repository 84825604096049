import { connect } from 'react-redux'

import { templateMethods } from './slices/templateReducer';
import { userMethods } from './slices/users';
import { userCostMethods } from './slices/userCost';
import { rolesMethods } from './slices/roles';
import { folderMethods } from './slices/folders';
import { bannerMethods } from './slices/banner';
import { keywordMethods } from './slices/keywords';
import { notificationsMethods } from './slices/notifications';
import { parametersMethods } from './slices/parameters';
import { companiesMethods } from './slices/companies';
import { wbsMethods } from './slices/wbs';
import { wbsAllMethods } from './slices/wbsAll';
import { wbsPlanningMethods, updateWbsPlanningSync } from './slices/wbsPlanning';
import { invoiceMethods } from './slices/invoice';
import { invoiceForecastMethods } from './slices/invoiceForecast';
import { userBookingMethods } from './slices/userBookings';
import { rolesAllMethods } from './slices/rolesAll';
import { parametersRelationsMethods } from './slices/parametersRelations';
import { parametersNumericalMethods } from './slices/parametersNumerical';
import { thirdPartiesMethods } from './slices/thirdParties';
import { setCurrentInvoiceState } from './slices/currentInvoice';
import { notificationsAllMethods } from './slices/notificationsAll';
import { permListMethods } from './slices/permlist';
import { appParamsMethods } from './slices/appParams';
import { userSpecificCostMethods } from './slices/userSpecificCost';

export const mapDispatchToProps = {
    // Banner
    ...bannerMethods,
    // Companies
    ...companiesMethods,
    // Folders
    ...folderMethods,
    // Keywords
    ...keywordMethods,
    // Notifications
    ...notificationsMethods,
    // Parameters
    ...parametersMethods,
    // Roles
    ...rolesMethods,
    // Template
    ...templateMethods,
    // Third party services
    ...thirdPartiesMethods,
    // Users
    ...userMethods,
    // UsersCost
    ...userCostMethods,
    // Wbs
    ...wbsMethods,
    // WbsAll
    ...wbsAllMethods,
    // WbsPlanning
    ...wbsPlanningMethods,
    updateWbsPlanningSync,
    // Invoices
    ...invoiceMethods,
    // Invoices forecast
    ...invoiceForecastMethods,
    // Userbooking
    ...userBookingMethods,
    //rolesAll
    ...rolesAllMethods,
    //parameters relations
    ...parametersRelationsMethods,
    //parameters numerical
    ...parametersNumericalMethods,
    //currentInvoice
    setCurrentInvoiceState,
    //notificationsAll
    ...notificationsAllMethods,
    //permList
    ...permListMethods,
    //appParams
    ...appParamsMethods,
    //specificCost
    ...userSpecificCostMethods
}

export const mapStateToProps = state => state;

export { connect }
