import { Box, Button, List, ListItem, Link } from "@material-ui/core"
import { FormProvider, useForm } from "react-hook-form"
import Card from "../components/card/Card"
import CardTitle from "../components/card/CardTitle"
import FormSelect from "../components/form/FormSelect"
import FormTextField from "../components/form/FormTextField"
import actions from '../store/modules/Report';
import { useContext, useEffect, useState } from "react"
import FormCategoryHeader from "../components/form/FormCategoryHeader"
import { connect, mapStateToProps, mapDispatchToProps } from '../store/dispatchers';
import FormDatePicker from "../components/form/FormDatePicker"
import LoadingButton from "../components/form/LoadingButton"
import FormSwitch from "../components/form/FormSwitch"
import SnackbarContext from "../context/snackbar"
import moment from "moment"
import LoadingFallback from './LoadingFallback';

const FORMATS = {
    pdf:"pdf",
    word:"vnd.openxmlformats-officedocument.wordprocessingml.document",
    excel:"vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};

const TEXT_PARAM = ['reports'];

const Reports = connect(mapStateToProps, mapDispatchToProps)(({parameters, fetchParametersNumerical, parametersNumerical}) => {

    const [reportsList,setReportsList] = useState([]);
    const [selectedReports,setSelectedReports] = useState(null);
    const [parametersList,setParametersList] = useState([]);
    const [loading,setLoading] = useState(false);
    const { openErrorSnackbar } = useContext(SnackbarContext);

    useEffect(()=>{
        actions.getSSRSReportsList({path:'/New_VP'}).then((res)=>{
            const filteredReports = res.filter(report=>report.TypeName === "Report")
            setReportsList(filteredReports)
        });
    },[])

    useEffect(()=>{
        if(selectedReports){
            actions.getSSRSReportParams({path:selectedReports.Path}).then((res)=>{
                setParametersList(res)
            })
        }
    },[selectedReports])

    useEffect(() => {
        fetchParametersNumerical()
    }, [])

    const powerBiUrl = parametersNumerical.find((param) => TEXT_PARAM.includes(param.class) && param.type === 'powerbi_url').textual_value

    const availableFormat = [
        "pdf",
        "word",
        "excel",
    ]
    const form = useForm({
        defaultValues:{
            format:"pdf"
        }
    })

    const downloadReport = (values) => {
        //setLoading(true)
        let payload = {};
        let data = {};
        for(const params of parametersList){
            let val = values[params.Name];
            if(val instanceof Date)val = moment(val).format('YYYY-MM-DD')
            if(params.ParameterTypeName === "DateTime" && (val === null || val === undefined)){
                val = moment(new Date()).format('YYYY-MM-DD')
            }
            data[params.Name] = val === undefined ? null : val;
        }
        payload.parameters = {...data} 
        payload.path = selectedReports?.Path||null;
        payload.format = values.format
        const now = moment(new Date()).format('YYYY-MM-DD HH-mm-ss');
        const name = (selectedReports?.Name||"")+" _ "+now;
        actions.getSSRSReport(payload).then(res=>{
            let fileLink = document.createElement('a');
            fileLink.href = "data:application/"+FORMATS[values.format]+";base64,"+res.Result;
            //fileLink.href = "data:application/pdf;base64,"+res.Result;
            fileLink.download = name;
            fileLink.click();
            setLoading(false);
        }).catch(e=>{
            console.log(e)
            openErrorSnackbar("error while creating report")
            setLoading(false);
        });
    }

    // display the spinner if the array of reportList is empty 
    if(reportsList.length === 0){
        return(
            <LoadingFallback/>
        )
    }

    return (
    <Box className="page">
        <Card hideButtons>
            <CardTitle>Paginated Reports</CardTitle>
            
            {selectedReports && <Box>
               
                <FormProvider {...form}>
                    <FormCategoryHeader>
                        {selectedReports && selectedReports.Name.replace(/[_]/g,' ')}<Button style={{marginLeft:"50px",color:"white"}} onClick={()=>setSelectedReports(null)} variant="outlined">Back</Button>
                    </FormCategoryHeader>
                    {parametersList.map(parameter=>{
                        if(parameter.ParameterTypeName === "String"){
                            if(parameter.ValidValuesQueryBased){
                                //check if parameters exists
                                //first sanitized name
                                let name = parameter.Name.replace(/[A-Z]/g,(z)=>" "+z).trim().toLowerCase().replace(/[ ]/g,"_")
                                if(parameters[name]){
                                    return <FormSelect
                                        name={parameter.Name}
                                        options={parameters[name]}
                                        label={parameter.Prompt}
                                    />
                                }
                            }else{
                                return <FormTextField name={parameter.Name} label={parameter.Prompt}/>
                            }
                        }
                        if(parameter.ParameterTypeName === "Boolean"){
                            return(
                                <Box marginTop="25px">
                                    <FormSwitch
                                        name={parameter.Name}
                                        label={parameter.Prompt}
                                        control={form.control}
                                    />
                                </Box>
                            )
                        }
                        if(parameter.ParameterTypeName === "DateTime"){
                            return <FormDatePicker
                                name={parameter.Name}
                                label={parameter.Prompt}
                            />
                        }
                        return null
                    })}
                    <FormSelect
                        name="format"
                        options={availableFormat}
                        label="format"
                    />
                    <LoadingButton
                        onClick={form.handleSubmit(downloadReport)}
                        variant="contained"
                        color="primary"
                        loading={loading}
                        style={{marginTop:"30px"}}
                        disabled={loading}
                    >
                            Download Report
                    </LoadingButton>
                </FormProvider>
            </Box>}
            {!selectedReports && <Box>
                <List>
                    {reportsList.map(report=>{
                        return (
                            <ListItem key={report.ID}>
                                <Button onClick={()=>setSelectedReports(report)} variant="outlined">{report.Name.replace(/[_]/g,' ')}</Button>
                            </ListItem>
                        )
                    })}
                </List>
            </Box>}
            <br />
            <CardTitle>Interactive Reports</CardTitle>
            <List>
                <ListItem>
                    <Link href={powerBiUrl} style={{textDecoration : 'none'}} target="_blank" rel="noopener">
                        <Button variant="outlined">PowerBI</Button>
                    </Link>
                </ListItem>
                <ListItem>
                    <span>{}</span>
                </ListItem>
            </List>

        </Card>
    </Box>
    
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)