import { useState, useCallback, useMemo, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core';
import { MdModeEdit } from 'react-icons/md';

import Card from '../card/Card';
import FormCategoryHeader from '../form/FormCategoryHeader';
import FormTable from '../form/FormTable';
import FormRow from '../form/FormRow';
import ConsultTextField from '../form/ConsultTextField';
import ConsultLinkField from '../form/ConsultLinkField';
import { connect, mapStateToProps } from '../../store/dispatchers';
import Map from '../Map';
import MapZoom from '../../enums/MapZoom';
import UserContext from '../../context/user';

const CompaniesConsult = ({ companies }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [ company ] = useState(companies.find(c => c.id.toString() === id));
    const companyMainAddress = useMemo(() => company.company_addresses.find(address => address.address_type === 'head_office') ?? company.company_addresses[0], [ company ]);
    const { hasPermission } = useContext(UserContext);

    const handleEditClick = useCallback(() => {
        history.push(`/companies/${id}/edit`);
    }, [ id, history ]);

    return (
        <Card
            title={ t('companies.title.consult-company', '{{name}}\'s information', { name: company.name }) }
            hideButtons
        >
            {hasPermission('company.update') && <Button
                color="primary"
                style={{ position: 'absolute', top: 25, right: 25 }}
                variant="contained"
                startIcon={<MdModeEdit/>}
                onClick={handleEditClick}
            >
                { t('button.edit', 'Edit') }
            </Button>}
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <FormCategoryHeader smallMarginTop>
                    { t('companies.basic-information', 'Basic information') }
                </FormCategoryHeader>
                <FormRow>
                    <Box display="flex" flexDirection="column">
                        <ConsultTextField
                            name="relation_type"
                            label={t('forms.fields.company.relation-type.label', 'Type')}
                            value={company.account_companies.map(ac => ac.relation_type).join(', ')}
                        />
                        <ConsultTextField
                            name="name"
                            label={t('forms.fields.company.name.label', 'Name')}
                            value={company.name}
                        />
                        <ConsultTextField
                            name="company_name_second"
                            label={t('forms.fields.company.namesecond.label', 'Company name 2')}
                            value={company.company_name_second}
                        />
                        <ConsultTextField
                            name="reference"
                            label={t('forms.fields.company.referencecode.label', 'Company initials')}
                            value={company.reference}
                        />
                        <ConsultTextField
                            name="vat_number"
                            label={t('forms.fields.company.vat.label', 'VAT')}
                            value={(company.vat_prefix ? company.vat_prefix : 'BE') +" "+company.vat_number}
                        />
                        <ConsultTextField
                            name="private"
                            label={t('forms.fields.company.private.label', 'Private?')}
                            value={company.private ? t('forms.fields.company.private.options.yes', 'Yes') : t('forms.fields.company.private.options.no', 'No')}
                        />
                        <ConsultLinkField
                            name="external_link"
                            label={t('forms.fields.company.external-link.label', 'M-Files Link')}
                            value={company.external_link}
                            openInNewTab
                        />
                        <ConsultLinkField
                            name="url"
                            label={t('forms.fields.company.url.label', 'Website')}
                            value={company.url}
                            openInNewTab
                        />
                        <ConsultTextField
                            name="remarks"
                            label={t('forms.fields.company.remarks.label', 'Remarks')}
                            value={company.remarks}
                        />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                        { companyMainAddress && (
                            <Map
                                containerStyle={{ height: 640, width: 640 }}
                                center={{
                                    lat: companyMainAddress.latitude,
                                    lng: companyMainAddress.longitude,
                                }}
                                zoom={MapZoom.BUILDINGS}
                            />
                        ) }
                    </Box>
                </FormRow>
                <FormCategoryHeader>
                    { t('companies.addresses', 'Addresses') }
                </FormCategoryHeader>
                <FormTable
                    columns={[
                        t('forms.fields.address.type.label', 'Type'),
                        t('forms.fields.address.street_name.label', 'Street Name'),
                        t('forms.fields.address.stree_number.label', 'Street Number'),
                        t('forms.fields.address.zip_code.label', 'ZIP Code'),
                        t('forms.fields.address.locality.label', 'Locality'),
                        t('forms.fields.address.country.label', 'Country'),
                    ]}
                    items={company.company_addresses}
                    mapToCellContents={item => [
                        item.address_type,
                        item.street_name,
                        item.street_number,
                        item.zip_code,
                        item.city,
                        item.country,
                    ]}
                />
                <FormCategoryHeader>
                    { t('companies.contacts', 'Contacts') }
                </FormCategoryHeader>
                <FormTable
                    columns={[
                        t('forms.fields.contact.type.label', 'Type'),
                        t('forms.fields.contact.first_name.label', 'First Name'),
                        t('forms.fields.contact.last_name.label', 'Last Name'),
                        t('forms.fields.contact.email.label', 'Email'),
                        t('forms.fields.contact.phone_number.label', 'Phone Number'),
                    ]}
                    items={company.company_contacts}
                    mapToCellContents={item => [
                        item.contact_type,
                        item.firstname,
                        item.lastname,
                        item.email,
                        item.phone_number,
                    ]}
                />
            </Box>
        </Card>
    );
}

export default connect(mapStateToProps)(CompaniesConsult);