import { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {
    fr as frLocale,
    enUS as enLocale,
    nlBE as nlLocale,
} from 'date-fns/locale';

import AppRouter from './Router'
import Banner from './components/banner/Banner';
import UserContext, { UserProvider } from './context/user';
import themes from './style/theme';
import ErrorFallback from './views/ErrorFallback';
import LoadingFallback from './views/LoadingFallback';
import { connect, mapStateToProps, mapDispatchToProps } from './store/dispatchers';
import axios from './services/axios';
import { getCookie } from './utils/cookies';
import { SnackbarProvider } from './context/snackbar';

const datePickerLocales = {
    en: enLocale,
    fr: frLocale,
    nl: nlLocale,
};

const WrappedApp = ({appParams, setTheme}) => {
    return (
        <UserProvider>
            <ThemeProvider theme={themes[appParams.theme]}>
                <ErrorBoundary fallback={({ resetError }) => <ErrorFallback resetError={resetError}/>}>
                    <App/>
                </ErrorBoundary>
            </ThemeProvider>
        </UserProvider>
    );
}

const App = connect(mapStateToProps, mapDispatchToProps)(({
    banner,
    fetchBannerData,
    fetchParameters,
    fetchKeywords,
    fetchRoles,
    addNotification,
    fetchParametersRelations,
    fetchParametersNumerical
}) => {
    const [ showBanner, setShowBanner ] = useState(false);
    const [ fetched, setFetched ] = useState(false);
    const [ isFirstLogin, setIsFirstLogin ] = useState(false);
    const { t, i18n, ready } = useTranslation('translation', { useSuspense: false });
    const { updateUser, user } = useContext(UserContext);
    // NOTE: HACK upateUser being added to the dependency array of fetchRequiredData made it rerun 3 times due to the
    // context changing. This is a hack to avoid that but there probably is something better than that.
    const updateUserRef = useRef(updateUser);

    const fetchRequiredData = useCallback(async () => {
        let userFetched;
        // TODO: write those lines better (with error checks)
        if(getCookie('__session')) {
            userFetched = (await axios.post('/auth')).data.value;
            if(userFetched){
                updateUserRef.current(userFetched);
                fetchRoles();
            } 
        } else {
            userFetched = (await axios.get('/user/is_first_login')).data.value;
            if(userFetched) {
                updateUserRef.current(userFetched);
                setIsFirstLogin(true);
            }
        }
        
        // TODO: handle error on initial fetches
        await Promise.all([
            //fetchRoles(),
            fetchBannerData(),
            fetchParameters(),
            fetchKeywords(),
            fetchParametersRelations(),
            fetchParametersNumerical(),
        ]);

        setFetched(true);
    }, [
        fetchBannerData,
        fetchRoles,
        fetchParameters,
        fetchKeywords,
        setFetched,
        setIsFirstLogin,
        fetchParametersRelations,
        fetchParametersNumerical
    ]);

    useEffect(() => {
        fetchRequiredData();
    }, [ fetchRequiredData ]);

    useEffect(()=>{
        let notificationsSource = null;
        if(user){
            notificationsSource = new EventSource(process.env.REACT_APP_API_URL+'/notifications/stream', { withCredentials: true });
            notificationsSource.onmessage = event => {
                addNotification(JSON.parse(event.data));
            };
        }

        return () => {
            if(notificationsSource)notificationsSource.close();
        }
    },[ addNotification, user ])

    useEffect(() => {
       /*  const now = Date.now();
        console.log('app' , banner.message)
        setShowBanner(banner.message && new Date(banner.message.start) < now && new Date(banner.message.end) >= now); */
        setShowBanner(!showBanner);
    }, [ banner, setShowBanner ]);

    if(fetched && ready) {
        return (
            <>
                { showBanner &&
                    <Banner onClose={() => setShowBanner(false)}>{ t(banner.message?.key) }</Banner>
                }
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datePickerLocales[i18n.language]}>
                    <SnackbarProvider>
                        <AppRouter isFirstLogin={isFirstLogin}/>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </>
        );
    }

    return (
        <LoadingFallback/>
    );
});

export default connect(mapStateToProps, mapDispatchToProps) (WrappedApp);
