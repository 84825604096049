import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js'

yup.setLocale({
    mixed: {
        default: 'forms.validation.mixed.invalid',
        required: 'forms.validation.mixed.required',
        oneOf: ({ values }) => ({ key: 'forms.validation.mixed.oneOf', values: { values } }),
        notOneOf: ({ values }) => ({ key: 'forms.validation.mixed.notOneOf', values: { values } }),
        notType: 'forms.validation.mixed.notType',
        // defined: 'forms.validation.mixed.defined',
    },
    string: {
        length: ({ length }) => ({ key: 'forms.validation.string.length', values: { length } }),
        min: ({ min }) => ({ key: 'forms.validation.string.minLength', values: { min } }),
        max: ({ max }) => ({ key: 'forms.validation.string.maxLength', values: { max } }),
        matches: ({ regex }) => ({ key: 'forms.validation.string.match', values: { regex } }),
        email: 'forms.validation.string.email',
        url: 'forms.validation.string.url',
        // uuid: 'forms.validation.uuid',
        // trim: 'forms.validation.trimmed',
        lowercase: 'forms.validation.string.lowercase',
        uppercase: 'forms.validation.string.uppercase',
    },
    number: {
        min: ({ min }) => ({ key: 'forms.validation.number.min', values: { min } }),
        max: ({ max }) => ({ key: 'forms.validation.number.max', values: { max } }),
        lessThan: ({ less }) => ({ key: 'forms.validation.number.lessThan', values: { value: less } }),
        moreThan: ({ more }) => ({ key: 'forms.validation.number.moreThan', values: { value: more } }),
        positive: 'forms.validation.number.positive',
        negative: 'forms.validation.number.negative',
        integer: 'forms.validation.number.integer',
    },
    date: {
        min: ({ min }) => ({ key: 'forms.validation.date.min', values: { min } }),
        max: ({ max }) => ({ key: 'forms.validation.date.max', values: { max } }),
    },
    boolean: {
        isValue: ({ value }) => ({ key: 'forms.validation.boolean.value', values: { value } }),
    },
    // object: {
    //     noUnknown: ({ unknown }) => ({ key: 'forms.validation.object.unknown', values: { unknown } }),
    // },
    array: {
        min: ({ min }) => ({ key: 'forms.validation.array.min', values: { min } }),
        max: ({ max }) => ({ key: 'forms.validation.array.max', values: { max } }),
        length: ({ length }) => ({ key: 'forms.validation.array.length', values: { length } }),
    },
});

yup.addMethod(yup.string, 'equalTo', (ref, msg) => yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'forms.validation.string.match',
    params: {
        reference: ref.path,
    },
    test: function (value) {
        return value === this.resolve(ref);
    },
}));

yup.addMethod(yup.string, 'phone', (ref, msg) => yup.mixed().test({
    name: 'phone',
    exclusive: false,
    message: msg || 'forms.validation.phone.invalid',
    test: function (value) {
        try {
            return isValidPhoneNumber(value, this.resolve(ref));
        } catch (e) {
            return false;
        }
    },
}));

yup.addMethod(yup.date, 'valid', (msg) => yup.mixed().test({
    name: 'valid',
    exclusive: false,
    message: msg || 'forms.validation.date.invalid',
    test: isFinite,
}));

// TODO: add method to validate M-Files links
// TODO: add method to validate links

/**
 * IMPORTANT TO KNOW
 * Yup schemas silently fail if one of their keys isn't in the values tested. The form must AT LEAST have all the keys from the schema.
 * That's why we need to separate the schema in multiple pieces so that it's reusable.
 * You can use schemaA.concat(schemaB) to combine schemas (like in UsersCreate).
 */

export const userSchema = yup.object().shape({
    // User
    first_name: yup.string().max(250).required(),
    last_name: yup.string().max(250).required(),
    birthdate: yup.date().max(new Date()).nullable(),
    picture: yup.string().nullable(),
    blocks_availibility_per_week: yup.number().min(0).max(50).transform((_, value) => value === '' ? undefined : value).required(),
    // User account
    role: yup.string().max(50).required(),
    // User extra info
    code: yup.string().max(10).nullable(),
    status_type: yup.string().max(50).nullable(),
    function_type: yup.string().max(50).required(),
    payroll_type: yup.string().max(50).nullable(),
    cost_type: yup.string().max(50).nullable(),
    external_folder_link: yup.string().nullable(),
    remarks: yup.string().nullable(),
    graduation_date: yup.date().max(new Date()).nullable(),
    degree: yup.string().max(250).nullable(),
    entry_date: yup.date().required(),
    exit_Date: yup.date().nullable(),
    last_evaluation_date: yup.date().nullable(),
    next_evaluation_date: yup.date().nullable(),
    seniority: yup.number().min(0).max(99.9).transform((_, value) => value === '' ? null : value).nullable(),
    absence_rate: yup.number().min(0).max(100).transform((_, value) => value === '' ? null : value).nullable(),
});

export const userLoginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
    password_confirm: yup.string().equalTo(yup.ref('password')).required(),
});

export const userLoginEditSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().nullable(),
    password_confirm: yup.string().equalTo(yup.ref('password')).nullable(),
});

/* const VAT = [
    {BE: 'BE0111111111'},
] */
/* // regex vat number belgium
const VATNumberRegex = /^(0?\d{9}|0?\d{12})$/;
vat_number: yup.string()
.matches(belgiumRegex, 'forms.validation.vat.invalid')
.min(12)
.required(), */
export const companySchema = yup.object().shape({
    name: yup.string().required(),
    reference: yup.string().max(50),
    vat_number: yup.string()
    .max(50)
    .nullable(),
    private: yup.boolean().transform((_, value) => value === '' ? undefined : value).required(),
    url: yup.string().nullable(),
    remarks: yup.string().nullable(),

});

export const companyAddressSchema = yup.object().shape({
    is_street_address: yup.boolean().typeError('test').isTrue().typeError('test').transform((_, value) => value === '' ? false : value).required(),
});

export const companyContactSchema = yup.object().shape({
    contact_type: yup.string().max(50).nullable(),
    firstname: yup.string().max(50).nullable(),
    lastname: yup.string().max(150).nullable(),
    email: yup.string().email().max(50).nullable(),
   /*  phone_number: yup.string().max(50).phone(yup.ref('phone_number_country')).nullable(), */
   phone_number: yup.string().max(50).nullable(),
    phone_number_country: yup.string().length(2).nullable(),
});

export const folderSchema = yup.object().shape({
    // Folder
    code: yup.string().max(50).nullable(),
    project_owner_company_id: yup.number().integer().min(0).transform((_, value) => value === '' ? undefined : value).required(),
    end_user_company_id: yup.number().integer().min(0).transform((_, value) => value === '' ? null : value).nullable(),
    name: yup.string().max(250).required(),
    description: yup.string().required(),
    location: yup.string().max(250).nullable(),
    project_manager_id: yup.number().integer().min(0).transform((_, value) => value === '' ? undefined : value).required(),
    external_documentation_link: yup.string().nullable(),
    // Folder extra info
    work_budget: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? null : value).nullable(),
    investment_budget: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? null : value).nullable(),
    work_start_date: yup.date().when('work_end_date', (work_end_date, schema) => work_end_date ? schema.max(work_end_date) : schema).nullable(),
    work_end_date: yup.date().when('work_start_date', (work_start_date, schema) => work_start_date ? schema.min(work_start_date) : schema).nullable(),
    project_gfa: yup.number().min(0).max(999999.99).transform((_, value) => value === '' ? null : value).nullable(),
    building_sector_type: yup.string().max(50).nullable(),
    building_subsector_type: yup.string().max(50).nullable(),
    procurement_type: yup.string().max(50).nullable(),
}, [['work_start_date', 'work_end_date']]);

export const taskSchema = yup.object().shape({
    task_type: yup.string().max(250).required(),
    task_code: yup.string().max(250).required(),
    name: yup.string().max(250).required(),
    description: yup.string().max(1000).nullable(),
    number_blocks: yup.number().min(0).max(1000).transform((_, value) => value === '' ? undefined : value).required(),
    bookable: yup.boolean(),
});

export const subfolderStockSchema = yup.object().shape({
    year: yup.number().max(9999).required(),
    trimester: yup.number().max(99).required(),
    amount: yup.number().max(1000000).required(),
    comment: yup.string().max(1000).required(),
});

export const costSchema = yup.object().shape({
    //subfolder_id: yup.number().max(99999).required(),
    daily_rate: yup.number().max(99999).required(),
});

export const subfolderSchema = yup.object().shape({
    // Subfolder
    status_type: yup.string().max(50).required(),
    customer_company_id: yup.number().integer().min(0).transform((_, value) => value === '' ? null : value).nullable(),
    name: yup.string().max(250).required(),
    project_leader: yup.number().integer().min(0).transform((_, value) => value === '' ? null : value).nullable().required(),
    // Subfolder extra info
    // mission_start: yup.date().max(yup.ref('temporary_reception_date')).nullable(),
    mission_start: yup.date()
        .when(['temporary_reception_date', 'final_reception_date', 'good_execution_attest_date'], (temporary_reception_date, final_reception_date, good_execution_attest_date, schema) => {
            const max = temporary_reception_date ?? final_reception_date ?? good_execution_attest_date;
            return max ? schema.max(max) : schema;
        })
        .nullable(),
    // temporary_reception_date: yup.date().min(yup.ref('mission_start')).max(yup.ref('final_reception_date')).nullable(),
    temporary_reception_date: yup.date()
        .when('mission_start', (mission_start, schema) => mission_start ? schema.min(mission_start) : schema)
        .when(['final_reception_date', 'good_execution_attest_date'], (final_reception_date, good_execution_attest_date, schema) => {
            const max = final_reception_date ?? good_execution_attest_date;
            return max ? schema.max(max) : schema;
        })
        .nullable(),
    // final_reception_date: yup.date().min(yup.ref('temporary_reception_date')).max(yup.ref('good_execution_attest_date')).nullable(),
    final_reception_date: yup.date()
        .when(['temporary_reception_date', 'mission_start'], (temporary_reception_date, mission_start, schema) => {
            const min = temporary_reception_date ?? mission_start;
            return min ? schema.min(min) : schema;
        })
        .when('good_execution_attest_date', (good_execution_attest_date, schema) => good_execution_attest_date ? schema.max(good_execution_attest_date) : schema)
        .nullable(),
    // good_execution_attest_date: yup.date().min(yup.ref('final_reception_date')).nullable(),
    good_execution_attest_date: yup.date()
        .when(['final_reception_date', 'temporary_reception_date', 'mission_start'], (final_reception_date, temporary_reception_date, mission_start, schema) => {
            const min = final_reception_date ?? temporary_reception_date ?? mission_start;
            return min ? schema.min(min) : schema;
        })
        .nullable(),
    total_work_amount: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? null : value).nullable(),
    // Subfolder extra info prospect
    object: yup.string().nullable(),
    opportunity_amount_low: yup.number().min(0).max(9999999999.99)
        .when('opportunity_amount_high', (opportunity_amount_high, schema) => opportunity_amount_high ? schema.max(opportunity_amount_high) : schema)
        .transform((_, value) => value === '' ? null : value)
        .nullable(),
    opportunity_amount_high: yup.number().min(0).max(9999999999.99)
        .when('opportunity_amount_low', (opportunity_amount_low, schema) => opportunity_amount_low ? schema.min(opportunity_amount_low) : schema)
        .transform((_, value) => value === '' ? null : value)
        .nullable(),
    source_type: yup.string().max(50).nullable(),
    comment: yup.string().nullable(),
    prospection_date: yup.date().nullable(),
    // Subfolder extra info offer
    submission_done: yup.boolean().when('status_type', {
        is: 'Recherche Affaire',
        then: yup.boolean().transform((_, value) => value === '' ? undefined : value).required(),
        otherwise: yup.boolean().transform((_, value) => value === '' ? null : value).nullable(),
    }),
    submission_date: yup.date().nullable(),
    go_to_tender_date: yup.date().nullable(),
    offer_amount: yup.number().min(0).max(9999999999.99).when('status_type', {
        is: 'Recherche Affaire',
        then: yup.number().transform((_, value) => value === '' ? undefined : value).required(),
        otherwise: yup.number().transform((_, value) => value === '' ? null : value).nullable(),
    }),
    submission_result_type: yup.string().max(50).nullable(),
    submission_comment: yup.string().nullable(),
    refusal_type: yup.string().max(50).nullable(),
    refusal_comment: yup.string().nullable()
}, [
    ['mission_start', 'temporary_reception_date'],
    ['mission_start', 'final_reception_date'],
    ['mission_start', 'good_execution_attest_date'],
    ['temporary_reception_date', 'final_reception_date'],
    ['temporary_reception_date', 'good_execution_attest_date'],
    ['final_reception_date', 'good_execution_attest_date'],
    ['opportunity_amount_low', 'opportunity_amount_high']
]);

export const subfolderAssociateSchema = yup.object().shape({
    associate_company_id: yup.number().integer().min(0).transform((_, value) => value === '' ? undefined : value).required(),
    associate_role_type: yup.string().max(50).required(),
    shares_percentage: yup.number().max(100).transform((_, value) => value === '' ? null : value).nullable(),
});

export const subfolderBankGuaranteeSchema = yup.object().shape({
    amount: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? undefined : value).required(),
    constitution_date: yup.date().max(new Date()).nullable(),
    organism_reference: yup.string().nullable(),
    release_date: yup.date().nullable(),
});
// regex valid to get valide years (yyyy)
const yearRegex = /^\d{4}$/;

export const subfolderBudgetSchema = yup.object().shape({
    budget_type: yup.string().max(50).required(),
    amount: yup.number().min(0).max(99999.99).transform((_, value) => value === '' ? undefined : value).required(),
    /* year: yup.string().max(4).transform((_, value) => value === '' ? null : value).nullable() */
/*     year: yup.string().max(4).test(yearRegex, 'Year must be a valid year (yyyy)', (value) => value === null || yearRegex.test(value)), */
});

export const subfolderCompetitorSchema = yup.object().shape({
    partner1_company_id: yup.number().integer().min(0).transform((_, value) => value === '' ? undefined : value).required(),
    price: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? null : value).nullable(),
    final_rating: yup.number().min(0).max(100).transform((_, value) => value === '' ? null : value).nullable(),
    comment: yup.string().nullable(),
});

export const subfolderSubcontractorSchema = yup.object().shape({
    company_id: yup.number().integer().min(0).transform((_, value) => value === '' ? undefined : value).required(),
    role: yup.string().max(250).nullable(),
    contract_type: yup.string().max(50).required(),
    contract_amount: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? undefined : value).required(),
});

export const temlplatePdfSchema = yup.object().shape({
    template_type: yup.string().max(250).required(),
});

export const invoiceForecastSchema = yup.object().shape({
    date: yup.date().required(),
    production_amount: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? undefined : value).required(),
    purchase_amount: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? undefined : value).required(),
    subcontracting_amount: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? undefined : value).required(),
    wbs_task_id: yup.number().min(0).max(9999999999.99).transform((_, value) => value === '' ? undefined : value).required(),
    memo: yup.string().max(250).required()
})

export const userBookingUpdateSchema = yup.object().shape({
    number_blocks: yup.number().min(0).max(10).transform((_, value) => value === '' ? undefined : value).required()
})


export default yup;
