import { Box, Button } from "@material-ui/core"
import CardTitle from "../card/CardTitle";
import { MdArrowForward, MdDelete } from 'react-icons/md';
import { useContext, useEffect, useState } from "react";
import { mapStateToProps, mapDispatchToProps, connect } from '../../store/dispatchers';
import { FormProvider, useForm } from "react-hook-form";
import FormSelect from "../form/FormSelect";
import SnackbarContext from "../../context/snackbar";
import { useTranslation } from "react-i18next";
import UserContext from "../../context/user";

const ParametersRelationsSettings = ({focusedKey,keys,parameters,parametersRelations,addParametersRelation, deleteParametersRelations}) => {

    const [params,setParams] = useState([]);
    const { t } = useTranslation();

    const [paramsRelSettings, setParamsRelSettings] = useState({});
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);
    const { hasPermission } = useContext(UserContext);

    const form = useForm({
        defaultValues:{
            currentType:"",
            targetClass:"employee_status",
            targetType:"employee"
        }
    })

    useEffect(()=>{
        setParamsRelSettings(parametersRelations);
    },[parametersRelations])

    useEffect(()=>{
        setParams(parameters["employee_status"])
    },[parameters])

    const onClickAddPrameterRelation = (values) => {
        if(values.currentType && values.targetClass && values.targetType){
            addParametersRelation({
                class_1:keys[focusedKey],
                type_1:values.currentType,
                class_2:values.targetClass,
                type_2:values.targetType
            }).then(()=>{
                openSuccessSnackbar("Parameters linked !")
            })
        }else{
            openErrorSnackbar('Error : Empty field(s)')
        }
    }

    const onClickRemoveRelation = (id) => {
        deleteParametersRelations(id).then(()=>{
            openSuccessSnackbar("Parameters unlinked")
        })
    }

    const onChangeClassTarget = (target) =>{
        setParams(parameters[target] ? parameters[target] : [])
    }

    const renderParametersRelations = (relations) => {
        let currentRelationsList = [];
        for(const [key,val] of Object.entries(relations)){
            currentRelationsList.push({type:key,relation:val})
        }
        return currentRelationsList.map(currentRelation=>{
            let currentRelationsList = [];
            for(const [keyClass,valClass] of Object.entries(currentRelation.relation)){
                currentRelationsList.push({class:keyClass,types:valClass})
            }
            return(
                <Box display="flex" flexDirection="row">
                    <Box flex="0.3">
                        {currentRelation.type}
                    </Box>
                    <Box flex="0.1">
                        {"=>"}
                    </Box>
                    <Box flex="0.6" display="flex" flexDirection="column">
                        {currentRelationsList.map(classItem=>{
                            return (
                                <Box display="flex" flexDirection="row">
                                    <Box flex="0.4">
                                        {classItem.class}
                                    </Box>
                                    <Box flex="0.6">
                                        {classItem.types.map(type=>{
                                            return (
                                                <Box display="flex" justifyContent="space-between">
                                                    <Box display="flex" flexDirection="row">
                                                        <MdArrowForward style={{marginTop:"auto",marginBottom:"auto",marginRight:5}}/> {type.type}
                                                    </Box>
                                                    { hasPermission('parameters.delete') && <MdDelete className="parameters-relation-delete-button" onClick={()=>onClickRemoveRelation(type.id)} style={{marginTop:"auto",marginBottom:"auto",marginRight:"30px"}}/>}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            ) 
        })
    }
    
    return (
        <>
            { hasPermission('parameters.write') && <><CardTitle>Créer une relation</CardTitle>
            <Box display="flex" flexDirection="column" className="parameters-settings-add-container">
                <FormProvider {...form}>
                    <Box display="flex" flexDirection="row">
                        <FormSelect
                            name="currentType"
                            label="current type"
                            options={parameters[keys[focusedKey]]}
                            style={{marginRight:"20px"}}
                            mapOptionToText={val=>t('parameters.'+keys[focusedKey]+'.'+val)}
                        />
                        <FormSelect
                            name="targetClass"
                            label="target class"
                            options={keys}
                            style={{marginRight:"20px"}}
                            changeEvent={(event)=>onChangeClassTarget(event.target.value)}
                        />
                        <FormSelect
                            name="targetType"
                            label="target type"
                            options={params}
                            style={{marginRight:"20px"}}
                        />
                        <Button className="parameters-settings-add-button" onClick={form.handleSubmit(onClickAddPrameterRelation)} variant="contained" color="primary">Ajouter</Button>
                    </Box>
                    
                </FormProvider>
            </Box></>}
            <CardTitle>Liste des relations</CardTitle>
            
            <Box className="paramaters-settings-container permissions-group-list" style={{ maxHeight: 'calc(100vh - 450px)' }}>
                <Box display="flex" flexDirection="row" fontSize={20} marginBottom="20px" borderBottom="1px solid grey" paddingBottom="10px">
                    <Box flex="0.3">Type of "{keys[focusedKey]}"</Box>
                    <Box flex="0.1"></Box>
                    <Box flex="0.6" display="flex" flexDirection="row">
                        <Box flex="0.4">Target Class</Box>
                        <Box flex="0.6">Target type</Box>
                    </Box>
                </Box>
                {paramsRelSettings[keys[focusedKey]] ? renderParametersRelations(paramsRelSettings[keys[focusedKey]]) : null}
            </Box>
        </>
    )
}

export default connect( mapStateToProps, mapDispatchToProps )(ParametersRelationsSettings)