import { useCallback, useContext } from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import SnackbarContext from '../../context/snackbar';

const useStyles = makeStyles(theme => ({
    root: ({ height, width }) => ({
        height,
        width,
        backgroundSize: 'cover',
    }),
    texts: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        transition: '200ms background-color ease-in-out, 200ms opacity ease-in-out',
        cursor: 'pointer',
    },
    textsUpload: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    textsEdit: {
        '& *': {
            opacity: 0,
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.84)',
            '& *': {
                opacity: 1,
            },
        },
    },
}));

const FormImageField = ({ name, uploadLabel, editLabel, height, width }) => {
    const classes = useStyles({ height, width });
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const { openErrorSnackbar } = useContext(SnackbarContext);

    const onDropAccepted = useCallback(([ file ]) => {
        const reader = new FileReader();

        reader.onabort = () => openErrorSnackbar(t('error.unexpected-file-error', 'Unexpected error while processing the file'));
        reader.onerror = () => openErrorSnackbar(t('error.unexpected-file-error', 'Unexpected error while processing the file'));
        reader.onload = () => {
            const base64Image = reader.result;
            setValue(name, base64Image);
        }
        reader.readAsDataURL(file);
    }, [ t, name, setValue, openErrorSnackbar ]);

    const onDropRejected = useCallback(([ fileRejection ]) => {
        const error = fileRejection.errors[0];
        openErrorSnackbar(t('error.' + error.code, error.message));
    }, [ t, openErrorSnackbar ]);

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted,
        onDropRejected,
        accept: 'image/*',
        multiple: false,
        maxFiles: 1,
        maxSize: 250000,
    });

    return (
        <Controller
            control={control}
            name={name}
            render={({ value }) => (
                <Paper
                    className={classes.root}
                    elevation={2}
                    style={{
                        backgroundImage: `url(${value})`,
                    }}
                    {...getRootProps()}
                >
                    <input {...getInputProps()}/>
                    <Box className={`${classes.texts} ${value ? classes.textsEdit : classes.textsUpload}`}>
                        <Typography component="h3" variant="h3" color="textSecondary">
                            +
                        </Typography>
                        <Typography component="h3" variant="h5" color="textSecondary">
                            { value ? editLabel : uploadLabel }
                        </Typography>
                    </Box>
                </Paper>
            )}
        >
        </Controller>
    );
};

export default FormImageField;