import { useCallback, useState, useEffect, useMemo } from 'react';
import { IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@material-ui/core';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { withTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import TableHeadCell from '../table/TableHeadCell';
import WhiteTableSortLabel from '../table/WhiteTableSortLabel';
import SortingOrder from '../../enums/SortingOrder';

const defaultComparator = (a, b) => {
    if (b < a) {
        return -1;
    }
    if (b > a) {
        return 1;
    }
    return 0;
}

const compare = (a, b, customComparator, order) => {
    const comparator = customComparator ?? defaultComparator;
    return order === SortingOrder.DESCENDING ? comparator(a, b) : -comparator(a, b);
}

/**
 * A table for exploring a list of `items` by displaying them in `columns`.
 * 
 * @param {object} props - `ExplorerTable` properties.
 * @param {{ name: string, width: number, sort?: (a: any, b: any) => number, useLink?: (item: any) => string }[]} props.columns - The columns definitions. `name` is the name displayed at the
 * top of the column. `width` is the width of the column relative to the table (in percent). The sum of all columns should preferably be <= 90 if you plan on having edit/delete buttons at
 * the end of each row. `sort` is a method used to compare column cells data when sorting the rows. If not specified, a default comparator will be used. `useLink` is a method used to
 * generate a link from the item passed to the row. If not specified, the column cells won't be turned into links.
 * @param {any[]} props.items - The items to display.
 * @param {string} [props.defaultOrderBy] - The name of the column to use for sorting by default.
 * @param {(item: any) => any[]} props.mapToCellContents - The method that maps each item to cell contents. The array returned must have the same order
 * as the `columns` array.
 * @param {(item: any) => void} [props.onConsultClick] - The method called when a row is clicked (doesn't trigger if the click is on a link). If not specified, rows won't be clickable.
 * @param {(item: any) => void} [props.conditionalEdit] - Callback to determine if the edit button should be displayed for a given item.
 * @param {(item: any) => void} [props.onEditClick] - The method called when the edit button is clicked. If not specified, the edit button won't appear.
 * @param {(item: any) => void} [props.onDeleteClick] - The method called when the delete button is clicked. If not specified, the delete button won't appear.
 */
const ExplorerTable = ({
    columns,
    items,
    defaultOrderBy,
    mapToCellContents,
    onConsultClick,
    onEditClick,
    conditionalEdit,
    onDeleteClick,
    theme,
}) => {
    const [ order, setOrder ] = useState(SortingOrder.ASCENDING);
    const [ orderBy, setOrderBy ] = useState(defaultOrderBy);
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(50);
    const [ sort, setSort ] = useState();
    const sortPropIndex = useMemo(() => columns.findIndex(column => column.name === orderBy), [ columns, orderBy ]);

    const handleChangePage = useCallback((_event, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback(event => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }, []);

    const handleSort = useCallback((column, sort) => {
        const isAscending = orderBy === column && order === SortingOrder.ASCENDING;
        setOrder(isAscending ? SortingOrder.DESCENDING : SortingOrder.ASCENDING);
        setOrderBy(column);
        setSort(() => sort);
    }, [ order, orderBy ]);

    // When items changes, we set the page number to 0
    useEffect(() => {
        setPage(0);
    }, [ items, setPage ]);

    return (
        <>
            <TableContainer className="app-kit-overflow-y" style={{ height: 'calc(100vh - 296px)', maxHeight: 'calc(100vh - 296px)' }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            { columns.map(({ name, width, sort }, index) =>
                                <TableHeadCell
                                    key={index}
                                    width={typeof width === 'number' ? `${width}%` : width}
                                    sortDirection={orderBy === name ? order : false}
                                >
                                    <WhiteTableSortLabel
                                        active={orderBy === name}
                                        direction={orderBy === name ? order : SortingOrder.ASCENDING}
                                        onClick={() => handleSort(name, sort)}
                                    >
                                        { name }
                                    </WhiteTableSortLabel>
                                </TableHeadCell>
                            ) }
                            <TableHeadCell style={{ minWidth: 100 }}>
                                {/* Actions */}
                            </TableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { items
                            .slice()
                            .sort((a, b) => {
                                if(orderBy) {
                                    return compare(mapToCellContents(a)[sortPropIndex], mapToCellContents(b)[sortPropIndex], sort, order)
                                }
                                return 0;
                            })
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => (
                                <TableRow
                                    key={item.id ?? index}
                                    onClick={onConsultClick ? (() => onConsultClick(item)) : (()=>{})}
                                    style={{ cursor: onConsultClick ? 'pointer' : 'default' }}
                                >
                                    { mapToCellContents(item).map((content, index) => (
                                        <TableCell key={index} style={{height: 48}}>
                                            { columns[index].useLink
                                                ? (
                                                    <Link to={columns[index].useLink(item)} onClick={event => event.stopPropagation()}>
                                                        {content}
                                                    </Link>
                                                )
                                                : content
                                            }
                                        </TableCell>
                                    )) }
                                    <TableCell align="right">
                                        { onEditClick && (conditionalEdit ? conditionalEdit(item) : true) &&
                                            <IconButton onClick={event => { event.stopPropagation(); if(onEditClick)onEditClick(item); }}>
                                                <MdModeEdit color={theme.palette.primary.dark}/>
                                            </IconButton>
                                        }
                                        { onDeleteClick &&
                                            <IconButton onClick={event => { event.stopPropagation(); if(onDeleteClick)onDeleteClick(item); }}>
                                                <MdDelete color={theme.palette.error.main}/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        ) }
                        {/* { emptyRows > 0 &&
                            <TableRow style={{ height: 81 * emptyRows }}>
                                <TableCell colSpan={8}/>
                            </TableRow>
                        } */}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[ 10, 25, 50, 100 ]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                // labelRowsPerPage="Rows per page:"
            />
        </>
    );
};

ExplorerTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        sort: PropTypes.func,
        useLink: PropTypes.func,
    })).isRequired,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    defaultOrderBy: PropTypes.string,
    mapToCellContents: PropTypes.func.isRequired,
    /* onConsultClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func, */
};

export default withTheme(ExplorerTable);