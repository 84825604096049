import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: tableField => ({
        width: '100%',
        marginTop: tableField ? 0 : 30,
    }),
});

const FormTextField = ({ name, label, hideText, tableField, onBlur, onChange, ...props }) => {
    const classes = useStyles(!!tableField);
    const { t } = useTranslation();
    const { register, errors } = useFormContext();

    const translate = useCallback(key => {
        if (!key) return;
        if (typeof key === 'string') return t(key);
        return t(key.key, key.values);
    }, [t]);

    return (
        <TextField
            type={hideText ? 'password' : 'text'}
            className={classes.root}
            name={name}
            label={label}
            inputRef={register}
            error={errors[name] && Boolean(errors[name])}
            helperText={errors[name] && translate(errors[name].message)}
            onBlur={onBlur}
            onChange={onChange}
            {...props}
        />
    );
}

export default FormTextField;