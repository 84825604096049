import { Box, FilledInput, IconButton, InputAdornment, TextField, useTheme } from "@material-ui/core"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { deaccentString } from "../../utils/converters";
import ParametersButtonKey from "../parameters/ParametersButtonKey";
import { MdAdd } from 'react-icons/md'
import AppDialog from "../dialogs/AppDialog";
import { FormProvider, useForm } from "react-hook-form";
import FormTextField from "../form/FormTextField";
import { Autocomplete } from "@material-ui/lab";


const GroupsExplorer = ({keys, focusedKey, onKeyClick, updatePermissionsGroup, permList}) => {

    const [ filter, setFilter ] = useState('');
    const { t } = useTranslation();
    const palette = useTheme().palette;
    const [openDialog,setOpenDialog] = useState(false);
    const [permission, setPermission] = useState('');

    const filteredKeys = keys ? keys.filter(key => {
        const treatedFilter = deaccentString(filter.toLowerCase());
        if(!treatedFilter)return true;
        if(key.includes(treatedFilter)) return true;
        return false;
    }) : [];

    const form = useForm({
        defaultValues:{
            group:'',
            permission:''
        }
    })

    const handleGroupCreate = (values) => {
        if(values.group !== "" && values.permission !== ""){
            let payload = {
                group:values.group,
                permissionsGroup:[
                    permission.name
                ]
            }
            updatePermissionsGroup(payload).then(()=>{
                setOpenDialog(false);
            });
        }
    }

    return (
        <>
            <FilledInput
                id="parameters-searchbar"
                inputProps={{ 
                    role: 'searchbox'
                }}
                name="parameters-searchbar"
                placeholder={t('translate.search', 'Search')}
                value={filter}
                onChange={event => setFilter(event.target.value)}
                fullWidth
                disableUnderline
                className="parameters-explorer-input"
                endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{setOpenDialog(true)}}
                      >
                        <MdAdd style={{color:palette.primary.main}}/>
                      </IconButton>
                    </InputAdornment>
                }
            />
            <Box className="parameters-explorer-list">
                {
                    filteredKeys.map((key,index) => (
                        <ParametersButtonKey
                        key={key}
                        keyName={key}
                        focused={focusedKey === index ? true : false}
                        onClick={()=>onKeyClick(index)}
                        />
                    ))
                }
            </Box>
            <AppDialog
                open={openDialog}
                setOpen={setOpenDialog}
                title="Add new Group"
                okLabel="Create"
                onOk={form.handleSubmit(handleGroupCreate)}
                onCancel={()=>setOpenDialog(false)}
                keepOpenAfterOk
                fullWidth={true}
            >
                <FormProvider {...form}>
                    <FormTextField
                        label="Group Name"
                        name="group"
                        style={{marginBottom:"30px"}}
                    />
                    <Autocomplete
                        style={{width:"100%",marginRight:"10px"}}
                        value={permission}
                        options={permList.filter(e=>true).sort((a,b)=>a.name.localeCompare(b.name))}
                        getOptionLabel={(option) => (option.name)}
                        renderOption={(props,option) => {
                            return(<Box display="flex" width="100%" flexDirection="row" justifyContent="space-between"><Box style={{whiteSpace:"nowrap"}}marginRight="20px">{props.name}</Box><Box whiteSpace="nowrap">{props.comment}</Box></Box>)
                        }}
                        onChange={(e,s)=>setPermission(s)}
                        renderInput={(params) => <TextField {...params} label="Permission" />}
                    />
                </FormProvider>
            </AppDialog>
        </>
    );
}

export default GroupsExplorer;