import { useContext, useCallback } from 'react';

import { Redirect, Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import UserContext from '../context/user';
import SnackbarContext from '../context/snackbar';
import yup from '../services/yup';
import axios from '../services/axios';
import FormTextField from '../components/form/FormTextField';
import useYupResolver from '../hooks/useYupResolver';
import { handleResponse } from '../utils/http';
import { connect, mapStateToProps, mapDispatchToProps } from '../store/dispatchers';
import { setCookie } from '../utils/cookies';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

const Login = ({fetchRoles, fetchKeywords}) => {
    const { t } = useTranslation();
    const { user, updateUser } = useContext(UserContext);
    const { openErrorSnackbar } = useContext(SnackbarContext);

    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const handleUnhandledError = useCallback((message, value) => {
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
    }, [ t, openErrorSnackbar ]);

    

    const handleSubmit = useCallback(async (values) => {
        const handleSuccess = (user) => {
            updateUser(user);
            fetchRoles();
            fetchKeywords();
        }
        const response = await axios.post('/login', {
            ...values,
            login_type: 'email',
        });
        if(response.data.__session)setCookie("__session",response.data.__session,10);
        handleResponse(response.data, form, handleSuccess, handleUnhandledError);
    }, [ form, updateUser, handleUnhandledError, fetchRoles]);

    if(user) {
        return <Redirect to="/"/>
    }

    return (
        <Box style={{ display: 'flex', height: '100vh', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography component="h1" variant="h4" style={{ marginBottom: 20 }}>Login</Typography>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)} style={{ display: 'flex', flexDirection: 'column', width: 600 }}>
                    <FormTextField
                        name="email"
                        label={t('forms.fields.user.email.label', 'Email')}
                    />
                    <FormTextField
                        name="password"
                        label={t('forms.fields.user.password.label', 'Password')}
                        hideText
                    />
                    <Link component={RouterLink} to="/recover-password" style={{ marginTop: 20, marginBottom: 20, fontWeight: 500 }}>
                        Forgot your password ?
                    </Link>
                    <Button variant="contained" color="primary" type="submit">
                        { t('button.login', 'Login') }
                    </Button>
                </form>
            </FormProvider>
        </Box>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);