import { useContext, useState } from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { FaUserSecret, FaSignOutAlt } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';

import UserContext from '../../context/user';
import IncarnationDialog from '../incarnation/IncarnationDialog';
import { setCookie } from'../../utils/cookies';
import { useHistory } from "react-router";

import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers'


const useStyles = makeStyles(theme => ({
    button: open => ({
        backgroundColor: open ? theme.palette.admin.navbar.dark : theme.palette.admin.navbar.dark,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        zIndex:-1
    }),
    options: {
        boxShadow: theme.shadows[4],
    },
    optionTextColor: {
        color: theme.palette.common.black,
    },
}));

const UserDropdown = ({fetchRoles}) => {
    const { user, incarnator, incarnateUser, updateUser } = useContext(UserContext);
    const [ open, setOpen ] = useState(false);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const classes = useStyles(open);
    const { t }= useTranslation();
    const history = useHistory();

    const handleIncarnationClick = () => {
        if(incarnator) {
            updateUser(incarnator);
            fetchRoles();
        } else {
            setDialogOpen(true);
        }
    };

    const handleLogoutClick = () => {
        updateUser(null);
        //TODO CLEAR COOCKIE
        setCookie("__session",null,-1);
    }

    const handleAccountClick = () => {
        history.push("/account");
    }

    const handleDialogClose = incarnated => {
        setDialogOpen(false);
        if(incarnated) {
            incarnateUser(incarnated);
            fetchRoles();
        }
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => open && setOpen(false)}>
                <Box className="navbar-dropdown cursor-pointer">
                    <Box className={`navbar-user-dropdown-button ${classes.button}`} onClick={() => setOpen(!open)}>
                        { incarnator &&
                            <>
                                <Box component="span">
                                    { `${incarnator.first_name} ${incarnator.last_name}` }
                                </Box>
                                { ` ${t('incarnation.as', 'as')} ` }
                            </>
                        }
                        <Box component="span">
                            { `${user.first_name} ${user.last_name}` }
                        </Box>
                    </Box>
                    { open &&
                        <List component="nav" className={`navbar-dropdown-items ${classes.options}`} data-testid="user-dropdown">
                            { user.is_super_admin &&
                                <ListItem button className={classes.optionTextColor} onClick={handleIncarnationClick}>
                                    <ListItemIcon>
                                        <FaUserSecret className={classes.optionTextColor}/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t('navbar.dropdown.options.incarnate-user', 'Incarnate a user') }/>
                                </ListItem>
                            }
                            { incarnator &&
                                <ListItem button className={classes.optionTextColor} onClick={handleIncarnationClick}>
                                    <ListItemIcon>
                                        <FaUserSecret className={classes.optionTextColor}/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t('navbar.dropdown.options.incarnate-user-stop', 'Stop incarnating this user') }/>
                                </ListItem>
                            }
                            {
                                <ListItem button className={classes.optionTextColor} onClick={handleAccountClick}>
                                    <ListItemIcon>
                                        <MdSettings className={classes.optionTextColor}/>
                                    </ListItemIcon>
                                    <ListItemText primary={ t('navbar.dropdown.options.account', 'Account settings') }/>
                                </ListItem>
                            }
                            {
                                <ListItem button className={classes.optionTextColor} onClick={handleLogoutClick}>
                                    <ListItemIcon>
                                        <FaSignOutAlt className={classes.optionTextColor}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Logout"/>
                                </ListItem>
                            }
                            
                        </List>
                    }
                </Box>
            </ClickAwayListener>
            <IncarnationDialog open={dialogOpen} onClose={handleDialogClose}/>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);