import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

// TODO: handle request errors (maybe in the axios service as an interceptor)
const fetchRoles = createAsyncThunk(
    'roles/fetchRoles',
    async () => {
        const response = await axios.get('/permissions/roles_by_user');
        return response.data;
    },
);

export const rolesMethods = {
    fetchRoles
}

const rolesSlice = createSlice({
    name: 'roles',
    initialState: [],
    extraReducers: {
        [fetchRoles.fulfilled]: (_state, action) => action.payload,
    },
});

export default rolesSlice;