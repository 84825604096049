import { useState, useEffect } from 'react';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FaPalette } from 'react-icons/fa';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import  UseStateWithLocalStorage  from '../../utils/useLocalStorage';


const ThemeToggler = ({ theme, setTheme }) => {
    const palette = useTheme().palette;
    const [checked, setChecked] = UseStateWithLocalStorage("themeSelectionToggle",false)
    const [themSelected,setThemeSelected] = UseStateWithLocalStorage("themeSelected","main")
    

    const toggleTheme = (event) => {
        const computedSelectedTheme = event.target.checked ? "classic" : "main"
        setChecked(event.target.checked);
        setThemeSelected(computedSelectedTheme)
        setTheme(computedSelectedTheme)
    };

    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    return (
        <>
            <FormControl>
                <FormControlLabel
                    labelPlacement='start'
                    control={
                        <Switch checked={checked} onChange={toggleTheme} />
                    }
                    label={<FaPalette style={{marginTop:"6px"}} color={palette.secondary.main} />}
                />
            </FormControl>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeToggler);