import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useYupResolver from '../../hooks/useYupResolver';
import { temlplatePdfSchema as schema } from '../../services/yup';
import { FormProvider, useForm } from 'react-hook-form';
import FormSelect from '../form/FormSelect';
import { useTranslation } from 'react-i18next';
import { connect, mapStateToProps , mapDispatchToProps} from '../../store/dispatchers';

const TemplateFormTypeDialog = ({ open , setOpen, parameters , createTemplatePdf , template}) => {
    const { t } = useTranslation();

    const [filteredParameters, setFilterdParameters] = useState([]);

    useEffect(()=>{
        let templateKeys = template.map(a=>a.template_type);
        //console.log(templateKeys)
        let parametersFilters = parameters.template_class ? [...parameters.template_class].filter(item=>!templateKeys.includes(item)) : [];
        setFilterdParameters(parametersFilters)
    },[parameters,template])

    const resolver = useYupResolver(schema);
    const form = useForm({
        resolver,
        defaultValues: {
            template_type: ''
        },
    });

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = (formData) => {
    createTemplatePdf({
        ...formData,
        template_class:"template",
        header:"",
        footer:"",
        body:""
    }).then(()=>{
        setOpen(false);
    })
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('template.dialog.title', 'Add new template type')}</DialogTitle>
        <DialogContent>
        {filteredParameters.length === 0 ? t('template.dialog.no_type_available','No new type of template available') : <FormProvider {...form}>
            <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormSelect
                            name="template_type"
                            label={t('forms.fields.template.type.label', 'Template type')}
                            options={filteredParameters}
                            mapOptionToText={val=>t('parameters.template_class.'+val)}
                        />
            </form>
        </FormProvider>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
          {filteredParameters.length === 0 ? null :  <Button onClick={form.handleSubmit(handleCreate)} color="primary">
          {t('button.create',"Create")}
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(TemplateFormTypeDialog)