import { useContext, useEffect, useState } from 'react';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import FoldersCreate from '../components/folders/FoldersCreate';
import FoldersExplorer from '../components/folders/FoldersExplorer';
import FoldersConsult from '../components/folders/FoldersConsult';
import FoldersEdit from '../components/folders/FoldersEdit';

import SubfoldersCreate from '../components/folders/subfolders/SubfoldersCreate';
import SubfoldersConsult from '../components/folders/subfolders/SubfoldersConsult';
import SubfoldersEdit from '../components/folders/subfolders/SubfoldersEdit';


import LoadingFallback from './LoadingFallback';

import InvoiceValidate from '../components/invoice/InvoiceValidate';

import { connect, mapStateToProps, mapDispatchToProps } from '../store/dispatchers';
import UserContext from '../context/user';

const RoutesWithSubfolder = ({ subfolders, fetchSubfolder }) => {
    const { folderId, id } = useParams();
    // eslint-disable-next-line
    const subfolder = subfolders?.find(subfolder => subfolder.id.toString() === id) || {};

    useEffect(() => {
        if(!subfolder.subfolder_budgets && subfolder && subfolder.id) {
            fetchSubfolder(subfolder.id);
        }
    }, [ subfolder, fetchSubfolder ]);

    if(!subfolder) {
        return <Redirect to={'/folders/' + folderId}/>;
    }

    if(!subfolder.subfolder_budgets) {
        // TODO: loading animation here instead of a box
        return <LoadingFallback/>;
    }

    return (
        <Switch>
            <Route exact path="/folders/:folderId/subfolders/:id" component={SubfoldersConsult}/>
            <Route exact path="/folders/:folderId/subfolders/:id/edit" component={SubfoldersEdit}/>
            <Route exact path="/folders/:folderId/subfolders/:id/validateInvoice" component={InvoiceValidate}/>
        </Switch>
    );
};

const RoutesWithSubfolders = ({ folder, fetchSubfolders,fetchWbsAllForFolderId,fetchWbsPlanning, ...props }) => {

    const { hasPermission } = useContext(UserContext);

    useEffect(() => {
        if(!folder.subfolders && hasPermission('subfolder.read')) {
            fetchSubfolders(folder.id);
        }
        
    }, [ folder, fetchSubfolders, hasPermission ]);

    useEffect(()=>{
        if(hasPermission('wbs.read'))fetchWbsAllForFolderId(folder.id).then(wbsList=>{
            if(wbsList.error){
                //not permissions
            }
            else{
                if(wbsList.payload.length !== 0){
                    fetchWbsPlanning(wbsList.payload[0].id)
                }
            }
        })
        // eslint-disable-next-line
    },[])

    /* if(!folder.subfolders) {
        // TODO: loading animation here instead of a box
        return <Box/>;
    } */

    return (
        <Switch>
            <Route exact path="/folders/:id" component={FoldersConsult}/>
            <Route exact path="/folders/:id/subfolders/create" component={SubfoldersCreate}/>
            <Route path="/folders/:folderId/subfolders/:id">
                <RoutesWithSubfolder subfolders={folder.subfolders} {...props}/>
            </Route>
        </Switch>
    );
};

const RoutesWithFolder = connect(mapStateToProps, mapDispatchToProps)(({ folders, ...props }) => {
    const { id } = useParams();
    const folder = folders.find(folder => folder.id.toString() === id);
    const { hasPermission } = useContext(UserContext);

    if(!folder) {
        return <Redirect to="/folders"/>;
    }
    

    return (
        <Switch>
            {hasPermission('folders.update') && <Route exact path="/folders/:id/edit" component={FoldersEdit}/>}
            <Route path="/folders/:folderId">
                <RoutesWithSubfolders folder={folder} {...props}/>
            </Route>
        </Switch>
    );
});

const Folders = ({folders,fetchFolders, fetchCompanies, fetchUsersSimple}) => {

    const [foldersIsEmpty,setFoldersIsEmpty] = useState(true);
    const { hasPermission } = useContext(UserContext);

    useEffect(()=>{
        // if(folders.length !== 0)setFoldersIsEmpty(false);
        Promise.all([
            fetchFolders(),
            fetchCompanies(),
            fetchUsersSimple(),
        ]).then(()=> {
            setFoldersIsEmpty(false);
        });
        // eslint-disable-next-line
    }, []);

    if(foldersIsEmpty)return (
        <LoadingFallback/>
    )

    return (
        <Box className="page">
            <Switch>
                {hasPermission('folders.read') && <Route exact path="/folders" component={FoldersExplorer}/>}
                {hasPermission('folders.write') && <Route exact path="/folders/create" component={FoldersCreate}/>}
                {hasPermission('folders.read') && <Route path="/folders/:id" component={RoutesWithFolder}/>}
            </Switch>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Folders);