import { Box } from '@material-ui/core';

import BannerCard from '../components/other/BannerCard';

const Other = () => {
    return (
        <Box className="page">
            <Box className="cards-row">
                <BannerCard/>
                <Box flex="1"/>
                <Box flex="1"/>
                <Box flex="1"/>
            </Box>
        </Box>
    );
};

export default Other;