import { FormControlLabel, Switch, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import { MdDelete } from 'react-icons/md';

const DialogTimeSheet = ({ open , setOpen,fetchSubFolderReload, userBookingFiltered, users, wbsPlanning ,editUserBooking, editInvoicingForecastDetail, invoiceForecastDetailId, parametersNumerical}) => {
    const { t } = useTranslation();
    const [userBookings,setUserBookings] = useState([]);

    useEffect(()=>{
        setUserBookings(userBookingFiltered);
    },[userBookingFiltered])


    const handleClose = () => {
        setOpen(false);
    }; 

    const editInvoiced = (id,invoiced,willNotInvoiced) => {
        let inv = invoiced;
        let nInv = willNotInvoiced;
        if(!invoiced && nInv)nInv = false;
        inv = !inv;

        let userBookingsCopy = [...userBookings]
        let index = userBookingsCopy.map(u=>u.id).indexOf(id);
        let ubook = {...userBookingsCopy[index]};
        ubook.invoiced = inv;
        ubook.will_not_invoice = nInv;
        userBookingsCopy[index] = ubook
        setUserBookings(userBookingsCopy);

        editUserBooking({
            id:id,
            userBooking:{
                invoiced:inv,
                will_not_invoice:nInv
            }
        })
    }

    const editWillNotInvoiced = (id,invoiced,willNotInvoiced) => {
        let inv = invoiced;
        let nInv = willNotInvoiced;
        if(invoiced && !nInv)inv = false;
        nInv = !nInv;

        let userBookingsCopy = [...userBookings]
        let index = userBookingsCopy.map(u=>u.id).indexOf(id);
        let ubook = {...userBookingsCopy[index]};
        ubook.invoiced = inv;
        ubook.will_not_invoice = nInv;
        userBookingsCopy[index] = ubook
        setUserBookings(userBookingsCopy);

        editUserBooking({
            id:id,
            userBooking:{
                invoiced:inv,
                will_not_invoice:nInv
            }
        })
    }

    const computeInvoingForecastDetail = () => {
        let computedInvoice = 0;
        userBookings.forEach((userBook)=>{
            if(userBook.invoiced){
                //get user
                let userFound = users.find(e=>e.id === userBook.user_id);
                let cost = 10;
                if(userBook.booking_cost_per_block)cost = userBook.booking_cost_per_block;
                else {
                    if(userFound.user_user_extra_info){
                        let paramCost = parametersNumerical.find(e=>e.class === userFound.user_user_extra_info.cost_class && e.type === userFound.user_user_extra_info.cost_type);
                        if(paramCost){
                            cost = paramCost.numerical_value
                        }
                    }
                }
                computedInvoice += (userBook.number_blocks * cost)
                editUserBooking({
                    id:userBook.id,
                    userBooking:{
                        invoicing_forecast_detail_id:invoiceForecastDetailId,
                    }
                })
            }
        })
        if(computedInvoice !== 0 )
        
        editInvoicingForecastDetail({
            id:invoiceForecastDetailId,
            invoicingForecastDetails:{
                amount:computedInvoice
            }
        }).then(()=>fetchSubFolderReload())
        else handleClose()
    }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
        <DialogTitle id="form-dialog-title">{t('folder.subfolder.invoice.edit.timesheet', 'Timesheets')}</DialogTitle>
        <DialogContent>
            <Table style={{width:"100%"}} className="invoice-forecast-list-table">
                <TableBody>
                    {userBookings.map(userBooking=>{
                        return (
                            <TableRow key={userBooking.id}>
                                <TableCell>
                                    <FormControlLabel
                                        labelPlacement="top"
                                        control={
                                            <Switch
                                                checked={userBooking.invoiced}
                                                color="primary"
                                                label="test"
                                                onChange={(event)=>editInvoiced(userBooking.id,userBooking.invoiced,userBooking.will_not_invoice)}
                                            />
                                        }
                                        label="A facturer"
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        labelPlacement="top"
                                        control={
                                            <Switch
                                                checked={userBooking.will_not_invoice}
                                                color="primary"
                                                label="test"
                                                onChange={(event)=>editWillNotInvoiced(userBooking.id,userBooking.invoiced,userBooking.will_not_invoice)}
                                            />
                                        }
                                        label="A ne pas facturer"
                                    />
                                </TableCell>
                                <TableCell>{userBooking.date}</TableCell>
                                <TableCell>{users.find(e=>e.id === userBooking.user_id).first_name} {users.find(e=>e.id === userBooking.user_id).last_name}</TableCell>
                                <TableCell>{wbsPlanning.find(e=>e.id === userBooking.wbs_task_id).name}</TableCell>
                                <TableCell>{userBooking.number_blocks} decidags</TableCell>
                                <TableCell><MdDelete/></TableCell>
                            </TableRow>
                        )
                    })}
                    
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
          <Button onClick={()=>computeInvoingForecastDetail()} color="primary">
          {t('button.compute',"Compute")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(DialogTimeSheet)