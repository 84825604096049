import { useState, useCallback, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import axios from '../../services/axios';

const useStyles = makeStyles({
    root: tableField => ({
        width: '100%',
        marginTop: tableField ? 0 : 30,
    }),
});

const FormAddressAutocomplete = ({
    name,
    sessionTokenName,
    descriptionName,        // Optional
    placeIdName,            // Optional
    isStreetAddressName,    // Optional
    label,
    tableField = false,
}) => {
    const classes = useStyles(tableField);
    const { t, i18n: { language } } = useTranslation();
    const { control, errors, setValue } = useFormContext();
    const [ sessionToken, setSessionToken ] = useState(uuid());
    const [ predictions, setPredictions ] = useState([]);
    const [ timer, setTimer ] = useState(null);
    const [ sessionTokenInvalid, setSessionTokenInvalid ] = useState(false);

    const translate = useCallback(key => {
        if(!key) return;
        if(typeof key === 'string') return t(key);
        return t(key.key, key.values);
    }, [ t ]);

    useEffect(() => {
        const token = uuid();
        setSessionToken(token);
        setValue(sessionTokenName, token);
    }, [ sessionTokenName, setSessionToken, setValue ]);

    const handleChange = useCallback((value, reason) => {
        if(reason === 'blur') return;
        setSessionTokenInvalid(true);
        if(reason === 'select-option') {
            if(placeIdName) setValue(placeIdName, value.place_id);
            if(isStreetAddressName) setValue(isStreetAddressName, value.types.includes('street_address') || value.types.includes('premise'));
            if(descriptionName) setValue(descriptionName, value.description)
        } else {
            if(placeIdName) setValue(placeIdName, null);
            if(isStreetAddressName) setValue(isStreetAddressName, true);
            if(descriptionName) setValue(descriptionName, null);
        }
    }, [ placeIdName, isStreetAddressName, descriptionName, setSessionTokenInvalid, setValue ]);

    const handleInputChange = useCallback((value, reason, onChange) => {
        onChange(value);
        if(sessionTokenInvalid) {
            const newSessionToken = uuid();
            setSessionToken(newSessionToken);
            setValue(sessionTokenName, newSessionToken);
            setSessionTokenInvalid(false);
        }
        if(placeIdName) setValue(placeIdName, null);
        if(descriptionName) setValue(descriptionName, null);
        if(isStreetAddressName) setValue(isStreetAddressName, value.length === 0);
        if(timer) {
            clearTimeout(timer);
        }
        if(value.length >= 2 && reason === 'input') {
            setTimer(setTimeout(async () => {
                const result = await axios.get(`/address/search/${value}`, {
                    params: {
                        session_token: sessionToken,
                        language,
                    },
                });
                if(result.data.status === "error"){
                    //throw error
                }
                else{
                    setPredictions(result.data.value);
                }
            }, 500));
        }
    }, [ sessionToken, sessionTokenInvalid, language, timer, placeIdName, isStreetAddressName, sessionTokenName, descriptionName, setTimer, setSessionToken, setSessionTokenInvalid, setValue ]);

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={({ onChange, value }) => (
                    <Autocomplete
                        inputValue={value ? value : ''}
                        className={classes.root}
                        options={predictions}
                        filterOptions={options => options}
                        getOptionLabel={option => option.description}
                        onChange={(_event, value, reason) => handleChange(value, reason)}
                        onInputChange={(_event, value, reason) => handleInputChange(value, reason, onChange)}
                        freeSolo
                        renderInput={params => (
                            <TextField
                                { ...params }
                                label={label}
                                error={(errors[name] && Boolean(errors[name])) || (isStreetAddressName && errors[isStreetAddressName] && Boolean(errors[isStreetAddressName]))}
                                helperText={(errors[name] && translate(errors[name].message)) || (isStreetAddressName && errors[isStreetAddressName] && translate(errors[isStreetAddressName].message))}
                            />
                        )}
                    />
                )}
            />
            { placeIdName && (
                <Controller control={control} name={placeIdName}/>
            ) }
            { isStreetAddressName && (
                <Controller control={control} name={isStreetAddressName}/>
            ) }
            { descriptionName && (
                <Controller control={control} name={descriptionName}/>
            ) }
            <Controller control={control} name={sessionTokenName}/>
        </>
    );
}

export default FormAddressAutocomplete;