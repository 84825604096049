import { useEffect, useMemo, useContext, useState } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import OAuth from '../services/oauth';
import LoadingFallback from './LoadingFallback';
import ResponseStatus from '../enums/ResponseStatus';
import SnackbarContext from '../context/snackbar';
import { connect, mapStateToProps, mapDispatchToProps } from '../store/dispatchers';

const Callback = ({ setThirdPartyState }) => {
    const { t } = useTranslation();
    const [ { state, code } ] = useQueryParams({
        state: StringParam,
        code: StringParam,
    });
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);
    const oauth = useMemo(() => OAuth.get(state), [ state ]);
    const [ oauthDone, setOauthDone ] = useState(false);

    useEffect(() => {
        if(oauth) {
            oauth.getToken(code).then(response => {
                if(response.status === ResponseStatus.SUCCESS) {
                    setThirdPartyState(response.value);
                    openSuccessSnackbar(t('success.oauth', 'Successfully authenticated to {{service}}', { service: Object.keys(response.value)[0] }));
                } else {
                    console.log(response.value);
                    openErrorSnackbar(t('error.' + response.value.error, response.value.message));
                }
                setOauthDone(true);
            });
        }
    }, [ t, oauth, code, setOauthDone, setThirdPartyState, openSuccessSnackbar, openErrorSnackbar ]);

    if(!oauth || oauthDone) {
        return (
            <Redirect to="/third-party-services"/>
        );
    }

    return (
        <LoadingFallback/>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Callback);