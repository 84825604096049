import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormDatePicker from '../form/FormDatePicker';
import FormTextField from '../form/FormTextField';
import moment from 'moment';
const DialogEditInvoice = ({ open , setOpen, editAction, editObject,subfolder,invoiceEmittedId,handleEditDoc}) => {
    const { t } = useTranslation();
    const [value,setValue] = useState(null);

    
    const form = useForm({    
      defaultValues:{
          memo:'',
          comment:'',
          number:null,
          issue_date: null,
          term_date: null,
          payment_date: null,
      }
  })


    useEffect(()=>{
      if(editObject){
        setValue(editObject);
            form.reset({
              memo:editObject ? editObject.memo : '',
              comment:editObject ? editObject.comment : '',
              number:editObject ? editObject.number : '',
              issue_date:editObject ? editObject.issue_date : '',
              payment_date:editObject ? editObject.payment_date : '',
              term_date:editObject ?  moment(editObject.issue_date).add(30,'days') : '',
              payment_date:editObject ? editObject.payment_date : '',

            })
        }
        // eslint-disable-next-line
    },[editObject])


    const handleClose = () => {
        setOpen(false);
    };
  
    const handleValidate = (val) => {
      editAction({
        id:value.id,
        data:{
          memo:val.memo,
          comment:val.comment,
          number:val.number,
          issue_date:val.issue_date,
          term_date:val.term_date,
          payment_date:val.payment_date
        }
      })
    }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('folder.subfolder.invoice.edit.titleEdit', 'Edit invoice')}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
              {value ? <Box display="flex" flexDirection="column">
                <FormTextField label="Description" name="memo"/>
                <FormTextField label="number" name="number"/>
                <FormTextField label="commentaire interne" name="comment"/>
                <FormDatePicker label="Issue date" name="issue_date"/>
                <FormDatePicker label="Term date" name="term_date"/>
                <FormDatePicker label="Payment date" name="payment_date"/>
                {invoiceEmittedId && (<Button onClick={handleEditDoc} style={{marginTop:"20px"}} variant="outlined">Edit document</Button>)}
              </Box>:null}
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
          <Button onClick={form.handleSubmit(handleValidate)} color="primary" variant="contained">
          {t('button.save',"Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogEditInvoice