import { Box, Button, FormLabel, TextField } from '@material-ui/core';
import { useContext, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SnackbarContext from '../context/snackbar';
import UserContext from '../context/user';
import { mapDispatchToProps, mapStateToProps,connect } from '../store/dispatchers';
import axios from '../services/axios';

const Account = ({editUserAccount}) => {

    const {user, updateUser} = useContext(UserContext);
    const fileRef = useRef(null);
    const [logo, setLogo] = useState(user.user_accounts[0]?.account?.invoice_logo || null);
    const [apiKey,setApiKey] = useState(user.user_accounts[0]?.account?.googl_api_key || null);
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

    const form = useForm({
        defaultValues: {
            invoice_logo: user.user_accounts[0]?.account?.invoice_logo || null,
            googl_api_key: user.user_accounts[0]?.account?.googl_api_key || null,
        }
    })

    const handleChangeImg = (val) => {
        const file = val.target.files[0];
        const reader =  new FileReader()
        reader.readAsDataURL(file);
        reader.onload = () => {
            setLogo(reader.result);
        }
    }

    const handleSubmit = () => {
        editUserAccount({
            id:user.user_accounts[0]?.account_id,
            data:{
                invoice_logo:logo,
                googl_api_key:apiKey
            }
        }).then(()=>{
            openSuccessSnackbar('Saved');
            axios.post('/auth').then(res=>{
                updateUser(res.data.value)
            })
        })
    }

    return (<Box display="flex" flexDirection="column">
        <FormProvider {...form}>
            <form>
                <FormLabel>Logo image (jpg,png,svg)</FormLabel><br/>
                <input ref={fileRef} name="logo" type="file" onChange={(e)=>handleChangeImg(e)} accept="image/jpeg, image/png, .svg"/>
                <Box padding="10px"><img src={logo} alt="" style={{width:"100%"}}/></Box>
                {/* <FormLabel>Google api key</FormLabel><br/> */}
                {/* <TextField value={apiKey} onChange={(e)=>setApiKey(e.target.value)} type="text" name="googl_api_key" /><br/><br/> */}
                <Button variant="contained" color="primary" onClick={()=>handleSubmit()}>Save</Button>
            </form>
        </FormProvider>
    </Box>)
}

export default connect(mapStateToProps,mapDispatchToProps)(Account)