import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchNotificationsAll = createAsyncThunk(
    'notificationsAll/fetchNotificationsAll',
    async () => {
        const response = await axios.get('/notifications/all');
        return response;
    },
);

const notificationsAllSlice = createSlice({
    name: 'notificationsAll',
    initialState: [],
    extraReducers: {
        [fetchNotificationsAll.fulfilled]: (_state, action) => action.payload.data,
    },
});

export const notificationsAllMethods = {
    fetchNotificationsAll
};

export default notificationsAllSlice;