import { useMemo, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Card from '../card/Card';
import { connect, mapStateToProps } from '../../store/dispatchers';

const useStyles = makeStyles(theme => ({
    expiryDate: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const ThirdPartyCard = ({ oauth, thirdParties }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [ currentTime, setCurrentTime ] = useState(Date.now());

    const expiryDate = useMemo(() => new Date(thirdParties[oauth.service]), [ thirdParties, oauth.service ]);
    const isExpired = useMemo(() => isNaN(expiryDate) || expiryDate <= currentTime, [ expiryDate, currentTime ]);

    useEffect(() => {
        if(thirdParties[oauth.service]) {
            let timeout = setTimeout(() => {
                setCurrentTime(Date.now());
                clearTimeout(timeout);
            }, Math.abs(Date.now() - expiryDate));
        }
    }, [ expiryDate, thirdParties, oauth.service, setCurrentTime ]);

    return (
        <Card
            title={t('oauth.' + oauth.service + '.title', oauth.service)}
            explanation={t('oauth.' + oauth.service + '.description')}
            doneLabel={t('button.connect', 'Connect')}
            doneDisabled={!isExpired}
            onDoneClick={() => oauth.authenticate()}
        >
            <Typography component="p" variant="body1" color="textPrimary">
                { t('oauth.card.expiry-date', 'Expiry Date: ') }
                <Typography component="span" variant="body1" color={isExpired ? 'error' : 'primary'} className={classes.expiryDate}>
                    { isExpired ? t('oauth.card.expired', 'Expired') : expiryDate.toLocaleString() }
                </Typography>
            </Typography>
        </Card>
    );
}

export default connect(mapStateToProps)(ThirdPartyCard);