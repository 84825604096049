import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchCost = createAsyncThunk(
    'usersCost/fetchCost',
    async (costId) => {
        const response = await axios.get(`/users/costs/getByUserId/${costId}`);
        return response.data;
    },
);

const addCost = createAsyncThunk(
    'usersCost/addCost',
    async (cost, { rejectWithValue }) => {
        const response = await axios.post('/users/costs/create', cost);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editCost = createAsyncThunk(
    'usersCost/editCost',
    async ({ id, user }, { rejectWithValue }) => {
        const response = await axios.put('/users/costs/update/2/' + id, user);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);


const deleteCost = createAsyncThunk(
    'usersCost/deleteCost',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/users/costs/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

export const userCostMethods = {
    fetchCost,
    addCost,
    editCost,
    deleteCost
};

const usersCostSlice = createSlice({
    name: 'usersCost',
    initialState: [],
    extraReducers: {
        [fetchCost.fulfilled]: (_state, action) => action.payload,
        [addCost.fulfilled]: (state, action) => [...state, action.payload.value],
        [editCost.fulfilled]: (state, action) => state.map(user => user.id === action.payload.value.id ? action.payload.value : user),
        [deleteCost.fulfilled]: (state, action) => state.filter(cost => cost.id !== action.meta.arg),
    },
});

export default usersCostSlice;