import axios from 'axios';
import { getCookie } from '../utils/cookies';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

console.log('baseUrl: ', process.env.REACT_APP_API_URL)

instance.interceptors.request.use(config => {
    const incarnatedUserId = sessionStorage.getItem('incarnatedUser');
    if(incarnatedUserId) {
        config.headers = {
            ...config.headers,
            userId: incarnatedUserId,
        };
    }
    const session = getCookie('__session')
    //get coockie natively
    //if no coockie are passed to request add it
    if(session && !config.headers['Authorization']) {
        config.headers = {
            ...config.headers,
            'Authorization': 'Bearer ' + session,
        }
    }
    return config;
});

export default instance;