import { Box, Tab, Tabs } from "@material-ui/core"
import { useEffect, useState } from "react";
import { mapStateToProps, mapDispatchToProps, connect } from '../store/dispatchers';
import { a11yProps, TabPanel } from '../components/navbar/TabsNavigation';
import Permisisons from '../components/Permissions/Roles';
import Groups from '../components/Permissions/Groups';

const Permissions = ({fetchRolesAll,fetchPermList}) => {
    const [tab, setTab] = useState(0);

    const handleChangeTab = (_event, newValue) => {
        setTab(newValue);
    };

    useEffect(()=>{
        fetchRolesAll();
        fetchPermList();
        // eslint-disable-next-line
    },[])
    return(
        <Box>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                className="tab-item"
                style={{marginBottom:20}}
            >    
                <Tab label="Roles" {...a11yProps(0)}/>
                <Tab label="Groupe de permissions" {...a11yProps(1)}/>
            </Tabs>
            <TabPanel value={tab} index={0}>
                <Permisisons/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Groups/>
            </TabPanel>
        </Box>
    )
}

export default connect( mapStateToProps, mapDispatchToProps )(Permissions);