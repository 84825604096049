import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

import { getCookie, setCookie } from '../utils/cookies';
import axios from './axios';

const language = getCookie('lang') || navigator.language.split('-')[0] || 'en';

export const supportedLanguages = [
    'en',
    'fr',
    'nl',
];

export const defaultLanguage = 'en';

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: language,
        supportedLngs: supportedLanguages,
        fallbackLng: defaultLanguage,
        debug: false,
        preload: supportedLanguages,
        // Allows integration of dynamic values into your translations: https://www.i18next.com/translation-function/interpolation
        interpolation: {
            escapeValue: false,
        },
        // returnEmptyString: false,
        saveMissing: true,
        missingKeyHandler: async (langs, _ns, key, fallback) => {
            const lang = langs[0];
            if(defaultLanguage === lang) {
                if(lang && key && fallback) saveTranslation(lang, key, fallback);
            }
        },
        backend: {
            loadPath: lang => process.env.REACT_APP_API_URL + `/translate/${lang}`,
        },
    });

export const changeLanguage = lang => {
    i18next.changeLanguage(lang);
    setCookie('lang', lang, 365);
};

export const saveTranslations = async (key, translations) => {
    for(const [ lang, value ] of Object.entries(translations)) {
        await saveTranslation(lang, key, value, false);
    }
    // NOTE: hack to refresh the navbar
    i18next.changeLanguage(i18next.language || defaultLanguage);
}

export const saveTranslation = async (lang = defaultLanguage, key, value, refresh = true) => {
    i18next.addResource(lang, 'translation', key, value);
    try {
        await axios.post(`/translate/${lang}`, {
            val: value,
            key: key,
        });
        if(refresh) {
            // NOTE: hack to refresh the navbar
            i18next.changeLanguage(i18next.language);
        }
    } catch(error) {
        // TODO: decide how to handle this error
        console.log(error);
    }
};

export default i18next;