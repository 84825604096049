const ErrorMessage = {
    // Generic messages
    /**
     * Some unique fields values already exist in the database.
     * 
     * @example RFormat.error(ErrorMessage.DUPLICATE_FIELDS, [ 'email' ]);
     * @example RFormat.error(ErrorMessage.DUPLICATE_FIELDS, { userLogin: [ 'email' ] });
     */
    DUPLICATE_FIELDS: 'duplicate_fields',
    /**
     * Some fields are missing from the request's body.
     * 
     * @example RFormat.error(ErrorMessage.MISSING_FIELDS, [ 'email', 'password' ]);
     * @example RFormat.error(ErrorMessage.MISSING_FIELDS, { folder: [ 'name' ] });
     */
    MISSING_FIELDS: 'missing_fields',
    /**
     * Some objects are missing from the request's body.
     * 
     * @example RFormat.error(ErrorMessage.MISSING_OBJECTS, [ 'folder', 'keywords' ]);
     */
    MISSING_OBJECTS: 'missing_objects',
    /**
     * The record that's getting modified is already deleted.
     * 
     * @example RFormat.error(ErrorMessage.RECORD_DELETED, { type: 'user', value: 1 });
     */
    RECORD_DELETED: 'record_deleted',
    /** 
     * No record matches with the identifier(s).
     * 
     * @example RFormat.error(ErrorMessage.RECORD_NOT_FOUND, { type: 'user', value: 1 });
     */
    RECORD_NOT_FOUND: 'record_not_found',
    /** 
     * An unexpected error happened during creation of an object.
     * 
     * @example RFormat.error(ErrorMessage.UNEXPECTED_CREATION_ERROR, err);
     */
    UNEXPECTED_CREATION_ERROR: 'unexpected_creation_error',
    /**
     * An unexpected error happened in an external service.
     * 
     * @example RFormat.error(ErrorMessage.UNEXPECTED_EXTERNAL_SERVICE_ERROR, { service: 'mfiles', error: err });
     */
    UNEXPECTED_EXTERNAL_SERVICE_ERROR: 'unexpected_external_service_error',
    /**
     * The type received isn't in the list of possible types.
     * 
     * @example RFormat.error(ErrorMessage.UNKNOWN_TYPE, 'github_oauth');
     */
    UNKNOWN_TYPE: 'unknown_type',
    /**
     * Some IDs should have the same value but are not matching.
     * 
     * @example RFormat.error(ErrorMessage.IDS_NOT_MATCHING, { type: 'wbs', value: [ 1, 5 ] });
     */
    IDS_NOT_MATCHING: 'ids_not_matching',




    // Precise messages
    /**
     * The company isn't deletable because some of its folders are not deleted.
     * 
     * @example RFormat.error(ErrorMessage.COMPANY_WITH_FOLDERS_NOT_DELETABLE, companyId);
     */
    COMPANY_WITH_FOLDERS_NOT_DELETABLE: 'company_with_folders_not_deletable',
    /**
     * The credentials received are invalid or incorrect.
     * 
     * @example RFormat.error(ErrorMessage.INVALID_CREDENTIALS, [ 'email', 'password' ]);
     */
    INVALID_CREDENTIALS: 'invalid_credentials',
    /**
     * The token in the URL parameters is invalid (password reset).
     * 
     * @example RFormat.error(ErrorMessage.INVALID_TOKEN);
     */
    INVALID_TOKEN: 'invalid_token',
    /** 
     * The oauth request is invalid in some way.
     * 
     * @example RFormat.error(ErrorMessage.OAUTH_ERROR, { error: 'invalid_request', message: '...', hint: '...' }),
     */
    OAUTH_ERROR: 'oauth_error',
    /**
     * The user isn't authorized to perform that action.
     * 
     * @example RFormat.error(ErrorMessage.UNAUTHORIZED);
     */
    UNAUTHORIZED: 'unauthorized',
    /**
     * An unexpected error happened during password checking.
     * 
     * @example RFormat.error(ErrorMessage.UNEXPECTED_PASSWORD_CHECKING_ERROR, err);
     */
    UNEXPECTED_PASSWORD_CHECKING_ERROR: 'unexpected_password_checking_error',
    /**
     * An unexpected error happened during password hashing.
     * 
     * @example RFormat.error(ErrorMessage.UNEXPECTED_PASSWORD_HASHING_ERROR, err);
     */
    UNEXPECTED_PASSWORD_HASHING_ERROR: 'unexpected_password_hashing_error',
};

export default ErrorMessage;
