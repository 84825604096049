import { Box, FilledInput, IconButton, InputAdornment, useTheme } from "@material-ui/core"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { deaccentString } from "../../utils/converters";
import ParametersButtonKey from "../parameters/ParametersButtonKey";
import { MdAdd } from 'react-icons/md'
import AppDialog from '../dialogs/AppDialog';
import { FormProvider, useForm } from "react-hook-form";
import FormTextField from "../form/FormTextField";

const RolesExplorer = ({keys, focusedKey, onKeyClick, updateRoles}) => {

    const [ filter, setFilter ] = useState('');
    const [openDialog,setOpenDialog] = useState(false);
    const { t } = useTranslation();
    const palette = useTheme().palette;

    const filteredKeys = keys ? keys.filter(key => {
        const treatedFilter = deaccentString(filter.toLowerCase());
        if(!treatedFilter)return true;
        if(key.includes(treatedFilter)) return true;
        return false;
    }) : [];

    const form = useForm({
        defaultValues:{
            role:''
        }
    })

    const handleRoleCreate = (values) => {
        if(values.role !== ""){
            let payload = {
                role:values.role,
                permissions_group:[
                    "default_permissions"
                ]
            }
            updateRoles(payload).then(()=>{
                setOpenDialog(false);
            });
        }
    }

    return (
        <>
            <FilledInput
                id="parameters-searchbar"
                inputProps={{ 
                    role: 'searchbox'
                }}
                name="parameters-searchbar"
                placeholder={t('translate.search', 'Search')}
                value={filter}
                onChange={event => setFilter(event.target.value)}
                fullWidth
                disableUnderline
                className="parameters-explorer-input"
                endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{setOpenDialog(true)}}
                      >
                        <MdAdd style={{color:palette.primary.main}}/>
                      </IconButton>
                    </InputAdornment>
                }
            />
            <Box className="parameters-explorer-list">
                {
                    filteredKeys.map((key,index) => (
                        <ParametersButtonKey
                        key={key}
                        keyName={key}
                        focused={focusedKey === index ? true : false}
                        onClick={()=>onKeyClick(index)}
                        />
                    ))
                }
            </Box>
            <AppDialog
                open={openDialog}
                setOpen={setOpenDialog}
                title="Add new Role"
                okLabel="Create"
                onOk={form.handleSubmit(handleRoleCreate)}
                onCancel={()=>setOpenDialog(false)}
                keepOpenAfterOk
            >
                <FormProvider {...form}>
                    <FormTextField
                        label="Role Name"
                        name="role"
                    />
                </FormProvider>
            </AppDialog>
        </>
    );
}

export default RolesExplorer;