import { useContext, useEffect, useState } from "react";
//import { useTranslation } from "react-i18next";
import { Box } from '@material-ui/core';
import { useTheme } from "@material-ui/core";
import moment from 'moment'

import PlannerExplorer from "../components/planner/PlannerExplorer";
import PlannerSettings from '../components/planner/PlannerSettings';

import { connect, mapStateToProps, mapDispatchToProps } from '../store/dispatchers';
import UserContext from "../context/user";
import LoadingFallback from "./LoadingFallback";

// TODO: use AdminCard in AdminTranslation
const Planner = ({fetchUserBookingsTaskForUser,userBookings,createUserBooking,deleteUserBooking,updateUserBooking,fetchFolders,fetchWbsPlanning,folders,wbsPlanning,users,fetchSubfolders,editUserBooking,fetchParametersNumerical,parametersNumerical}) => {
    const { user } = useContext(UserContext);
    const palette = useTheme().palette
    //const { t } = useTranslation();
    const [userBookingsList,setUserBookingsList] = useState([]);
    const [dateMonthRange,setDateMonthRange] = useState(moment(new Date()).startOf('week').add(1,'day').toDate());
    const [selectedTask,setSelectedTask] = useState(null);
    const [loadingData,setLoadingData] = useState(true);

    useEffect(()=>{
        Promise.all([
            fetchUserBookingsTaskForUser(),
            fetchFolders(),
            fetchParametersNumerical(),
        ]).then(()=>{
            setLoadingData(false);
        })
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        setUserBookingsList(userBookings);
    },[userBookings,dateMonthRange])

    const addUserBookingToList = (task) => {
        let userBookingCopy = [...userBookingsList];
        userBookingCopy.push(task);
        setUserBookingsList(userBookingCopy);
    }

    if(loadingData) {
        return <LoadingFallback/>
    }

    return (
        <Box className="page">
            <Box display="flex" flexDirection="row" className="cards-row" height="calc(100vh - 114px)" width="100%">
                <Box style={{backgroundColor:palette.admin.main}} width="100%" height="100%" borderRadius="5px" >
                    <PlannerExplorer 
                        userBookings={userBookingsList} 
                        dateMonthRange={dateMonthRange} 
                        setDateMonthRange={(date)=>setDateMonthRange(date)}
                        selectedTask={selectedTask}
                        setSelectedTask={setSelectedTask}
                        fetchFolders={fetchFolders}
                        fetchWbsPlanning={fetchWbsPlanning}
                        user={user}
                        createUserBooking={createUserBooking}
                        wbsPlanning={wbsPlanning}
                        folders={folders}
                        users={users}
                        addUserBookingToList={addUserBookingToList}
                        fetchSubfolders={fetchSubfolders}
                        deleteUserBooking={deleteUserBooking}
                        updateUserBooking={updateUserBooking}
                        editUserBooking={editUserBooking}
                        parametersNumerical={parametersNumerical}
                        fetchUserBookingsTaskForUser={fetchUserBookingsTaskForUser}
                    />
                </Box>
                {/* <Box width="60%" height="100%" borderRadius="5px" style={{backgroundColor:"white"}}>
                    <PlannerSettings
                        dateMonthRange={dateMonthRange}
                        selectedTask={selectedTask}
                        userBookings={userBookingsList}
                        setDateMonthRange={(date)=>setDateMonthRange(date)}
                        createUserBooking={createUserBooking}
                        user={user}
                        deleteUserBooking={deleteUserBooking}
                        updateUserBooking={updateUserBooking}
                        setSelectedTask={setSelectedTask}
                        folders={folders}
                    />
                </Box> */}
            </Box>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Planner);