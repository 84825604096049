import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: ({ height, width, value }) => ({
        height,
        width,
        backgroundSize: 'cover',
        backgroundImage: `url(${value})`, 
    }),
});

const ConsultImageField = ({ height, width, value }) => {
    const classes = useStyles({ height, width, value });

    return (
        <Paper
            className={classes.root}
            elevation={2}
        />
    );
};

export default ConsultImageField;