import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import bannerSlice from './slices/banner';
import companiesSlice from './slices/companies';
import foldersSlice from './slices/folders';
import keywordsSlice from './slices/keywords';
import notificationsSlice from './slices/notifications';
import parametersSlice from './slices/parameters';
import pdfTemplateSlice from './slices/templateReducer';
import rolesSlice from './slices/roles';
import usersSlice from './slices/users';
import wbsSlice from './slices/wbs';
import wbsAllSlice from './slices/wbsAll';
import wbsPlanningSlice from './slices/wbsPlanning';
import invoiceSlice from './slices/invoice';
import invoiceForecastSlice from './slices/invoiceForecast';
import userbookingSlice from './slices/userBookings';
import rolesAllSlice from './slices/rolesAll';
import parametersRelationSlice from './slices/parametersRelations';
import usersCostSlice from './slices/userCost';
import parametersNumericalSlice from './slices/parametersNumerical';
import thirdPartiesSlice from './slices/thirdParties';
import currentInvoiceSlice from './slices/currentInvoice';
import notificationsAllSlice from './slices/notificationsAll';
import permListSlice from './slices/permlist';
import appParamsSlice from './slices/appParams';
import usersSpecificCostSlice from './slices/userSpecificCost';

export default configureStore({
    reducer: {
        banner: bannerSlice.reducer,
        companies: companiesSlice.reducer,
        folders: foldersSlice.reducer,
        keywords: keywordsSlice.reducer,
        notifications: notificationsSlice.reducer,
        parameters: parametersSlice.reducer,
        roles: rolesSlice.reducer,
        template: pdfTemplateSlice.reducer,
        wbs: wbsSlice.reducer,
        users: usersSlice.reducer,
        usersCost: usersCostSlice.reducer,
        wbsAll: wbsAllSlice.reducer,
        wbsPlanning: wbsPlanningSlice.reducer,
        invoices: invoiceSlice.reducer,
        invoicesForecats: invoiceForecastSlice.reducer,
        userBookings: userbookingSlice.reducer,
        rolesAll: rolesAllSlice.reducer,
        parametersRelations: parametersRelationSlice.reducer,
        parametersNumerical: parametersNumericalSlice.reducer,
        thirdParties: thirdPartiesSlice.reducer,
        currentInvoice: currentInvoiceSlice.reducer,
        notificationsAll: notificationsAllSlice.reducer,
        permList: permListSlice.reducer,
        appParams: appParamsSlice.reducer,
        usersSpecificCost:usersSpecificCostSlice.reducer
    },
    middleware: [...getDefaultMiddleware({ immutableCheck: false })]
});