import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: ({hasValue,isClickable}) => ({
        '& .MuiInputBase-root.Mui-disabled': {
            color: hasValue ? theme.palette.text.primary : theme.palette.text.hint,
            fontStyle: hasValue ? 'normal' : 'italic',
            
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: theme.palette.text.primary,
        },
        '& .MuiInputBase-input':{
            cursor:isClickable?'pointer':"default"
        }
    }),
}));

const ConsultTextField = ({
    value,
    startAdornment,
    endAdornment,
    isClickable,
    ...props
}) => {
    const classes = useStyles({hasValue : value != null && value !== '',isClickable});

    const startAdornmentComponent = startAdornment && (
        <InputAdornment position="start">
            { startAdornment }
        </InputAdornment>
    );

    const endAdornmentComponent = endAdornment && (
        <InputAdornment position="end">
            { endAdornment }
        </InputAdornment>
    );

    return (
        <TextField
            className="form-input"
            InputProps={{
                startAdornment: startAdornmentComponent,
                endAdornment: endAdornmentComponent,
                className:isClickable ? 'cursor-pointer' : '',
            }}
            value={value || 'None'}
            classes={classes}
            disabled
            { ...props }
        />
    );
}

export default ConsultTextField;