import { Box, Button, CircularProgress, FormControlLabel, Switch, useTheme } from "@material-ui/core"
import { useCallback, useMemo, useState } from "react";
import Card from '../card/Card'
import ExplorerToolbar from '../explorer/ExplorerToolbar'
import ExplorerTable from '../explorer/ExplorerTable'
import AppDialog from '../dialogs/AppDialog'
import { useTranslation } from "react-i18next";
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';

const UserSubfolderSelectDialog = ({loadingFolders,folders,users,fetchSubfolders,fetchWbsPlanning,wbsPlanning,onTaskSelect}) => {

    const { t } = useTranslation()
    const [selectedFolder,setSelectedFolder] = useState(null);
    const [open,setOpen] = useState(false);
    const [ filteredFolders, setFilteredFolders ] = useState(folders);
    const palette = useTheme().palette;
    const [filters,setFilters] = useState({
        closed:false,
        abandoned:false,
        active:true,
        intermediate:true,
        internal_project:true,
        litigation:true,
        tendering:true,
        transfer:true,
        prospect:true,
        no_subfolder:true
    });

    const [loadingTasks,setLoadingTasks] = useState(false);
    const [subfolders,setSubfolder] = useState([]);

    const mapToRow = useCallback(folder => [
        folder.lead_code,
        folder.code,
        folder.name,
        // eslint-disable-next-line
    ], [ folders,users ]);

    const mapToRowTasks = useCallback(subfolder => [
        selectedFolder?.lead_code+'/'+subfolder.rank,
        subfolder.code,
        subfolder.name,
    ], [selectedFolder]);

    const foldersFields = useMemo(() => folders.map(folder => [
        folder.lead_code,
        folder.code,
        folder.name,
    ]), [ folders ]);

    const columns = [
        { name: t('table.folders.columns.lead_code', 'Code prospect'), width: 20 },
        { name: t('table.folders.columns.code', 'Folder code'), width: 20 },
        { name: t('table.folders.columns.name', 'Name'), width: 60 },
    ];
    const columnsTask = [
        { name: t('table.subfolders.columns.rank_code', 'Code'), width: 30 },
        { name: t('table.subfolders.columns.code', 'Subfolder code'), width: 30 },
        { name: t('table.subfolders.columns.name', 'Name'), width: 30 },
    ];

    const handleConsultClick = (folder) => {
        setSelectedFolder(folder);
        setLoadingTasks(true)
        fetchSubfolders(folder.id).then(subfoldersPayload=>{
            setSubfolder(subfoldersPayload.payload.value);
            setLoadingTasks(false)
        });
    }

    const handleConsultClickTask = (task) => {
        onTaskSelect(task)
    }

    const filter = useCallback(folder => {
        let isToReturn = false;
        if(filters.closed) if(folder.closed_subfolders_count && folder.closed_subfolders_count !== 0)isToReturn = true;
        if(filters.abandoned) if(folder.abandoned_subfolders_count && folder.abandoned_subfolders_count !== 0) isToReturn = true;
        if(filters.active) if(folder.active_subfolders_count && folder.active_subfolders_count !== 0) isToReturn = true;
        if(filters.intermediate) if(folder.intermediate_subfolders_count && folder.intermediate_subfolders_count !== 0) isToReturn = true;
        if(filters.internal_project) if(folder.internal_project_subfolders_count && folder.internal_project_subfolders_count !== 0) isToReturn = true;
        if(filters.litigation) if(folder.litigation_subfolders_count && folder.litigation_subfolders_count !== 0) isToReturn = true;
        if(filters.tendering) if(folder.tendering_subfolders_count && folder.tendering_subfolders_count !== 0) isToReturn = true;
        if(filters.transfer) if(folder.transfer_subfolders_count && folder.transfer_subfolders_count !== 0) isToReturn = true;
        if(filters.prospect) if(folder.prospect_subfolders_count && folder.prospect_subfolders_count !== 0) isToReturn = true;
        if(filters.no_subfolder) if(folder.subfolder_count === 0) isToReturn = true;
        return isToReturn;
    }, [filters]);

    const filtersRender = () =>{
        let items = []
        let filtersCopy = {...filters};
        for(const [key] of Object.entries(filters)){
            items.push(<>
                <FormControlLabel
                    control={<Switch checked={filters[key]} 
                    onChange={(event)=>{filtersCopy[key] = !filtersCopy[key]; setFilters(filtersCopy)}} 
                    />}
                    label={key.replace(/[_]/g,' ')}
                    style={{color:"black"}}
                />
            </>)
        }
        return items;
    }

    return <>
    {
        !loadingFolders ? 
    <>
        { !selectedFolder ? <Box>
            <span>1. Choose a folder</span>
            <Card
                hideButtons
                header={
                    <ExplorerToolbar
                        filter={filter}
                        data={folders}
                        dataFields={foldersFields}
                        createLabel={''}
                        createIcon={null}
                        onCreateClick={null}
                        onFilter={setFilteredFolders}
                    >
                        <Button style={{width:"250px"}} onClick={()=>setOpen(true)}>
                            Set Filters
                        </Button>
                    </ExplorerToolbar>
                }
            >
                <ExplorerTable
                    columns={columns}
                    items={filteredFolders}
                    mapToCellContents={mapToRow}
                    onConsultClick={handleConsultClick}
                    onEditClick={null}
                    onDeleteClick={null}
                />
            </Card>
            <AppDialog
                open={open}
                setOpen={setOpen}
                title={t('dialog.filters.title', 'Filters settings')}
                onCancel={()=>setOpen(false)}
            >
                <Box display="flex" flexDirection="column">
                    {filtersRender()}
                </Box>
            </AppDialog>
        </Box>:
        <Box display="flex" flexDirection="column">
            <span>1. Selected folder : 
                <span style={{color:palette.primary.main}}>{selectedFolder.lead_code} - {selectedFolder.code} - {selectedFolder.name} </span>
                 <span onClick={()=>setSelectedFolder(null)} style={{textDecoration:"underline",cursor:"pointer"}}>Modifier</span>
            </span>
            <span>2. Choose a task</span>
            {loadingTasks ? <Box display="flex" flexDirection="row" justifyContent="center" marginTop="300px" marginBottom="300px">
                <CircularProgress size={50}/>
            </Box> : <Box>
                <Card
                    hideButtons
                    header={null}
                >
                    <ExplorerTable
                        columns={columnsTask}
                        items={subfolders}
                        mapToCellContents={mapToRowTasks}
                        onConsultClick={handleConsultClickTask}
                    />
                </Card>
            </Box>}
        </Box>
        }
    </> : 
    <Box display="flex" flexDirection="row" justifyContent="center" marginTop="300px" marginBottom="300px">
        <CircularProgress size={50}/>
    </Box>
    }</>
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSubfolderSelectDialog);