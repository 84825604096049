/**
 * Min and max values for vat.
 * Used in the util isValidVat.
 *
 * @type {{MIN: number}}
 */
const VAT_RATES = {
    MIN: 0
};

export default VAT_RATES;
