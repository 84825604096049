import { Box, Button, TextField } from "@material-ui/core"
import CardTitle from "../card/CardTitle";
import { MdDelete } from 'react-icons/md';
import { useContext, useEffect, useState } from "react";
import { mapStateToProps, mapDispatchToProps, connect } from '../../store/dispatchers';
import { textToKey, keyToText, deaccentString } from '../../utils/converters';
import { useTranslation } from "react-i18next";
import UserContext from "../../context/user";


const NUM_PARAM = ['employee_cost_category','planner']
const TEXT_PARAM = ['reports']

const ParametersSettings = ({focusedKey,keys,parameters,addParameter,deleteParameter, parametersNumerical,updateParametersNumerical, filter}) => {

    const [inputValue,setInputValue] = useState('');
    const [params,setParams] = useState([]);
    const [inputValues,setInputValues] = useState([]);
    const { t } = useTranslation();
    const { hasPermission } = useContext(UserContext);

    const filteredKeys = keys.filter(key => {
        const treatedFilter = deaccentString(filter.toLowerCase());
        if(!treatedFilter)return true;
        if(key.includes(treatedFilter)) return true;
        return false;
    });

    useEffect(()=>{
        setParams(parameters)
        setInputValue('')
    },[parameters,focusedKey])

    const onClickAddPrameter = () => {
        //transform input Value space to underscore and toLowerCase
        let inputFormated = textToKey(inputValue);
        const newParams = {
            obselete:0,
            class:filteredKeys[focusedKey],
            type:inputFormated
        }
        addParameter(newParams);
    }

    const onClickRemoveparameter = (item) => {
        const parameterToDelete = {
            className:filteredKeys[focusedKey],
            type:item
        }
        deleteParameter(parameterToDelete)
    }

    const renderInput = (item,className=null) => {
        //find parametersNumerical
        let numParams = parametersNumerical.find(param=>NUM_PARAM.includes(param.class) && param.type === item);
        let itemIndex = inputValues.map(e=>e.id).indexOf(numParams.id);
        let foundItem = {}
        if(itemIndex === -1){
            if(!inputValues.map(e=>e.id).includes(numParams.id)){
                let inputValueCopy = [...inputValues]
                inputValueCopy.push(numParams);
                setInputValues(inputValueCopy);
                foundItem = numParams;
            }
        }   
        else{
            foundItem = {...inputValues[itemIndex]}
        }
        return (<>
                <input type="number" onBlur={()=>{handleInputBlur(foundItem)}} onChange={(event)=>handleInputChange(event,foundItem)} value={foundItem.numerical_value} className="parameteres-employee-cost-category-input"></input>
        </>)
    }

    const renderTextInput = (item, className = null) => {
        //find parametersNumerical
        let textParams = parametersNumerical.find(
          (param) => TEXT_PARAM.includes(param.class) && param.type === item
        )
        let itemIndex = inputValues.map((e) => e.id).indexOf(textParams.id)
        let foundItem = {}
        if (itemIndex === -1) {
          if (!inputValues.map((e) => e.id).includes(textParams.id)) {
            let inputValueCopy = [...inputValues]
            inputValueCopy.push(textParams)
            setInputValues(inputValueCopy)
            foundItem = textParams
          }
        } else {
          foundItem = { ...inputValues[itemIndex] }
        }
        return (
          <>
            <input
              type='text'
              onBlur={() => {
                handleInputBlur(foundItem)
              }}
              onChange={(event) => handleInputChange(event, foundItem)}
              value={foundItem.textual_value}
              className='parameteres-employee-cost-category-input'
              style={{width: '100%'}}
            ></input>
          </>
        )
      }

    const handleInputChange = (event,item) =>{
        let inputValuesCopy = [...inputValues];
        let itemIndex = inputValuesCopy.map(e=>e.id).indexOf(item.id);
        if(itemIndex !== -1){
            let inputCopy = {...inputValuesCopy[itemIndex]};
            (item.type === 'powerbi_url') ? inputCopy.textual_value = event.target.value : inputCopy.numerical_value = event.target.value
            inputValuesCopy[itemIndex] = inputCopy
            setInputValues(inputValuesCopy)
        }
    }

    const handleInputBlur =(item)=>{
        if(item.textual_value === '')item.textual_value = null;
        if(item.numerical_value === '')item.numerical_value = null;
        updateParametersNumerical({
            id:item.id,
            data:item
        })
    }
    
    return (
        <>
            { hasPermission('parameters.write') && <><CardTitle>Ajouter un paramètre</CardTitle>
            <Box display="flex" flexDirection="row" className="parameters-settings-add-container">
                <TextField
                    size="small"
                    variant="outlined"
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                />
                <Button className="parameters-settings-add-button" onClick={()=>onClickAddPrameter()} variant="contained" color="primary">Ajouter</Button>
            </Box></>}
            <CardTitle>Liste des paramètres</CardTitle>
            
            <Box className="paramaters-settings-container">
                {params[filteredKeys[focusedKey]]?.map(item=>{
                    return (
                        <Box className="paramaters-settings-item" key={item}>
                            <Box flex={0.34} display="flex" justifyContent="start">{t(`parameters.${filteredKeys[focusedKey]}.${item}`,keyToText(item))}</Box>
                            <Box flex={(keyToText(item) === 'powerbi url') ? 0.66 : 0.33} display="flex" justifyContent="center">
                                {(keyToText(item) === 'powerbi url') ? 
                                TEXT_PARAM.includes(filteredKeys[focusedKey]) ?renderTextInput(item,filteredKeys[focusedKey]):null
                                :
                                NUM_PARAM.includes(filteredKeys[focusedKey]) ?renderInput(item,filteredKeys[focusedKey]):null}
                            </Box>
                            { hasPermission('parameters.delete') && <Box flex={0.33} display="flex" justifyContent="flex-end"><MdDelete onClick={()=>onClickRemoveparameter(item)} className="parameters-settings-icon-delete"/></Box>}
                        </Box>
                    )
                })}
            </Box>
        </>
    )
}

export default connect( mapStateToProps, mapDispatchToProps )(ParametersSettings)