import { TextField, Box, Link, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: (hasValue) => ({
        '& .MuiInputBase-root.Mui-disabled': {
            color: hasValue ? theme.palette.text.primary : theme.palette.text.hint,
            fontStyle: hasValue ? 'normal' : 'italic',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: theme.palette.text.primary,
        },
    }),
    linkLabel: {
        color: theme.palette.text.primary,
    },
}));

const ConsultLinkField = ({
    value,
    label,
    openInNewTab,
    ...props
}) => {
    const classes = useStyles(value != null && value !== '');
    const prefixVal = value && value.indexOf('http') !== 0 ? `http://${value}` : value;
    if(value) {
        return (
            <Box className="form-input">
                <InputLabel shrink={true} className={classes.linkLabel}>
                    { label }
                </InputLabel>
                <Link href={prefixVal} target={openInNewTab ? '_blank' : '_self'}>
                    { prefixVal }
                </Link>
            </Box>
        );
    }

    return (
        <TextField
            className={`form-input ${classes.root}`}
            label={label}
            value="None"
            disabled
            { ...props }
        />
    );
}

export default ConsultLinkField;