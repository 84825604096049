import axios from './axios';

export const getAllUsers = async () => {
    const response = await axios.get('/users/all');
    return response.data;
}

export const getUser = async (id) => {
    const response = await axios.get('/users/' + id);
    return response.data;
}