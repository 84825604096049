import { Box, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, TextField, Typography, withTheme } from "@material-ui/core"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import CardTitle from "../card/CardTitle"
import FormSelect from "../form/FormSelect"
import { IoClose } from 'react-icons/io5';

const RolesSettings = ({focusedKey, keys, roles, groups, theme, updateRoles}) => {

    const [groupsList,setGroupsList] = useState([]);
    const [allgroups,setAllGroups] = useState([]);

    const [selectedPermission, setSelectedPermission] = useState('');

    useEffect(()=>{
        let groupListTemp = roles[keys[focusedKey]]?.permissions_group ? roles[keys[focusedKey]]?.permissions_group : []
        setGroupsList(groupListTemp)
        let groupsListAll = [];

        for(const [key] of Object.entries(groups)){
            if(!groupListTemp.includes(key))
            groupsListAll.push(key);
        }
        setAllGroups(groupsListAll)
        
        // eslint-disable-next-line
    },[focusedKey, roles, groups,keys])

    const handleAddPermissionGroup = () => {
        if(roles[keys[focusedKey]] && selectedPermission){
            let permissionsGroupCopy;
            if(!roles[keys[focusedKey]].permissions_group) permissionsGroupCopy = [selectedPermission]
            else permissionsGroupCopy = [...roles[keys[focusedKey]].permissions_group,selectedPermission]
            let payload = {
                role:keys[focusedKey],
                permissions_group:permissionsGroupCopy
            }
            updateRoles(payload).then(()=>setSelectedPermission(''));
        }
    }

    const handleRemovePermissionGroup = (groupPermissionsKey) => {
        let permissionsGroupCopy = [...roles[keys[focusedKey]].permissions_group];
        //find index of groupPermissionsKey
        let indexOfGroupPermission = permissionsGroupCopy.indexOf(groupPermissionsKey);
        if(indexOfGroupPermission >= 0)permissionsGroupCopy.splice(indexOfGroupPermission,1);
        let payload = {
            role:keys[focusedKey],
            permissions_group:permissionsGroupCopy
        }
        updateRoles(payload);
    }

    return (
        <>
        <CardTitle>Gestion des permissions d'un rôle</CardTitle>

        <Box display="flex" flexDirection="row" justifyContent="space-between" height="100%" marginTop="30px">
            <Box display="flex" flexDirection="column" flex={1} height="100%" >
                <Typography style={{marginBottom:30}} variant="h6">Group de permissions</Typography>
                <Box display="flex" flexDirection="column">
                    <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                        <Select value={selectedPermission} onChange={event=>setSelectedPermission(event.target.value)} style={{width:"70%"}}>
                            {allgroups.map(item=>{
                                return(
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                )
                            })}
                        </Select>
                        <Button onClick={()=>handleAddPermissionGroup()} variant="contained" color="primary" style={{width:"20%"}}>Add</Button>
                    </Box>
                    <List>  
                        {groupsList.map(gl=>{
                            const isDef = gl === "default_permissions"
                            return (
                            <ListItem key={gl}>
                                <ListItemIcon>
                                    {!isDef && <IconButton onClick={()=>handleRemovePermissionGroup(gl)}><IoClose color={theme.palette.error.main}/></IconButton>}
                                </ListItemIcon>
                                <ListItemText primary={gl}/>
                            </ListItem>
                            )}
                        )}
                        {/* {
                            groupsListHerited.map(gl=>{
                                return (
                                <ListItem key={gl}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText style={{color:"grey"}} primary={gl}/>
                                </ListItem>
                                )}
                            )
                        } */}
                    </List>
                </Box>
            </Box>
        </Box>
        </>
    )
}

export default withTheme(RolesSettings);