import { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, TextField, Toolbar, InputAdornment } from '@material-ui/core';
import { withTheme, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { MdSearch } from 'react-icons/md';
import { FaDownload } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { kebabCase } from '../../utils/converters';
import { exportToExcel } from '../../services/xlsx';

const useStyles = makeStyles({
    root: {
        '& > *': {
            '&:first-child': {
                marginLeft: 0,
            },
            marginLeft: 20,
        },
    },
});

/**
 * A toolbar for filtering, exporting and creating data.
 * 
 * @param {object} props - `ExplorerToolbar` properties.
 * @param {(item: any, index: number) => boolean} [props.filter] - A method to filter an item before it is filtered by the value of the searchbar.
 * @param {any[]} props.data - The data used by the toolbar for filtering and exploring.
 * @param {any[][]} props.dataFields - The fields values of each item, used for filtering.
 * @param {string} props.createLabel - The label of the create button.
 * @param {any} props.createIcon - The icon of the create button.
 * @param {() => void} props.onCreateClick - The method called when the create button is clicked.
 * @param {(data: any[]) => void} props.onFilter - The method called with the filtered data.
 * @param {boolean} props.matchMultipleFields - Whether or not the searchbar value should be considered as one filter (false) or more (true).
 * The value is checked against `dataFields` in both cases.
 * @param {string[]} props.excelColumnNames - The column names in the exported Excel file.
 * @param {string} props.excelSheetName - The name and title of the exported Excel file.
 * @param {(item: any) => any[]} props.mapToExcelRow - The method that maps each item to an excel row. The array returned must have the same order
 * as the `excelColumnNames` array.
 */
const ExplorerToolbar = ({
    children,
    filter,
    data,
    dataFields,
    createLabel,
    createIcon,
    onCreateClick,
    onFilter,
    matchMultipleFields,
    excelColumnNames,
    excelSheetName,
    mapToExcelRow,
    theme,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [ filterValue, setFilterValue ] = useState('');
    const [ excelRows, setExcelRows ] = useState(data);
    const kcDataFields = useMemo(() => dataFields.map(fields => fields.map(field => field === null ? field : kebabCase(field))), [ dataFields ]);
    
    const kcFilterValue = useMemo(() => {
        if(matchMultipleFields) {
            return filterValue.split(/ +/).map(part => kebabCase(part));
        }
    
        return filterValue;
    }, [ filterValue, matchMultipleFields ]);
   
    const handleExportClick = useCallback(() => {
        exportToExcel(excelRows, excelColumnNames, excelSheetName);
    }, [ excelRows, excelColumnNames, excelSheetName ]);

    useEffect(() => {
        const filtered = [];
        const rows = [];
        for(let i = 0; i < kcDataFields.length; i++) {
            if(!filter || filter(data[i], i)) {
                if(matchMultipleFields) {
                    if(kcFilterValue.every(part => kcDataFields[i].some(field => field?.includes(part)))) {
                        filtered.push(data[i]);
                        if(mapToExcelRow)rows.push(mapToExcelRow(data[i]));
                    }
                } else {
                    if(kcDataFields[i].some(field => field?.includes(kcFilterValue))) {
                        filtered.push(data[i]);
                        if(mapToExcelRow)rows.push(mapToExcelRow(data[i]));
                    }
                }
            }
        }
        onFilter(filtered);
        setExcelRows(rows);
    }, [ kcDataFields, kcFilterValue, filter, matchMultipleFields, data, onFilter, setExcelRows, mapToExcelRow ]);

    //console.log(filterValue)
    // get first index in excelRows

    return (
        <Toolbar className={classes.root} disableGutters>
            <TextField
                variant="outlined"
                placeholder="Search..."
                value={filterValue}
                onChange={event => setFilterValue(event.target.value)}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <MdSearch size={20} color={theme.palette.text.secondary}/>
                        </InputAdornment>
                    ),
                }}
            />
            { children }
            {mapToExcelRow && <Button
                color="primary"
                variant="contained"
                style={{ height: 56, minWidth: 170 }}
                onClick={handleExportClick}
                startIcon={<FaDownload size={16}/>}
            >
                { t('button.export', 'Export') }
            </Button>}
            {onCreateClick &&
            <Button
                color="primary"
                variant="contained"
                style={{ height: 56, minWidth: 170 }}
                onClick={onCreateClick}
                startIcon={createIcon}
            >
                { createLabel }
            </Button>}
        </Toolbar>
    );
}

ExplorerToolbar.propTypes = {
    filter: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    dataFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
    //createLabel: PropTypes.string.isRequired,
    //createIcon: PropTypes.node.isRequired,
    /* onCreateClick: PropTypes.func, */
    onFilter: PropTypes.func.isRequired,
    matchMultipleFields: PropTypes.bool,
    //excelColumnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    //excelSheetName: PropTypes.string.isRequired,
    //mapToExcelRow: PropTypes.func.isRequired,
}

ExplorerToolbar.defaultProps = {
    matchMultipleFields: true,
}

export default withTheme(ExplorerToolbar);