import { useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { showReportDialog } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import UserContext from '../context/user';

const ErrorFallback = ({ resetError }) => {
    const { user, incarnator } = useContext(UserContext)
    const { t, i18n } = useTranslation();

    const handleBackToHomeClick = () => {
        resetError();
    };

    const handleFillReportClick = () => {
        const filler = incarnator ?? user;
        showReportDialog({
            user: {
                name: filler && `${filler.firstName} ${filler.lastName}`,
                email: filler && filler.email,
            },
            lang: i18n.language,
            labelName: t('forms.fields.full-name.label', 'Full Name'),
            labelEmail: t('forms.fields.email.label', 'Email'),
            labelComments: t('forms.fields.what-happened.label', 'What happened ?'),
            labelSubmit: t('button.submit', 'Submit'),
            labelClose: t('button.close', 'Close'),
            // Sentry doesn't have an onClose callback so we need to add a listener
            // for the close button click event
            onLoad: () => {
                document.querySelector('.sentry-error-embed .close')
                        .addEventListener('click', handleBackToHomeClick);
            },
        });
    };

    return (
        <Box className="error-fallback">
            <Box>
                <Typography component="h1" variant="h2">
                    { t('fallback.error.title', 'Something went wrong...') }
                </Typography>
                <Typography component="h2" variant="body1">
                    { t('fallback.error.sorry', 'We are really sorry but it looks like an error occured.') }
                </Typography>
                <Typography component="h2" variant="body1">
                    { t('fallback.error.possibilities', 'You can click one of the buttons below to get redirected to the website, or to help us fix this error by filling a report.') }
                </Typography>
                <Box className="error-fallback-buttons">
                    <Button variant="contained" color="primary" onClick={handleBackToHomeClick}>
                        { t('button.back-home', 'Back to Home') }
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleFillReportClick}>
                        { t('button.fill-report', 'Fill a report') }
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ErrorFallback;