import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchParameters = createAsyncThunk(
    'parameters/fetchParameters',
    async () => {
        const response = await axios.get('/parameters/all');
        return response.data;
    },
);

const addParameter = createAsyncThunk(
    'parameters/addParameter',
    async (parameter) => {
        const response = await axios.post('/parameters/create', parameter);
        if(response.data.status === 'error') throw response.data.value;
        const res = await axios.get('/parameters/all');
        return res.data;
    },
);

const deleteParameter = createAsyncThunk(
    'parameters/deleteParameter',
    async (data) => {
        const response = await axios.delete(`/parameters/delete/${data.type}/${data.className}`);
        if(response.data.status === 'error') throw response.data.value;
        const res = await axios.get('/parameters/all');
        return res.data;
    },
);

export const parametersMethods = {
    fetchParameters,
    addParameter,
    deleteParameter
}

const parametersSlice = createSlice({
    name: 'parameters',
    initialState: {},
    extraReducers: {
        [fetchParameters.fulfilled]: (_state, action) => action.payload,
        [addParameter.fulfilled]: (_state, action) => action.payload,
        [deleteParameter.fulfilled]: (_state, action) => action.payload,
    },
});

export default parametersSlice;