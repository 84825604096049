import NumberFormat from 'react-number-format';

const FormattedNumber = ({ value, suffix }) => {
    return (
        <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator=" "
            decimalScale={2}
            isNumericString
            suffix={suffix}
        />
    );
}

export default FormattedNumber;