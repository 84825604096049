import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormDatePicker from '../form/FormDatePicker';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import { TextField } from '@material-ui/core';
import SnackbarContext from '../../context/snackbar';
import FormNumberField from '../form/FormNumberField';
import moment from 'moment';
import { useParams } from 'react-router';
const DialogUploadInvoice = ({ open ,goInvoiceList, setOpen, parameters, uploadInvoices,subfolderId, fetchSubfolder,fetchSubfoldersInvoices,fetchInvoices,folders}) => {
    const { openSuccessSnackbar, openWarningSnackbar } = useContext(SnackbarContext);
    const { t } = useTranslation();
    const [amountComputedVat,setAmountComputedVat] = useState(0);
    const [isChangedFromInput,setIsChnagedFromInput] = useState(false);
    let date_now = new Date();
    const form = useForm({
      defaultValues:{
        memo:"",
        invoice_type:'purchase',
        issue_date:date_now,
        term_date: moment(date_now).add(30,'days'),
        number:null,
        amount:0,
        vat_rate:21,
        amount_vat_included:0
      }
    })

    const file = useRef(null)
    const watchAmount = form.watch('amount')
    const watchVat = form.watch('vat_rate')

    const watchAmountVat = form.watch('amount_vat_included')
    const { folderId, id } = useParams();

    const [subfolder,setSubfolder] = useState({});
    const [folder,setFolder] = useState({});
    const [fetchedInvoices,setFetchedInvoices] = useState(false);
    useEffect(()=>{
      fetchInvoices()
    },[watchAmountVat])
    useEffect(()=>{
      let fold =  folders.find(f=>f.id.toString() === folderId);
      setFolder(fold);
      let subfold = fold.subfolders.find(sf=>sf.id.toString() === id);
      setSubfolder(subfold);
    },[folders,folderId,id])

    useEffect(()=>{
      fetchSubfoldersInvoices({subfolder_id:id,folder_id:folderId}).then(res=>{
          setFetchedInvoices(true);
      });

    },[])
    useEffect(()=>{
      if(watchAmount && watchVat){
        let amountIncluded = (watchAmount/100)*watchVat
        setAmountComputedVat(amountIncluded);
        setIsChnagedFromInput(false)
      }
    },[watchAmount,watchVat])


    const handleClose = () => {
        setOpen(false);
    };
  
    const handleValidate = (val) => {
        let formData = new FormData();
        formData.append("file", file.current.files[0]);
        
        const payload = {
            file:file.current.files[0],
            form:{
                invoice_type:val.invoice_type,
                issue_date:val.issue_date,
                memo:val.memo,
                number:val.number,
                subfolder_id:subfolderId,
                amount:val.amount,
                vat_rate:val.vat_rate,
                amount_vat_included:amountComputedVat,
                term_date: moment(val.issue_date).add(30,'days')
            }
        }
        
        
        uploadInvoices(payload).then((res)=>{
          fetchSubfolder(subfolderId);
          setOpen(false);
          if(amountComputedVat !== (val.amount/100)*val.vat_rate)openWarningSnackbar("VAT rate has been blanked because amount VAT included was entered manually")
          else openSuccessSnackbar("Upload completed")
          goInvoiceList()
        });
    }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('folder.subfolder.invoice.upload.title', 'Upload invoice')}</DialogTitle>
        <DialogContent>
            <FormProvider {...form}>
                <FormTextField name="memo" label="description"/>
                <FormTextField name="number" label="invoice number"/>
                <FormNumberField
                  name="amount"
                  label="Amount"
                  endAdornment="€"
                />
                <FormNumberField
                  name="vat_rate"
                  label="VAT rate"
                  endAdornment="%"
                />
                <FormNumberField
                  value={amountComputedVat}
                  label="Amount VAT included"
                  endAdornment="€"
                  onChange={(val)=>{
                      setAmountComputedVat(val)
                      setIsChnagedFromInput(true);
                  }}
                />
                <FormSelect
                    name="invoice_type"
                    label={t('forms.fields.invoice.type.label', 'Invoice type')}
                    options={parameters.invoicing_forecast}
                />
                <FormDatePicker label="Issue date" name="issue_date"/>
                <FormDatePicker label="Term date" name="term_date"/>
                <TextField inputRef={file} type="file" style={{marginTop:"40px",width:"100%"}}/>
            </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('button.cancel',"Cancel")}
          </Button>
          <Button onClick={form.handleSubmit(handleValidate)} color="primary">
          {t('button.save',"Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default  connect(mapStateToProps, mapDispatchToProps)(DialogUploadInvoice)