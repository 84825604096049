import { FaUpload } from 'react-icons/fa';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect, mapStateToProps, mapDispatchToProps } from '../../../store/dispatchers';
import SnackbarContext from "../../../context/snackbar";
import { useContext } from 'react';
  
/**
 * A button for importing a xlsx.
 * 
 * @param {func} props.importWbsPlanningsAsync - store action to manage send of file to API.
 * @param {func} props.fetchWbsPlanning - store action to fetch data and trigger the re-render.
 * @param {integer} props.wbsId - The id of current wbs
 */
const PlanningImport = ({importWbsPlanningsAsync, fetchWbsPlanning, wbsId}) => {
    const { t } = useTranslation();
    const { openSuccessSnackbar } = useContext(SnackbarContext);

    const buildFileSelector = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.accept = '.xlsx';
        fileSelector.onchange = e => handleFileChosen(e.target.files[0] || null)
        return fileSelector;
    }

    const handleFileSelect = (e) => {
        const fileSelect = buildFileSelector();
        e.preventDefault();
        fileSelect.click();
    }
    
    const handleFileChosen = (file) => {
        if(file){
            importWbsPlanningsAsync({file: file, wbsId: wbsId}).then(() =>{
                fetchWbsPlanning(wbsId); // To trigger re-render of task plannings
                openSuccessSnackbar(t('wbs.plannings.importDone','Import done !'));
            });
        }
    }
    
    return (
        <Button
            color="primary"
            variant="contained"
            style={{ height: 38, minWidth: 170, marginTop: 'auto', marginRight: 10 }}
            onClick={handleFileSelect}
            startIcon={<FaUpload size={16}/>}
        >
            { t('button.import', 'Import') }
        </Button>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningImport);