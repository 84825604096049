import ResponseStatus from '../enums/ResponseStatus'
import ErrorMessage from '../enums/ErrorMessage';

const handleFieldsError = (fields, form, error) => {
    const flattenedFields = Array.isArray(fields) ? fields : Object.values(fields).flat();
    for(const field of flattenedFields) {
        form?.setError(field, error);
    }
}

export const handleResponse = (response, form, successCallback, unhandledErrorCallback, warningCallback = unhandledErrorCallback) => {
    if(response && response.status === ResponseStatus.SUCCESS) return successCallback(response.value);
    if(response && response.status === ResponseStatus.WARNING) return warningCallback(response.message, response.value);

    if(!form) {
        return unhandledErrorCallback(response.message, response.value);
    }

    switch(response.message) {
        case ErrorMessage.MISSING_FIELDS:
            return handleFieldsError(response.value, form, { type: 'required', message: 'forms.validation.mixed.required' });
        case ErrorMessage.DUPLICATE_FIELDS:
            return handleFieldsError(response.value, form, { type: 'unique', message: 'forms.validation.string.unique' });
        case ErrorMessage.INVALID_CREDENTIALS:
            return handleFieldsError(response.value, form, { type: 'invalid-credentials', message: 'forms.validation.mixed.invalid_credentials' });
        default:
            return unhandledErrorCallback(response.message, response.value);
    }
}