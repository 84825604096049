import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchInvoiceForecast = createAsyncThunk(
    'invoiceForecast/fetchInvoiceForecast',
    async (_ , { rejectWithValue }) => {
        const response = await axios.get('/keywords/all');
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const createInvoiceForecast = createAsyncThunk(
    'invoiceForecast/createInvoiceForecast',
    async (invoicingForecast , { rejectWithValue }) => {
        const response = await axios.post('/invoice/forecast/create',invoicingForecast);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const createInvoiceForecastDetail = createAsyncThunk(
    'invoiceForecast/createInvoiceForecastDetail',
    async (invoicingForecastDetails , { rejectWithValue }) => {
        const response = await axios.post('/invoice/forecast_detail/create',invoicingForecastDetails);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editInvoicingForecastDetail = createAsyncThunk(
    'invoiceForecast/editInvoicingForecastDetail',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/invoice/forecast_detail/edit/${data.id}`,data.invoicingForecastDetails);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editInvoicingForecast = createAsyncThunk(
    'invoiceForecast/editInvoicingForecast',
    async (data , { rejectWithValue }) => {
        const response = await axios.put(`/invoice/forecast/edit/${data.id}`,data.invoicingForecast);
        if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);



export const invoiceForecastMethods = {
    fetchInvoiceForecast,
    createInvoiceForecast,
    createInvoiceForecastDetail,
    editInvoicingForecastDetail,
    editInvoicingForecast
}

const invoiceForecastSlice = createSlice({
    name: 'invoiceForecast',
    initialState: [],
    extraReducers: {
        [fetchInvoiceForecast.fulfilled]: (_state, action) => action.payload.value,
    },
});

export default invoiceForecastSlice;
