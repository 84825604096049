import VAT_RATES from "../enums/VatRate";

/**
 * Checks if a VAT (value-added tax) rate is valid.
 * @param {number} vat - The VAT rate to be checked.
 * @returns {boolean} - True if the VAT rate is a number between the ones defined in enums (inclusive), false otherwise.
 */
export const isValidVat = (vat) => {
    return typeof vat === 'number' &&
        vat >= VAT_RATES.MIN
}
