import { createContext, useState, useCallback } from 'react';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { timeUnits } from '../utils/units';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
    const [ open, setOpen ] = useState(false);
    const [ alert, setAlert ] = useState({ severity: 'error' });
    
    const openSnackbar = useCallback((message, severity) => {
        setAlert({ message, severity });
        setOpen(true);
    }, [ setAlert, setOpen ]);

    const openErrorSnackbar = useCallback(message => openSnackbar(message, 'error'), [ openSnackbar ]);

    const openWarningSnackbar = useCallback(message => openSnackbar(message, 'warning'), [ openSnackbar ]);

    const openInfoSnackbar = useCallback(message => openSnackbar(message, 'info'), [ openSnackbar ]);

    const openSuccessSnackbar = useCallback(message => openSnackbar(message, 'success'), [ openSnackbar ]);

    const handleClose = useCallback((_event, reason) => {
        if(reason !== 'clickaway') {
            setOpen(false);
        }
    }, [ setOpen ]);
    
    return (
        <SnackbarContext.Provider value={{
            openErrorSnackbar,
            openWarningSnackbar,
            openInfoSnackbar,
            openSuccessSnackbar,
        }}>
            { children }
            <Snackbar
                open={open}
                autoHideDuration={5 * timeUnits.second}
                onClose={handleClose}
            >
                <Alert
                    severity={alert.severity}
                    onClose={handleClose}
                >
                    { alert.message }
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

export default SnackbarContext;