import PhoneField from 'material-ui-phone-number';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
    root: tableField => ({
        width: '100%',
        marginTop: tableField ? 0 : 30,
    }),
});


const FormPhoneField = ({ label, name, tableField = false }) => {
    const classes = useStyles(tableField);
    const { t } = useTranslation();
    const { control, errors } = useFormContext();

    const translate = useCallback(key => {
        if(!key) return;
        if(typeof key === 'string') return t(key);
        return t(key.key, key.values);
    }, [ t ]);

    return (
        <Controller
            control={control}
            name={name}
            render={({ onChange, value }) => (
                <PhoneField
                    defaultCountry="be"
                    className={classes.root}
                    label={label}
                    value={value}
                    onChange={onChange}
                    error={errors[name] && Boolean(errors[name])}
                    helperText={errors[name] && translate(errors[name].message)}
                />
            )}
        />
    );
}

export default FormPhoneField;