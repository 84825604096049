import { useState, useCallback, useContext, useEffect } from 'react';
import { useParams, useHistory,useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button,Tab, Tabs } from '@material-ui/core';
import { MdModeEdit } from 'react-icons/md';

import Card from '../card/Card';
import FormCategoryHeader from '../form/FormCategoryHeader';
import FormRow from '../form/FormRow';
import ConsultTextField from '../form/ConsultTextField';
import ConsultNumberField from '../form/ConsultNumberField';
import ConsultDatePicker from '../form/ConsultDatePicker';
import ConsultImageField from '../form/ConsultImageField';
import ConsultLinkField from '../form/ConsultLinkField';
import { connect, mapStateToProps } from '../../store/dispatchers';
import UserContext from '../../context/user';
import moment from 'moment';
import FormTable from '../form/FormTable';
import { a11yProps, TabPanel } from '../navbar/TabsNavigation';
import UserDisponibility from './UserDisponibility';

const UsersConsult = ({ users, usersCost, usersSpecificCost }) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [user,setUser] = useState(users.find(u => u.id.toString() === id));
    const { hasPermission } = useContext(UserContext);
    const [tab, setTab] = useState(+query.get('tab') || 0);

    useEffect(()=>{
        setUser(users.find(u => u.id.toString() === id))
    },[users,id])

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    const [userCostCategory,setUserCostCategory] = useState([]);
    const [userCostSpecific,setUserCostSpecific] = useState([]);

    const [userTargetInternal,setUsertargetInternal] = useState(null)
    const [userTargetClient,setUsertargetClient] = useState(null)

    const handleEditClick = useCallback((tab) => {
        history.push(`/users/${id}/edit`+ (tab ? `?tab=${tab}` : ""));
    }, [id, history]);

    useEffect(()=>{
       let userCostCateg = usersCost.filter(e=>e.subfolder_id === null)
       let userCostSpeci = usersCost.filter(e=>e.subfolder_id !== null)
       setUserCostCategory(userCostCateg)
       setUserCostSpecific(userCostSpeci)
    },[usersCost])

    useEffect(()=>{
        if(user.user_user_project_targets && user.user_user_project_targets.length !== 0){
          
            let targetInternal = user.user_user_project_targets.find(e=>e.project_type === "support")
            let targetClient = user.user_user_project_targets.find(e=>e.project_type === "operations")
            if(targetInternal)setUsertargetInternal(targetInternal.target)
            if(targetClient)setUsertargetClient(targetClient.target)
        }
    },[user])

    return (
        <>
        <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            className="tab-item"
        >
            <Tab label="Informations générales" {...a11yProps(0)} />
            <Tab label="Disponibilité et abscences" {...a11yProps(2)} />
            {hasPermission('user_extended.read') && <Tab label="Costs" {...a11yProps(1)} />}
        </Tabs>
        <TabPanel value={tab} index={0}>
            <Card
                title={t('users.title.consult-user', '{{firstName}} {{lastName}}\'s information', { firstName: user.first_name, lastName: user.last_name })}
                hideButtons
            >
                {hasPermission('users.update') && <Button
                    color="primary"
                    style={{ position: 'absolute', top: 25, right: 25 }}
                    variant="contained"
                    startIcon={<MdModeEdit />}
                    onClick={()=>handleEditClick()}
                >
                    {t('button.edit', 'Edit')}
                </Button>}
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormCategoryHeader smallMarginTop>
                        {t('users.basic-information', 'Basic information')}
                    </FormCategoryHeader>
                    <FormRow>
                        <Box display="flex" flexDirection="column">
                            <ConsultTextField
                                id="input-first-name"
                                name="first_name"
                                label={t('forms.fields.user.first-name.label', 'First Name')}
                                value={user.first_name}
                            />
                            <ConsultTextField
                                id="input-last-name"
                                name="last_name"
                                label={t('forms.fields.user.last-name.label', 'Last Name')}
                                value={user.last_name}
                            />
                            <ConsultTextField
                                id="input-code"
                                name="code"
                                label={t('forms.fields.user.Trigram.label', 'Trigram')}
                                value={user.user_user_extra_info?.code}
                            />
                            {hasPermission('user_extended.read') && <ConsultNumberField
                                id="input-blocks-availability-per-week"
                                name="blocks_availibility_per_week"
                                label={t('forms.fields.user.blocks-availability-per-week.label', 'Blocks Availability Per Week')}
                                value={user.blocks_availibility_per_week}
                            />}
                            {hasPermission('user_extended.read') && <ConsultTextField
                                id="input-role"
                                name="role"
                                label={t('forms.fields.user.role.label', 'Role')}
                                value={user.user_accounts ? (user.user_accounts[0]?.role ?? 'default') : ''}
                            />}
                            {hasPermission('user_extended.read') && <ConsultTextField
                                id="input-status-type"
                                name="status_type"
                                label={t('forms.fields.user.status-type.label', 'Status')}
                                value={user.user_user_extra_info?.status_type}
                            />}
                            {hasPermission('user_extended.read') && <ConsultTextField
                                id="input-function-type"
                                name="function_type"
                                label={t('forms.fields.user.function-type.label', 'Function')}
                                value={user.user_user_extra_info?.function_type}
                            />}
                            {hasPermission('user_extended.read') && <ConsultTextField
                                id="input-payroll-type"
                                name="payroll_type"
                                label={t('forms.fields.user.payroll-type.label', 'Payroll')}
                                value={user.user_user_extra_info?.payroll_type}
                            />}
                            {hasPermission('user_extended.read') && <ConsultLinkField
                                id="input-external-folder-link"
                                name="external_folder_link"
                                label={t('forms.fields.user.external-folder-link.label', 'M-Files Link')}
                                value={user.user_user_extra_info?.external_folder_link}
                                openInNewTab
                            />}
                            {hasPermission('user_extended.read') && <ConsultTextField
                                id="input-remarks"
                                name="remarks"
                                label={t('forms.fields.user.remarks.label', 'Remarks')}
                                value={user.user_user_extra_info?.remarks}
                            />}
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                            {user.picture && (
                                <ConsultImageField
                                    value={user.picture}
                                    height={400}
                                    width={400}
                                />
                            )}
                        </Box>
                    </FormRow>
                    <FormRow>
                        {hasPermission('user_extended.read') && <ConsultDatePicker
                            id="input-birthdate"
                            name="birthdate"
                            label={t('forms.fields.user.birthdate.label', 'Birthdate')}
                            value={user.birthdate ? new Date(user.birthdate) : null}
                            format="dd/MM/yyyy"
                        />}
                        {hasPermission('user_extended.read') && <ConsultDatePicker
                            id="input-graduation-date"
                            name="graduation_date"
                            label={t('forms.fields.user.graduation-date.label', 'Graduation Date')}
                            value={user.user_user_extra_info.graduation_date ? new Date(user.user_user_extra_info.graduation_date) : null}
                            format="dd/MM/yyyy"
                        />}
                    </FormRow>
                    <FormRow>
                        {hasPermission('user_extended.read') && <ConsultDatePicker
                            id="input-entry-date"
                            name="entry_date"
                            label={t('forms.fields.user.entry-date.label', 'Entry Date')}
                            value={user.user_user_extra_info.entry_date ? new Date(user.user_user_extra_info.entry_date) : null}
                            format="dd/MM/yyyy"
                        />}
                        {hasPermission('user_extended.read') && <ConsultDatePicker
                            id="input-exit-date"
                            name="exit_Date"
                            label={t('forms.fields.user.exit-date.label', 'Exit Date')}
                            value={user.user_user_extra_info.exit_Date ? new Date(user.user_user_extra_info.exit_Date) : null}
                            format="dd/MM/yyyy"
                        />}
                    </FormRow>
                    <FormRow>
                        {hasPermission('user_extended.read') && <ConsultTextField
                            /* id="input-code" */
                            name="degree"
                            label={t('forms.fields.user.Degree.label', 'Degree')}
                            value={user.user_user_extra_info.degree}
                        />}
                    </FormRow>
                    <FormRow>
                        {hasPermission('user_extended.read') && <ConsultDatePicker
                            id="input-last-evaluation"
                            name="last_evaluation_date"
                            label={t('forms.fields.user.last-evaluation.label', 'Last Evaluation')}
                            value={user.user_user_extra_info.last_evaluation_date ? new Date(user.user_user_extra_info.last_evaluation_date) : null}
                            format="dd/MM/yyyy"
                        />}
                        {hasPermission('user_extended.read') && <ConsultDatePicker
                            id="input-next-evaluation"
                            name="next_evaluation_date"
                            label={t('forms.fields.user.next-evaluation.label', 'Next Evaluation')}
                            value={user.user_user_extra_info.next_evaluation_date ? new Date(user.user_user_extra_info.next_evaluation_date) : null}
                            format="dd/MM/yyyy"
                        />}
                    </FormRow>
                    <FormRow>
                        {hasPermission('user_extended.read') && <ConsultNumberField
                            id="input-absence-rate"
                            name="absence_rate"
                            label={t('forms.fields.user.absence-rate.label', 'Absence Rate')}
                            value={user.user_user_extra_info.absence_rate}
                        />}
                    </FormRow>
                    <FormRow>
                        {hasPermission('user_extended.read') && <ConsultNumberField
                            label={t('forms.fields.user.number_holidays.label', 'Number holidays')}
                            value={user.user_user_extra_info.number_holidays}
                        />}
                        {hasPermission('user_extended.read') && <ConsultTextField
                            label={t('forms.fields.user.powerbi-identifier.label', 'PowerBI Identifier')}
                            value={user.user_user_extra_info.powerbi_identifier}
                        />}
                    </FormRow>
                    {hasPermission('user_extended.read') && <FormCategoryHeader>
                        {t('users.occupation_target', 'Occupation target')}
                    </FormCategoryHeader>}
                    {hasPermission('user_extended.read') && <FormRow>
                        <ConsultNumberField
                           label={t('forms.fields.user.occupation_target_client.label', 'Customer project')}
                            value={userTargetClient}
                        />
                        <ConsultNumberField
                            label={t('forms.fields.user.absence-rate-internal.label', 'Internal project')}
                            value={userTargetInternal}
                        />
                    </FormRow>}

                    {user.user_logins && user.user_logins[0] && (<><FormCategoryHeader>
                        { t('users.login-information', 'Login information') }
                    </FormCategoryHeader>
                    <ConsultTextField
                        id="input-email"
                        name="email"
                        label={t('forms.fields.user.email.label', 'Email')}
                        value={user.user_logins[0].email}
                    />
                    </>)}
                </Box>
            </Card>
        </TabPanel>
        <TabPanel value={tab} index={1}>
            <Card hideButtons title={t('users.consult-cost', 'User cost')}>
                    {hasPermission("subfolder.write") && <Button
                            color="primary"
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            variant="contained"
                            startIcon={<MdModeEdit />}
                            onClick={()=>handleEditClick(1)}
                        >
                        {t('button.edit', 'Edit')}
                    </Button>}
                <FormCategoryHeader>
                    {t('users.costs-daily-sales-rate', 'Daily sales rate')}
                </FormCategoryHeader>
                <FormTable
                    columns={[
                        t('forms.fields.cost.category.label', 'Category'),
                        t('forms.fields.cost.date_start.label', 'Date start'),
                        t('forms.fields.cost.date_end.label', 'Date end'),
                    ]}
                    items={userCostCategory}
                    mapToCellContents={item => [
                        t('parameters.employee_cost_category.'+item.cost_type),
                        moment(item.date_start).format('DD/MM/YYYY'),
                        moment(item.date_end).format('DD/MM/YYYY')
                    ]}
                />
                <FormCategoryHeader>
                    {t('users.costs-specific', 'Costs specific')}
                </FormCategoryHeader>
                <FormTable
                    columns={[
                        t('forms.fields.cost.subfodler.label', 'Subfolder'),
                        t('forms.fields.cost.daily_rate.label', 'Daily rate'),
                        t('forms.fields.cost.date_start.label', 'Date start'),
                        t('forms.fields.cost.date_end.label', 'Date end'),
                    ]}
                    items={userCostSpecific}
                    mapToCellContents={item => [
                        item.subfolder?.code || item.subfolder_id,
                        item.daily_rate +" €/jour",
                        moment(item.date_start).format('DD/MM/YYYY'),
                        moment(item.date_end).format('DD/MM/YYYY')
                    ]}
                />
                <FormCategoryHeader>
                    {t('users.specific-cost', 'Specific Cost')}
                </FormCategoryHeader>
                <FormTable
                    columns={[
                        t('forms.fields.cost.cost_per_block.label', 'Cost per block'),
                        t('forms.fields.cost.date_start.label', 'Date start'),
                        t('forms.fields.cost.date_end.label', 'Date end'),
                    ]}
                    items={usersSpecificCost}
                    mapToCellContents={item => [
                        item.cost_per_block,
                        moment(item.date_start).format('DD/MM/YYYY'),
                        moment(item.date_end).format('DD/MM/YYYY')
                    ]}
                />
            </Card>
        </TabPanel>
        <TabPanel value={tab} index={2}>
            <Card hideButtons title={t('users.title.disponibility_and_abs', 'Disponibility and absence')}>
                    {hasPermission("users.edit") && <Button
                            color="primary"
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            variant="contained"
                            startIcon={<MdModeEdit />}
                            onClick={()=>handleEditClick(2)}
                        >
                        {t('button.edit', 'Edit')}
                    </Button>}
                <UserDisponibility
                    user={user}
                    consultOnly
                />
            </Card>
        </TabPanel>
        </>
    );
};

export default connect(mapStateToProps)(UsersConsult);