import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchParametersNumerical = createAsyncThunk(
    'parametersNumerical/fetchParametersNumerical',
    async () => {
        const response = await axios.get('/parameters/numerical');
        return response.data;
    },
);

const updateParametersNumerical = createAsyncThunk(
    'parametersNumerical/fetchParametersNumerical',
    async (data) => {
        const response = await axios.put(`/parameters/update/${data.id}`,data.data);
        if(response.data.status === 'error') throw response.data.value;
        const res = await axios.get('/parameters/numerical');
        return res.data;
    },
);


export const parametersNumericalMethods = {
    fetchParametersNumerical,
    updateParametersNumerical
}

const parametersNumericalSlice = createSlice({
    name: 'parametersNumerical',
    initialState: {},
    extraReducers: {
        [fetchParametersNumerical.fulfilled]: (_state, action) => action.payload,
        [updateParametersNumerical.fulfilled]: (_state, action) => action.payload,
    },
});

export default parametersNumericalSlice;