import { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core';


import { Tab, Tabs } from '@material-ui/core';
import { userSchema, userLoginEditSchema } from '../../services/yup'
import Card from '../card/Card';
import FormCategoryHeader from '../form/FormCategoryHeader';
import FormRow from '../form/FormRow';
import FormDatePicker from '../form/FormDatePicker';
import FormNumberField from '../form/FormNumberField';
import FormImageField from '../form/FormImageField';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import { mapFieldKeys, mapISOKeysToDates } from '../../utils/converters';
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import useYupResolver from '../../hooks/useYupResolver';
import { handleResponse } from '../../utils/http';
import SnackbarContext from '../../context/snackbar';
import FormTable from '../form/FormTable';
import moment from 'moment';
import { a11yProps, TabPanel } from '../navbar/TabsNavigation';
import UserSubfolderSelectDialog from './UserSubfolderSelectDialog';
import AppDialog from '../dialogs/AppDialog';
import UserContext from '../../context/user';
import UserDisponibility from './UserDisponibility';

const UsersEdit = ({ roles, users, parameters, editUser, editUserLogin,fetchCost, usersCost,addCost,deleteCost,fetchFolders, deleteSpecificCost, addSpecificCost, usersSpecificCost, fetchSpecificCost }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [user,setUser] = useState(users.find(u => u.id.toString() === id));
    const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    const [userRoles, setUserRoles] = useState({});
    const [open,setOpen] = useState(false);
    const [loadingFolders,setLoadingFolders] = useState(false);
    const [formSubfoldercode,setFormSubfoldercode] = useState(null);
    const [formSubfolderid,setFormSubfolderid] = useState(null);
    const selectableRoles = useMemo(() => Object.keys(roles).filter(role => role !== 'super-admin'), [ roles ]);
    const { hasPermission } = useContext(UserContext);

    useEffect(() => {
        setUser(users.find(u => u.id.toString() === id))
    },[users]);

    useEffect(()=>{
        if(open){
            setLoadingFolders(true)
            fetchFolders().then(()=>{
                setLoadingFolders(false)
            })
        }
    },[open,fetchFolders])

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [tab, setTab] = useState(+query.get('tab'));

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        let rolesFiltered = {};
        for (const [key] of Object.entries(roles)) {
            if(key !== "super-admin")rolesFiltered[key] = true;
        }
        setUserRoles(rolesFiltered)
    }, [roles])

    const [userCostCategory,setUserCostCategory] = useState([]);
    const [userCostSpecific,setUserCostSpecific] = useState([]);

    useEffect(()=>{
        let userCostCateg = usersCost.filter(e=>e.subfolder_id === null)
        let userCostSpeci = usersCost.filter(e=>e.subfolder_id !== null)
        setUserCostCategory(userCostCateg)
        setUserCostSpecific(userCostSpeci)
     },[usersCost])

    const resolver = useYupResolver(userSchema);

    const role = user && user.user_accounts && user.user_accounts[0]
        ? user.user_accounts[0].role 
        : 'default'

    const form = useForm({
        resolver,
        defaultValues: {
            // User
            first_name: user?.first_name,
            last_name: user?.last_name,
            birthdate: user?.birthdate ? new Date(user.birthdate) : null,
            picture: user?.picture,
            blocks_availibility_per_week: user?.blocks_availibility_per_week,
            // User account

            role : role,

            // User extra info
            ...mapISOKeysToDates(user?.user_user_extra_info),
            client_project_target:user?.user_user_project_targets?.find(e=>e.project_type === "operations")?.target || null,
            internal_project_target:user?.user_user_project_targets?.find(e=>e.project_type === "support")?.target || null
        },
    });

    useEffect(()=>{
        if(user.user_user_project_targets && user.user_user_project_targets.length !== 0){
            let targetInternal = user.user_user_project_targets.find(e=>e.project_type === "support")
            let targetClient = user.user_user_project_targets.find(e=>e.project_type === "operations")
            if(targetInternal)form.setValue('internal_project_target',targetInternal.target)
            if(targetClient)form.setValue('client_project_target',targetClient.target)
        }
        // eslint-disable-next-line
    },[user])

    const costCategoryForm = useForm({
        defaultValues: {
            cost_type: '',
            date_start: null,
            date_end: null,
        },
    });

    const costSpecificForm = useForm({
        defaultValues: {
            subfolder_id: '',
            daily_rate: '',
            date_start: null,
            date_end: null,
        },
    });

    const specificCostForm = useForm({
        defaultValues:{
            cost_per_block:'',
            date_start: null,
        }
    })

    const watchWorkStartDateSpecific = costSpecificForm.watch('date_start');
    const watchWorkEndDateSpecific = costSpecificForm.watch('date_end');

    const loginResolver = useYupResolver(userLoginEditSchema);
    const loginForm = useForm({
        resolver: loginResolver,
        defaultValues: {
            email: user?.user_logins[0].email,
            password: '',
            password_confirm: '',
        },
        validateMode: 'onChange',
        reValidateMode: 'onChange',
    });

    const watchPassword = loginForm.watch('password');

    const handleUnhandledError = useCallback((message, value) => {
        setLoading(false);
        openErrorSnackbar(t('error.' + message));
        console.error(message, value);
    }, [t, openErrorSnackbar]);

    const handleUserSuccess = useCallback(() => {
        setLoading(false);
        openSuccessSnackbar(t('success.user_updated', 'User successfully updated'));
        history.push('/users/' + id);
    }, [t, id, history, openSuccessSnackbar]);

    const handleLoginSubmit = useCallback(async (values) => {
        const mappedValues = mapFieldKeys(values);
        const userLogin = mappedValues.password ? mappedValues : { email: mappedValues.email };
        
        // console.log('user. :', user);
        // console.log('user_logins : ', user.user_logins[0].id);

        // todo : ????
        // when edit user for the first time, only email is accessible on user_logins
        // the back save the user with route users/edit/user/id
        // but don't save anything with route users/edit/login/id since id is undefined at this time for unknow reason
        // but when we submit the second time, the id exist on user_logins
        
        const result = await editUserLogin({
            id: user.user_logins[0].id,
            userLogin,
        });
        handleResponse(result.payload, loginForm, handleUserSuccess, handleUnhandledError);
    }, [user, loginForm, editUserLogin, handleUserSuccess, handleUnhandledError]);

    const handleCostCategorySubmit = (values) => {
        if(values.daily_rate !== "" && values.date_start){
            let foundLastValue = userCostCategory.find((e)=>{
                if(e.date_end === null)return true;
                return false;
            })
            let isValid = true;
            if(foundLastValue){
                if(moment(values.date_start).isBefore(moment(foundLastValue.date_start).add(1,'day')))isValid = false;
            }
            if(!isValid)openErrorSnackbar('Date Cannot be in an other existing range')
            else{
                addCost({
                    newCostCategory:{
                        ...values,
                        date_end:null,
                        user_id:id
                    },
                    lasCategory:foundLastValue
                }).then(()=>{
                    fetchCost(id)
                    costCategoryForm.reset({
                        subfolder_id: '',
                        daily_rate: '',
                        date_start: null,
                        date_end: null,
                    })
                    openSuccessSnackbar('Successfully added')
                })
            }
        }else{
            openErrorSnackbar('Invalid date ')
        }   
    }

    const handlSpecificCostSubmit = (values) => {
        if(values.cost_per_block !== "" && values.date_start){
            let foundLastValue = usersSpecificCost.find((e)=>{
                if(e.date_end === null)return true;
                return false;
            })
            let isValid = true;
            if(foundLastValue){
                if(moment(values.date_start).isBefore(moment(foundLastValue.date_start).add(1,'day')))isValid = false;
            }
            if(!isValid)openErrorSnackbar('Date start Cannot be in past')
            else{
                addSpecificCost({
                    newCostCategory:{
                        ...values,
                        date_end:null,
                        user_id:id
                    },
                    lasCategory:foundLastValue
                }).then(()=>{
                    fetchSpecificCost(id)
                    specificCostForm.reset({
                        cost_per_block: '',
                        date_start: null,
                        date_end: null,
                    })
                    openSuccessSnackbar('Successfully added')
                })
            }
        }
    }

    const handleCostSpecificSubmit = (values) => {
        // allow to add cost if date_end is null 
        if(values.daily_rate !== "" && values.date_start){
            let foundLastValue = userCostSpecific.find((e)=>{
                if(e.date_end === null && e.subfolder_id === formSubfolderid)return true;
                return false;
            })
            let isValid = true;
            if(foundLastValue){
                if(moment(values.date_start).isBefore(moment(foundLastValue.date_start).add(1,'day')))isValid = false;
            }
            if(!isValid)openErrorSnackbar('Date Cannot be in an other existing range')

            else{
                addCost({
                    newCostCategory:{
                        ...values,
                        date_end:null,
                        subfolder_id:formSubfolderid,
                        user_id:id
                    },
                    lasCategory:foundLastValue
                }).then(()=>{
                    fetchCost(id)
                    costSpecificForm.reset({
                        cost_type: '',
                        date_start: null,
                        date_end: null,
                    })
                    openSuccessSnackbar('Successfully added')
                })
            }
        }else{
            openErrorSnackbar('Invalid date')
        }
    }

    const handleCostCategoryRemove = (value) => {
        deleteCost(value.id);
    }

    const handleSpecificCostCategoryRemove = (value) => {
        deleteSpecificCost(value.id);
    }

    const taskSelectFromDialog = (subfolder) => {
        costSpecificForm.setValue("subfolder_id",subfolder.id)
        setFormSubfoldercode(subfolder.code)
        setFormSubfolderid(subfolder.id)
        setOpen(false);
    }

    const handleSubmit = useCallback(async (values) => {
        const loginValues = loginForm.getValues();
        if (loginValues.password !== loginValues.password_confirm) {
            loginForm.setError('password_confirm', { type: 'match', message: 'forms.validation.string.match' });
            return;
        }
        setLoading(true);
        const mappedValues = mapFieldKeys(values);
        const result = await editUser({
            id,
            user: {
                user: {
                    ...mappedValues,
                },
                userAccount: {
                    role: mappedValues.role,
                    account_id: 1,
                },
                userExtraInfo: {
                    ...mappedValues,
                },
                userTarget: {
                    client_project_target:mappedValues.client_project_target,
                    internal_project_target:mappedValues.internal_project_target
                }
            },
        });
        handleResponse(result.payload, form, loginForm.handleSubmit(handleLoginSubmit), handleUnhandledError);
    }, [loginForm, form, id, editUser, handleLoginSubmit, handleUnhandledError]);

    return (
        <>
        <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            className="tab-item"
        >
            <Tab label="Informations générales" {...a11yProps(0)} />
            {hasPermission('user_extended.read') && <Tab label="Disponibilité et abscences" {...a11yProps(2)} />}
            <Tab label="Costs" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tab} index={0}>
            <Card
                title={t('users.title.edit-user', 'Edit {{firstName}} {{lastName}}\'s information', { firstName: user.first_name, lastName: user.last_name })}
                doneLabel={t('button.submit', 'Submit')}
                onDoneClick={form.handleSubmit(handleSubmit)}
                loading={loading}
            >
                <FormProvider {...form}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader smallMarginTop>
                            {t('users.basic-information', 'Basic information')}
                        </FormCategoryHeader>
                        <FormRow>
                            <Box display="flex" flexDirection="column">
                                <FormTextField
                                    name="first_name"
                                    label={t('forms.fields.user.first-name.label', 'First Name')}
                                />
                                <FormTextField
                                    name="last_name"
                                    label={t('forms.fields.user.last-name.label', 'Last Name')}
                                />
                                <FormTextField
                                    name="code"
                                    label={t('forms.fields.user.code.label', 'Code')}
                                />
                                <FormNumberField
                                    name="blocks_availibility_per_week"
                                    label={t('forms.fields.user.blocks-availability-per-week.label', 'Blocks Availability Per Week')}
                                    min={0}
                                    max={50}
                                    notNullable
                                />
                                <FormSelect
                                    name="role"
                                    label={t('forms.fields.user.role.label', 'Role')}
                                    options={Object.keys(userRoles)}
                                />
                                <FormSelect
                                    name="status_type"
                                    label={t('forms.fields.user.status-type.label', 'Status')}
                                    options={parameters.employee_status}
                                    allowNull
                                    mapOptionToText={val=>t('parameters.employee_status.'+val)}
                                />
                                <FormSelect
                                    name="function_type"
                                    label={t('forms.fields.user.function-type.label', 'Function')}
                                    options={parameters.employee_function}
                                    mapOptionToText={val=>t('parameters.employee_function.'+val)}
                                />
                                <FormSelect
                                    name="payroll_type"
                                    label={t('forms.fields.user.payroll-type.label', 'Payroll')}
                                    options={parameters.employee_payroll}
                                    allowNull
                                    mapOptionToText={val=>t('parameters.employee_payroll.'+val)}
                                />
                                <FormTextField
                                    name="external_folder_link"
                                    label={t('forms.fields.user.external-folder-link.label', 'M-Files Link')}
                                />
                                <FormTextField
                                    name="remarks"
                                    label={t('forms.fields.user.remarks.label', 'Remarks')}
                                />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center" flex="1">
                                <FormImageField
                                    name="picture"
                                    uploadLabel={t('forms.fields.user.picture.upload-label', 'Upload a picture')}
                                    editLabel={t('forms.fields.user.picture.edit-label', 'Upload another picture')}
                                    height={400}
                                    width={400}
                                />
                            </Box>
                        </FormRow>
                        <FormRow>
                            <FormDatePicker
                                name="birthdate"
                                label={t('forms.fields.user.birthdate.label', 'Birthdate')}
                                disableFuture
                            />
                            <FormDatePicker
                                name="graduation_date"
                                label={t('forms.fields.user.graduation-date.label', 'Graduation Date')}
                                disableFuture
                            />
                        </FormRow>
                        <FormRow>
                            <FormDatePicker
                                name="entry_date"
                                label={t('forms.fields.user.entry-date.label', 'Entry Date')}
                                clearable={false}
                            />
                            <FormDatePicker
                                name="exit_Date"
                                label={t('forms.fields.user.exit-date.label', 'Exit Date')}
                            />
                        </FormRow>
                        <FormRow>
                            <FormTextField
                            name="degree"
                            label={t('forms.fields.user.Degree.label', 'Degree')}
                            />
                         </FormRow>
                        <FormRow>
                            <FormDatePicker
                                name="last_evaluation_date"
                                label={t('forms.fields.user.last-evaluation.label', 'Last Evaluation')}
                                disableFuture
                            />
                            <FormDatePicker
                                name="next_evaluation_date"
                                label={t('forms.fields.user.next-evaluation.label', 'Next Evaluation')}
                            />
                        </FormRow>
                        <FormRow>
                            <FormNumberField
                                name="seniority"
                                label={t('forms.fields.user.seniority.label', 'Seniority')}
                                min={0}
                                max={99.9}
                                decimalPlaces={1}
                                readOnly
                            />
                            <FormNumberField
                                name="absence_rate"
                                label={t('forms.fields.user.absence-rate.label', 'Absence Rate')}
                                min={0}
                                max={100}
                                readOnly
                            />
                        </FormRow>
                        <FormRow>
                            <FormNumberField
                                name="number_holidays"
                                label={t('forms.fields.user.number_holidays.label', 'Number holidays')}
                                min={0}
                                max={9999999}
                            />
                            <FormTextField
                                name="powerbi_identifier"
                                label={t('forms.fields.user.powerbi-identifier.label', 'PowerBI Identifier')}
                            />
                        </FormRow>
                        <FormCategoryHeader>
                            {t('users.occupation_target', 'Occupation target')}
                        </FormCategoryHeader>
                        <FormRow>
                            <FormNumberField
                                name="client_project_target"
                                label={t('forms.fields.user.occupation_target_client.label', 'Customer project')}
                                min={0}
                                max={100}
                                endAdornment="%"
                            />
                            <FormNumberField
                                name="internal_project_target"
                                label={t('forms.fields.user.occupation_target_internal.label', 'Internal project')}
                                min={0}
                                max={100}
                                endAdornment="%"
                            />
                        </FormRow>

                    </form>
                </FormProvider>
                <FormProvider {...loginForm}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader>
                            {t('users.login-information', 'Login information')}
                        </FormCategoryHeader>
                        <FormTextField
                            name="email"
                            label={t('forms.fields.user.email.label', 'Email')}
                        />
                        <FormTextField
                            name="password"
                            label={t('forms.fields.user.password.label', 'Password')}
                            hideText
                        />
                        <FormTextField
                            name="password_confirm"
                            label={t('forms.fields.user.password-confirm.label', 'Confirm Password')}
                            hideText
                            onChange={event => {
                                if (watchPassword === event.target.value) {
                                    loginForm.clearErrors('password_confirm');
                                }
                            }}
                        />
                    </form>
                </FormProvider>
                

            </Card>
        </TabPanel>
        <TabPanel value={tab} index={1}>
            <Card hideButtons noMarginTop>
            <FormProvider {...costCategoryForm}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader>
                            {t('users.costs-category', 'Costs category')}
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.cost.category.label', 'Category'),
                                t('forms.fields.cost.date_start.label', 'Date start'),
                                t('forms.fields.cost.date_end.label', 'Date end'),
                            ]}
                            items={userCostCategory}
                            // 'invalid date' 
                            mapToCellContents={item => [
                                t('parameters.employee_cost_category.'+item.cost_type),
                                moment(item.date_start).format('DD/MM/YYYY'),
                                item.date_end ? moment(item.date_end).format('DD/MM/YYYY') : ''
                            ]}
                            formFields={[
                                <FormSelect
                                name="cost_type"
                                label={t('forms.fields.user.cost-type.label', 'Cost')}
                                options={parameters.employee_cost_category}
                                allowNull
                                />,
                                <FormDatePicker
                                    name="date_start"
                                    label={t('forms.fields.user.cost-date-start.label', 'Date start')}
                                />,
                                <div></div>
                            ]}
                            onAddClick={costCategoryForm.handleSubmit(handleCostCategorySubmit)}
                            onRemoveClick={handleCostCategoryRemove}
                        />
                    </form>
                </FormProvider>
                <FormProvider {...costSpecificForm}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader>
                        {t('users.costs-specific', 'Costs specific')}
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.cost.subfodler.label', 'Subfolder'),
                                t('forms.fields.cost.daily_rate.label', 'Daily rate'),
                                t('forms.fields.cost.date_start.label', 'Date start'),
                                t('forms.fields.cost.date_end.label', 'Date end'),
                            ]}
                            items={userCostSpecific.sort((a, b) => a.subfolder_id-b.subfolder_id)}
                            mapToCellContents={item => [
                                item.subfolder?.code || item.subfolder_id,
                                item.daily_rate +" €/jour",
                                moment(item.date_start).format('DD/MM/YYYY'),
                                item.date_end ? moment(item.date_end).format('DD/MM/YYYY') : ''
                            ]}
                            formFields={[
                                <Button
                                    style={{marginTop:"30px"}}
                                    onClick={(event)=>{
                                        setOpen(true)
                                    }}
                                    variant="outlined"
                                >
                                    {formSubfoldercode ? formSubfoldercode : t('forms.fields.user.cost_subfolder.chooseButton', 'Choose subfolder')}
                                </Button>,

                                <FormNumberField
                                    name="daily_rate"
                                    label={t('forms.fields.user.cost_daily_rater.label', 'Daily rate')}
                                    min={0}
                                    max={9999999}
                                />,
                                <FormDatePicker
                                    name="date_start"
                                    label={t('forms.fields.user.cost-date-start.label', 'Date start')}
                                    maxDate={watchWorkEndDateSpecific}
                                />,
                                <div></div>
                            ]}
                            onAddClick={costSpecificForm.handleSubmit(handleCostSpecificSubmit)}
                            onRemoveClick={handleCostCategoryRemove}
                        />
                    </form>
                </FormProvider>
                <FormProvider {...specificCostForm}>
                    <form style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormCategoryHeader>
                            {t('users.specific-cost', 'Specific Cost')}
                        </FormCategoryHeader>
                        <FormTable
                            columns={[
                                t('forms.fields.cost.cost_per_block.label', 'Cost per block'),
                                t('forms.fields.cost.date_start.label', 'Date start'),
                                t('forms.fields.cost.date_end.label', 'Date end'),
                            ]}
                            items={usersSpecificCost}
                            mapToCellContents={item => [
                                item.cost_per_block,
                                moment(item.date_start).format('DD/MM/YYYY'),
                                item.date_end ? moment(item.date_end).format('DD/MM/YYYY') : ''
                            ]}
                            formFields={[
                                <FormNumberField
                                    name="cost_per_block"
                                    label={t('forms.fields.cost.cost_per_block.label', 'Cost per block')}
                                    min={0}
                                    max={9999999}
                                />,
                                <FormDatePicker
                                    name="date_start"
                                    label={t('forms.fields.user.cost-date-start.label', 'Date start')}
                                />,
                                <div></div>
                            ]}
                            onAddClick={specificCostForm.handleSubmit(handlSpecificCostSubmit)}
                            onRemoveClick={handleSpecificCostCategoryRemove}
                        />
                    </form>
                </FormProvider>
            </Card>
        </TabPanel>
        <TabPanel value={tab} index={2}>
            <Card hideButtons title={t('users.title.disponibility_and_abs', 'Disponibility and absence')}>
                <UserDisponibility
                    user={user}
                />
            </Card>
        </TabPanel>
        
        <AppDialog
            open={open}
            setOpen={setOpen}
            title="Select a subfolder"
            okLabel="Select"
            onOk={()=>{}}
            onCancel={()=>setOpen(false)}
            keepOpenAfterOk
            fullWidth
            maxWidth="lg"
        >
            <UserSubfolderSelectDialog
                loadingFolders={loadingFolders}
                onTaskSelect={taskSelectFromDialog}
            />
        </AppDialog>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersEdit);