import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
    root: tableField => ({
        width: '100%',
        marginTop: tableField ? 0 : 30,
    }),
});

const FormDatePicker = ({
    name,
    label,
    clearable = true,
    minDate,
    maxDate,
    disableFuture,
    tableField = false,
    onChangeEvent,
    ...props
}) => {
    const classes = useStyles(tableField)
    const { t } = useTranslation();
    const { control, errors, setValue } = useFormContext();
    
    const translate = useCallback(key => {
        if(!key) return;
        if(!/^[a-z\d_.-]+$/.test(key)) return key;
        if(typeof key === 'string') return t(key);
        return t(key.key, key.values);
    }, [ t ]);

    const errorKey = useMemo(() => {
        const error = errors[name];
        if(error) {
            return error.message.key ?? error.message;
        }
        return undefined;
    }, [ errors, name ])

    return (
        <Controller
            control={control}
            name={name}
            
            render={({ value }) => (
                <KeyboardDatePicker
                    {...props}
                    className={classes.root}
                    label={label}
                    value={value}
                    onChange={date => {
                        setValue(name, isFinite(date) ? date : (clearable ? null : undefined));
                        if(onChangeEvent)onChangeEvent(date);
                    }}
                    error={errorKey && Boolean(errorKey)}
                    helperText={errorKey && translate(errorKey)}
                    format="dd/MM/yyyy"
                    minDate={minDate?.toString()}
                    maxDate={maxDate?.toString()}
                    disableFuture={disableFuture}
                    clearable={clearable}
                />
            )}
        />
    );
};

export default FormDatePicker;