import { Box, Button, CircularProgress, FormControlLabel, Switch, useTheme } from "@material-ui/core"
import { useCallback, useMemo, useState, useEffect } from "react";
import Card from '../card/Card'
import ExplorerToolbar from '../explorer/ExplorerToolbar'
import ExplorerTable from '../explorer/ExplorerTable'
import AppDialog from '../dialogs/AppDialog'
import { useTranslation } from "react-i18next";
import { connect, mapStateToProps, mapDispatchToProps } from '../../store/dispatchers';
import { FaCaretDown } from 'react-icons/fa';

const PlannerTaskSelectDialog = ({loadingFolders,folders,users,fetchWbsAllForFolderId,fetchWbsPlanning,wbsPlanning,onTaskSelect,fetchSubfolders}) => {

    const { t } = useTranslation()
    const [selectedFolder,setSelectedFolder] = useState(null);
    const [open,setOpen] = useState(false);
    const [ filteredFolders, setFilteredFolders ] = useState(folders);
    const palette = useTheme().palette;
    const [wbsTree,setWbsTree] = useState(null);
    const [filters,setFilters] = useState({
        closed:false,
        abandoned:false,
        active:true,
        intermediate:true,
        internal_project:true,
        litigation:true,
        tendering:true,
        transfer:true,
        prospect:true,
        no_subfolder:true
    });

    const list_to_tree = (list) => {
        let map = {}, node, roots=[],i;
        for(i = 0; i < list.length; i++){
            map[list[i].id] = i;
            list[i].wbs_tasks = [];
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parent_id !== 0) {
              // if you have dangling branches check that map[node.parentId] exists
              list[map[node.parent_id]].wbs_tasks.push(node);
            } else {
              roots.push(node);
            }
        }
        return roots;
    }

    useEffect(()=>{
        let wbsPlanningCopy = [...wbsPlanning];
        wbsPlanningCopy = wbsPlanningCopy.sort((a,b)=>{
            if(a.parent_id === null)return -1
            return +1
        }).map(item=>{
            let itemCopy = {...item};
            
            if(itemCopy.parent_id === null){
                itemCopy.parent_id = 0;
            }
            itemCopy.is_child = true;
            for(const tsk of wbsPlanning){
                if(tsk.parent_id === itemCopy.id)itemCopy.is_child = false;
            }
            return itemCopy
        })
        let tree = list_to_tree(wbsPlanningCopy);
        if(tree.length > 1)tree = tree.sort((a,b)=>a.rank-b.rank);
        setWbsTree(tree);
    },[wbsPlanning])

    useEffect(()=>{
        if(selectedFolder && selectedFolder.id){
            fetchSubfolders(selectedFolder.id);
        }
    },[selectedFolder])

    const getCodeSubfolder = useCallback((task,code = false) => {
        return task.task_code+" "+task.name
    },[folders])

    const [loadingTasks,setLoadingTasks] = useState(false);

    const mapToRow = useCallback(folder => [
        folder.lead_code,
        folder.code,
        folder.name,
        // eslint-disable-next-line
    ], [ folders,users ]);

    const mapToRowTasks = useCallback(task => [
        getCodeSubfolder(task),
        getCodeSubfolder(task,true),
    ], [getCodeSubfolder]);

    const foldersFields = useMemo(() => folders.map(folder => [
        folder.lead_code,
        folder.code,
        folder.name,
    ]), [ folders ]);

    const columns = [
        { name: t('table.folders.columns.lead_code', 'Code prospect'), width: 20 },
        { name: t('table.folders.columns.code', 'Folder code'), width: 20 },
        { name: t('table.folders.columns.name', 'Name'), width: 60 },
    ];
    const columnsTask = [
        { name: t('table.tasks.columns.code', 'Task code'), width:50 },
        { name: t('table.tasks.columns.name', 'Task name'), width: 50 },
    ];

    const handleConsultClick = (folder) => {
        setSelectedFolder(folder);
        setLoadingTasks(true)
        fetchWbsAllForFolderId(folder.id).then((AllWbs)=>{
            //fetch tasks
            if(AllWbs.payload.length !== 0){
                fetchWbsPlanning(AllWbs.payload[0].id).then(()=>{
                    setLoadingTasks(false)
                })
            }
            else{
                setLoadingTasks(false)
            }
        });
    }

    const handleConsultClickTask = (task) => {
        onTaskSelect(task)
    }

    const filter = useCallback(folder => {
        let isToReturn = false;
        if(filters.closed) if(folder.closed_subfolders_count && folder.closed_subfolders_count !== 0)isToReturn = true;
        if(filters.abandoned) if(folder.abandoned_subfolders_count && folder.abandoned_subfolders_count !== 0) isToReturn = true;
        if(filters.active) if(folder.active_subfolders_count && folder.active_subfolders_count !== 0) isToReturn = true;
        if(filters.intermediate) if(folder.intermediate_subfolders_count && folder.intermediate_subfolders_count !== 0) isToReturn = true;
        if(filters.internal_project) if(folder.internal_project_subfolders_count && folder.internal_project_subfolders_count !== 0) isToReturn = true;
        if(filters.litigation) if(folder.litigation_subfolders_count && folder.litigation_subfolders_count !== 0) isToReturn = true;
        if(filters.tendering) if(folder.tendering_subfolders_count && folder.tendering_subfolders_count !== 0) isToReturn = true;
        if(filters.transfer) if(folder.transfer_subfolders_count && folder.transfer_subfolders_count !== 0) isToReturn = true;
        if(filters.prospect) if(folder.prospect_subfolders_count && folder.prospect_subfolders_count !== 0) isToReturn = true;
        if(filters.no_subfolder) if(folder.subfolder_count === 0) isToReturn = true;
        return isToReturn;
    }, [filters]);

    const filtersRender = () =>{
        let items = []
        let filtersCopy = {...filters};
        for(const [key] of Object.entries(filters)){
            items.push(<>
                <FormControlLabel
                    control={<Switch checked={filters[key]} 
                    onChange={(event)=>{filtersCopy[key] = !filtersCopy[key]; setFilters(filtersCopy)}} 
                    />}
                    label={key.replace(/[_]/g,' ')}
                    style={{color:"black"}}
                />
            </>)
        }
        return items;
    }

    const getSubfolderName = (subfolderId) => {
        if(selectedFolder.subfolders && selectedFolder.subfolders.length > 0){
            const subfold = selectedFolder.subfolders.find(f=>f.id === subfolderId);
            if(subfold)return subfold.code+" "+subfold.name
        }else{
            return "subfolder with id "+subfolderId+" not found"
        }
    }

    const renderTaskRow = (tasks, level) => {
        if(tasks.length !== 0)
        return tasks.map(task=>{
            let isGreyTask = false;
            if(!task.is_child || !task.bookable)isGreyTask = true;
            if(task?.subfolder?.status_type && task?.subfolder?.status_type !== "active")isGreyTask = true;
            return <><Box key={'tr'+level+''+task.id} marginTop="10px">
                    <Box 
                        paddingLeft={2*level} 
                        display="flex" 
                        flexDirection="row" 
                        color={!isGreyTask ? palette.primary.main : "gray"} 
                        fontWeight={!isGreyTask && "bold"}
                        className={!isGreyTask && "hover-black cursor-pointer"}
                        onClick={()=>{
                            if(!isGreyTask)handleConsultClickTask(task)
                        }}
                    >
                        {!task.is_child ? <FaCaretDown style={{paddingTop:"5px",paddingRight:"2px"}}/> : <Box display="block" width="18px"></Box>}
                        { task.subfolder_id ? getCodeSubfolder(task) : task.task_code+" - "+task.name }
                    </Box>
            </Box>
            {(task.wbs_tasks.length === 0) ? null : renderTaskRow(task.wbs_tasks,level+1)}
            </>
        })
        return null;
    }

    return <Box className="app-kit-overflow-y">
    {
        !loadingFolders ? 
    <Box className="app-kit-overflow-y">
        { !selectedFolder ? <Box>
            <span>1. Choose a folder</span>
            <Card
                hideButtons
                header={
                    <ExplorerToolbar
                        filter={filter}
                        data={folders}
                        dataFields={foldersFields}
                        createLabel={''}
                        createIcon={null}
                        onCreateClick={null}
                        onFilter={setFilteredFolders}
                    >
                        <Button style={{width:"250px"}} onClick={()=>setOpen(true)}>
                            Set Filters
                        </Button>
                    </ExplorerToolbar>
                }
            >
                <ExplorerTable
                    columns={columns}
                    items={filteredFolders}
                    mapToCellContents={mapToRow}
                    onConsultClick={handleConsultClick}
                    onEditClick={null}
                    onDeleteClick={null}
                />
            </Card>
            <AppDialog
                open={open}
                setOpen={setOpen}
                title={t('dialog.filters.title', 'Filters settings')}
                onCancel={()=>setOpen(false)}
            >
                <Box display="flex" flexDirection="column">
                    {filtersRender()}
                </Box>
            </AppDialog>
        </Box>:
        <Box display="flex" flexDirection="column">
            <span>1. Selected folder : 
                <span style={{color:palette.primary.main}}>{selectedFolder.lead_code} - {selectedFolder.code} - {selectedFolder.name} </span>
                 <span onClick={()=>setSelectedFolder(null)} style={{textDecoration:"underline",cursor:"pointer"}}>Modifier</span>
            </span>
            <span>2. Choose a task</span>
            {loadingTasks ? <Box display="flex" flexDirection="row" justifyContent="center" marginTop="300px" marginBottom="300px">
                <CircularProgress size={50}/>
            </Box> : 
            <Box>
                {/* {<Card
                    hideButtons
                    header={null}
                >
                    <ExplorerTable
                        columns={columnsTask}
                        items={wbsPlanning}
                        mapToCellContents={mapToRowTasks}
                        onConsultClick={handleConsultClickTask}
                    />
                </Card>} */}
                {wbsTree ? <Box height="100vh" marginTop="30px">
                    {renderTaskRow(wbsTree,0)}
                </Box>: null}
            </Box>}
        </Box>
        }
    </Box> : 
    <Box display="flex" flexDirection="row" justifyContent="center" marginTop="300px" marginBottom="300px">
        <CircularProgress size={50}/>
    </Box>
    }</Box>
}

export default connect(mapStateToProps, mapDispatchToProps)(PlannerTaskSelectDialog);