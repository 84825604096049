/**
 * Converts an object `obj` into an array of keys representing the paths of each value in the object
 * (assumes the object doesn't contain any array).
 * 
 * @param {any} obj The object to convert.
 * @param {string} [base] A base to prepend to each key, optional.
 * @returns {string[]} The array of keys representing the paths of each value in the object.
 * 
 * @example
 * const obj = {
 *     a: {
 *         b: 'goat'
 *     },
 *     c: 'sunshine'
 * };
 * // Returns [ 'a.b', 'c' ]
 * objectToKeyList(obj);
 * // Returns [ 'base.a.b', 'base.c' ]
 * objectToKeyList(obj, 'base');
 */
export const objectToKeyList = (obj, base) => {
    const keyList = [];
    for(const [ key, value ] of Object.entries(obj)) {
        const fullKey = base ? `${base}.${key}` : key;
        if(typeof value === 'object') {
            keyList.push(...objectToKeyList(value, fullKey));
        } else {
            keyList.push(fullKey);
        }
    }
    return keyList;
};

/**
 * Replaces accented characters in string `str` by their unaccented equivalent.
 * 
 * @param {string} str The string to deaccent.
 * @returns {string} The deaccented string.
 */
export const deaccentString = str => {
    if(!str) return str;
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/**
 * Converts a `value` to its kebab-case equivalent. If the `value` isn't a string,
 * it will be first converted to a string using `toString()`.
 * 
 * @param {any} val The value to convert.
 * @returns {string} The kebab-cased string.
 */
export const kebabCase = value => {
    if(!value)return '';
    return deaccentString(typeof value === 'string' ? value : value.toString())
        .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
        .replace(/([a-z])([A-Z])/g, (_m, a, b) => a + '-' + b.toLowerCase())
        .replace(/[^A-Za-z0-9]+/g, '')
        .toLowerCase();
};

export const uppercaseFirst = value => {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Maps form keys to appropriate values for a request.
 * 
 * @param {any} obj The form object to check.
 * @returns {any} A new object with the empty keys mapped to null and the dates mapped to ISO strings.
 */
export const mapFieldKeys = obj => {
    const newObj = { ...obj };
    for(const [key, value] of Object.entries(obj)) {
        if(value instanceof Date) {
            newObj[key] = value.toISOString();
        } else if(value === '') {
            newObj[key] = null;
        }
    }
    return newObj;
};

/**
 * Maps ISO values to Date objects.
 * 
 * @param {any} obj The object to check.
 * @returns {any} A new object with the ISO values mapped to Date objects.
 */
export const mapISOKeysToDates = obj => {
    if(!obj) return obj;
    const newObj = { ...obj };
    for(const [key, value] of Object.entries(obj)) {
        if(value && /_date$/i.test(key)) {
            newObj[key] = new Date(value);
        }
    }
    return newObj;
}

/**
 * Maps list values to tree objects.
 * 
 * @param {any} list the list to transform.
 * @returns {any} a tree object.
 */

export const listToTree = list => {
    let map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i++) {
        map[list[i].id] = i;
        list[i].wbs_tasks = [];
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id !== 0) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parent_id]].wbs_tasks.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

/**
 * Transform string to keyword for json format.
 * 
 * @param {string} string string to transform.
 * @returns {any} string in keyword form.
 */

export const textToKey = string => {
    string = string.replace(/([ ])/g,"_");
    string = string.toLowerCase();
    return string;
}
/**
 * Transform keyword to string for translation.
 * 
 * @param {string} keyword string to transform.
 * @returns {any} keyword in string form.
 */

export const keyToText = keyword => {
    keyword = keyword.replace(/([_])/g," ");
    return keyword;
}