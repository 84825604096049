import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import ResponseStatus from '../../enums/ResponseStatus';

const fetchFolders = createAsyncThunk(
    'folders/fetchFolders',
    async (_, { rejectWithValue }) => {
        const response = await axios.get('/folders/all');
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addFolder = createAsyncThunk(
    'folders/addFolder',
    async (folder, { rejectWithValue }) => {
        const response = await axios.post('/folders/create', folder);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteFolder = createAsyncThunk(
    'folders/deleteFolder',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/folders/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editFolder = createAsyncThunk(
    'folders/editFolder',
    async ({ id, folder }, { rejectWithValue }) => {
        const response = await axios.put('/folders/edit/' + id, folder);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteFolderKeywords = createAsyncThunk(
    'folders/deleteFolderKeywords',
    async ({ id, keywords }, { rejectWithValue }) => {
        const response = await axios.put(`/folders/${id}/keywords/delete`, keywords);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return { ...response.data, value: keywords };
    },
);

const fetchSubfolders = createAsyncThunk(
    'folders/fetchSubfolders',
    async (folderId, { rejectWithValue }) => {
        const response = await axios.get(`/subfolder/by_folder_id/${folderId}`);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const fetchSubfolder = createAsyncThunk(
    'folders/fetchSubfolder',
    async (id, { rejectWithValue }) => {
        const response = await axios.get(`/subfolder/${id}`);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
)

const addSubfolder = createAsyncThunk(
    'folders/addSubfolder',
    async (subfolder, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/create', subfolder);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
)

const editSubfolder = createAsyncThunk(
    'folders/editSubfolder',
    async ({ id, subfolder }, { rejectWithValue }) => {
        const response = await axios.put('/subfolder/update/' + id, subfolder);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteSubfolder = createAsyncThunk(
    'folders/deleteSubfolders',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/subfolder/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addSubfolderAssociate = createAsyncThunk(
    'folders/addSubfolderAssociate',
    async (associate, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/associate/create', associate);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteSubfolderAssociate = createAsyncThunk(
    'folders/deleteSubfolderAssociate',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/subfolder/associate/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addSubfolderBankGuarantee = createAsyncThunk(
    'folders/addSubfolderBankGuarantee',
    async (bankGuarantee, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/bankguarantee/create', bankGuarantee);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteSubfolderBankGuarantee = createAsyncThunk(
    'folders/deleteSubfolderBankGuarantee',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/subfolder/bankguarantee/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addSubfolderBudget = createAsyncThunk(
    'folders/addSubfolderBudget',
    async (budget, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/budget/create', budget);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteSubfolderBudget = createAsyncThunk(
    'folders/deleteSubfolderBudget',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/subfolder/budget/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
)

const addSubfolderStock = createAsyncThunk(
    'folders/addSubfolderStock',
    async (stock, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/stock/create', stock);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editSubfolderStock = createAsyncThunk(
    'folders/editSubfolderStock',
    async (stock, { rejectWithValue }) => {
        const response = await axios.put(`/subfolder/stock/edit/${stock.id}`, stock.data);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteSubfolderStock = createAsyncThunk(
    'folders/deleteSubfolderStock',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/subfolder/stock/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addSubfolderCompetitor = createAsyncThunk(
    'folders/addSubfolderCompetitor',
    async (competitor, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/competitor/create', competitor);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editSubfolderCompetitor = createAsyncThunk(
    'folders/editSubfolderCompetitor',
    async (competitor, { rejectWithValue }) => {
        const response = await axios.put(`/subfolder/competitor/edit/${competitor.id}`, competitor.data);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteSubfolderCompetitor = createAsyncThunk(
    'folders/deleteSubfolderCompetitor',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/subfolder/competitor/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    }
)

const addSubfolderExtraInfoOffer = createAsyncThunk(
    'folders/addSubfolderExtraInfoOffer',
    async (offer, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/extra_info_offer/create', offer);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editSubfolderExtraInfoOffer = createAsyncThunk(
    'folders/editSubfolderExtraInfoOffer',
    async ({ id, offer }, { rejectWithValue }) => {
        const response = await axios.put('/subfolder/extra_info_offer/edit/' + id, offer);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addSubfolderExtraInfoProspect = createAsyncThunk(
    'folders/addSubfolderExtraInfoProspect',
    async (prospect, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/extra_info_prospect/create', prospect);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editSubfolderExtraInfoProspect = createAsyncThunk(
    'folders/editSubfolderExtraInfoProspect',
    async ({ id, prospect }, { rejectWithValue }) => {
        const response = await axios.put('/subfolder/extra_info_prospect/edit/' + id, prospect);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

// const deleteSubfolderExtraInfoProspect = createAsyncThunk(
//     'folders/deleteSubfolderExtraInfoProspect',
//     async (id) => {
//         const response = await axios.delete('/subfolder/extra_info_prospect/delete/' + id);
//         if(response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
//         return response.data;
//     },
// );

const addSubfolderSubcontractor = createAsyncThunk(
    'folders/addSubfolderSubcontractor',
    async (subcontractor, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/subcontractor/create', subcontractor);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteSubfolderSubcontractor = createAsyncThunk(
    'folders/deleteSubfolderSubcontractor',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/subfolder/subcontractor/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const addSubfolderContract = createAsyncThunk(
    'folders/addSubfolderContract',
    async (contract, { rejectWithValue }) => {
        const response = await axios.post('/subfolder/contract/create', contract);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const editSubfolderContract = createAsyncThunk(
    'folders/editSubfolderContract',
    async (contract, { rejectWithValue }) => {
        const response = await axios.put('/subfolder/contract/edit/' + contract.id, contract.data);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

const deleteSubfolderContract = createAsyncThunk(
    'folders/deleteSubfolderContract',
    async (id, { rejectWithValue }) => {
        const response = await axios.delete('/subfolder/contract/delete/' + id);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);


const fetchSubfoldersInvoices = createAsyncThunk(
    'folders/fetchSubfoldersInvoices',
    async (data, { rejectWithValue }) => {
        const response = await axios.get(`/subfolder/invoices/${data.subfolder_id}`);
        if (response.data.status === ResponseStatus.ERROR) return rejectWithValue(response.data);
        return response.data;
    },
);

export const folderMethods = {
    fetchFolders,
    addFolder,
    deleteFolder,
    editFolder,
    deleteFolderKeywords,
    fetchSubfolders,
    fetchSubfolder,
    addSubfolder,
    editSubfolder,
    deleteSubfolder,
    addSubfolderAssociate,
    deleteSubfolderAssociate,
    addSubfolderBankGuarantee,
    deleteSubfolderBankGuarantee,
    addSubfolderStock,
    editSubfolderStock,
    deleteSubfolderStock,
    addSubfolderBudget,
    deleteSubfolderBudget,
    addSubfolderCompetitor,
    deleteSubfolderCompetitor,
    addSubfolderExtraInfoOffer,
    editSubfolderExtraInfoOffer,
    addSubfolderExtraInfoProspect,
    editSubfolderExtraInfoProspect,
    addSubfolderSubcontractor,
    deleteSubfolderSubcontractor,
    fetchSubfoldersInvoices,
    editSubfolderCompetitor,
    addSubfolderContract,
    editSubfolderContract,
    deleteSubfolderContract,
}

const foldersSlice = createSlice({
    name: 'folders',
    initialState: [],
    extraReducers: {
        [fetchFolders.fulfilled]: (_state, action) => action.payload.value,
        [addFolder.fulfilled]: (state, action) => [...state, action.payload.value],
        [deleteFolder.fulfilled]: (state, action) => state.filter(folder => folder.id !== action.meta.arg),
        [editFolder.fulfilled]: (state, action) => state.map(folder => folder.id === action.meta.arg.id ? action.payload.value : folder),
        [deleteFolderKeywords.fulfilled]: (state, action) => state.map(folder => folder.id === action.meta.arg.id ? {
            ...folder,
            folder_keywords: folder.folder_keywords.filter(keyword => !action.payload.value.includes(keyword.label)),
        } : folder),
        [fetchSubfolders.fulfilled]: (state, action) => state.map(folder => folder.id === action.meta.arg ? ({ ...folder, subfolders: action.payload.value }) : folder),
        [fetchSubfolder.fulfilled]: (state, action) => state.map(folder => {
            if (folder.id !== action.payload.value.folder_id) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => subfolder.id === action.meta.arg ? action.payload.value : subfolder),
            };
        }),
        [fetchSubfoldersInvoices.fulfilled]:(state,action)=>state.map(folder => {
            if(folder.id !== +action.meta.arg.folder_id)return folder
            
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder =>{
                    if(subfolder.id !== +action.meta.arg.subfolder_id)return subfolder
                    return {
                        ...subfolder,
                        ...action.payload.value
                    }
                }),
            };
        }),
        [deleteSubfolder.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            const prevLength = folder.subfolders.length;
            let subfolders = [...folder.subfolders]
            if(action.payload.status === "success")subfolders = subfolders.filter(subfolder => subfolder.id !== action.meta.arg);
            return prevLength > subfolders.length ? ({ ...folder, subfolders }) : folder;
        }),
        [addSubfolder.fulfilled]: (state, action) => state.map(folder => {
            if (folder.id !== action.payload.value.folder_id) return folder;
            const subfolders = folder.subfolders ?? [];
            return { ...folder, subfolders: [...subfolders, action.payload.value] };
        }),
        [editSubfolder.fulfilled]: (state, action) => state.map(folder => {
            if (folder.id !== action.payload.value.folder_id) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => subfolder.id === action.meta.arg.id ? action.payload.value : subfolder),
            };
        }),
        [addSubfolderAssociate.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_associates: [...subfolder.subfolder_associates, action.payload.value],
                    };
                }),
            };
        }),
        [deleteSubfolderAssociate.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (!subfolder.subfolder_associates) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_associates: subfolder.subfolder_associates.filter(associate => associate.id !== action.meta.arg),
                    };
                }),
            }
        }),
        [addSubfolderBankGuarantee.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_bank_guarantees: [...subfolder.subfolder_bank_guarantees, action.payload.value],
                    };
                }),
            };
        }),
        [deleteSubfolderBankGuarantee.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (!subfolder.subfolder_bank_guarantees) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_bank_guarantees: subfolder.subfolder_bank_guarantees.filter(bankGuarantee => bankGuarantee.id !== action.meta.arg),
                    };
                }),
            }
        }),
        [addSubfolderBudget.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_budgets: [...subfolder.subfolder_budgets, action.payload.value],
                    };
                }),
            };
        }),
        [deleteSubfolderBudget.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (!subfolder.subfolder_budgets) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_budgets: subfolder.subfolder_budgets.filter(budget => budget.id !== action.meta.arg),
                    };
                }),
            };
        }),
        [addSubfolderStock.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_stocks: [...subfolder.subfolder_stocks, action.payload.value],
                    };
                }),
            };
        }),
        [editSubfolderStock.fulfilled]:(state,action)=>state.map(folder=>{
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_stocks: subfolder.subfolder_stocks.map(subfolderStock=>{
                            if(subfolderStock.id !== action.payload.value.id)return subfolderStock;
                            return action.payload.value
                        })
                    };
                }),
            };
        }),
        [deleteSubfolderStock.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (!subfolder.subfolder_stocks) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_stocks: subfolder.subfolder_stocks.filter(stock => stock.id !== action.meta.arg),
                    };
                }),
            };
        }),
        [addSubfolderCompetitor.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_competitors: [...subfolder.subfolder_competitors, action.payload.value],
                    };
                }),
            };
        }),
        [deleteSubfolderCompetitor.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (!subfolder.subfolder_competitors) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_competitors: subfolder.subfolder_competitors.filter(competitor => competitor.id !== action.meta.arg),
                    };
                }),
            };
        }),
        [addSubfolderExtraInfoOffer.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_extra_info_offer: action.payload.value,
                    };
                }),
            };
        }),
        [editSubfolderExtraInfoOffer.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_extra_info_offer: action.payload.value,
                    };
                }),
            };
        }),
        [editSubfolderCompetitor.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_competitors: subfolder.subfolder_competitors.map(competitor => {
                            if (competitor.id !== action.meta.arg.id) return competitor;
                            return action.payload.value;
                        }),
                    };
                }),
            };
        }),
        [addSubfolderExtraInfoProspect.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_extra_info_prospect: action.payload.value,
                    };
                }),
            };
        }),
        [editSubfolderExtraInfoProspect.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_extra_info_prospect: action.payload.value,
                    };
                }),
            };
        }),
        // [deleteSubfolderExtraInfoProspect.fulfilled]: (state, action) => state.map(folder => {
        //     if(!folder.subfolders) return folder;
        //     return {
        //         ...folder,
        //         subfolders: folder.subfolders.map(subfolder => {
        //             if(subfolder.id !== action.meta.arg) return subfolder;
        //             return {
        //                 ...subfolder,
        //                 subfolder_extra_info_prospect: null,
        //             };
        //         }),
        //     };
        // }),
        [addSubfolderSubcontractor.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_subcontractors: [...subfolder.subfolder_subcontractors, action.payload.value],
                    };
                }),
            };
        }),
        [deleteSubfolderSubcontractor.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (!subfolder.subfolder_subcontractors) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_subcontractors: subfolder.subfolder_subcontractors.filter(subcontractor => subcontractor.id !== action.meta.arg),
                    };
                })
            };
        }),
        [addSubfolderContract.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolder_id) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_contracts: [...subfolder.subfolder_contracts, action.payload.value],
                    };
                }),
            };
        }),
        [editSubfolderContract.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (subfolder.id !== action.meta.arg.subfolderId) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_contracts: subfolder.subfolder_contracts.map(contract => {
                            if (contract.id !== action.meta.arg.id) return contract;
                            return action.payload.value;
                        }),
                    };
                }),
            };
        }),
        [deleteSubfolderContract.fulfilled]: (state, action) => state.map(folder => {
            if (!folder.subfolders) return folder;
            return {
                ...folder,
                subfolders: folder.subfolders.map(subfolder => {
                    if (!subfolder.subfolder_contracts) return subfolder;
                    return {
                        ...subfolder,
                        subfolder_contracts: subfolder.subfolder_contracts.filter(contract => contract.id !== action.meta.arg),
                    };
                })
            };
        }),
    },
});

export default foldersSlice;