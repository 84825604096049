// material ui icon delete
import { useTheme } from "@material-ui/core";
import { IconButton } from '@material-ui/core';
import { MdModeEdit, MdDelete } from 'react-icons/md';

const ButtonDeleteTask = ({OnClickFunction}) => {
   const palette = useTheme().palette; 
    // render delete icon
    return (
        <IconButton onClick={event => { event.stopPropagation(); OnClickFunction()}} style={{padding:'0px'}}>
            <MdDelete className="wbs-add-task-button" style={{fontSize:"25px",color:palette.secondary.main, marginRight:"10px"}}/>
        </IconButton>
    );
}


export default ButtonDeleteTask;