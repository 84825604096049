import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

const fetchWbs = createAsyncThunk(
    'wbs/fetchWbs',
    async (wbsId) => {
        const response = await axios.get(`/wbs/${wbsId}`);
        return response.data;
    },
);


const addTask = createAsyncThunk(
    'wbs/addWbs',
    async (wbs) => {
        const response = await axios.post('/wbs/task/create', wbs.wbstask);
        if (response.data.status === 'error') throw response.data.message;
        return response.data;
    },
);

const editTask = createAsyncThunk(
    'wbs/editTask',
    async (wbs) => {
        console.log('edit task wbs.id : ', wbs.id);
        const response = await axios.put(`/wbs/task/edit/${wbs.id}`, wbs.task);
        if (response.data.status === 'error') throw response.data.message;
        return response.data;
    },
);

const editTaskMulti = createAsyncThunk(
    'wbs/editTaskMulti',
    async (tasks) => {
        const response = await axios.put(`/wbs/task/editMulti`, tasks);
        if (response.data.status === 'error') throw response.data.message;
        return response.data;
    },
);

const addTaskPlanning = createAsyncThunk(
    'wbs/addTaskPlanning',
    async (taskPlanning) => {
        const response = await axios.post('/wbs/task_planning/create', taskPlanning.taskPlanning);
        if (response.data.status === 'error') throw response.data.message;
        return response.data;
    },
);

const editTaskPlanning = createAsyncThunk(
    'wbs/editTaskPlanning',
    async (taskPlanning) => {
        const response = await axios.put(`/wbs/task_planning/edit/${taskPlanning.taskPlanningId}`, taskPlanning.taskPlanning);
        if (response.data.status === 'error') throw response.data.message;
        return response.data;
    },
);
/* const deleteWbs = createAsyncThunk(
    'wbs/deleteWbs',
    async (wbsId) => {
        const response = await axios.delete('/wbs/delete/' + wbsId);
        if (response.data.status === 'error') throw response.data.value;
        return response.data;
    },
); */
export const wbsMethods = {
    editTaskPlanning,
    addTaskPlanning,
    addTask,
    fetchWbs,
    editTask,
    editTaskMulti,
  /*   deleteWbs */
}

const wbsSlice = createSlice({
    name: 'wbs',
    initialState: {},
    extraReducers: {
        [fetchWbs.fulfilled]: (_state, action) => action.payload,
        [addTask.fulfilled]: (_state, action) => action.payload,
       /*  [deleteWbs.fulfilled]: (state, action) => state.filter(wb => wb.id !== action.meta.args), */
    },
});



export default wbsSlice;