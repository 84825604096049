import { useEffect, useState, useContext } from 'react'
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import CompaniesConsult from '../components/companies/CompaniesConsult';
import CompaniesCreate from '../components/companies/CompaniesCreate';
import CompaniesEdit from '../components/companies/CompaniesEdit';
import CompaniesExplorer from '../components/companies/CompaniesExplorer';

import { connect, mapStateToProps, mapDispatchToProps } from '../store/dispatchers';

import LoadingFallback from './LoadingFallback';
import UserContext from '../context/user';


const RoutesWithCompany = connect(mapStateToProps)(({ companies }) => {
    const { id } = useParams();
    const { hasPermission } = useContext(UserContext);

    if(!companies.some(company => company.id.toString() === id)) {
        return <Redirect to="/companies"/>;
    }

    return (
        <Switch>
            <Route exact path="/companies/:id" component={CompaniesConsult}/>
            {hasPermission('company.update') ? <Route exact path="/companies/:id/edit" component={CompaniesEdit}/> : null }
        </Switch>
    );
});

const Companies = ({companies,fetchCompanies}) => {

    const [companiesIsEmpty,setCompaniesIsEmpty] = useState(true);
    const { hasPermission } = useContext(UserContext);

    useEffect(() => {
        if(companies.length !== 0)setCompaniesIsEmpty(false);
        fetchCompanies().then(()=>setCompaniesIsEmpty(false))
        // eslint-disable-next-line
    }, []);

    if(companiesIsEmpty)return (
        <LoadingFallback/>
    )

    return (
        <Box className="page">
            <Switch>
                <Route exact path="/companies" component={CompaniesExplorer}/>
                {hasPermission('company.write') ? <Route exact path="/companies/create" component={CompaniesCreate}/> : null }
                <Route path="/companies/:id" component={RoutesWithCompany}/>
            </Switch>
        </Box>
    );
};

export default connect(mapStateToProps,mapDispatchToProps)(Companies);