import store from '../store';

/**
 * Checks that a `role` has the given `permission`.
 * 
 * @param {string} role The role to check.
 * @param {string} permission The permission (type.method) to check for the `role`.
 * @param {{role:{permissions:['type.method']}}} roles The roles with their permissions.
 * @returns {boolean} Whether or not the `role` has those `permission`.
 */
export const checkPermissions = (role, permission, roles = store.getState().roles) => {
    if(roles[role]){
        let permSplit = permission.split('.');
        const permPossibilities = [ permission, permSplit[0] + '.*', '*' ];
        if(roles[role].permissions.some(p => permPossibilities.includes(p))) {
            return true;
        }
    }
    return false;
}
